<template>
  <div v-if="!loading && testimonials.length" class="testimonials-slider">
    <a
      href="#"
      class="testimonials-slider__nav testimonials-slider__nav--prev"
      @click.prevent="previous"
    >
      <IconChevronLeft width="40" height="40" />
    </a>
    <flickity
      ref="flickity"
      :options="flickityOptions"
      class="testimonials-slider__inner"
    >
      <div
        v-for="testimonial in testimonials"
        class="carousel-cell testimonials-slider__testimonial"
      >
        <div class="testimonials-slider__testimonial__rating">
          <IconStar />
          <IconStar />
          <IconStar />
          <IconStar />
          <IconStar />
        </div>
        <h4 class="testimonials-slider__testimonial__text">
          {{ testimonial.text }}
        </h4>
        <h6 class="testimonials-slider__testimonial__author">
          {{ testimonial.author }}
        </h6>
      </div>
    </flickity>
    <a
      href="#"
      class="testimonials-slider__nav testimonials-slider__nav--next"
      @click.prevent="next"
    >
      <IconChevronRight width="40" height="40" />
    </a>
  </div>
</template>
<script>
import Flickity from 'vue-flickity';
import IconChevronLeft from '~/components/icons/IconChevronLeft';
import IconChevronRight from '~/components/icons/IconChevronRight';
import IconStar from '~/components/icons/IconStar';
import Partner from '~/models/Partner';
import Testimonial from '~/models/Testimonial';

export default {
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
  components: {
    IconStar,
    IconChevronRight,
    IconChevronLeft,
    Flickity,
  },
  data() {
    return {
      loading: true,
      testimonials: [],
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        adaptiveHeight: true,
      },
    };
  },
  async created() {
    this.loading = true;
    try {
      const partner = new Partner(this.partner);
      this.testimonials = await new Testimonial().for(partner).$get();
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    previous() {
      this.$refs.flickity.previous();
    },
    next() {
      this.$refs.flickity.next();
    },
  },
};
</script>
<style lang="scss">
.testimonials-slider-wrapper {
}
.testimonials-slider {
  background-color: rgba($white, 0.7);
  border-radius: $border-radius-lg;
  padding: $s-32 $s-16;
  display: flex;
  position: relative;

  &__inner {
    margin: 0 auto;
    max-width: 657px;
    width: 100%;
  }

  &__nav {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: rgba($gray-800, 0.25);
    transition: color 0.15s ease-out;
    padding: $s-2;

    @at-root {
      .no-touchevents {
        .testimonials-slider__nav:hover {
          color: rgba($gray-800, 0.5);
        }
      }
    }

    &--prev {
      left: $s-16;
    }

    &--next {
      right: $s-16;
    }
  }

  &__testimonial {
    width: 100%;
    text-align: center;
    &__rating {
      color: $accent-purple;
    }

    &__text {
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;
      color: $gray-800;
      margin-top: $s-8;
    }

    &__author {
      font-size: 16px;
      line-height: 24px;
      color: rgba($gray-800, 0.5);
      margin-top: $s-8;
    }
  }

  .carousel-cell {
    text-align: center;

    .rating {
    }
  }
}

@include media-breakpoint-down(sm) {
  .testimonials-slider {
    border-radius: 0;
    margin: 0 -#{$container-padding};
    padding: $s-16 $s-16;

    &__nav {
      &--prev {
        left: 0;
      }

      &--next {
        right: 0;
      }
    }

    &__testimonial {
      &__text {
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
</style>
