<template>
  <Transition name="slideout" appear>
    <div class="slideout">
      <slot />
      <div class="slideout__close-wrapper">
        <a href="#" class="slideout__close" @click.prevent="$emit('close')">
          <IconDropUp />
        </a>
      </div>
    </div>
  </Transition>
</template>
<script>
import IconDropUp from '~/components/icons/IconDropUp';

export default {
  components: {
    IconDropUp,
  },
};
</script>
<style lang="scss">
.slideout {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: $z-index-slideout;

  &__close-wrapper {
    display: flex;
    justify-content: center;
    margin: 0;
  }

  &__close {
    padding: $s-2;
    color: rgba($white, 0.5);
  }
}
.slideout-enter-active,
.slideout-leave-active {
  transition: transform 0.15s ease-out;
}

.slideout-enter {
  transform: translateY(-100%);
}

.slideout-leave-to {
  transform: translateY(-100%);
}
</style>
