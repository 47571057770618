import { render, staticRenderFns } from "./AuthBox.vue?vue&type=template&id=799b3965&"
var script = {}
import style0 from "./AuthBox.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.thatsthe1.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('799b3965')) {
      api.createRecord('799b3965', component.options)
    } else {
      api.reload('799b3965', component.options)
    }
    module.hot.accept("./AuthBox.vue?vue&type=template&id=799b3965&", function () {
      api.rerender('799b3965', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/pages/auth/AuthBox.vue"
export default component.exports