<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M3 7v2H2v7h20V9h-1V7h2a1 1 0 011 1v11a1 1 0 01-1 1H13v2h4a1 1 0 110 2H7a1 1 0 110-2h4v-2H1a1 1 0 01-1-1V8a1 1 0 011-1h2zm15-7a1 1 0 011 1v13H5V1a1 1 0 011-1z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
