<template>
  <div class="layout layout-dashboard" :class="classes">
    <DashboardDrawer
      :open="drawerOpen"
      :condensed="drawerCondensed && breakpoints.is === 'xl'"
      @clickaway="onDrawerClickaway"
      @toggle-condensed="toggleDrawerCondensed"
    />
    <DashboardHeader v-if="breakpoints.is !== 'xl'" @toggle-open="toggleOpen" />
    <IncompleteProfileBanner />
    <div class="layout-dashboard__content">
      <slot />
    </div>
    <ConfirmModal />
    <PortalTarget name="modals" multiple />
  </div>
</template>
<script>
import breakpoints from '~/plugins/breakpoints.js';
import DashboardDrawer from '~/layouts/partials/DashboardDrawer';
import ConfirmModal from '~/components/confirm-modal/ConfirmModal';
import PersistentStorage from '~/persistent-storage';
import DashboardHeader from '~/layouts/partials/DashboardHeader';
import IncompleteProfileBanner from '~/components/IncompleteProfileBanner';

export default {
  components: {
    DashboardHeader,
    DashboardDrawer,
    ConfirmModal,
    IncompleteProfileBanner,
  },
  data() {
    return {
      drawerOpen: breakpoints.is === 'xl',
      drawerCondensed: false,
      breakpoints,
    };
  },
  created() {
    let drawerStatus = PersistentStorage.getDrawerStatus();
    this.drawerCondensed = drawerStatus && drawerStatus === 'condensed';
  },
  mounted() {
    this.renderMessages(this.$global.flash);
  },
  computed: {
    classes() {
      let classes = [];

      if (this.drawerCondensed) {
        classes.push('layout-dashboard--drawer-condensed');
      }

      return classes;
    },
  },
  methods: {
    renderMessages(messages) {
      Object.keys(messages).forEach((type) => {
        let message = messages[type];
        this.$toasted.show(message, {
          type,
        });
      });
    },
    onDrawerClickaway() {
      if (breakpoints.is === 'xl') {
        return;
      }

      if (!this.drawerOpen) {
        return;
      }

      this.toggleOpen();
    },
    toggleOpen() {
      this.drawerOpen = !this.drawerOpen;
    },
    toggleDrawerCondensed() {
      this.drawerCondensed = !this.drawerCondensed;
      PersistentStorage.setDrawerStatus(
        this.drawerCondensed ? 'condensed' : 'not-condensed'
      );
    },
  },
};
</script>
<style lang="scss">
.layout-dashboard {
  background-color: $gray-100;
  overflow: hidden;
  min-height: 100%;
  margin-left: $drawer-desktop-width;

  &__content {
    width: 100%;
    transition: width 0.15s ease-out, margin-left 0.15s ease-out;
    padding: 30px 25px;
    min-height: 100vh;
  }

  &--drawer-condensed {
    margin-left: $drawer-desktop-width-condensed;
  }
}

@include media-breakpoint-down(lg) {
  .layout-dashboard {
    margin-left: 0;

    &__content {
      padding: 0 0 30px 0;
    }
  }
}
</style>
