var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("LineChart", {
    ref: "lineChart",
    attrs: {
      "chart-data": _vm.chartData,
      color: _vm.primaryChartColor,
      options: _vm.chartOptions,
      styles: _vm.chartStyles
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }