import Model from '~/models/Model';
import ConnectionStatusEnum from '~/enums/ConnectionStatusEnum';

export default class Connection extends Model {
  resource() {
    return 'connections';
  }

  isAccepted() {
    return this.status === ConnectionStatusEnum.ACCEPTED;
  }

  statusClassName() {
    switch (this.status) {
      case ConnectionStatusEnum.PENDING:
        return 'pending';
      case ConnectionStatusEnum.ACCEPTED:
        return 'connected';
      case ConnectionStatusEnum.DECLINED:
        return 'declined';
    }
  }

  statusLabel() {
    switch (this.status) {
      case ConnectionStatusEnum.PENDING:
        return 'Pending';
      case ConnectionStatusEnum.ACCEPTED:
        return 'Connected';
      case ConnectionStatusEnum.DECLINED:
        return 'Declined';
    }
  }
}
