<template>
  <StorePageWrapper :type="type" :business="business">
    <div class="store__container">
      <PartnerListing :business="business" />
    </div>
  </StorePageWrapper>
</template>
<script>
import StorePageWrapper from '~/pages/store/StorePageWrapper';
import PartnerListing from '~/pages/store/partials/PartnerListing';
import StorePageMixin from '~/pages/store/StorePageMixin';

export default {
  mixins: [StorePageMixin],
  props: {
    agent_id: {
      validator: (prop) => typeof prop === 'number' || prop === null,
      required: true,
    },
  },
  components: {
    PartnerListing,
    StorePageWrapper,
  },
  async created() {
    try {
      this.business = await this.fetchBusiness();
    } catch (e) {
      this.$axios.handleError(e);
    }
  },
  mounted() {
    if (this.agent_id) {
      window.sessionStorage.setItem('from-agent-link', this.agent_id);
    }
  },
};
</script>
