import { hash } from '~/utils';
import Money from '~/money';
import Price from '~/price';
import PersistentStorage from '~/persistent-storage';

const getItemId = (product, options) => {
  let id = `${product.id}`;

  if (options.color) {
    id = `${id}_${options.color}`;
  }
  if (options.answer) {
    id = `${id}_${hash(options.answer)}`;
  }

  return id;
};

const state = () => ({
  items: PersistentStorage.getCartItems() || [],
  vatRate: null,
});

const getters = {
  itemsByCompany(state) {
    return state.items.reduce((byCompany, item) => {
      if (!byCompany[item.product.company_id]) {
        byCompany[item.product.company_id] = [];
      }

      byCompany[item.product.company_id].push(item);

      return byCompany;
    }, {});
  },
  companyIds(state, getters) {
    return Object.keys(getters.itemsByCompany).map((id) => parseInt(id));
  },
  getItemsForCompany: (state, getters) => (id) => {
    return getters.itemsByCompany[id];
  },
  findById: (state) => (id) => {
    return state.items.find((i) => i.id === id);
  },
  itemNetSubtotal: (state) => (item) => {
    return Price(item.product.price, state.vatRate).net().multiply(item.qty);
  },
  itemGrossSubtotal: (state) => (item) => {
    return Price(item.product.price, state.vatRate).gross().multiply(item.qty);
  },
  deliveryCostForCompany: (state, getters) => (id) => {
    const items = getters.getItemsForCompany(id);

    return items.reduce((max, item) => {
      const deliveryCost = Money(item.product.delivery_cost);
      if (deliveryCost.greaterThan(max)) {
        max = deliveryCost;
      }
      return max;
    }, Money({ amount: 0 }));
  },

  netTotalForCompany: (state, getters) => (id) => {
    const items = getters.getItemsForCompany(id);

    const productsTotal = items.reduce((total, item) => {
      return total.add(getters.itemNetSubtotal(item));
    }, Money({ amount: 0 }));

    const deliveryCost = getters.deliveryCostForCompany(id);

    return productsTotal.add(deliveryCost);
  },
  vatAmountForCompany: (state, getters) => (id) => {
    const netTotal = getters.netTotalForCompany(id);

    return netTotal.multiply(state.vatRate);
  },
  grossTotalForCompany: (state, getters) => (id) => {
    const netTotal = getters.netTotalForCompany(id);
    const vatAmount = getters.vatAmountForCompany(id);

    return netTotal.add(vatAmount);
  },
  deliveryCost(state, getters) {
    const companyIds = getters.companyIds;
    let sum = Money({ amount: 0 });
    companyIds.forEach((id) => {
      sum = sum.add(getters.deliveryCostForCompany(id));
    });

    return sum;
  },
  grossDeliveryCost(state, getters) {
    let deliveryCost = getters.deliveryCost;
    const vatAmount = deliveryCost.multiply(state.vatRate);

    return deliveryCost.add(vatAmount);
  },
  netTotal(state, getters) {
    const companyIds = getters.companyIds;
    let sum = Money({ amount: 0 });
    companyIds.forEach((id) => {
      sum = sum.add(getters.netTotalForCompany(id));
    });

    return sum;
  },
  grossTotal(state, getters) {
    const companyIds = getters.companyIds;
    let sum = Money({ amount: 0 });
    companyIds.forEach((id) => {
      sum = sum.add(getters.grossTotalForCompany(id));
    });

    return sum;
  },
  cartCount(state) {
    return state.items.reduce((count, item) => {
      return count + item.qty;
    }, 0);
  },
  cartPayload(state, getters) {
    const companyIds = getters.companyIds;
    return {
      total: {
        amount: getters.grossTotal.getAmount(),
        currency: getters.grossTotal.getCurrency(),
      },
      companies_carts: companyIds.map((id) => {
        return {
          company_id: id,
          items: getters.getItemsForCompany(id).map((item) => ({
            product_id: item.product.id,
            qty: item.qty,
            options: item.options,
          })),
        };
      }),
    };
  },
};

const mutations = {
  setItems(state) {
    state.items = PersistentStorage.getCartItems() || [];
  },
  setVatRate(state, vatRate) {
    state.vatRate = vatRate;
  },
  add(state, item) {
    state.items.push(item);
    PersistentStorage.setCartItems(state.items);
  },
  update(state, { id, attributes }) {
    let index = state.items.findIndex((i) => i.id === id);

    let item = state.items[index];

    state.items.splice(index, 1, {
      ...item,
      ...attributes,
    });
    PersistentStorage.setCartItems(state.items);
  },
  remove(state, id) {
    let index = state.items.findIndex((i) => i.id === id);
    state.items.splice(index, 1);
    PersistentStorage.setCartItems(state.items);
  },
  clear(state) {
    state.items = [];
    PersistentStorage.setCartItems(state.items);
  },
};

const actions = {
  add({ state, getters, commit }, { product, qty, options }) {
    let id = getItemId(product, options);

    let item = getters.findById(id);

    if (item) {
      // Update current item qty.
      commit('update', {
        id,
        attributes: {
          qty: item.qty + qty,
        },
      });
    } else {
      commit('add', {
        id,
        product,
        qty,
        options,
      });
    }
  },
  updateQty({ commit }, { id, qty }) {
    commit('update', {
      id,
      attributes: {
        qty,
      },
    });
  },
  remove({ commit }, id) {
    commit('remove', id);
  },
  clear({ commit }) {
    commit('clear');
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
