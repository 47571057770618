var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass: "ladda-button",
      class: _vm.classes,
      attrs: {
        type: _vm.type,
        "data-style": _vm.dataStyle,
        disabled: _vm.disabled
      },
      on: { click: _vm.handleClick }
    },
    [
      _c(
        "span",
        { staticClass: "ladda-label" },
        [_vm._t("default", [_vm._v("Submit")])],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }