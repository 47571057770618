var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Dashboard" } },
    [
      _c("MetricSection", { attrs: { title: "Overview" } }, [
        _c(
          "div",
          { staticClass: "metric-section__tiles" },
          [
            _c("MetricValue", { attrs: { title: "Sales", endpoint: "sales" } }),
            _vm._v(" "),
            _c("MetricValue", {
              attrs: {
                title: "Company Commission",
                endpoint: "totalPartnersCommission"
              }
            }),
            _vm._v(" "),
            _c("MetricValue", {
              attrs: { title: "Platform Commission", endpoint: "appFee" }
            }),
            _vm._v(" "),
            _c("MetricValue", {
              attrs: {
                title: "Total Products Sold",
                format: "integer",
                endpoint: "orderItemsCount"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "MetricSection",
        {
          attrs: {
            title: "Analytics",
            link: { href: _vm.route("admin.sales"), title: "All Sales" }
          }
        },
        [
          _c("MetricTrend", {
            attrs: {
              endpoint: "analytics",
              "has-service-filter": "",
              "has-category-filter": "",
              options: [
                { label: "Sales", value: "sales" },
                { label: "Commission", value: "commission" }
              ]
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "metric-row" }, [
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              {
                attrs: {
                  title: "Partners",
                  link: {
                    href: _vm.route("admin.partners"),
                    title: "All Partners"
                  }
                }
              },
              [
                _c("MetricPerformance", {
                  attrs: {
                    options: [
                      { label: "Sales", value: "sales" },
                      { label: "Commission", value: "commission" }
                    ],
                    endpoint: "topPartners"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              { attrs: { title: "Categories" } },
              [
                _c("MetricPerformance", {
                  attrs: {
                    options: [
                      { label: "Sales", value: "sales" },
                      { label: "Commission", value: "commission" }
                    ],
                    endpoint: "topCategories"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "metric-row" }, [
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              { attrs: { title: "Onboarding Status" } },
              [
                _c("MetricSingleStatus", {
                  attrs: { endpoint: "onboardingStatus" }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              { attrs: { title: "User Status" } },
              [
                _c("MetricTotalBreakdown", {
                  attrs: { endpoint: "userStatus" }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }