var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Transition", { attrs: { name: "slideout", appear: "" } }, [
    _c(
      "div",
      { staticClass: "slideout" },
      [
        _vm._t("default"),
        _vm._v(" "),
        _c("div", { staticClass: "slideout__close-wrapper" }, [
          _c(
            "a",
            {
              staticClass: "slideout__close",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("close")
                }
              }
            },
            [_c("IconDropUp")],
            1
          )
        ])
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }