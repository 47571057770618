<template>
  <ValidationProvider
    ref="provider"
    class="form-group"
    tag="div"
    :vid="vid"
    :name="name"
    v-slot="{ errors }"
  >
    <label class="v-checkbox">
      <input
        type="checkbox"
        :value="value"
        :checked="checked"
        @change="updateInput"
      />
      <span class="v-checkbox__wrapper">
        <span class="v-checkbox__icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
            <path d="M4 11L0 7l1.5-1.5L4 8l6-6 1.5 1.5L4 11z" />
          </svg>
        </span>
        <span class="v-checkbox__label">
          <slot />
        </span>
      </span>
    </label>
    <span v-if="errors[0]" class="form-group__error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from 'vee-validate';

export default {
  components: {
    ValidationProvider,
  },
  model: {
    prop: 'modelValue',
    event: 'change',
  },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    name: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [Boolean, Number],
      default: undefined,
    },
    value: {
      type: [Boolean, Number],
      required: true,
    },
  },

  computed: {
    checked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }

      return typeof this.modelValue === 'string' ? true : !!this.modelValue;
    },
  },

  methods: {
    updateInput(event) {
      let isChecked = event.target.checked;

      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit('change', newValue);
      } else {
        this.$emit('change', !!isChecked);
      }
    },
  },
};
</script>
<style lang="scss">
.v-checkbox {
  margin-bottom: 0;
  position: relative;
  font-size: 16px;

  input {
    position: absolute;
    margin: 5px;
    padding: 0;
    /* for mobile accessibility (iOS Label Bug) */
    visibility: hidden;
  }

  &__wrapper {
    margin-bottom: 0;
    display: flex;
    align-items: center;
  }

  &__icon {
    position: relative;
    display: flex;
    width: 20px;
    height: 20px;
    border: 1px solid $gray-500;
    background-color: white;
    border-radius: $border-radius;
    transition: border-color 0.15s ease-out;
    margin-right: 10px;
    overflow: hidden;

    align-items: center;
    justify-content: center;

    svg {
      margin-left: 0;
      opacity: 0;
      transition: opacity 0.15s ease-out;
      z-index: 1;
      position: relative;
      fill: $white;
      width: 12px;
    }

    &:before {
      content: '';
      opacity: 0;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      height: 100%;
      background: $primary;
      transform: translateX(-50%) translateY(-50%);
      transition: opacity 0.15s ease-out;
    }
  }

  input[type='checkbox']:checked + span .v-checkbox__icon {
    border-color: $primary;

    svg {
      opacity: 1;
    }

    &:before {
      opacity: 1;
    }
  }

  &__label {
    a {
      text-decoration: underline;
    }
  }

  &--black {
    .v-checkbox {
      &__icon {
        &:before {
          background: $gray-800;
        }
      }

      input[type='checkbox']:checked + span .v-checkbox__icon {
        border-color: $gray-800;
      }
    }
  }
}
</style>
