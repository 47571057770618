<script>
import Chart from 'chart.js';
import { generateChart, mixins } from 'vue-chartjs';

Chart.defaults.LineWithLine = Chart.defaults.line;
Chart.controllers.LineWithLine = Chart.controllers.line.extend({
  draw: function (ease) {
    Chart.controllers.line.prototype.draw.call(this, ease);

    if (this.chart.tooltip._active && this.chart.tooltip._active.length) {
      let activePoint = this.chart.tooltip._active[0],
        ctx = this.chart.ctx,
        x = activePoint.tooltipPosition().x,
        y = activePoint.tooltipPosition().y,
        topY = this.chart.legend.bottom,
        bottomY = this.chart.chartArea.bottom;

      let color = hexToRgb(window.chartPrimaryColor);
      // draw line
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, bottomY + 14);
      ctx.lineWidth = 1;
      ctx.strokeStyle = `rgba(${color.r}, ${color.g}, ${color.b}, 0.25)`;
      ctx.stroke();
      ctx.restore();
    }
  },
});

function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

const CustomLine = generateChart('custom-line', 'LineWithLine');

export default {
  extends: CustomLine,
  mixins: [mixins.reactiveProp],
  props: ['chartData', 'options', 'color'],
  mounted() {
    window.chartPrimaryColor = this.color;
    this.renderChart(this.chartData, this.options);
  },
  methods: {
    getChartObject() {
      return this.$data._chart;
    },
  },
};
</script>
