<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M12 15a3 3 0 110 6 3 3 0 010-6zm0-6a8.94 8.94 0 016.364 2.636l.707.707-1.414 1.414-.707-.707A6.956 6.956 0 0012 11c-1.87 0-3.627.728-4.95 2.05l-.707.707-1.414-1.414.707-.707A8.94 8.94 0 0112 9zm0-6c4.007 0 7.774 1.561 10.606 4.394l.707.707-1.414 1.414-.707-.707A12.91 12.91 0 0012 5a12.91 12.91 0 00-9.192 3.808l-.707.707L.687 8.101l.707-.707A14.899 14.899 0 0112 3z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
