<template>
  <div class="store-similar">
    <Loader v-if="loading" />
    <template v-else>
      <div class="store-similar__top-bar">
        <h5 class="store-similar__top-bar__title">Similar products</h5>
      </div>
      <div class="store-similar__products">
        <div
          v-for="product in products"
          :key="product.id"
          class="store-similar__products__item"
        >
          <StoreProductCard
            :product="product"
            :get-product-url="getProductUrl"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import StoreProductCard from '~/pages/store/partials/StoreProductCard';
import Loader from '~/components/Loader';
import Product from '~/models/Product';

export default {
  components: {
    StoreProductCard,
    Loader,
  },
  props: {
    businessType: {
      type: String,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    product: {
      type: Product,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      products: [],
    };
  },
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      this.loading = true;
      try {
        this.products = await this.product.getSimilarProducts(this.business);
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },
    getProductUrl(product) {
      return this.route(`store.${this.businessType}.product`, {
        [this.businessType]: this.business.slug,
        product: product.slug,
      });
    },
  },
};
</script>
<style lang="scss">
.store-similar {
  &__top-bar {
    border-bottom: 1px solid $gray-500;
    padding: $s-3 0;

    &__title {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: rgba($black, 0.5);
    }
  }

  &__products {
    $gutter: 24px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter / 2;
    margin-left: -$gutter / 2;
    padding-top: $s-8;

    &__item {
      width: 100%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
      flex: 0 0 25%;
      max-width: 25%;
      margin-bottom: $s-12;
    }
  }
}

@include media-breakpoint-down(md) {
  .store-similar {
    &__products {
      &__item {
        flex: 0 0 33.3333%;
        max-width: 33.3333%;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .store-similar {
    &__products {
      $gutter: 16px;
      margin-right: -$gutter / 2;
      margin-left: -$gutter / 2;

      &__item {
        padding-right: $gutter / 2;
        padding-left: $gutter / 2;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
}
</style>
