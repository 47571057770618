var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout layout-marketing" },
    [
      _c("MarketingHeader"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "layout-marketing__content" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("PortalTarget", { attrs: { name: "modals", multiple: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }