<script>
import DatatableModel from '~/models/Datatable';
import ConnectionStatusEnum from '~/enums/ConnectionStatusEnum';
import Connections from '~/pages/dashboard/company/partials/connections/Connections';

export default {
  extends: Connections,
  methods: {
    getBaseQuery() {
      return DatatableModel.where('company_id', this.$global.userable.id).where(
        'status',
        ConnectionStatusEnum.ACCEPTED
      );
    },
  },
};
</script>
