<template>
  <div class="categories-table">
    <Datatable
      ref="datatable"
      :get-base-query="getBaseQuery"
      :query-custom-params="queryCustomParams"
    >
      <template v-slot:actions>
        <a href="#" class="btn btn--with-icon" @click="showModal = true">
          <IconAdd width="18" height="18" />
          <span>Add new</span>
        </a>
        <slot name="actions"></slot>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="table__row-actions">
          <a href="#" @click.prevent.stop="edit(item._meta)">
            <IconEdit />
          </a>
        </div>
      </template>
    </Datatable>
    <Portal to="modals">
      <CreateOrEditCategoryModal
        v-if="showModal"
        :model="categoryToEdit"
        @saved="handleSaved"
        @close="
          showModal = false;
          categoryToEdit = null;
        "
      />
    </Portal>
  </div>
</template>
<script>
import CreateOrEditCategoryModal from '~/pages/dashboard/admin/partials/CreateOrEditCategoryModal';
import Datatable from '~/components/datatable/Datatable';
import IconAdd from '~/components/icons/IconAdd';
import IconEdit from '~/components/icons/IconEdit';
import DatatableModel from '~/models/Datatable';

export default {
  components: {
    CreateOrEditCategoryModal,
    Datatable,
    IconAdd,
    IconEdit,
  },
  data() {
    return {
      queryCustomParams: {
        table_id: 'admin-categories',
      },
      categoryToEdit: null,
      showModal: false,
    };
  },
  methods: {
    getBaseQuery() {
      return new DatatableModel();
    },

    edit(category) {
      this.categoryToEdit = category;
      this.showModal = true;
    },

    handleSaved() {
      this.refresh();
    },
    refresh() {
      this.$refs.datatable.fetchResources(true);
    },
  },
};
</script>
