<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="form" @submit.prevent="handleSubmit(submit)">
      <div class="form-group">
        <ValidationProvider
          name="sectors_ids"
          vid="sectors_ids"
          v-slot="{ errors }"
        >
          <label>Sectors</label>
          <MultiplePillSelect
            v-model="form.sectors_ids"
            :loading="loadingSectors"
            :options="sectors"
            value-key="id"
            label-key="name"
            @input="$refs.form.reset()"
          />
          <span v-if="errors[0]" class="form-group__error">{{
            errors[0]
          }}</span>
        </ValidationProvider>
      </div>
      <LoadingBtn
        :loading="loading"
        :disabled="invalid"
        class="btn"
        type="submit"
      >
        Save
      </LoadingBtn>
    </form>
  </ValidationObserver>
</template>
<script>
import TabCompanyOrPartnerBase from '~/pages/dashboard/common/settings/TabCompanyOrPartnerBase';
import MultiplePillSelect from '~/components/form/MultiplePillSelect';
import ValidationProvider from '~/components/form/ValidationProvider';
import Sector from '~/models/Sector';

export default {
  extends: TabCompanyOrPartnerBase,
  components: {
    MultiplePillSelect,
    ValidationProvider,
  },
  data() {
    return {
      sectors: [],
      loadingSectors: false,
    };
  },
  async created() {
    await this.loadSectors();
  },
  methods: {
    getForm() {
      return {
        sectors_ids: this.userable.sectors.map((s) => s.id),
      };
    },
    async loadSectors() {
      this.loadingSectors = true;
      try {
        this.sectors = await Sector.params({ per_page: 10000 })
          .include('servicesCount')
          .$get();

        this.sectors = this.sectors.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loadingSectors = false;
      }
    },
  },
};
</script>
