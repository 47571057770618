import { formatMoney } from '~/utils';

const isHalf = (number) => {
  return Math.abs(number) % 1 === 0.5;
};

const isEven = (value) => {
  return value % 2 === 0;
};

// http://wiki.c2.com/?BankersRounding
const roundHalfEven = (number) => {
  const rounded = Math.round(number);
  return isHalf(number) ? (isEven(rounded) ? rounded : rounded - 1) : rounded;
};

const round = roundHalfEven;

const Money = ({ amount, currency }) => {
  amount = parseInt(amount);

  if (!currency) {
    currency = 'EUR';
  }

  return {
    getAmount() {
      return amount;
    },

    getCurrency() {
      return currency;
    },

    add(addend) {
      return Money({
        amount: amount + addend.getAmount(),
        currency: currency,
      });
    },

    subtract(subtrahend) {
      return Money({
        amount: amount - subtrahend.getAmount(),
        currency: currency,
      });
    },

    multiply(multiplier) {
      return Money({
        amount: round(amount * multiplier),
        currency: currency,
      });
    },

    divide(divider) {
      return Money({
        amount: round(amount / divider),
        currency: currency,
      });
    },

    greaterThan(money) {
      return amount > money.getAmount();
    },

    greaterThanOrEqual(money) {
      return amount >= money.getAmount();
    },

    lessThan(money) {
      return amount < money.getAmount();
    },

    lessThanOrEqual(money) {
      return amount <= money.getAmount();
    },

    format() {
      return formatMoney({ amount, currency });
    },
  };
};

export default Money;
