import { Model as BaseModel } from 'vue-api-query';
import { isEmptyObject } from '~/utils';

export default class Model extends BaseModel {
  baseURL() {
    return `${this.$http.defaults.baseURL}/v1`;
  }

  getUrl() {
    let base = this._fromResource || `${this.baseURL()}/${this.resource()}`;
    base = this._customResource
      ? `${this.baseURL()}/${this._customResource}`
      : base;
    return `${base}${this._builder.query()}`;
  }

  withHeaders(headers) {
    this.headers = headers;

    return this;
  }

  request(config) {
    if (this.headers) {
      config = { ...config, headers: this.headers };
    }

    return this.$http.request(config);
  }

  async firstOrNull() {
    let first = await this.first();

    if (isEmptyObject(first)) {
      return null;
    }

    return first;
  }

  getWithCancelToken(cancelToken) {
    let currentRequestFunc = this.request;

    this.request = (config) => {
      return this.$http.request({
        ...config,
        cancelToken: cancelToken,
      });
    };

    let promise = this.get();

    this.request = currentRequestFunc;

    return promise;
  }

  applyFilters(filters) {
    Object.keys(filters).forEach((key) => {
      let value = filters[key];
      if (value === undefined || value === '' || value === null) {
        return;
      }

      if (Array.isArray(filters[key])) {
        this.whereIn(key, filters[key]);
      } else {
        this.where(key, filters[key]);
      }
    });

    return this;
  }

  saveOnly(fields) {
    return this.hasId() ? this._updateOnly(fields) : this._createOnly(fields);
  }

  _updateOnly(fields) {
    return this.request({
      method: 'PUT',
      url: this.endpoint(),
      data: this._getOnlySpecificFields(fields),
    }).then((response) => {
      let self = Object.assign(this, response.data);
      return self;
    });
  }

  _createOnly(fields) {
    return this.request({
      method: 'POST',
      url: this.endpoint(),
      data: this._getOnlySpecificFields(fields),
    }).then((response) => {
      let self = Object.assign(this, response.data);
      return self;
    });
  }

  _getOnlySpecificFields(fields) {
    return Object.keys(this).reduce((obj, key) => {
      if (fields.includes(key)) {
        obj[key] = this[key];
      }
      return obj;
    }, {});
  }
}
