var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testimonial-form" },
    [
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  {
                    staticClass: "form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.submit)
                      }
                    }
                  },
                  [
                    _c("VInput", {
                      attrs: {
                        name: "Name",
                        vid: "author",
                        "info-text": "Displayed to brands on the marketplace"
                      },
                      model: {
                        value: _vm.form.author,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "author", $$v)
                        },
                        expression: "form.author"
                      }
                    }),
                    _vm._v(" "),
                    _c("VTextarea", {
                      attrs: { name: "Testimonial", vid: "text" },
                      model: {
                        value: _vm.form.text,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "text", $$v)
                        },
                        expression: "form.text"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "testimonial-form__actions" },
                      [
                        _c(
                          "LoadingBtn",
                          {
                            staticClass: "btn",
                            attrs: {
                              loading: _vm.saving,
                              disabled: invalid,
                              type: "submit"
                            }
                          },
                          [_vm._v("Save\n        ")]
                        ),
                        _vm._v(" "),
                        _vm.testimonial.id
                          ? _c(
                              "LoadingBtn",
                              {
                                staticClass: "btn btn--accent-red",
                                attrs: { loading: _vm.removing },
                                on: { click: _vm.remove }
                              },
                              [_vm._v("Remove\n        ")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.last
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn--secondary",
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    return _vm.$emit("add")
                                  }
                                }
                              },
                              [_vm._v("Add another")]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }