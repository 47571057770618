var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product.colors.length
    ? _c("div", { staticClass: "pd__colors-list" }, [
        _c("div", { staticClass: "pd__colors-list__title" }, [
          _vm._v("Available colours")
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pd__colors-list__samples" },
          _vm._l(_vm.product.colors, function(color) {
            return _c("span", {
              key: color,
              staticClass: "pd__colors-list__sample",
              style: { backgroundColor: "#" + color }
            })
          }),
          0
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }