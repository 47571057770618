<template>
  <div class="pd__accordion">
    <a href="#" class="pd__accordion__header" @click.prevent="toggle">
      <span>{{ title }}</span>
      <IconAdd v-if="!expanded" width="18" height="18" />
      <IconClose v-if="expanded" width="18" height="18" />
    </a>
    <div v-show="expanded" class="pd__accordion__body">
      <slot></slot>
    </div>
  </div>
</template>
<script>
import IconAdd from '~/components/icons/IconAdd';
import IconClose from '~/components/icons/IconClose';
export default {
  components: { IconClose, IconAdd },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggle() {
      this.expanded = !this.expanded;
    },
  },
};
</script>
<style lang="scss">
.pd__accordion {
  width: 100%;

  &__header {
    padding: $s-2 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-size: 18px;
      color: rgba($black, 0.5);
      line-height: 28px;
      font-weight: 500;
    }

    svg {
      color: rgba($black, 0.25);
    }
  }

  &__body {
    margin: $s-4 0;

    p {
      font-size: 14px;
      font-weight: 300;
      line-height: 20px;
      color: rgba($gray-800, 0.5);
    }
  }
}
</style>
