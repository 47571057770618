import Vue from 'vue';
import Axios from 'axios';
import cookies from 'axios/lib/helpers/cookies';
import getHandleError from '~/plugins/axios/get-handle-error';

const createAxiosInstance = (axiosOptions) => {
  // Create new axios instance
  const axios = Axios.create(axiosOptions);
  axios.CancelToken = Axios.CancelToken;
  axios.isCancel = Axios.isCancel;

  return axios;
};

export default (app) => {
  const baseURL = process.env.MIX_API_BASE_URL;
  const apiBaseURL = `${baseURL}/api`;
  const axiosOptions = {
    baseURL: apiBaseURL,
    xsrfCookieName: 'XSRF-TOKEN',
    xsrfHeaderName: 'X-XSRF-TOKEN',
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
    withCredentials: true,
  };

  const axios = createAxiosInstance(axiosOptions);

  axios.handleError = getHandleError();

  axios.getCsrfTokenHeader = () => ({
    [axiosOptions.xsrfHeaderName]:
      cookies.read(axiosOptions.xsrfCookieName) ?? undefined,
  });

  const key = '$axios';
  app[key] = axios;
  Vue.prototype[key] = axios;
};
