<template>
  <ModalWithSteps class="modal--with-steps" @modal-close="handleClose">
    <div class="modal__header">
      <h3 class="modal__title">Add a new product</h3>
      <h3 class="modal__title modal__steps__number">
        Step {{ currentStep }} of {{ totalSteps }}
      </h3>
      <button type="button" class="modal__close" @click="handleClose">
        <IconClose />
      </button>
    </div>
    <div class="modal__steps__progress">
      <div :style="{ width: `${progressPercentage}%` }"></div>
    </div>
    <div class="modal__body">
      <div v-show="currentStep === 1" class="modal__step">
        <ValidationObserver ref="formOne" v-slot="{ handleSubmit, invalid }">
          <form
            class="form"
            @submit.prevent="handleSubmit(() => validateStep(1))"
          >
            <VInput
              v-model="form.name"
              name="Product name"
              vid="name"
              rules="required|min:3"
            />
            <div class="form-2-col">
              <SectorField v-model="form.sector_id" filter-by-auth />
              <CategoryField
                v-model="form.category_id"
                :sector-id.sync="form.sector_id"
                filter-by-auth
              />
            </div>
            <VTextarea
              v-model="form.description"
              name="Description"
              vid="description"
              label-info-text="General description of this product - optional"
            />
            <VTextarea
              v-model="form.features"
              name="Features"
              vid="features"
              label-info-text="Specific features unique to this product - optional"
            />
            <div class="modal__step__actions">
              <LoadingBtn
                :loading="loadingStep[1]"
                :disabled="invalid"
                class="btn"
                type="submit"
              >
                Next
              </LoadingBtn>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div v-show="currentStep === 2" class="modal__step">
        <ValidationObserver ref="formTwo" v-slot="{ handleSubmit, invalid }">
          <form
            class="form"
            @submit.prevent="handleSubmit(() => validateStep(2))"
          >
            <div class="form-2-col">
              <VInput
                v-model="form.weight"
                name="Weight"
                vid="weight"
                label-info-text="- Optional"
              />
              <VInput
                v-model="form.dimensions"
                name="Dimensions"
                vid="dimensions"
                placeholder="Length x Width x Height"
                label-info-text="- Optional"
              />
            </div>
            <div class="form-2-col">
              <VInput
                v-model="form.size"
                name="Size"
                vid="size"
                label-info-text="- Only 1 size supported - optional"
              />
              <VInput
                v-model="form.colors_string"
                label="Available colours"
                name="Colours"
                vid="colors_string"
                label-info-text="- Hex values, comma separated - optional"
              />
            </div>
            <div class="form-2-col">
              <VInput
                v-model="form.sku"
                name="SKU code"
                vid="sku"
                rules="required"
                info-text="Existing SKU code for this product"
              />
              <VInput
                v-model="form.initial_stock_count"
                name="Stock count"
                vid="initial_stock_count"
                rules="required|integer|min:0"
                info-text="Quantity of this product available on platform"
              />
            </div>
            <div class="modal__step__actions">
              <a
                href="#"
                class="btn btn--secondary"
                @click.prevent="currentStep = 1"
                >Back</a
              >
              <LoadingBtn
                :loading="loadingStep[2]"
                :disabled="invalid"
                class="btn"
                type="submit"
              >
                Next
              </LoadingBtn>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div v-show="currentStep === 3" class="modal__step">
        <ValidationObserver ref="formThree" v-slot="{ handleSubmit, invalid }">
          <form
            class="form"
            @submit.prevent="handleSubmit(() => validateStep(3))"
          >
            <div class="form-2-col">
              <VMoney
                v-model="priceInMajorUnits"
                name="Price (€)"
                vid="price"
                rules="required|decimal|min_value:0.1"
                label-info-text="This price is excl. VAT"
              />
              <VMoney
                v-model="deliveryCostInMajorUnits"
                name="Delivery (€)"
                vid="delivery_cost"
                rules="required|decimal|min_value:0"
                label-info-text="Excl. VAT."
              />
            </div>
            <VPercent
              v-model="defaultCommissionPercentage"
              name="Default commission (%)"
              vid="default_commission_rate"
              rules="required|decimal|max_value:100|min_value:0"
              info-text="Commission rate based on product RRP"
            />
            <VTextarea
              v-model="form.commission_details"
              name="Commission details"
              vid="commission_details"
              label-info-text="Shown to partners in the marketplace."
            />
            <div class="modal__step__actions">
              <a
                href="#"
                class="btn btn--secondary"
                @click.prevent="currentStep = 2"
                >Back</a
              >
              <LoadingBtn
                :loading="loadingStep[3]"
                :disabled="invalid"
                class="btn"
                type="submit"
              >
                Next
              </LoadingBtn>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div v-show="currentStep === 4" class="modal__step">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <form class="form" @submit.prevent="handleSubmit(submit)">
            <VInput
              v-model="form.video_url"
              name="Product video URL"
              vid="video_url"
              rules="url"
              label-info-text="- Optional"
              info-text="Standard youtube and vimeo URLs supported. These videos are displayed to partners and customers."
            />
            <VInput
              v-model="form.question"
              name="Product question"
              vid="question"
              label-info-text="- Optional"
              info-text="Support for customisable products. Shown to customers on checkout. Answer received on the Receipt. 255 character limit."
            />
            <ValidationProvider name="images" vid="images" v-slot="{ errors }">
              <div class="form-group">
                <label
                  ><span>Add photos of the product</span
                  ><span class="optional-label"
                    >- Minimum 1 required. 880x880px jpeg for best results</span
                  ></label
                >
                <MultiUploader
                  :for="mediaFor"
                  :collection="MediaCollectionEnum.IMAGES"
                  :count="form.images.length"
                  :limit="imagesLimit"
                  @upload-success="onMediaAdded"
                />
                <MediaList
                  v-if="form.images.length"
                  :list="form.images"
                  @remove="handleRemoveMedia"
                />
                <span v-if="errors[0]" class="form-group__error">{{
                  errors[0]
                }}</span>
              </div>
            </ValidationProvider>
            <div class="modal__step__actions">
              <a
                href="#"
                class="btn btn--secondary"
                @click.prevent="currentStep = 3"
                >Back</a
              >
              <LoadingBtn
                :loading="loading"
                :disabled="invalid"
                class="btn"
                type="submit"
              >
                Add your product
              </LoadingBtn>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </ModalWithSteps>
</template>
<script>
import ProductModal from '~/components/product-modal/ProductModal';
import Product from '~/models/Product';
import ModalWithSteps from '~/components/ModalWithSteps';

export default {
  extends: ProductModal,
  components: {
    ModalWithSteps,
  },
  data() {
    return {
      loadingStep: {
        1: false,
        2: false,
        3: false,
      },
      totalSteps: 4,
      currentStep: 1,
    };
  },
  computed: {
    progressPercentage() {
      return (this.currentStep / this.totalSteps) * 100;
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        let product = new Product({
          ...this.form,
        });

        await product.save();

        this.$toasted.success('Product added!');
        this.$emit('created');
        this.handleClose();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    async validateStep(step) {
      this.loadingStep[step] = true;

      try {
        let endpoint = new Product().endpoint();
        await this.$axios.post(`${endpoint}/validate-step`, {
          ...this.form,
          step,
        });
        this.currentStep += 1;
      } catch (e) {
        let $form = this.getStepForm(step);
        this.$axios.handleError(e, $form);
      } finally {
        this.loadingStep[step] = false;
      }
    },

    getStepForm(step) {
      switch (step) {
        case 1:
          return this.$refs.formOne;
        case 2:
          return this.$refs.formTwo;
        case 3:
          return this.$refs.formThree;
      }
    },
  },
};
</script>
