var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      key: _vm.product.id,
      staticClass: "store-product-card",
      attrs: { href: _vm.getProductUrl(_vm.product) }
    },
    [
      _c("div", { staticClass: "store-product-card__image" }, [
        _c("img", {
          attrs: {
            src: _vm.product.image
              ? _vm.product.image.urls.medium
              : _vm.getProductPlaceholderImg(),
            alt: "",
            width: "640",
            height: "640"
          }
        })
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "store-product-card__business-name" },
        [
          _c("VClamp", { attrs: { autoresize: "", "max-lines": 1 } }, [
            _vm._v(_vm._s(_vm.product.company.business_name))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "store-product-card__name" },
        [
          _c("VClamp", { attrs: { autoresize: "", "max-lines": 1 } }, [
            _vm._v(_vm._s(_vm.product.name))
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn--black store-product-card__btn",
          class: { disabled: _vm.outOfStock },
          attrs: { href: _vm.getProductUrl(_vm.product) }
        },
        [
          _vm.outOfStock
            ? [_vm._v("\n      Out of stock\n    ")]
            : [_vm._v("View"), _c("span", [_vm._v(" product")])],
          _vm._v(
            " -\n    " +
              _vm._s(
                _vm
                  .Price(_vm.product.price, this.vatRate)
                  .gross()
                  .format()
              )
          )
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }