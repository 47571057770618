var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FieldWrapper", {
    attrs: {
      rules: _vm.rules,
      name: _vm.name,
      label: _vm.label,
      vid: _vm.vid,
      "label-info-text": _vm.labelInfoText,
      "info-text": _vm.infoText,
      disabled: _vm.disabled,
      "no-label": _vm.noLabel
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var inputId = ref.inputId
          var errors = ref.errors
          return [
            _c("div", { staticClass: "v-qty-input" }, [
              _vm.canDecrement
                ? _c(
                    "a",
                    {
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.decrement($event)
                        }
                      }
                    },
                    [
                      _c(
                        "svg",
                        {
                          attrs: {
                            xmlns: "http://www.w3.org/2000/svg",
                            viewBox: "0 0 10 2"
                          }
                        },
                        [_c("path", { attrs: { d: "M10 .5v1H0v-1z" } })]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentValue,
                    expression: "currentValue"
                  }
                ],
                staticClass: "form-control",
                class: { "is-invalid": errors[0] },
                attrs: {
                  id: inputId,
                  type: "text",
                  placeholder: _vm.placeholder,
                  name: _vm.vid,
                  disabled: _vm.disabled
                },
                domProps: { value: _vm.currentValue },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.currentValue = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.increment($event)
                    }
                  }
                },
                [
                  _c(
                    "svg",
                    {
                      attrs: {
                        xmlns: "http://www.w3.org/2000/svg",
                        viewBox: "0 0 10 10"
                      }
                    },
                    [
                      _c("path", {
                        attrs: {
                          d: "M5.5 0v4.5H10v1H5.5V10h-1V5.5H0v-1h4.5V0z"
                        }
                      })
                    ]
                  )
                ]
              )
            ])
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }