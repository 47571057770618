<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M24 19.288V21c0 1.657-3.134 3-7 3s-7-1.343-7-3v-1.712C11.597 20.361 14.049 21 17 21c2.951 0 5.403-.639 7-1.712zm-24-3C1.597 17.361 4.049 18 7 18c.341 0 .673-.011 1-.027v2.993c-.327.02-.66.034-1 .034-3.866 0-7-1.343-7-3zm24-2V16c0 1.657-3.134 3-7 3s-7-1.343-7-3v-1.712C11.597 15.361 14.049 16 17 16c2.951 0 5.403-.639 7-1.712zm-24-3C1.597 12.361 4.049 13 7 13c.341 0 .673-.011 1-.027v2.993c-.327.02-.66.034-1 .034-3.866 0-7-1.343-7-3zM17 8c3.866 0 7 1.343 7 3s-3.134 3-7 3-7-1.343-7-3 3.134-3 7-3zM0 6.288C1.597 7.361 4.049 8 7 8a17.81 17.81 0 002.92-.234c-1.21.84-1.905 1.937-1.917 3.2-.329.02-.662.034-1.003.034-3.866 0-7-1.343-7-3zM7 0c3.866 0 7 1.343 7 3s-3.134 3-7 3-7-1.343-7-3 3.134-3 7-3z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
