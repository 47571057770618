import UppyVuexModule from '~/components/upload/UppyVuexModule';

export default class UppyVuexStore {
  constructor(options) {
    this.id = options.id;
    this.store = options.store;

    this.moduleId = `uppy-${this.id}`;
    this.mutationName = 'update';
    this.mutationType = `${this.moduleId}/${this.mutationName}`;
    this.store.registerModule(this.moduleId, UppyVuexModule);
  }

  cleanUp() {
    this.store.unregisterModule(this.moduleId);
  }

  setState(patch) {
    this.store.commit(this.mutationType, patch);
  }

  getState() {
    return this.store.state[this.moduleId].uppy;
  }

  subscribe(cb) {
    let prevState = this.getState();
    return this.store.subscribe((mutation) => {
      if (mutation.type === this.mutationType) {
        const nextState = this.getState();
        if (prevState !== nextState) {
          const patch = getPatch(prevState, nextState);
          cb(prevState, nextState, patch);
          prevState = nextState;
        }
      }
    });
  }
}

function getPatch(prev, next) {
  const nextKeys = Object.keys(next);
  const patch = {};
  nextKeys.forEach((k) => {
    if (prev[k] !== next[k]) patch[k] = next[k];
  });
  return patch;
}
