var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "form-control-wrapper form-control-wrapper--with-info-icon"
      },
      [
        _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.onClickAway,
                expression: "onClickAway"
              }
            ],
            staticClass: "typeahead"
          },
          [
            _c(
              "div",
              { staticClass: "typeahead__input" },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.input,
                      expression: "input"
                    }
                  ],
                  attrs: { type: "text", autocomplete: "chrome-off" },
                  domProps: { value: _vm.input },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.input = $event.target.value
                      },
                      _vm.onInput
                    ]
                  }
                }),
                _vm._v(" "),
                _vm.loading ? _c("Loader") : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "transition",
              { attrs: { name: "typeahead-options-transition" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showPredictions,
                        expression: "showPredictions"
                      }
                    ],
                    staticClass: "typeahead__options"
                  },
                  [
                    _vm.predictions.length === 0 && !_vm.loading
                      ? _c(
                          "span",
                          {
                            staticClass: "typeahead__no-results",
                            staticStyle: { "text-align": "center" }
                          },
                          [_vm._v("No addresses found.")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.predictions, function(prediction) {
                      return _c(
                        "a",
                        {
                          staticClass: "typeahead__item",
                          attrs: { href: "#" },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.onSelect(prediction)
                            }
                          }
                        },
                        [_vm._v(_vm._s(prediction.description))]
                      )
                    })
                  ],
                  2
                )
              ]
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value:
                  "Please enter your Google Maps Plus Code if your Street Address is not prompted in the address bar.",
                expression:
                  "\n        'Please enter your Google Maps Plus Code if your Street Address is not prompted in the address bar.'\n      "
              }
            ],
            staticClass: "form-control-info"
          },
          [_c("IconMoreInfo", { attrs: { width: "20", height: "20" } })],
          1
        )
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [
      _c("span", [_vm._v("Address")]),
      _vm._v(" "),
      _c("span", { staticClass: "optional-label" }, [
        _vm._v("- Also accepts Google Maps Plus Code")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }