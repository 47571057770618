<template>
  <DashboardPage title="Settings">
    <SettingsWrapper>
      <Tab name="Contact Details">
        <TabContactDetails />
      </Tab>
      <Tab name="Account">
        <TabAccount />
      </Tab>
      <Tab name="Change Password">
        <TabChangePassword />
      </Tab>
      <Tab name="Bank Details">
        <TabBankDetails />
      </Tab>
    </SettingsWrapper>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import DashboardPage from '~/components/DashboardPage';
import Tab from '~/components/Tab';
import SettingsWrapper from '~/pages/dashboard/common/settings/SettingsWrapper';
import TabBankDetails from '~/pages/dashboard/common/settings/TabBankDetails';
import TabAccount from '~/pages/dashboard/common/settings/TabAccount';
import TabChangePassword from '~/pages/dashboard/common/settings/TabChangePassword';
import TabContactDetails from '~/pages/dashboard/agent/settings/partials/TabContactDetails';

export default {
  extends: Page,
  components: {
    TabContactDetails,
    DashboardPage,
    Tab,
    SettingsWrapper,
    TabBankDetails,
    TabAccount,
    TabChangePassword,
  },
};
</script>
