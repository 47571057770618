var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FieldWrapper", {
    attrs: {
      rules: _vm.rules,
      name: _vm.name,
      label: _vm.label,
      vid: _vm.vid,
      "label-info-text": _vm.labelInfoText
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var inputId = ref.inputId
          var errors = ref.errors
          return [
            _c(
              "Money",
              _vm._b(
                {
                  model: {
                    value: _vm.currentValue,
                    callback: function($$v) {
                      _vm.currentValue = $$v
                    },
                    expression: "currentValue"
                  }
                },
                "Money",
                _vm.money,
                false
              )
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }