var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "landing-page" },
    [
      _c(
        "div",
        {
          staticClass: "landing-page__banner",
          class: {
            "landing-page__banner--no-search":
              _vm.MIX_PLATFORM_FEATURES_CODE_LOOKUP === "0"
          }
        },
        [
          _c(
            "div",
            { staticClass: "container" },
            [
              _c("LogoWhite"),
              _vm._v(" "),
              _c("h4", { staticClass: "landing-page__tagline" }, [
                _vm._v("Professional affiliate platform")
              ]),
              _vm._v(" "),
              _vm.MIX_PLATFORM_FEATURES_CODE_LOOKUP !== "0"
                ? _c(
                    "div",
                    { staticClass: "landing-page__search" },
                    [_c("SearchByMarketplaceId")],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "landing-page__join" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("h4", { staticClass: "landing-page__join__title" }, [
              _vm._v(
                "\n        Join our platform today and monetise the power of your professional\n        recommendations\n      "
              )
            ]),
            _vm._v(" "),
            _vm.user
              ? [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--lg btn--transparent-white-button",
                      attrs: { href: _vm.route("dashboard") }
                    },
                    [_vm._v("Dashboard")]
                  )
                ]
              : [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--lg btn--transparent-white-button",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.showRegisterModal = true
                        }
                      }
                    },
                    [_vm._v("Get started")]
                  )
                ]
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c(
        "Portal",
        { attrs: { to: "modals" } },
        [
          _vm.showRegisterModal
            ? _c("RegisterModal", {
                on: {
                  close: function($event) {
                    _vm.showRegisterModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }