<template>
  <div>
    <Datatable
      :get-base-query="getBaseQuery"
      :query-custom-params="queryCustomParams"
      default-order-by="last_transaction_date"
    />
  </div>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import DownloadInvoiceModal from '~/components/DownloadInvoiceModal';
import DatatableModel from '~/models/Datatable';

export default {
  extends: Page,
  components: {
    DownloadInvoiceModal,
    DashboardPage,
    Datatable,
  },
  data() {
    return {
      queryCustomParams: {},
    };
  },
  created() {
    this.queryCustomParams = {
      table_id: 'company-partner-sales',
      company_id: this.$global.userable.id,
    };
  },
  methods: {
    getBaseQuery() {
      return new DatatableModel();
    },
  },
};
</script>
