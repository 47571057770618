<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(submit)" class="form">
      <VInput
        v-model="form.old_password"
        name="Old Password"
        vid="old_password"
        type="password"
        rules="required"
      />
      <VInput
        v-model="form.new_password"
        name="New Password"
        vid="new_password"
        type="password"
        rules="required"
      />
      <VInput
        v-model="form.new_password_confirmation"
        name="New Password Confirmation"
        vid="new_password_confirmation"
        type="password"
        rules="required|min:6"
      />
      <LoadingBtn
        :loading="loading"
        :disabled="invalid"
        class="btn"
        type="submit"
        >Save
      </LoadingBtn>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import VInput from '~/components/form/VInput';
import LoadingBtn from '~/components/LoadingBtn';
import VTextarea from '~/components/form/VTextarea';
import User from '~/models/User';

export default {
  components: {
    VTextarea,
    ValidationObserver,
    VInput,
    LoadingBtn,
  },
  data() {
    return {
      loading: false,
      form: null,
    };
  },
  created() {
    this.form = this.getForm();
  },
  computed: {
    id() {
      return this.$global.user.id;
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        const model = new User({
          id: this.id,
          ...this.form,
        });

        await model.save();

        this.form = this.getForm();
        this.$toasted.success('Password updated successfully.');
        this.$refs.form.reset();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    getForm() {
      return {
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
      };
    },
  },
};
</script>
