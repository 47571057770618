var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FieldWrapper", {
    attrs: {
      rules: _vm.rules,
      name: _vm.name,
      label: _vm.label,
      vid: _vm.vid,
      "label-info-text": _vm.labelInfoText,
      disabled: _vm.disabled,
      "no-label": _vm.noLabel
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var inputId = ref.inputId
          var errors = ref.errors
          return [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.currentValue,
                    expression: "currentValue"
                  }
                ],
                attrs: { id: inputId, name: _vm.vid },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.currentValue = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              [
                _vm.loading
                  ? _c("option", { attrs: { value: "" } }, [
                      _vm._v("Loading...")
                    ])
                  : _vm.emptyValueLabel
                  ? _c("option", { attrs: { value: "" } }, [
                      _vm._v(_vm._s(_vm.emptyValueLabel))
                    ])
                  : _vm.placeholder
                  ? _c("option", { attrs: { disabled: "", value: "" } }, [
                      _vm._v(_vm._s(_vm.placeholder))
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.options, function(option) {
                  return _c(
                    "option",
                    {
                      key: option[_vm.valueKey],
                      domProps: { value: _vm.getValue(option) }
                    },
                    [_vm._v(_vm._s(option[_vm.labelKey]) + "\n    ")]
                  )
                })
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }