<template>
  <StorePageWrapper :type="type" :business="business">
    <div class="store-product-detail">
      <div class="store__container">
        <div class="store-product-detail__back-button">
          <BackButton />
        </div>
        <Loader v-if="loading" />
        <template v-else>
          <ProductDetail>
            <template v-slot:left>
              <ProductDetailIntro :product="product" />
              <ProductDetailWatchVideo :product="product" />
              <ProductDetailAddToCartForm :product="product" />
              <ProductDetailAccordions :product="product" />
            </template>
            <template v-slot:right>
              <ProductDetailGallery :product="product" />
            </template>
          </ProductDetail>
          <SimilarProducts
            :product="product"
            :business-type="type"
            :business="business"
          />
        </template>
      </div>
    </div>
  </StorePageWrapper>
</template>
<script>
import Loader from '~/components/Loader';
import ProductDetail from '~/components/product/ProductDetail';
import Product from '~/models/Product';
import StorePageWrapper from '~/pages/store/StorePageWrapper';
import ProductDetailColorsList from '~/components/product/ProductDetailColorsList';
import ProductDetailAccordions from '~/components/product/ProductDetailAccordions';
import ProductDetailGallery from '~/components/product/ProductDetailGallery';
import ProductDetailWatchVideo from '~/components/product/ProductDetailWatchVideo';
import ProductDetailIntro from '~/components/product/ProductDetailIntro';
import ProductPriceAndCommission from '~/components/ProductPriceAndCommission';
import SimilarProducts from '~/pages/store/partials/SimilarProducts';
import ProductDetailAddToCartForm from '~/components/product/ProductDetailAddToCartForm';
import BackButton from '~/components/BackButton';
import StorePageMixin from '~/pages/store/StorePageMixin';

export default {
  mixins: [StorePageMixin],
  components: {
    BackButton,
    ProductDetailAddToCartForm,
    SimilarProducts,
    Loader,
    ProductDetail,
    StorePageWrapper,
    ProductDetailColorsList,
    ProductDetailAccordions,
    ProductDetailGallery,
    ProductDetailWatchVideo,
    ProductDetailIntro,
    ProductPriceAndCommission,
  },
  props: {
    product_slug: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      loader: false,
      product: null,
    };
  },

  async created() {
    this.loading = true;
    try {
      this.business = await this.fetchBusiness();
      this.product = await this.fetch();
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    fetch() {
      return Product.include('images', 'category.sector', 'company')
        .params({
          with_stock_total: true,
        })
        .find(this.product_slug);
    },
  },
};
</script>
<style lang="scss">
.store-product-detail {
  .loader {
    margin: $s-20 0;
  }

  &__back-button {
    margin-top: $s-16;
  }

  .pd {
    margin-top: $s-12;

    &__left {
      padding-right: $s-3;
    }

    &__right {
      padding-left: $s-12;
    }
  }

  .pd__watch-video {
    margin-top: $s-8;
  }

  .pd__accordions {
    margin-top: $s-12;
  }

  .pd__gallery {
    margin: 0 0 0 auto;
  }
  .store-similar {
    margin-top: $s-32;
    margin-bottom: $s-8;
  }
}

@include media-breakpoint-down(md) {
  .store-product-detail {
    margin-top: 0;

    &__back-button {
      margin-top: $s-6;
    }

    .pd {
      margin-top: 0;

      &__left {
        padding-right: 0;
      }

      &__right {
        padding-left: 0;
      }
    }

    .pd__gallery {
      margin: $s-6 auto 0;
    }

    .store-similar {
      margin-top: $s-16;
    }
  }
}

@media (max-width: 440px) {
  .store-product-detail {
    margin-top: 0;
    position: relative;

    &__back-button {
      background-color: $white;
      margin: 0 -#{$container-padding};
      padding: $s-4 $container-padding;
      border-bottom: 1px solid $gray-500;
    }

    .pd__gallery {
      margin: 0 -#{$container-padding};
      width: auto;

      &__slider-wrapper {
        border: none;
        border-bottom: 1px solid $gray-500;
      }
    }

    .store-similar {
      margin-top: $s-12;
    }
  }
}
</style>
