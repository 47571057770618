<template>
  <div class="product-price-and-commission">
    <div class="product-price-and-commission__item">
      <IconPrice width="16" height="16" />
      <span>{{
        Price(product.price, userable.country.vat_rate).gross().format()
      }}</span>
    </div>
    <div class="product-price-and-commission__item">
      <IconSectors width="16" height="16" />
      <span
        >{{ formatRateToPercentageRounded(product.default_commission_rate) }}
        <span>commission</span></span
      >
    </div>
  </div>
</template>
<script>
import IconCommission from '~/components/icons/IconCommission';
import IconPrice from '~/components/icons/IconPrice';
import { formatMoney, formatRateToPercentageRounded } from '~/utils';
import IconPercentageRound from '~/components/icons/IconPercentageRound';
import IconSectors from '~/components/icons/IconSectors';
import { mapState } from 'vuex';
import Price from '~/price';

export default {
  components: {
    IconSectors,
    IconPercentageRound,
    IconCommission,
    IconPrice,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Price,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'userable']),
  },
  methods: {
    formatMoney,
    formatRateToPercentageRounded,
  },
};
</script>
<style lang="scss">
.product-price-and-commission {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &__item {
    display: flex;
    align-items: center;
    margin-bottom: $s-2;

    margin-right: $s-6;
    font-size: 12px;
    line-height: 18px;
    font-weight: 500;
    color: rgba($gray-800, 0.5);

    svg {
      margin-right: $s-2;
    }
  }
}

@include media-breakpoint-down(xxs) {
  .product-price-and-commission {
    &__item {
      span > span {
        display: none;
      }
    }
  }
}
</style>
