<template>
  <div class="cart" :class="{ 'cart--display-only': displayOnly }">
    <div v-if="!items.length" class="cart__no-items">
      <span>Your cart is empty.</span>
    </div>
    <template v-else>
      <div class="cart__table dark-theme-input">
        <div class="cart__table__row cart__table__row--header">
          <div class="cart__table__cell cart__table__cell--header">
            <span>Cart</span>
          </div>
          <div
            class="cart__table__cell cart__table__cell--header cart__table__cell--size"
          >
            <span>Size</span>
          </div>
          <div class="cart__table__cell cart__table__cell--header">
            <span>Quantity</span>
          </div>
          <div class="cart__table__cell cart__table__cell--header">
            <span>Price</span>
          </div>
        </div>
        <div v-for="item in items" class="cart__table__row">
          <div class="cart__table__cell">
            <span
              >{{ item.product.company.business_name }} -
              {{ item.product.name }}</span
            >
            <span
              v-if="item.product.size"
              class="cart__table__cell__extra-info"
              >{{ item.product.size }}</span
            >
            <span class="cart__table__cell__extra-info" v-else>-</span>
          </div>
          <div class="cart__table__cell cart__table__cell--size">
            <span v-if="item.product.size">{{ item.product.size }}</span>
            <span v-else>-</span>
          </div>
          <div class="cart__table__cell">
            <template v-if="displayOnly">
              <span>{{ item.qty }}</span>
            </template>
            <template v-else>
              <VQtyInput
                :value="item.qty"
                name="quantity"
                vid="qty"
                no-label
                rules="numeric|min_value:1"
                @input="($event) => onUpdateQty(item, $event)"
              />
              <a
                href="#"
                class="cart__qty-remove"
                @click.prevent="removeFromCart(item)"
                ><IconClose
              /></a>
            </template>
          </div>
          <div class="cart__table__cell">
            <span>{{ itemGrossSubtotal(item).format() }}</span>
          </div>
        </div>
      </div>
      <a
        v-if="!displayOnly"
        :href="route(`store.${type}.checkout`, business.slug)"
        class="btn btn--lg btn--on-black-bg"
        >Checkout - {{ grossTotal.format() }}</a
      >
      <div v-if="displayOnly" class="cart__shipping-and-total">
        <div class="cart__aggregate cart__shipping">
          <span>Shipping</span>
          <span>{{ grossDeliveryCost.format() }}</span>
        </div>
        <div class="cart__aggregate cart__shipping">
          <span>Total</span>
          <span>{{ grossTotal.format() }}</span>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import VQtyInput from '~/components/form/VQtyInput';
import IconClose from '~/components/icons/IconClose';
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
    IconClose,
    VQtyInput,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    displayOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState('cart', ['items']),
    ...mapGetters('cart', [
      'grossTotal',
      'grossDeliveryCost',
      'itemGrossSubtotal',
    ]),
  },
  methods: {
    onUpdateQty(item, qty) {
      this.$store.dispatch('cart/updateQty', { id: item.id, qty: qty });
    },
    removeFromCart(item) {
      this.$store.dispatch('cart/remove', item.id);
    },
  },
};
</script>
<style lang="scss">
.cart {
  width: 100%;
  overflow: hidden;

  &__no-items {
    padding: $s-6 0;
    text-align: center;
    span {
      color: rgba($white, 0.75);
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
    }
  }

  &__table {
    padding-bottom: $s-8;

    &__row {
      display: flex;
      align-items: center;
      margin-bottom: $s-6;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__cell {
      flex: 0 0 15%;
      display: flex;
      align-items: center;
      padding-right: $s-2;

      &:first-child {
        padding-left: $s-2;
        flex: 0 0 55%;
      }

      &:last-child {
        justify-content: flex-end;
        padding-right: $s-2;
      }

      span {
        font-size: 18px;
        font-weight: 300;
        line-height: 28px;
        color: $white;
      }

      span.cart__table__cell__extra-info {
        display: none;
      }

      &--header {
        span {
          font-size: 12px;
          font-weight: 500;
          color: rgba($white, 0.75);
        }
      }

      .form-group {
        padding-bottom: 0;
      }
    }
  }

  .v-qty-input {
    > a {
      svg {
        fill: rgba($white, 0.5);
      }
    }
  }

  &__qty-remove {
    margin-left: $s-3;
    color: rgba($white, 0.5);
  }

  .btn {
    margin-top: $s-2;
    width: 100%;
  }

  &--display-only {
    .cart {
      &__table {
        border-bottom: 1px solid rgba($white, 0.25);
      }
    }
  }

  &__shipping-and-total {
    width: 25%;
    float: right;
  }

  &__aggregate {
    margin-top: $s-4;
    padding: $s-4 $s-2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($white, 0.25);

    span {
      font-size: 18px;
      font-weight: 300;
      line-height: 28px;
      color: $white;

      &:first-child {
        font-size: 12px;
        font-weight: 500;
        color: rgba($white, 0.75);
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .cart {
    &__table {
      &__row {
        span {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &__cell {
        flex: 0 0 30%;

        &:first-child {
          padding-left: 0;
          flex: 1 0 50%;
          display: flex;
          flex-wrap: wrap;
          padding-right: $s-4;
        }

        &:last-child {
          padding-right: 0;
          flex: 0 0 20%;
        }

        span.cart__table__cell__extra-info {
          font-size: 10px;
          color: rgba($white, 0.75);
          flex: 1 1 100%;
        }
      }

      &__cell--size {
        display: none;
      }
    }

    .v-qty-input {
      input {
        border: none;
        padding: 0;
      }

      > a {
        &:first-child {
          left: 0;
        }

        &:last-child {
          right: 0;
        }
      }
    }

    &__qty-remove {
      margin-left: $s-1;
    }

    &__shipping-and-total {
      width: 50%;
    }

    &__aggregate {
      margin-top: $s-2;
      padding: $s-3 $s-2;

      span {
        font-size: 14px;
        line-height: 20px;

        &:first-child {
          font-size: 12px;
        }
      }
    }
  }
}
@include media-breakpoint-down(xxs) {
  .cart {
    &__table {
      &__cell {
        flex: 0 0 35%;

        &:first-child {
          flex: 0 0 45%;
        }

        &:last-child {
          flex: 0 0 20%;
        }
      }
    }
  }
}
</style>
