const state = () => {
  return {
    uppy: {},
  };
};

const mutations = {
  update(state, patch) {
    state.uppy = Object.assign({}, state.uppy, patch);
  },
};

export default { namespaced: true, state, mutations };
