var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "multi-uploader" }, [
    _c(
      "div",
      {
        staticClass: "droparea",
        on: {
          dragover: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.handleDragOver($event)
          },
          dragleave: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.handleDragLeave($event)
          },
          drop: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.handleDrop($event)
          },
          click: _vm.onClickUpload
        }
      },
      [
        _c(
          "a",
          {
            staticClass: "btn btn--secondary",
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return (function() {})($event)
              }
            }
          },
          [_vm._v("Browse files")]
        ),
        _vm._v(" "),
        _c("span", { staticClass: "droparea__info" }, [
          _vm._v("or drag them in")
        ]),
        _vm._v(" "),
        _vm.showProgressBar
          ? _c("div", { staticClass: "multi-uploader__state" }, [
              _c("span", [_vm._v("Uploading...")]),
              _vm._v(" "),
              true
                ? _c("div", { staticClass: "multi-uploader__progress" }, [
                    _c("div", {
                      staticClass: "multi-uploader__progress__bar",
                      style: { width: _vm.totalProgress + "%" }
                    })
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ]
    ),
    _vm._v(" "),
    _c("input", {
      ref: "file",
      attrs: { type: "file", multiple: true },
      on: { change: _vm.onInputChange }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }