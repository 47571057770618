const state = () => ({
  user: null,
  userable: null,
});

const getters = {
  userType(state) {
    return state.user?.userable_type;
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setUserable(state, userable) {
    state.userable = userable;
  },
  updateUser(state, user) {
    state.user = {
      ...state.user,
      ...user,
    };
  },
  updateUserable(state, userable) {
    state.userable = {
      ...state.userable,
      ...userable,
    };
  },
};

export default {
  state,
  getters,
  mutations,
  namespaced: true,
};
