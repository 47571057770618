<template>
  <DashboardPage title="Sales">
    <Tabs>
      <Tab name="Product sales" selected>
        <OrdersTable />
      </Tab>
      <Tab name="Partner sales">
        <OrdersByPartnerTable />
      </Tab>
    </Tabs>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Tabs from '~/components/Tabs';
import Tab from '~/components/Tab';
import OrdersTable from '~/pages/dashboard/company/partials/sales/OrdersTable';
import OrdersByPartnerTable from '~/pages/dashboard/company/partials/sales/OrdersByPartnerTable';
import DashboardPage from '~/components/DashboardPage';

export default {
  extends: Page,
  components: {
    DashboardPage,
    OrdersTable,
    OrdersByPartnerTable,
    Tabs,
    Tab,
  },
};
</script>
<style lang="scss"></style>
