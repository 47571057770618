<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M12 10.68c-.732 0-1.32.588-1.32 1.32 0 .732.588 1.32 1.32 1.32.732 0 1.32-.588 1.32-1.32 0-.732-.588-1.32-1.32-1.32zM12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm2.628 14.628L4.8 19.2l4.572-9.828L19.2 4.8l-4.572 9.828z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
