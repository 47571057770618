<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="form" @submit.prevent="handleSubmit(submit)">
      <VInput
        v-model="form.vat_no"
        name="VAT Number"
        vid="vat_no"
        placeholder="Enter VAT Number"
      />
      <div class="form-group">
        <SingleUploader
          :media="form.vat_certificate"
          :for="mediaFor"
          :collection="MediaCollectionEnum.VAT_CERTIFICATE"
          :allowed-file-types="['application/pdf']"
          @upload-success="onVatCertificateChange"
        >
          <template v-slot:preview="{ media }">
            <template v-if="!media">
              <svg
                class="temp-vat-certificate-placeholder"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 40 40"
              >
                <g>
                  <g opacity=".1">
                    <path
                      d="M20 36.667c-4.055 0-7.775-1.46-10.668-3.877 1.398-2.638 4.14-4.457 7.335-4.457h6.666c3.195 0 5.937 1.819 7.335 4.457A16.592 16.592 0 0 1 20 36.667zm-6.667-20C13.333 12.985 16.407 10 20 10s6.667 2.985 6.667 6.667v1.666C26.667 22.015 23.593 25 20 25s-6.667-2.985-6.667-6.667zM20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                    />
                  </g>
                </g>
              </svg>
            </template>
            <span v-else>
              {{ media.file_name }}
            </span>
          </template>
          <template v-slot:text>
            <span class="single-uploader__title">Add VAT Certificate</span>
            <span class="single-uploader__subtitle">PDF | Max 20mb</span>
          </template>
        </SingleUploader>
      </div>
      <LoadingBtn
        :loading="loading"
        :disabled="invalid"
        class="btn"
        type="submit"
      >
        Save
      </LoadingBtn>
    </form>
  </ValidationObserver>
</template>
<script>
import VInput from '~/components/form/VInput';
import TabCompanyOrPartnerBase from '~/pages/dashboard/common/settings/TabCompanyOrPartnerBase';
import UserEnum from '~/enums/UserEnum';
import MediaModelEnum from '~/enums/MediaModelEnum';
import MediaCollectionEnum from '~/enums/MediaCollectionEnum';
import SingleUploader from '~/components/upload/SingleUploader';

export default {
  extends: TabCompanyOrPartnerBase,
  components: {
    VInput,
    SingleUploader,
  },
  data() {
    return {
      MediaCollectionEnum,
    };
  },
  methods: {
    getForm() {
      return {
        vat_no: this.userable.vat_no,
        vat_certificate: this.userable.vat_certificate,
      };
    },

    onVatCertificateChange(file, response) {
      this.form.vat_certificate = response.body;
    },
  },
};
</script>
<style lang="scss">
.temp-vat-certificate-placeholder {
  width: 60px;
  height: 60px;
}
</style>
