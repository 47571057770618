<template>
  <div class="marketing-page marketing-page-terms-and-conditions">
    <div class="marketing-page__banner">
      <div class="container">
        <h2 class="marketing-page__title">About us</h2>
      </div>
    </div>
    <div class="marketing-page__content">
      <div class="container">
        <template v-if="isCas">
          <h5>Welcome to caskadepro</h5>
          <p>
            caskadepro is an online Professional Affiliate Platform designed
            specifically to allow you, the Professional, to monetise the power
            of your professional recommendations and advice.
          </p>

          <p>
            Ever wondered what your bank balance would look like if you had a
            euro for every time you have given direction on a product previously
            to someone and received nothing in return for it? Wonder no more.
          </p>

          <p>
            Our outstanding new concept is something that allows you to provide
            a unique level of professional service to your clients and be
            rewarded at the same time.
          </p>

          <p>
            In today's marketplace the number of advertising channels employed
            by Brands to expand their footprint and drive sales continues to
            grow exponentially. caskadepro recognises that Professional
            expertise remains the most effective and valuable way of educating
            the end consumer pre purchase.
          </p>

          <p>Monetise the Power of your Professional Recommendation.</p>
        </template>
        <template v-else>
          <h5>Welcome to That’sThe1</h5>
          <p>
            That’sThe1 is an affiliate platform for every type of online content
            creator inclusive of Influencers, Bloggers and Youtubers. Partner up
            with leading brands from the Hair, Beauty, Skin Care, Lifestyle and
            Health & Fitness industries and monetise the power of your
            recommendations.
          </p>

          <p>
            That’sThe1 affiliate links will enhance the impact of your content
            and allow you to build relationships with your favourite brands,
            while promoting and connecting these brands with your followers.
          </p>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import PlatformSlugEnum from '~/enums/PlatformSlugEnum';

export default {
  components: {},
  data() {
    return {
      isCas: process.env.MIX_PLATFORM_SLUG === PlatformSlugEnum.CAS,
    };
  },
};
</script>
<style lang="scss"></style>
