// we hit https://github.com/Financial-Times/polyfill-library/issues/123 when we tried to
// setup babel-env - usage
import 'core-js/es/promise';
import 'core-js/es/string/starts-with';
import 'core-js/es/object/values';
import 'core-js/es/object/assign';
import 'core-js/es/object/keys';
import 'core-js/es/array/find';
import 'core-js/es/array/filter';
import 'core-js/es/array/find-index';
import 'core-js/es/array/for-each';
import 'core-js/es/array/includes';
import 'core-js/es/array/some';
import 'core-js/es/array/reduce';
import 'core-js/es/array/sort';
import 'core-js/es/array/fill';
import 'core-js/es/promise/finally';
import 'core-js/es/number/is-integer';

import '~/plugins/modernizr-custom';
require('./bootstrap-vue');
