<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="form" @submit.prevent="handleSubmit(submit)">
      <div class="form-group">
        <ValidationProvider
          name="services_ids_per_sector"
          vid="services_ids_per_sector"
          v-slot="{ errors }"
        >
          <label>Sectors & Services</label>
          <ServicesBySectorSelect
            v-model="form.services_ids_per_sector"
            @input="$refs.form.reset()"
          />
          <span v-if="errors[0]" class="form-group__error">{{
            errors[0]
          }}</span>
        </ValidationProvider>
      </div>
      <LoadingBtn
        :loading="loading"
        :disabled="invalid"
        class="btn"
        type="submit"
      >
        Save
      </LoadingBtn>
    </form>
  </ValidationObserver>
</template>
<script>
import TabCompanyOrPartnerBase from '~/pages/dashboard/common/settings/TabCompanyOrPartnerBase';
import ServicesBySectorSelect from '~/pages/auth/ServicesBySectorSelect';
import ValidationProvider from '~/components/form/ValidationProvider';

export default {
  extends: TabCompanyOrPartnerBase,
  components: {
    ServicesBySectorSelect,
    ValidationProvider,
  },
  methods: {
    getForm() {
      return {
        services_ids_per_sector: this.mapServicesIntoProperFormatForComponent(),
      };
    },
    mapServicesIntoProperFormatForComponent() {
      return this.userable.services.reduce((r, service) => {
        if (!r[service.sector_id]) {
          r[service.sector_id] = [];
        }
        r[service.sector_id].push(service.id);

        return r;
      }, {});
    },
    prepareForm(form) {
      return {
        ...form,
        // Filter out empty sectors.
        // We need this due to the min:1 validation rule.
        services_ids_per_sector: Object.keys(
          form.services_ids_per_sector
        ).reduce((r, key) => {
          let values = form.services_ids_per_sector[key];
          if (values.length) {
            r[key] = values;
          }
          return r;
        }, {}),
      };
    },
  },
};
</script>
