var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-price-and-commission" }, [
    _c(
      "div",
      { staticClass: "product-price-and-commission__item" },
      [
        _c("IconPrice", { attrs: { width: "16", height: "16" } }),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm
                .Price(_vm.product.price, _vm.userable.country.vat_rate)
                .gross()
                .format()
            )
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "product-price-and-commission__item" },
      [
        _c("IconSectors", { attrs: { width: "16", height: "16" } }),
        _vm._v(" "),
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.formatRateToPercentageRounded(
                _vm.product.default_commission_rate
              )
            ) + "\n      "
          ),
          _c("span", [_vm._v("commission")])
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }