<template>
  <div class="time-filter">
    <ul class="time-options">
      <li
        :class="{ active: item.value === selected }"
        v-for="item in options"
        :key="item.value"
        @click="setActive(item.value)"
      >
        {{ item.label }}
      </li>
    </ul>
    <VSelect
      :value="selected"
      :options="options"
      class="filter-select"
      @input="setActive"
    />
  </div>
</template>
<script>
import VSelect from '~/components/form/VSelect';
export default {
  components: { VSelect },
  props: {
    options: {
      type: Array,
      default: () => {
        return [
          {
            label: '24H',
            value: '24H',
          },
          {
            label: '1W',
            value: '1W',
          },
          {
            label: '1M',
            value: '1M',
          },
          {
            label: '1Y',
            value: '1Y',
          },
          {
            label: 'ALL',
            value: 'ALL',
          },
        ];
      },
    },
    selected: {
      type: String,
      default: 'ALL',
    },
  },

  methods: {
    setActive(value) {
      if (this.selected === value) {
        return false;
      }
      this.$emit('input', value);
    },
  },
};
</script>
<style lang="scss">
.time-filter {
  display: inline-block;

  ul {
    li {
      display: inline-block;
      color: $gray-800;
      opacity: 0.25;
      padding: 10px 9px;
      font-weight: 500;
      font-size: $text-sm;
      text-transform: uppercase;

      &:hover,
      &.active {
        cursor: pointer;
        color: $primary;
        opacity: 1;
      }
    }
  }

  .filter-select {
    display: none;
    padding-bottom: 0;
  }
}

@include media-breakpoint-down(xl) {
  .time-filter {
    ul {
      display: none;
    }

    .filter-select {
      display: block;
    }
  }
}
</style>
