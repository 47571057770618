var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Sales" } },
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            { attrs: { name: "Product sales", selected: "" } },
            [_c("OrdersTable")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Partner sales" } },
            [_c("OrdersByPartnerTable")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }