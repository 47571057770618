<script>
import qs from 'qs';
import PersistentStorage from '~/persistent-storage';
import SimplePagination from '~/components/SimplePagination';

export default {
  components: {
    SimplePagination,
  },
  data() {
    return {
      loadingItems: false,
      items: [],
      page: 1,
      perPage: 10,
      orderBy: null,
      isLastPage: false,
      filters: {},
      queryCustomParams: {},
    };
  },
  computed: {
    showPagination() {
      return this.hasMultiplePages;
    },
    hasMultiplePages() {
      return !(this.page === 1 && this.isLastPage);
    },
  },
  watch: {
    filters: {
      handler() {
        this.requestAfterFiltersUpdate();
      },
      deep: true,
    },
    orderBy() {
      this.requestAfterFiltersUpdate();
    },
  },
  async created() {
    this.loadingItems = true;
    this.loadFromQueryString();
    await this.fetch();
    this.loadingItems = false;
  },
  methods: {
    async fetch() {
      try {
        let query = this.getBaseQuery()
          .applyFilters(this.filters)
          .page(this.page)
          .params({
            per_page: this.perPage,
            ...this.queryCustomParams,
          })
          .orderBy(this.orderBy);

        this.syncUrl();

        let response = await query.get();
        this.items = response.data;
        this.isLastPage =
          response.meta.current_page === response.meta.last_page;

        this.afterFetch();
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
    syncUrl() {
      let query = '';

      const prepend = () => {
        return query === '' ? '?' : '&';
      };

      let sanitizedFilters = Object.keys(this.filters).reduce(
        (sanitizedFilters, key) => {
          if (this.filters[key]) {
            sanitizedFilters[key] = this.filters[key];
          }

          return sanitizedFilters;
        },
        {}
      );

      if (Object.keys(sanitizedFilters).length > 0) {
        query +=
          prepend() +
          qs.stringify({ filter: sanitizedFilters }, { encode: false });
      }

      if (this.orderBy) {
        query += prepend() + `sort=${this.orderBy}`;
      }

      if (this.page) {
        query += prepend() + `page=${this.page}`;
      }

      window.history.replaceState(null, null, query);
    },
    loadFromQueryString() {
      let queryString = window.location.search;
      if (!queryString) {
        return;
      }
      // Remove the '?'
      queryString = queryString.substr(1);

      let query = qs.parse(queryString);

      if (query.filter) {
        let parseToInt = [
          'sectors.id',
          'sectors.categories.id',
          'category.sector.id',
          'category.id',
        ];
        Object.keys(query.filter).forEach((key) => {
          if (parseToInt.includes(key)) {
            query.filter[key] = parseInt(query.filter[key]);
          }
        });

        this.filters = {
          ...this.filters,
          ...query.filter,
        };
      }

      if (query.sort) {
        this.orderBy = query.sort;
      }

      if (query.page) {
        this.page = parseInt(query.page);
      }
    },

    prev() {
      this.page--;
      this.onPageUpdate();
    },
    next() {
      this.page++;
      this.onPageUpdate();
    },

    async requestAfterFiltersUpdate() {
      this.loadingItems = true;
      this.items = [];
      this.page = 1;
      await this.fetch();
      this.loadingItems = false;
    },

    async onPageUpdate() {
      this.loadingItems = true;
      await this.fetch();
      this.loadingItems = false;
    },

    afterFetch() {},
  },
};
</script>
