<template>
  <ul class="landing-and-marketing-menu">
    <li>
      <a :href="route('about-us')">About us</a>
    </li>
    <template v-if="user">
      <li>
        <a :href="route('dashboard')">Dashboard</a>
      </li>
    </template>
    <template v-else>
      <li>
        <a :href="route('login')">Sign in</a>
      </li>
      <li>
        <a
          href="#"
          class="btn btn--transparent-white-button"
          @click.prevent="showRegisterModal = true"
          >Get started</a
        >
      </li>
    </template>
    <Portal to="modals">
      <RegisterModal
        v-if="showRegisterModal"
        @close="showRegisterModal = false"
      />
    </Portal>
  </ul>
</template>
<script>
import { mapState } from 'vuex';
import RegisterModal from '~/pages/auth/RegisterModal';
import PlatformSlugEnum from '~/enums/PlatformSlugEnum';

export default {
  components: { RegisterModal },
  data() {
    return {
      showRegisterModal: false,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>
<style lang="scss">
.landing-and-marketing-menu {
  display: flex;
  align-items: center;

  li {
    margin: 0 $s-4;

    &:last-child {
      margin-right: 0;
    }
  }

  a:not(.btn) {
    font-size: 14px;
    font-weight: 500;
    color: $white;
    transition: color 0.15s ease-out;

    @at-root {
      .no-touchevents {
        .marketing-header__menu a:not(.btn):hover {
          &:hover {
            color: rgba($white, 0.7);
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .landing-and-marketing-menu {
    li {
      margin: 0 $s-2;
    }
  }
}
</style>
