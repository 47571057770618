<template>
  <div class="map-wrapper">
    <img :src="src" alt="" />
  </div>
</template>
<script>
export default {
  props: {
    address: {
      type: Object,
    },
  },

  data() {
    return {};
  },

  computed: {
    src() {
      let baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';
      let center = `${this.address.lat},${this.address.lng}`;
      let markers = `${this.address.lat},${this.address.lng}`;
      let size = `656x416`;
      let zoom = 12;
      let scale = 2;
      let apiKey = process.env.MIX_GOOGLE_STATIC_MAP_API_KEY;
      let color = process.env.MIX_PLATFORM_PRIMARY_COLOR.replace('#', '0x');

      return `${baseUrl}?center=${center}&zoom=${zoom}&size=${size}&scale=${scale}&markers=color:${color}|${markers}&key=${apiKey}`;
    },
  },
};
</script>
