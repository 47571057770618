var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "marketing-page marketing-page-terms-and-conditions" },
      [
        _c("div", { staticClass: "marketing-page__banner" }, [
          _c("div", { staticClass: "container" }, [
            _c("h2", { staticClass: "marketing-page__title" }, [
              _vm._v("Returns and Refunds")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "marketing-page__content" }, [
          _c("div", { staticClass: "container" }, [
            _c("h5", [
              _vm._v("How to return or exchange or cancel your order:")
            ]),
            _vm._v(" "),
            _c("ul", [
              _c("li", [
                _vm._v(
                  "\n          Contact the Company where the product comes from directly to let\n          them know that you wish to return, exchange or cancel your order.\n        "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n          You do this through the contact details of the Company / Brand on\n          your invoice / confirmation email you received with your order.\n        "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n          Under Irish Consumer Law – you have 14 days to Cancel / Return /\n          Exchange your product.\n        "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n          Anything past the 14 day cooling-off period cannot be returned /\n          cancelled or refunded.\n        "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n          All of our brands / partners agree and comply.\n        "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n          If you have any further questions, please get in touch with us\n          through email\n          "
                ),
                _c(
                  "a",
                  { attrs: { href: "mailto:caskade@lanaicreative.com" } },
                  [_vm._v("caskade@lanaicreative.com")]
                ),
                _vm._v("\n          or call us on "),
                _c("a", { attrs: { href: "tel:35315688888" } }, [
                  _vm._v("+353 (1) 5688888")
                ])
              ])
            ]),
            _vm._v(" "),
            _c("h5", [_vm._v("What you cannot send back or cancel")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "Unfortunately, some items are non-cancellable and non-refundable:"
              )
            ]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n          Anything that is personalised or otherwise can't be resold due to a\n          bespoke element\n        "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Hairbrushes cannot be returned for hygiene reasons")
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v("Liquid products cannot be returned once opened.")
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        To avoid disappointment, please check whether an item is cancellable\n        or non-cancellable before ordering.\n      "
              )
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }