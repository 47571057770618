<template>
  <div class="layout layout-marketing">
    <MarketingHeader />
    <div class="layout-marketing__content">
      <slot />
    </div>
    <PortalTarget name="modals" multiple />
  </div>
</template>
<script>
import MarketingHeader from '~/layouts/partials/MarketingHeader';
export default {
  components: {
    MarketingHeader,
  },
};
</script>
<style lang="scss">
.marketing-page {
  &__banner {
    background-color: $primary;
  }

  &__title {
    line-height: 32px;
    font-weight: 600;
    font-size: 32px;
    padding: $s-10;
    color: $white;
    text-align: center;
  }

  &__content {
    padding: $s-12 0 $s-20;

    .container {
      max-width: 768px;
    }

    h5 {
      font-weight: 500;
      margin-bottom: $s-3;
      margin-top: $s-6;
    }

    p {
      font-size: 14px;
      line-height: 20px;
      color: rgba($gray-800, 0.5);
      margin-bottom: $s-10;
    }

    ul,
    ol {
      margin: $s-10 0 $s-10 $s-6;
    }

    ul {
      list-style-type: decimal;
    }

    ol {
      list-style-type: circle;
    }

    li {
      font-size: 14px;
      line-height: 20px;
      color: rgba($gray-800, 0.5);
      margin-bottom: $s-2;
    }

    a {
      color: $primary;
    }
  }
}
</style>
