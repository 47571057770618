<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(submit)" class="form">
      <div class="form-group">
        <SingleUploader
          :media="form.avatar"
          :for="MediaModelEnum.USER"
          :collection="MediaCollectionEnum.AVATAR"
          @upload-success="onAvatarChange"
        >
          <template v-slot:preview="{ media }">
            <UserAvatar
              :avatar="media"
              :size="64"
              :colour="user.avatar_colour"
            />
          </template>
          <template v-slot:text>
            <span class="single-uploader__title">Change avatar</span>
            <span class="single-uploader__subtitle"
              >400x400px jpeg with max size 20MB.</span
            >
          </template>
        </SingleUploader>
      </div>
      <VInput v-model="form.name" name="Name" vid="name" rules="required" />
      <VInput :value="user.email" name="Email" vid="email" :disabled="true" />
      <LoadingBtn
        :loading="loading"
        :disabled="invalid"
        class="btn"
        type="submit"
        >Save
      </LoadingBtn>
    </form>
  </ValidationObserver>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import UserAvatar from '~/components/UserAvatar';
import VInput from '~/components/form/VInput';
import LoadingBtn from '~/components/LoadingBtn';
import VTextarea from '~/components/form/VTextarea';
import SingleUploader from '~/components/upload/SingleUploader';
import User from '~/models/User';
import MediaModelEnum from '~/enums/MediaModelEnum';
import MediaCollectionEnum from '~/enums/MediaCollectionEnum';

export default {
  components: {
    UserAvatar,
    SingleUploader,
    VTextarea,
    ValidationObserver,
    VInput,
    LoadingBtn,
  },
  data() {
    return {
      loading: false,
      form: null,
      MediaModelEnum,
      MediaCollectionEnum,
    };
  },
  created() {
    this.form = this.getForm();
  },
  computed: {
    id() {
      return this.$global.user.id;
    },
    user() {
      return this.$global.user;
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        const model = new User({
          id: this.id,
          ...this.form,
        });

        await model.save();

        window.location.reload();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    getForm() {
      return {
        name: this.user.name,
        avatar: this.user.avatar,
      };
    },

    onAvatarChange(file, response) {
      this.form.avatar = response.body;
    },
  },
};
</script>
