<template>
  <div class="marketplace__card" :class="classes">
    <div class="marketplace__card__left">
      <div class="marketplace__card__logo">
        <UserLogo
          :logo="business.logo"
          :colour="business.logo_colour"
          :business-name="business.business_name"
          :size="200"
        />
      </div>
      <template v-if="userType !== UserEnum.ADMIN">
        <a
          href="#"
          v-if="!connection"
          class="marketplace__card__connection marketplace__card__connection--connect"
          @click.prevent="connect"
        >
          <IconConnect width="16" height="16" />
          <span>Connect</span>
        </a>
        <div
          v-else
          class="marketplace__card__connection"
          :class="`marketplace__card__connection--${connection.statusClassName()}`"
          @click=""
        >
          <IconConnect width="16" height="16" />
          <span>{{ connection.statusLabel() }}</span>
        </div>
      </template>
    </div>
    <div class="marketplace__card__right">
      <h4 class="marketplace__card__title">
        <a
          :href="getUrl()"
          @mouseover="isHovering = true"
          @mouseout="isHovering = false"
        >
          <VClamp autoresize :max-lines="1">{{
            business.business_name
          }}</VClamp></a
        >
      </h4>
      <div class="marketplace__card__fixed-height">
        <div class="marketplace__card__tags">
          <CardTag
            v-if="type === UserEnum.PARTNER"
            v-for="service in business.services"
            :key="`service_${service.id}`"
            type="service"
            class="marketplace__card__tag"
            >{{ service.name }}</CardTag
          >
          <CardTag
            v-for="sector in business.sectors"
            :key="`sector_${sector.id}`"
            type="sector"
            class="marketplace__card__tag"
            >{{ sector.name }}</CardTag
          >
        </div>
        <div class="marketplace__card__description">
          <VClamp autoresize :max-lines="3">
            {{ business.about }}
            <template v-slot:after>
              <a
                :href="getUrl()"
                class="marketplace__card__description__read-more"
                @mouseover="isHovering = true"
                @mouseout="isHovering = false"
                >Read more</a
              >
            </template>
          </VClamp>
        </div>
        <template v-if="type === UserEnum.PARTNER">
          <PartnerMoreDetails :partner="business" />
        </template>
        <template v-else>
          <CompanyMoreDetails :company="business" />
        </template>
      </div>
      <a :href="getUrl()" class="btn btn--marketplace-card">View profile</a>
      <div
        v-if="connection && connection.isAccepted()"
        class="marketplace__card__app-logo"
      >
        <LogoMark />
      </div>
    </div>
  </div>
</template>
<script>
import breakpoints from '~/plugins/breakpoints.js';
import VClamp from 'vue-clamp';
import IconConnect from '~/components/icons/IconConnect';
import UserLogo from '~/components/UserLogo';
import ConnectionStatusEnum from '~/enums/ConnectionStatusEnum';
import Connection from '~/models/Connection';
import UserEnum from '~/enums/UserEnum';
import PartnerMoreDetails from '~/pages/dashboard/marketplace/partials/PartnerMoreDetails';
import CompanyMoreDetails from '~/pages/dashboard/marketplace/partials/CompanyMoreDetails';
import CardTag from '~/components/CardTag';
import ConnectRequestMixin from '~/pages/dashboard/marketplace/partials/ConnectRequestMixin';
import LogoMark from '~/components/LogoMark';
import { mapGetters } from 'vuex';

export default {
  mixins: [ConnectRequestMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
    applicant: {
      type: Object,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
    connection: {
      validator: (prop) => prop === null || prop instanceof Connection,
      required: false,
    },
  },

  components: {
    CardTag,
    CompanyMoreDetails,
    PartnerMoreDetails,
    UserLogo,
    VClamp,
    IconConnect,
    LogoMark,
  },

  data() {
    return {
      breakpoints,
      isHovering: false,
      ConnectionStatusEnum,
      UserEnum,
    };
  },
  computed: {
    ...mapGetters('auth', ['userType']),
    classes() {
      let classes = [];

      if (this.isHovering) {
        classes.push('marketplace__card--hover');
      }

      return classes;
    },
  },
  methods: {
    async connect() {
      await this.connectRequest(
        this.getConnectionAttributes({
          type: this.type,
          business: this.business,
          applicant: this.applicant,
        })
      );
    },
    getUrl() {
      if (this.type === UserEnum.PARTNER) {
        return this.route('marketplace.partners.show', {
          partner: this.business.slug,
        });
      } else {
        return this.route('marketplace.companies.show', {
          company: this.business.slug,
        });
      }
    },
  },
};
</script>
<style lang="scss">
.marketplace__card {
  display: flex;
  padding: $s-6 0;
  border-bottom: 1px solid $gray-500;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: $s-6 - $s-3;
    left: -#{$s-3};
    right: -#{$s-3};
    bottom: $s-6 - $s-3;
    background-color: $white;
    opacity: 0;
    transition: opacity 0.15s ease-out;
    border-radius: 5px;
    z-index: 0;
  }

  &__left {
    margin-right: $s-6;
    position: relative;
    z-index: 1;
  }

  &__logo {
    width: 200px;
    height: 200px;
    background-color: $white;
    border: 1px solid $gray-500;
  }

  &__connection {
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba($gray-800, 0.5);
    background-color: $white;
    border: 1px solid $gray-500;
    border-top: 0;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;

    span {
      margin-left: $s-2;
    }

    &--connect {
      span {
        transition: color 0.15s ease-out;
      }
      svg {
        transition: color 0.15s ease-out;
      }
    }
    &--pending {
      svg {
        color: $accent-yellow;
      }
    }

    &--connected {
      svg {
        color: $primary;
      }
    }

    &--declined {
      svg {
        color: $accent-red;
      }
    }

    @at-root {
      .no-touchevents {
        .marketplace__card__connection--connect:hover {
          span {
            color: $gray-800;
          }
          svg {
            color: $primary;
          }
        }
      }
    }
  }

  &__right {
    /*padding-right: 84px;*/
    position: relative;
    z-index: 1;
    flex-grow: 1;
  }

  &__title {
    font-size: 36px;
    line-height: 44px;
    font-weight: 300;
    color: $gray-800;
    margin-top: $s-1;
    width: 100%;
    padding-right: 20px;
  }

  &__tags {
    margin-top: $s-2;
    display: flex;
    flex-wrap: wrap;
    padding-right: 0;
  }

  &__tag {
    margin-right: $s-3;
    margin-bottom: $s-2;
  }

  &__description {
    margin-top: $s-2;
    color: rgba($gray-800, 0.5);
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    padding-right: 84px;

    &__read-more {
      color: $gray-800;
      padding-left: 2px;
    }
  }

  &__app-logo {
    position: absolute;
    bottom: 22px;
    right: 22px;

    img {
      max-width: 30px;
    }
  }

  &--hover {
    &:before {
      opacity: 1;
    }
  }

  @at-root {
    .marketplace__card {
      &__title,
      &__description__read-more {
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .btn--marketplace-card {
    margin-top: $s-6;
    display: none;
  }
}
@include media-breakpoint-down(md) {
  .marketplace__card {
    display: flex;
    padding: $s-4 0;
    border-bottom: none;
    position: relative;

    &__left {
      margin-right: 0;
    }

    &__logo {
      width: $s-40;
      height: $s-40;
    }

    &__right {
      position: absolute;
      padding-right: 0;
      top: $s-4;
      bottom: $s-4;
      right: 0;
      left: $s-40 + $s-6;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
      padding-right: 35px;
      flex-grow: 0;
      flex-shrink: 0;
    }

    &__fixed-height {
      overflow: hidden;
      position: relative;
      flex-grow: 1;
      flex-shrink: 1;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        height: 10px;
        width: 100%;
        background-image: linear-gradient(
          180deg,
          rgba(245, 245, 245, 0) 0%,
          rgba(245, 245, 245, 0) 0%,
          #f5f5f5 100%,
          #f5f5f5 100%
        );
      }
    }

    &__tags {
      padding-right: 0;
    }

    &__description {
      display: none;
    }

    .business-more-details {
      margin-top: 0;
    }

    &__app-logo {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    .btn--marketplace-card {
      display: inline-block;
      flex-grow: 0;
      flex-shrink: 0;
      margin-bottom: 0;
    }
  }
}
@include media-breakpoint-down(xxs) {
  .marketplace__card {
    &__logo {
      width: $s-32;
      height: $s-32;
    }

    &__right {
      left: $s-32 + $s-4;
    }
  }
}
</style>
