<template>
  <div class="marketing-header">
    <div class="container">
      <a :href="route('home')" class="marketing-header__logo">
        <LogoMarkWhite />
        <LogoWhite />
      </a>
      <div class="marketing-header__menu">
        <LandingAndMarketingMenu />
      </div>
    </div>
  </div>
</template>
<script>
import LogoMarkWhite from '~/components/LogoMarkWhite';
import LogoWhite from '~/components/LogoWhite';
import LandingAndMarketingMenu from '~/layouts/partials/LandingAndMarketingMenu';
export default {
  components: {
    LandingAndMarketingMenu,
    LogoMarkWhite,
    LogoWhite,
  },
};
</script>
<style lang="scss">
.marketing-header {
  background-color: $primary;

  .container {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    .logo {
      max-width: 176px;
    }

    .logo-mark {
      display: none;
    }
  }
}

@include media-breakpoint-down(sm) {
  .marketing-header {
    &__logo {
      .logo {
        display: none;
      }

      .logo-mark {
        display: block;
        max-width: 30px;
      }
    }
  }
}
@include media-breakpoint-down(xs) {
  .marketing-header {
    &__logo {
      .logo-mark {
        max-width: 24px;
      }
    }
  }
}
</style>
