var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VSelect", {
    attrs: {
      name: "Select a service",
      vid: "service_id",
      "value-key": "id",
      "label-key": "name",
      options: _vm.options,
      placeholder: "Select...",
      loading: _vm.loading
    },
    model: {
      value: _vm.currentValue,
      callback: function($$v) {
        _vm.currentValue = $$v
      },
      expression: "currentValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }