var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "search-input" },
    [
      _c("IconSearch", { attrs: { width: 20, height: 20 } }),
      _vm._v(" "),
      _c("input", {
        attrs: { type: "text", placeholder: "Search..." },
        domProps: { value: _vm.value },
        on: { input: _vm.onInput }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }