var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M24 12.072C24 5.689 19.001.422 12.626.09 6.252-.243.732 4.472.066 10.82-.601 17.17 3.82 22.929 10.125 23.926v-8.385H7.078v-3.469h3.047V9.428c0-3.007 1.792-4.669 4.532-4.669.9.013 1.798.091 2.687.234v2.954H15.83a1.734 1.734 0 00-1.955 1.875v2.25H17.2l-.532 3.469h-2.8v8.385A12 12 0 0024 12.072z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }