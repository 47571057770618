<template>
  <div class="select-dropdown">
    <button class="select-dropdown__btn" @click="toggle">
      <span>{{ selected.label }}</span>
      <IconDropDown width="20" height="20" />
    </button>
    <transition name="dropdown-transition">
      <div v-if="isOpen" class="select-dropdown__menu">
        <a
          v-for="item in items"
          href="#"
          class="select-dropdown__item"
          @click.prevent="onSelect(item)"
          >{{ item.label }}</a
        >
      </div>
    </transition>
  </div>
</template>
<script>
import IconDropDown from '~/components/icons/IconDropDown';
export default {
  components: { IconDropDown },
  props: {
    selected: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    show() {
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
    onSelect(item) {
      this.$emit('selected', item);
      this.hide();
    },
  },
};
</script>
<style lang="scss">
.select-dropdown {
  position: relative;
  float: left;
  z-index: 999;

  &__btn {
    display: inline-flex;
    align-items: center;
    padding: 10px 20px;
    background-color: $white;
    border: 1px solid #e5e5e5;
    color: $gray-800;
    border-radius: $border-radius;

    span {
      margin-right: $s-1;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
    }

    svg {
      color: rgba($gray-800, 0.25);
    }
  }

  &__menu {
    top: $btn-height + 8px;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    overflow: hidden;
  }

  &__item {
    width: 100%;
    font-weight: 500;
    color: $black;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 16px;
    display: block;
    background-color: $white;
    transition: background-color 0.15s ease-out;
  }

  @at-root {
    .no-touchevents {
      .dropdown__item:hover {
        background-color: $gray-100;
      }
    }
  }
}

.dropdown-transition-enter-active,
.dropdown-transition-leave-active {
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
}

.dropdown-transition-enter,
.dropdown-transition-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
