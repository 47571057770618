export default {
  validate: (value) => {
    if (value === null || value === undefined || value === '') {
      return false;
    }

    return /^(?=.*[A-Z])(?=.*(_|[^\w])).+$/.test(String(value));
  },
  params: [],
  message: 'Password must contain an uppercase letter and a symbol.',
};
