var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DashboardPage", { attrs: { title: "Connect" } }, [
    _c("div", { staticClass: "connect-inner" }, [
      _c(
        "div",
        { staticClass: "connect-inner__pane" },
        [
          _c("ValidationObserver", {
            ref: "form",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var handleSubmit = ref.handleSubmit
                  var invalid = ref.invalid
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.submit)
                          }
                        }
                      },
                      [
                        _c("VSelect", {
                          attrs: {
                            name: "Company",
                            vid: "company_id",
                            "value-key": "id",
                            "label-key": "business_name",
                            "empty-value-label": "Select company...",
                            rules: "required",
                            options: _vm.companies,
                            loading: _vm.loading
                          },
                          model: {
                            value: _vm.form.company_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "company_id", $$v)
                            },
                            expression: "form.company_id"
                          }
                        }),
                        _vm._v(" "),
                        _c("VSelect", {
                          attrs: {
                            name: "Partner",
                            vid: "partner_id",
                            "value-key": "id",
                            "label-key": "business_name",
                            "empty-value-label": "Select partner...",
                            rules: "required",
                            options: _vm.partners,
                            loading: _vm.loading
                          },
                          model: {
                            value: _vm.form.partner_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "partner_id", $$v)
                            },
                            expression: "form.partner_id"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "LoadingBtn",
                          {
                            staticClass: "btn",
                            attrs: {
                              loading: _vm.connecting,
                              disabled: invalid,
                              type: "submit"
                            }
                          },
                          [_vm._v("Connect\n          ")]
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }