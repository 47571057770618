<script>
import MarketplaceShow from '~/pages/dashboard/marketplace/MarketplaceShow';
import UserEnum from '~/enums/UserEnum';
import Connection from '~/models/Connection';
import Company from '~/models/Company';

export default {
  extends: MarketplaceShow,
  data() {
    return {
      type: UserEnum.COMPANY,
    };
  },
  methods: {
    getConnection() {
      return Connection.where('partner_id', this.userable.id)
        .where('company_id', this.business.id)
        .firstOrNull();
    },
    getBaseQuery() {
      return Company.include(
        'productsCount',
        'partnersAcceptedCount',
        'sectors'
      );
    },
  },
};
</script>
