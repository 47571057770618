var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Settings" } },
    [
      _c(
        "SettingsWrapper",
        [
          _c(
            "Tab",
            { attrs: { name: "Business Details" } },
            [_c("TabCompanyOrPartnerBusinessDetails")],
            1
          ),
          _vm._v(" "),
          _c("Tab", { attrs: { name: "Account" } }, [_c("TabAccount")], 1),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Change Password" } },
            [_c("TabChangePassword")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Bank Details" } },
            [_c("TabBankDetails")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Testimonials" } },
            [_c("TabTestimonials")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "VAT Settings" } },
            [_c("TabCompanyOrPartnerVat")],
            1
          ),
          _vm._v(" "),
          _vm.hasFeatureServices()
            ? _c(
                "Tab",
                { attrs: { name: "Sectors & Services" } },
                [_c("TabSectorsAndServices")],
                1
              )
            : _c("Tab", { attrs: { name: "Sectors" } }, [_c("TabSectors")], 1),
          _vm._v(" "),
          !_vm.userable.is_individual
            ? _c("Tab", { attrs: { name: "Settings" } }, [_c("TabMore")], 1)
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }