<script>
import FieldWrapper from '~/components/form/FieldWrapper';
import FieldWrapperPropsMixin from '~/components/form/FieldWrapperPropsMixin';

export default {
  components: {
    FieldWrapper,
  },
  mixins: [FieldWrapperPropsMixin],
  props: {
    value: {
      type: [Number, String, Array, Object, Boolean],
      default: () => '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      currentValue: this.value,
    };
  },
  watch: {
    currentValue(value) {
      this.$emit('input', value);
    },
    value(val) {
      if (val !== this.currentValue) {
        this.currentValue = val;
      }
    },
  },
  created() {
    if (this.value) {
      this.currentValue = this.value;
    }
  },
};
</script>
