<script>
import UserEnum from '~/enums/UserEnum';
import Connection from '~/models/Connection';
import ConnectionApplicantEnum from '~/enums/ConnectionApplicantEnum';

export default {
  methods: {
    async connectRequest(attributes) {
      let subtitle =
        this.type === UserEnum.PARTNER
          ? 'Send a request so you can grow your partner network?'
          : 'Send a request to connect and start selling more products?';
      const { confirmed, stopLoading } = await this.$confirm({
        subtitle: subtitle,
        withLoading: true,
        danger: false,
      });

      if (!confirmed) {
        stopLoading();
        return;
      }

      try {
        let connection = new Connection(attributes);
        await connection.save();

        this.$emit('connect-request', connection);
        this.$toasted.success('Request sent');

        return connection;
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        stopLoading();
      }
    },
    getConnectionAttributes({ type, business, applicant }) {
      if (type === UserEnum.PARTNER) {
        return {
          partner_id: business.id,
          company_id: applicant.id,
          applicant: ConnectionApplicantEnum.COMPANY,
        };
      } else {
        return {
          company_id: business.id,
          partner_id: applicant.id,
          applicant: ConnectionApplicantEnum.PARTNER,
        };
      }
    },
  },
};
</script>
