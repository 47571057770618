import Model from '~/models/Model';
import Money from '~/money';

export default class Product extends Model {
  resource() {
    return 'products';
  }

  async updateStock(newStockCount) {
    const endpoint = this.endpoint();

    return await this.request({
      method: 'POST',
      url: `${endpoint}/update-stock`,
      data: {
        new_stock_count: newStockCount,
      },
    });
  }

  async getSimilarProducts(partner = null) {
    const endpoint = this.endpoint();

    let query = Product.include('image', 'company');
    if (partner) {
      query.where('listingPartners.id', partner.id);
    }
    // Get actual query string.
    query = query._builder.query();

    let response = await this.request({
      method: 'GET',
      url: `${endpoint}/similar-products${query}`,
    });

    let products = response.data;

    return products.map((p) => {
      return new Product(p);
    });
  }
}
