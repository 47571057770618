var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "business-more-details", class: _vm.classes },
    [
      !_vm.partner.is_individual && _vm.partner.stores_count !== null
        ? _c(
            "div",
            { staticClass: "business-more-details__item" },
            [
              _c("IconStores", {
                attrs: { width: _vm.size, height: _vm.size }
              }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.countString(_vm.partner.stores_count, "locations"))
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.partner.is_individual && _vm.partner.sections_count !== null
        ? _c(
            "div",
            { staticClass: "business-more-details__item" },
            [
              _c("IconSections", {
                attrs: { width: _vm.size, height: _vm.size }
              }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.countString(_vm.partner.sections_count, "sections")
                  )
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.partner.is_individual
        ? _c(
            "div",
            { staticClass: "business-more-details__item" },
            [
              _c("IconAgent", { attrs: { width: _vm.size, height: _vm.size } }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  _vm._s(_vm.countString(_vm.partner.agents_count, "agents"))
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "business-more-details__item" },
        [
          _c("IconPartners", { attrs: { width: _vm.size, height: _vm.size } }),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.countString(
                  _vm.partner.companies_accepted_count,
                  "connections"
                )
              )
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _vm.all && _vm.partner.has_wifi
        ? _c(
            "div",
            { staticClass: "business-more-details__item" },
            [
              _c("IconWiFi", { attrs: { width: _vm.size, height: _vm.size } }),
              _vm._v(" "),
              _c("span", [_vm._v("Free wi-fi")])
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.all && _vm.partner.is_front_of_house
        ? _c(
            "div",
            { staticClass: "business-more-details__item" },
            [
              _c("IconDesk", { attrs: { width: _vm.size, height: _vm.size } }),
              _vm._v(" "),
              _c("span", [_vm._v("Front of house")])
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }