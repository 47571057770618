<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(submit)" class="form">
      <VInput
        :value="userable.partner.code"
        name="Marketplace ID"
        readonly
        :info-text="`Share this unique ID with your clients so they can easily find your store on ${route(
          'home'
        )}`"
      />
      <VInput
        v-model="form.contact_number"
        label="Contact Number (with country code eg. +353861802036)"
        name="Contact Number"
        vid="contact_number"
      />
      <VInput
        v-model="form.vat_no"
        name="VAT Number"
        vid="vat_no"
        placeholder="Enter VAT Number"
      />
      <AddressTypeahead v-model="place" />
      <LoadingBtn
        :loading="loading"
        :disabled="invalid"
        class="btn"
        type="submit"
        >Save
      </LoadingBtn>
    </form>
  </ValidationObserver>
</template>
<script>
import VInput from '~/components/form/VInput';
import TabCompanyOrPartnerBase from '~/pages/dashboard/common/settings/TabCompanyOrPartnerBase';
import Agent from '~/models/Agent';
import AddressTypeahead from '~/components/AddressTypeahead';
import SavesAddressMixin from '~/pages/dashboard/common/settings/SavesAddressMixin';

export default {
  extends: TabCompanyOrPartnerBase,
  mixins: [SavesAddressMixin],
  components: {
    VInput,
    AddressTypeahead,
  },
  computed: {
    id() {
      return this.userable.id;
    },
    userable() {
      return this.$global.userable;
    },
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        const model = new Agent({
          id: this.id,
          ...this.form,
        });
        await model.save();
        this.$toasted.success('Settings saved.');
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },
    getForm() {
      return {
        contact_number: this.userable.contact_number,
        vat_no: this.userable.vat_no,
        place_id: this.userable.place_id,
        place_description: this.userable.place_description,
      };
    },
  },
};
</script>
