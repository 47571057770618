<template>
  <Modal @modal-close="handleClose">
    <div class="modal__header">
      <h3 class="modal__title">Edit product</h3>
      <button type="button" class="modal__close" @click="handleClose">
        <IconClose />
      </button>
    </div>
    <div class="modal__body">
      <Loader class="modal__body__loader" v-if="fetchingProduct" />
      <ValidationObserver v-else ref="form" v-slot="{ handleSubmit, invalid }">
        <form class="form" @submit.prevent="handleSubmit(submit)">
          <VInput
            v-model="form.name"
            name="Product name"
            vid="name"
            rules="required|min:3"
          />
          <div class="form-2-col">
            <SectorField v-model="form.sector_id" filter-by-auth />
            <CategoryField
              v-model="form.category_id"
              :sector-id.sync="form.sector_id"
              filter-by-auth
            />
          </div>
          <VTextarea
            v-model="form.description"
            name="Description"
            vid="description"
            label-info-text="- Optional"
          />
          <VTextarea
            v-model="form.features"
            name="Features"
            vid="features"
            label-info-text="- Optional"
          />
          <div class="form-2-col">
            <VInput
              v-model="form.weight"
              name="Weight"
              vid="weight"
              label-info-text="- Optional"
            />
            <VInput
              v-model="form.dimensions"
              name="Dimensions"
              vid="dimensions"
              placeholder="Length x Width x Height"
              label-info-text="- Optional"
            />
          </div>
          <div class="form-2-col">
            <VInput
              v-model="form.size"
              name="Size"
              vid="size"
              label-info-text="- Only 1 size supported - optional"
            />
            <VInput
              v-model="form.colors_string"
              label="Available colours"
              name="Colours"
              vid="colors_string"
              label-info-text="- Hex values, comma separated - optional"
            />
          </div>
          <VInput
            :value="form.sku"
            name="SKU code"
            vid="sku"
            :disabled="true"
          />
          <div class="form-2-col">
            <VMoney
              v-model="priceInMajorUnits"
              name="Price (€)"
              vid="price"
              rules="required|decimal|min_value:0.1"
            />
            <VMoney
              v-model="deliveryCostInMajorUnits"
              name="Delivery (€)"
              vid="delivery_cost"
              rules="required|decimal|min_value:0"
            />
          </div>
          <VPercent
            v-model="defaultCommissionPercentage"
            name="Default commission (%)"
            vid="default_commission_rate"
            rules="required|decimal|max_value:100|min_value:0"
          />
          <VTextarea
            v-model="form.commission_details"
            name="Commission details"
            vid="commission_details"
            label-info-text="- Optional"
          />
          <VInput
            v-model="form.video_url"
            name="Product video URL"
            vid="video_url"
            rules="url"
            label-info-text="- Optional"
            info-text="Some text here"
          />
          <VInput
            v-model="form.question"
            name="Product question"
            vid="question"
            label-info-text="- Optional"
            placeholder="Enter a custom message on the label below"
          />
          <ValidationProvider name="images" vid="images" v-slot="{ errors }">
            <div class="form-group">
              <label
                ><span>Add photos to your gallery</span
                ><span class="optional-label"
                  >- Minimum 1 required. 880x880px jpeg for best results<</span
                ></label
              >
              <MultiUploader
                :for="mediaFor"
                :collection="MediaCollectionEnum.IMAGES"
                :count="form.images.length"
                :limit="imagesLimit"
                @upload-success="onMediaAdded"
              />
              <MediaList
                v-if="form.images.length"
                :list="form.images"
                @remove="handleRemoveMedia"
              />
              <span v-if="errors[0]" class="form-group__error">{{
                errors[0]
              }}</span>
            </div>
          </ValidationProvider>
          <div class="modal__btn-wrapper">
            <LoadingBtn
              :loading="loading"
              :disabled="invalid"
              class="btn"
              type="submit"
            >
              Update
            </LoadingBtn>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </Modal>
</template>
<script>
import ProductModal from '~/components/product-modal/ProductModal';
import Product from '~/models/Product';
import Loader from '~/components/Loader';

export default {
  extends: ProductModal,
  components: {
    Loader,
  },
  props: {
    productId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      product: null,
      fetchingProduct: true,
    };
  },
  async created() {
    this.fetchingProduct = true;
    try {
      this.product = await Product.include('images').find(this.productId);
      this.hydrateFormData();
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.fetchingProduct = false;
    }
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        let product = new Product({
          id: this.productId,
          ...this.form,
        });

        await product.save();

        this.$toasted.success('Product updated!');
        this.$emit('updated');
        this.handleClose();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },
    hydrateFormData() {
      this.form = {
        company_id: this.$global.userable.id,

        name: this.product.name || '',
        category_id: this.product.category_id || '',
        description: this.product.description || '',
        features: this.product.features || '',

        weight: this.product.weight || '',
        dimensions: this.product.dimensions || '',
        size: this.product.size || '',
        colors_string: this.product.colors_string || '',
        sku: this.product.sku || '',

        price: this.product.price.amount || '',
        delivery_cost: this.product.delivery_cost.amount || '',
        default_commission_rate: this.product.default_commission_rate || '',
        commission_details: this.product.commission_details || '',

        video_url: this.product.video_url || '',
        question: this.product.question || '',

        images: this.product.images || [],
      };

      this.priceInMajorUnits = this.product.price.amount / 100;
      this.deliveryCostInMajorUnits = this.product.delivery_cost.amount / 100;
      this.defaultCommissionPercentage =
        this.product.default_commission_rate * 100;
    },
  },
};
</script>
