var render, staticRenderFns
import script from "./SavesAddressMixin.vue?vue&type=script&lang=js&"
export * from "./SavesAddressMixin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.thatsthe1.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1eff9ab2')) {
      api.createRecord('1eff9ab2', component.options)
    } else {
      api.reload('1eff9ab2', component.options)
    }
    
  }
}
component.options.__file = "resources/js/pages/dashboard/common/settings/SavesAddressMixin.vue"
export default component.exports