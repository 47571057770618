<template>
  <div class="progressLine">
    <span class="line">
      <span class="completed" :style="{ width: `${value}%` }"></span>
    </span>
    <span class="value">{{ value }}%</span>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang="scss">
.progressLine {
  display: flex;
  width: 100%;
  align-items: center;

  .line {
    flex: 1 1 75%;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    height: 8px;
    position: relative;

    .completed {
      border-radius: 4px;
      background-color: $accent-purple;
      position: absolute;
      height: 8px;
    }
  }

  .value {
    flex: 1 1 25%;
    padding-left: $s-4;
    text-align: right;
    font-size: $text-lg;
    color: $gray-800;
    line-height: $text-3xl;
    font-weight: 300;
  }
}
</style>
