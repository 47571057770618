<template>
  <div class="multiple-pill-select">
    <Loader v-if="loading" />
    <template v-else>
      <a
        href="#"
        v-for="option in options"
        class="multiple-pill-select__choice"
        :class="{
          'multiple-pill-select__choice--selected': isSelected(option),
        }"
        @click.prevent="toggle(option)"
      >
        {{ option[labelKey] }}
      </a>
      <a
        v-if="showSelectAll"
        href="#"
        class="multiple-pill-select__choice"
        :class="{
          'multiple-pill-select__choice--selected': allSelected,
        }"
        @click.prevent="selectAll"
        >Select all{{ selectLabelWithSpace }}</a
      >
      <a
        v-if="showSelectAllAndFuture"
        href="#"
        class="multiple-pill-select__choice"
        :class="{
          'multiple-pill-select__choice--selected': allAndFuture,
        }"
        @click.prevent="toggleAllAndFuture"
        >Select all current and future{{ selectLabelWithSpace }}</a
      >
    </template>
  </div>
</template>
<script>
import InputMixin from '~/components/form/InputMixin';
import { areArraysEqualSets } from '~/utils';
import Loader from '~/components/Loader';

// Currently it's not used.
const ALL_AND_FUTURE = 'all_current_and_all_future';

export default {
  mixins: [InputMixin],
  components: {
    Loader,
  },
  props: {
    valueKey: {
      type: String,
      required: false,
      default: 'value',
    },
    labelKey: {
      type: String,
      required: false,
      default: 'label',
    },
    selectLabel: {
      type: String,
      required: false,
      default: '',
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSelectAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    showSelectAllAndFuture: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      currentValue: this.value ? this.value : [],
    };
  },
  computed: {
    allSelected() {
      return this.currentValue.length === this.options.length;
    },
    allAndFuture() {
      return this.currentValue.includes(ALL_AND_FUTURE);
    },
    selectLabelWithSpace() {
      return this.selectLabel ? ` ${this.selectLabel}` : '';
    },
  },
  watch: {
    value(val) {
      if (!this.isSame(val, this.currentValue)) {
        this.currentValue = val;
      }
    },
  },
  methods: {
    isSame(val1, val2) {
      return areArraysEqualSets(val1, val2);
    },
    toggle(val) {
      if (this.isSelected(val)) {
        this.currentValue = this.currentValue.filter(
          (v) => v !== val[this.valueKey]
        );
        return;
      }
      this.currentValue.push(val[this.valueKey]);
    },
    selectAll() {
      this.currentValue = this.options.map((o) => o[this.valueKey]);
    },
    toggleAllAndFuture() {
      if (this.allAndFuture) {
        this.currentValue = this.currentValue.filter(
          (v) => v !== ALL_AND_FUTURE
        );
        return;
      }

      this.currentValue.push(ALL_AND_FUTURE);
    },
    isSelected(value) {
      return this.currentValue.includes(value[this.valueKey]);
    },
  },
};
</script>
<style lang="scss">
.multiple-pill-select {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  &__choice {
    border-radius: 20px;
    margin: 0 $s-2 $s-4;
    padding: $s-2 $s-4;
    color: rgba($gray-800, 0.5);
    background-color: rgba($black, 0.05);
    transition: background-color 0.15s ease-out, color 0.15s ease-out;
    font-size: 14px;
  }

  @at-root {
    .no-touchevents {
      .multiple-pill-select__choice:hover:not(.multiple-pill-select__choice--selected) {
        color: $gray-800;
        background-color: rgba($black, 0.1);
      }
    }
  }

  &__choice {
    &--selected {
      color: $white;
      background-color: $primary;
    }
  }
}
</style>
