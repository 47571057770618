<template>
  <div class="media-list">
    <div v-for="media in list" class="media-item">
      <div class="media-item__inner">
        <a
          href="#"
          class="media-item__remove"
          @click.prevent="$emit('remove', media)"
        >
          <IconClose :width="20" :height="20" />
        </a>
        <img :src="media.urls.thumb" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import IconClose from '~/components/icons/IconClose';

export default {
  components: {
    IconClose,
  },
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.media-list {
  display: flex;
  flex-wrap: wrap;
  margin: $s-4 -#{$s-1} 0;
}

.media-item {
  width: 33.333%;
  max-width: 128px;
  padding: 0 $s-1;
  margin-bottom: $s-2;

  &__inner {
    position: relative;
  }

  img {
    width: 100%;
  }

  &__remove {
    width: 24px;
    height: 24px;
    display: flex;
    background-color: rgba($black, 0.25);
    border-radius: 50%;
    position: absolute;
    left: $s-2;
    top: $s-2;

    svg {
      margin: auto;
      fill: rgba($black, 0.5);
      width: 12px;
    }

    @at-root {
      .no-touchevents {
        .media-item__remove:hover {
          background-color: rgba($black, 0.5);
        }
      }
    }
  }
}
</style>
