var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product.video
    ? _c(
        "a",
        {
          staticClass: "pd__watch-video",
          attrs: { href: "#" },
          on: {
            click: function($event) {
              $event.preventDefault()
              return _vm.openLightbox($event)
            }
          }
        },
        [
          _c("IconPlay", { attrs: { width: "16", height: "16" } }),
          _vm._v(" "),
          _c("span", [_vm._v("Watch product video")])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }