var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Datatable", {
    ref: "datatable",
    attrs: {
      "get-base-query": _vm.getBaseQuery,
      "query-custom-params": _vm.queryCustomParams
    },
    scopedSlots: _vm._u([
      {
        key: "cell(actions)",
        fn: function(ref) {
          var item = ref.item
          return [
            _c(
              "div",
              { staticClass: "table__row-actions" },
              [
                _c("UpdateConnectionStatus", {
                  attrs: { connection: _vm.connectionFromRow(item) },
                  on: { updated: _vm.onConnectionUpdated }
                })
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }