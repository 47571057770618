<template>
  <div v-on-clickaway="onClickaway" class="drawer" :class="classes">
    <div class="drawer__top">
      <a :href="route('dashboard')" class="drawer__logo">
        <LogoMark v-if="condensed" />
        <Logo v-else />
      </a>
      <div class="drawer__toggle" @click="$emit('toggle-condensed')">
        <IconChevronLeft :width="20" :height="20" />
      </div>
    </div>
    <div class="drawer__content">
      <ul class="drawer__menu">
        <li v-for="item in items">
          <a
            :href="route(item.route)"
            class="drawer__menu__link"
            :class="{ active: route().current(item.route) }"
          >
            <span class="drawer__menu__icon" v-tooltip="getMenuTooltip(item)">
              <component :is="item.icon" :width="20" :height="20" />
            </span>
            <span class="drawer__menu__label">{{ item.label }}</span>
          </a>
        </li>
      </ul>
    </div>
    <div class="drawer__bottom">
      <VPopover
        placement="right-end"
        boundaries-element="body"
        popover-class="tooltip-with-menu"
      >
        <span class="drawer__user">
          <UserAvatar :avatar="user.avatar" :colour="user.avatar_colour" />
          <span>{{ user.name }}</span>
        </span>
        <template slot="popover">
          <div class="tooltip-menu">
            <a
              :href="profileUrl"
              target="_blank"
              v-if="
                (!isTt1 && userType === UserEnum.PARTNER) ||
                userType === UserEnum.COMPANY
              "
              class="tooltip-menu__item"
            >
              View profile
            </a>
            <button
              v-if="
                (userType === UserEnum.PARTNER ||
                  userType === UserEnum.AGENT) &&
                userable.has_profile_complete &&
                userable.has_stripe_connected
              "
              class="tooltip-menu__item"
              v-clipboard:copy="storeUrl"
              v-clipboard:success="onUrlCopied"
              v-clipboard:error="onUrlCopyFailed"
            >
              Copy store URL
            </button>
            <a class="tooltip-menu__item" :href="this.route('logout')"
              >Log out</a
            >
          </div>
        </template>
      </VPopover>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import { mixin as clickaway } from 'vue-clickaway';
import UserAvatar from '~/components/UserAvatar';
import UserEnum from '~/enums/UserEnum';
import IconChevronLeft from '~/components/icons/IconChevronLeft';
import IconDashboard from '~/components/icons/IconDashboard';
import IconSales from '~/components/icons/IconSales';
import IconProducts from '~/components/icons/IconProducts';
import IconMarketplace from '~/components/icons/IconMarketplace';
import IconSectors from '~/components/icons/IconSectors';
import IconConnect from '~/components/icons/IconConnect';
import IconCompanies from '~/components/icons/IconCompanies';
import IconPartners from '~/components/icons/IconPartners';
import IconAgent from '~/components/icons/IconAgent';
import IconCategory from '~/components/icons/IconCategory';
import IconSettings from '~/components/icons/IconSettings';
import { VPopover } from 'v-tooltip';
import VueClipboard from 'vue-clipboard2';
import { mapGetters, mapState } from 'vuex';
import Logo from '~/components/Logo';
import LogoMark from '~/components/LogoMark';
import { closest } from '~/utils';
import PlatformSlugEnum from '~/enums/PlatformSlugEnum';

Vue.use(VueClipboard);

export default {
  components: {
    UserAvatar,
    IconChevronLeft,
    IconDashboard,
    IconSales,
    IconProducts,
    IconMarketplace,
    IconSectors,
    IconConnect,
    IconCompanies,
    IconCategory,
    IconPartners,
    IconAgent,
    IconSettings,
    VPopover,
    Logo,
    LogoMark,
  },
  mixins: [clickaway],
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    condensed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      UserEnum,
      isTt1: process.env.MIX_PLATFORM_SLUG === PlatformSlugEnum.TT1,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'userable']),
    ...mapGetters('auth', ['userType']),
    items() {
      switch (this.userType) {
        case UserEnum.ADMIN:
          return this.adminItems;
        case UserEnum.COMPANY:
          return this.companyItems;
        case UserEnum.PARTNER:
          return this.partnerItems;
        case UserEnum.AGENT:
          return this.agentItems;
      }
    },
    adminItems() {
      return [
        {
          label: 'Dashboard',
          icon: 'IconDashboard',
          route: 'admin.dashboard',
        },
        {
          label: 'Companies',
          icon: 'IconCompanies',
          route: 'admin.companies',
        },
        {
          label: 'Partners',
          icon: 'IconPartners',
          route: 'admin.partners',
        },
        {
          label: 'Sales',
          icon: 'IconSales',
          route: 'admin.sales',
        },
        {
          label: 'Connect',
          icon: 'IconConnect',
          route: 'admin.connect',
        },
        {
          label: 'Configuration',
          icon: 'IconCategory',
          route: 'admin.configuration',
        },
        {
          label: 'Settings',
          icon: 'IconSettings',
          route: 'admin.settings',
        },
      ];
    },
    companyItems() {
      let items = [
        {
          label: 'Dashboard',
          icon: 'IconDashboard',
          route: 'company.dashboard',
        },
        {
          label: 'Marketplace',
          icon: 'IconMarketplace',
          route: 'marketplace.partners.index',
        },
        {
          label: 'Sales',
          icon: 'IconSales',
          route: 'company.sales',
        },
        {
          label: 'Products',
          icon: 'IconProducts',
          route: 'company.products',
        },
        {
          label: 'Partners',
          icon: 'IconPartners',
          route: 'company.partners',
        },
        {
          label: 'Settings',
          icon: 'IconSettings',
          route: 'company.settings',
        },
      ];

      if (this.isTt1) {
        items = items.filter((i) => i.label !== 'Marketplace');
      }

      return items;
    },
    partnerItems() {
      let items = [
        {
          label: 'Dashboard',
          icon: 'IconDashboard',
          route: 'partner.dashboard',
        },
        {
          label: 'Marketplace',
          icon: 'IconMarketplace',
          route: 'marketplace.companies.index',
        },
        {
          label: 'Sales',
          icon: 'IconSales',
          route: 'partner.sales',
        },
        {
          label: 'Products',
          icon: 'IconProducts',
          route: 'partner.products',
        },
        {
          label: 'Companies',
          icon: 'IconCompanies',
          route: 'partner.companies',
        },
        {
          label: 'Agents',
          icon: 'IconAgent',
          route: 'partner.agents',
        },
        {
          label: 'Settings',
          icon: 'IconSettings',
          route: 'partner.settings',
        },
      ];

      if (this.userable.is_individual) {
        items = items.filter((i) => i.label !== 'Agents');
      }

      if (this.isTt1) {
        items = items.filter((i) => i.label !== 'Marketplace');
      }

      return items;
    },
    agentItems() {
      return [
        {
          label: 'Dashboard',
          icon: 'IconDashboard',
          route: 'agent.dashboard',
        },
        {
          label: 'Sales',
          icon: 'IconSales',
          route: 'agent.sales',
        },
        {
          label: 'Settings',
          icon: 'IconSettings',
          route: 'agent.settings',
        },
      ];
    },
    classes() {
      let classes = [];

      if (this.condensed) {
        classes.push('drawer--condensed');
      }

      if (this.open) {
        classes.push('drawer--open');
      }

      return classes;
    },
    storeUrl() {
      let type = this.userType;

      let params = {
        partner: this.userable.slug,
      };

      if (type === UserEnum.AGENT) {
        params.partner = this.userable.partner.slug;
        params.agent = this.userable.id;
      }

      return this.route(`store.partner`, params);
    },
    profileUrl() {
      let routeName =
        this.userType === UserEnum.PARTNER ? 'partners' : 'companies';
      return this.route(`marketplace.${routeName}.show`, {
        [this.userType]: this.userable.slug,
      });
    },
  },
  methods: {
    getMenuTooltip(item) {
      if (!this.condensed) {
        return false;
      }

      return {
        content: item.label,
        placement: 'right',
        boundariesElement: 'body',
      };
    },
    onUrlCopied() {
      this.$toasted.success('URL has been copied to your clipboard.');
    },
    onUrlCopyFailed() {
      this.$toasted.success(
        `We couldn't copy the URL. You can copy it manually ${this.storeUrl}`
      );
    },
    onClickaway(event) {
      let clickedEl = event.target;

      let clickedBurgerIcon = closest(
        clickedEl,
        (el) =>
          el &&
          el.classList &&
          el.classList.contains('dashboard-header__drawer-toggle')
      );

      if (clickedBurgerIcon) {
        return;
      }

      this.$emit('clickaway');
    },
  },
};
</script>
<style lang="scss">
.drawer {
  $toggle-width: 20px;
  $top-x-padding: 20px;
  $drawer-top-height: 80px;
  $drawer-bottom-height: 80px;
  z-index: $z-index-drawer;

  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  border-right: 1px solid $gray-500;
  background-color: $white;
  width: $drawer-desktop-width;
  transform: translateX(-100%);
  transition: width 0.15s ease-out, transform 0.15s ease-out;

  &__top {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $drawer-top-height;
    display: flex;
    align-content: center;
    border-bottom: 1px solid $gray-500;
    padding-left: $top-x-padding;
    padding-right: $toggle-width + $top-x-padding;
  }

  &__logo {
    width: 100%;
    position: relative;
    height: 100%;
    overflow: hidden;

    .logo,
    .logo-mark {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .logo {
      width: $drawer-desktop-width - ($top-x-padding * 2 + $toggle-width) - 1;
    }

    .logo-mark {
      width: $drawer-desktop-width-condensed - ($top-x-padding * 2) - 1;
    }
  }

  &__toggle {
    height: calc(100% + 1px);
    background-color: rgba($black, 0.1);
    width: 20px;
    display: flex;
    position: absolute;
    right: 0;
    cursor: pointer;
    transform-origin: right;
    transition: transform 0.15s ease-out;

    svg {
      margin: auto;
      opacity: 0.1;
    }
  }

  &__content {
    padding-top: $drawer-top-height;
    padding-bottom: $drawer-bottom-height;
    height: 100%;
    overflow-y: auto;
  }

  &__menu {
    $menu-margin-right: 15px;
    $icon-margin-right: 15px;

    margin: 35px 0;

    &__link {
      padding: 0 5px 0 20px;
      display: flex;
      align-items: center;
      background-color: rgba($black, 0);
      transition: background-color 0.15s ease-out;
    }

    &__icon {
      padding: 10px;
      margin-right: 5px;
      transition: background-color 0.15s;
      flex-shrink: 0;
      line-height: 0;
      border-radius: 50%;
      background-color: rgba($black, 0);

      svg {
        opacity: 0.25;
        transition: fill 0.15s ease-out, opacity 0.15s ease-out;
      }
    }

    &__label {
      font-size: 14px;
      color: rgba($black, 0.25);
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 16rem;
      transition: max-width 0.15s ease-out, color 0.15s ease-out;
    }

    &__link.active {
      .drawer__menu__icon {
        svg {
          opacity: 1;
          color: $primary;
        }
      }

      .drawer__menu__label {
        color: rgba($black, 1);
      }
    }

    @at-root {
      .drawer__menu {
        &__link:hover {
          background-color: rgba($black, 0.05);
        }
      }
    }
  }

  &__bottom {
    width: 100%;
    height: $drawer-bottom-height;
    display: flex;
    align-items: center;
    padding: 0 20px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-top: 1px solid $gray-500;
  }

  &__user {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .user-avatar {
      margin-right: $s-3;
      border-radius: 50%;
      border: 2px solid rgba(0, 0, 0, 0.1);
    }

    > span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      transition: max-width 0.15s ease-out;
    }
  }

  &--open {
    transform: translateX(0);
  }

  &--condensed {
    width: $drawer-desktop-width-condensed;

    .drawer {
      &__toggle {
        transform: rotateY(180deg);
      }

      &__top {
        padding-right: $top-x-padding;
      }

      &__menu {
        &__label {
          max-width: 0;
        }

        @at-root {
          .drawer__menu {
            &__link:hover {
              background-color: rgba($black, 0);

              .drawer__menu__icon {
                background-color: rgba($black, 0.05);
              }
            }
          }
        }
      }

      &__user {
        display: flex;
        align-items: center;

        span {
          max-width: 0;
        }
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .drawer {
    &__top {
      display: none;
    }

    &__content {
      padding-top: 0;
    }
  }
}
</style>
