var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M23.999 12.977C23.487 19.139 18.319 24 12.025 24c-3.182 0-6.07-1.25-8.223-3.276l4.492-4.043a5.975 5.975 0 003.731 1.306c2.974 0 5.443-2.171 5.922-5.01zM11.023 0v6.053c-2.839.48-5.01 2.949-5.01 5.922 0 1.183.348 2.285.94 3.217l-4.492 4.042A11.947 11.947 0 010 11.974C0 5.684 4.86.515 11.023 0zm2.004.001C18.858.485 23.515 5.141 24 10.973h-6.053a6.02 6.02 0 00-4.92-4.92z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }