<template>
  <DashboardPage title="Companies">
    <Tabs>
      <Tab name="Requests" tooltip="Connection Requests sent by you" selected>
        <ConnectionsRequested />
      </Tab>
      <Tab name="Pending" tooltip="Connection Requests awaiting your response">
        <ConnectionsPending
          ref="connectionsPendingTable"
          @updated="onConnectionUpdated"
        />
      </Tab>
      <Tab name="Live" tooltip="Current Live Partner Connections">
        <ConnectionsLive ref="connectionsLiveTable" />
      </Tab>
      <Tab name="Declined" tooltip="Declined Connection Requests">
        <ConnectionsDeclined ref="connectionsDeclinedTable" />
      </Tab>
    </Tabs>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Tabs from '~/components/Tabs';
import Tab from '~/components/Tab';
import DashboardPage from '~/components/DashboardPage';
import ConnectionsRequested from '~/pages/dashboard/partner/partials/connections/ConnectionsRequested';
import ConnectionsPending from '~/pages/dashboard/partner/partials/connections/ConnectionsPending';
import ConnectionsLive from '~/pages/dashboard/partner/partials/connections/ConnectionsLive';
import ConnectionsDeclined from '~/pages/dashboard/partner/partials/connections/ConnectionsDeclined';

export default {
  extends: Page,
  components: {
    ConnectionsDeclined,
    ConnectionsLive,
    ConnectionsPending,
    ConnectionsRequested,
    DashboardPage,
    Tabs,
    Tab,
  },
  methods: {
    onConnectionUpdated() {
      this.$refs.connectionsPendingTable.refresh();
      this.$refs.connectionsLiveTable.refresh();
      this.$refs.connectionsDeclinedTable.refresh();
    },
  },
};
</script>
<style lang="scss"></style>
