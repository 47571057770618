var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "StorePageWrapper",
    { attrs: { type: _vm.type, business: _vm.business } },
    [
      _c("div", { staticClass: "store-product-detail" }, [
        _c(
          "div",
          { staticClass: "store__container" },
          [
            _c(
              "div",
              { staticClass: "store-product-detail__back-button" },
              [_c("BackButton")],
              1
            ),
            _vm._v(" "),
            _vm.loading
              ? _c("Loader")
              : [
                  _c("ProductDetail", {
                    scopedSlots: _vm._u([
                      {
                        key: "left",
                        fn: function() {
                          return [
                            _c("ProductDetailIntro", {
                              attrs: { product: _vm.product }
                            }),
                            _vm._v(" "),
                            _c("ProductDetailWatchVideo", {
                              attrs: { product: _vm.product }
                            }),
                            _vm._v(" "),
                            _c("ProductDetailAddToCartForm", {
                              attrs: { product: _vm.product }
                            }),
                            _vm._v(" "),
                            _c("ProductDetailAccordions", {
                              attrs: { product: _vm.product }
                            })
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "right",
                        fn: function() {
                          return [
                            _c("ProductDetailGallery", {
                              attrs: { product: _vm.product }
                            })
                          ]
                        },
                        proxy: true
                      }
                    ])
                  }),
                  _vm._v(" "),
                  _c("SimilarProducts", {
                    attrs: {
                      product: _vm.product,
                      "business-type": _vm.type,
                      business: _vm.business
                    }
                  })
                ]
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }