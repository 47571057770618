<template>
  <FieldWrapper
    v-slot="{ inputId, errors }"
    :rules="rules"
    :name="name"
    :label="label"
    :vid="vid"
    :label-info-text="labelInfoText"
    :info-text="infoText"
    :disabled="disabled"
    :no-label="noLabel"
  >
    <div class="v-qty-input">
      <a v-if="canDecrement" href="#" @click.prevent="decrement">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 2">
          <path d="M10 .5v1H0v-1z" />
        </svg>
      </a>
      <input
        :id="inputId"
        v-model="currentValue"
        type="text"
        :placeholder="placeholder"
        :name="vid"
        class="form-control"
        :class="{ 'is-invalid': errors[0] }"
        :disabled="disabled"
      />
      <a href="#" @click.prevent="increment">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 10">
          <path d="M5.5 0v4.5H10v1H5.5V10h-1V5.5H0v-1h4.5V0z" />
        </svg>
      </a>
    </div>
  </FieldWrapper>
</template>
<script>
import InputMixin from '~/components/form/InputMixin';

export default {
  mixins: [InputMixin],
  computed: {
    canDecrement() {
      return this.currentValue > 1;
    },
  },
  methods: {
    decrement() {
      this.currentValue--;
    },
    increment() {
      this.currentValue++;
    },
  },
};
</script>
<style lang="scss">
.v-qty-input {
  position: relative;

  > a {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    padding: 10px;

    &:first-child {
      left: 6px;
    }

    &:last-child {
      right: 6px;
    }

    svg {
      width: 10px;
      height: auto;
      fill: rgba($gray-800, 0.25);
    }
  }

  input {
    text-align: center;
  }
}

@include media-breakpoint-down(md) {
  .v-qty-input {
    position: relative;

    > a {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      padding: 10px;

      &:first-child {
        left: 6px;
      }

      &:last-child {
        right: 6px;
      }

      svg {
        width: 10px;
        height: auto;
        fill: rgba($gray-800, 0.25);
      }
    }

    input {
      text-align: center;
    }
  }
}
</style>
