<template>
  <div class="pd">
    <div class="pd__left">
      <slot name="left"></slot>
    </div>
    <div class="pd__right">
      <slot name="right"></slot>
    </div>
  </div>
</template>
<script>
import ProductDetailIntro from '~/components/product/ProductDetailIntro';
import ProductDetailWatchVideo from '~/components/product/ProductDetailWatchVideo';
import ProductDetailGallery from '~/components/product/ProductDetailGallery';
import ProductPriceAndCommission from '~/components/ProductPriceAndCommission';
import ProductDetailAccordions from '~/components/product/ProductDetailAccordions';
import ProductDetailColorsList from '~/components/product/ProductDetailColorsList';

export default {
  components: {
    ProductDetailColorsList,
    ProductDetailAccordions,
    ProductDetailGallery,
    ProductDetailWatchVideo,
    ProductDetailIntro,
    ProductPriceAndCommission,
  },
};
</script>
<style lang="scss">
.pd {
  display: flex;

  &__left {
    width: 50%;
    padding-right: $s-40;
  }

  &__right {
    width: 50%;
    margin-top: $s-2;
  }
}
@include media-breakpoint-down(md) {
  .pd {
    flex-wrap: wrap;

    &__left {
      width: 100%;
      padding-right: 0;
      margin-top: $s-8;
      order: 2;
      max-width: 900px;
    }

    &__right {
      width: 100%;
      margin-top: 0;
      order: 1;
    }
  }
}
</style>
