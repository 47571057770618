var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c("table", [
        _c("thead", [
          _c(
            "tr",
            _vm._l(_vm.fields, function(field) {
              return _c("th", [
                _c("span", [
                  _c("span", [_vm._v(_vm._s(field.label))]),
                  _vm._v(" "),
                  field.sortable
                    ? _c("span", { staticClass: "table__sortable" }, [
                        _c(
                          "a",
                          {
                            class: { active: _vm.activeOrderBy === field.key },
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.sort("asc", field.key)
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "6",
                                  height: "5",
                                  viewBox: "0 0 6 5"
                                }
                              },
                              [
                                _c("g", [
                                  _c("path", {
                                    attrs: { d: "M3 .5l3 4H0l3-4z" }
                                  })
                                ])
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            class: {
                              active: _vm.activeOrderBy === "-" + field.key
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.sort("desc", field.key)
                              }
                            }
                          },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  xmlns: "http://www.w3.org/2000/svg",
                                  width: "6",
                                  height: "5",
                                  viewBox: "0 0 6 5"
                                }
                              },
                              [
                                _c("g", [
                                  _c("path", {
                                    attrs: { d: "M3 4.5l3-4H0l3 4z" }
                                  })
                                ])
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ])
              ])
            }),
            0
          )
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._t(
              "body",
              _vm._l(_vm.items, function(item, index) {
                return _c(
                  "tr",
                  { key: index },
                  _vm._l(_vm.fields, function(field) {
                    return _c(
                      "td",
                      { key: field.key, staticClass: "table__cell" },
                      [
                        _c("TableCell", {
                          attrs: {
                            item: item,
                            field: field,
                            cell: item[field.key]
                          },
                          on: { "open-invoices-modal": _vm.openInvoicesModal },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(" + field.key + ")",
                                fn: function(ref) {
                                  var item = ref.item
                                  return [
                                    _vm._t("cell(" + field.key + ")", null, {
                                      item: item
                                    })
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
              }),
              { items: _vm.items }
            )
          ],
          2
        )
      ]),
      _vm._v(" "),
      _vm.loading ? _c("div", { staticClass: "table__loader" }) : _vm._e(),
      _vm._v(" "),
      _c(
        "Portal",
        { attrs: { to: "modals" } },
        [
          _vm.showInvoicesModal
            ? _c("DownloadInvoiceModal", {
                attrs: { invoices: _vm.invoicesForModal },
                on: {
                  close: function($event) {
                    _vm.showInvoicesModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }