<template>
  <div>
    <Datatable
      :get-base-query="getBaseQuery"
      :query-custom-params="queryCustomParams"
    />
  </div>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import DownloadInvoiceModal from '~/components/DownloadInvoiceModal';
import DatatableModel from '~/models/Datatable';
import CompanyOrder from '~/models/CompanyOrder';

export default {
  extends: Page,
  components: {
    DownloadInvoiceModal,
    DashboardPage,
    Datatable,
  },
  data() {
    return {
      queryCustomParams: {
        table_id: 'company-product-sales',
      },
    };
  },
  methods: {
    getBaseQuery() {
      return DatatableModel.where('company_id', this.$global.userable.id);
    },
  },
};
</script>
