<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M22.751 19.337l-4.875-4.875a9.022 9.022 0 10-3.414 3.414l4.875 4.875a1 1 0 001.414 0l2-2a1 1 0 000-1.414zM3.044 10.044a7 7 0 117 7 7.008 7.008 0 01-7-7z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
