var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "media-list" },
    _vm._l(_vm.list, function(media) {
      return _c("div", { staticClass: "media-item" }, [
        _c("div", { staticClass: "media-item__inner" }, [
          _c(
            "a",
            {
              staticClass: "media-item__remove",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$emit("remove", media)
                }
              }
            },
            [_c("IconClose", { attrs: { width: 20, height: 20 } })],
            1
          ),
          _vm._v(" "),
          _c("img", { attrs: { src: media.urls.thumb, alt: "" } })
        ])
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }