var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    staticClass: "form-group",
    class: _vm.classes,
    attrs: {
      rules: _vm.rules,
      name: _vm.name || _vm.label,
      vid: _vm.vid,
      tag: "div"
    },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var errors = ref.errors
            return [
              !_vm.noLabel
                ? _c(
                    "label",
                    {
                      attrs: { for: _vm.name },
                      on: {
                        click: function($event) {
                          return _vm.$refs.input.focus()
                        }
                      }
                    },
                    [
                      _c("span", [_vm._v(_vm._s(_vm.label || _vm.name))]),
                      _vm._v(" "),
                      _vm.labelInfoText
                        ? _c("span", { staticClass: "optional-label" }, [
                            _vm._v(_vm._s(_vm.labelInfoText))
                          ])
                        : _vm._e()
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "form-control-wrapper",
                  class: {
                    "form-control-wrapper--with-info-icon": _vm.infoText
                  }
                },
                [
                  _vm._t("default", null, {
                    inputId: _vm.inputId,
                    errors: errors
                  }),
                  _vm._v(" "),
                  _vm.infoText
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: _vm.infoText,
                              expression: "infoText"
                            }
                          ],
                          staticClass: "form-control-info"
                        },
                        [
                          _c("IconMoreInfo", {
                            attrs: { width: "20", height: "20" }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              ),
              _vm._v(" "),
              errors[0]
                ? _c("span", { staticClass: "form-group__error" }, [
                    _vm._v(_vm._s(errors[0]))
                  ])
                : _vm._e()
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }