<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M23.621 10.226l-11-9a1 1 0 00-1.266 0l-11 9a1 1 0 001.266 1.548l1.367-1.118V22a1 1 0 001 1h6v-6h4v6h6a1 1 0 001-1V10.656l1.367 1.118a1 1 0 101.266-1.548z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
