<template>
  <Modal @modal-close="handleClose" size="sm">
    <div class="modal__header">
      <h3 class="modal__title">Update stock level - {{ product.name }}</h3>
      <button type="button" class="modal__close" @click="handleClose">
        <IconClose />
      </button>
    </div>
    <div class="modal__body">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
        <form class="form" @submit.prevent="handleSubmit(save)">
          <VInput
            v-model="form.new_stock_count"
            name="Stock level"
            vid="new_stock_count"
            rules="required|numeric|min:0"
            info-text="Only products in stock are available for sale in partner stores."
          />
          <div class="modal__btn-wrapper">
            <LoadingBtn
              :loading="loading"
              :disabled="invalid"
              class="btn"
              type="submit"
              >Update</LoadingBtn
            >
          </div>
        </form>
      </ValidationObserver>
    </div>
  </Modal>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import LoadingBtn from '~/components/LoadingBtn';
import Modal from '~/components/Modal';
import VInput from '~/components/form/VInput';
import VSelect from '~/components/form/VSelect';
import IconClose from '~/components/icons/IconClose';
import VPercent from '~/components/form/VPercent';
import { cloneDeep, fractionToPercentage, percentageToFraction } from '~/utils';
import SingleUploader from '~/components/upload/SingleUploader';
import MediaModelEnum from '~/enums/MediaModelEnum';
import MediaCollectionEnum from '~/enums/MediaCollectionEnum';
import UserAvatar from '~/components/UserAvatar';
import Agent from '~/models/Agent';
import Loader from '~/components/Loader';
import Product from '~/models/Product';

export default {
  components: {
    VInput,
    LoadingBtn,
    Modal,
    ValidationObserver,
    VSelect,
    IconClose,
    VPercent,
    UserAvatar,
    SingleUploader,
    Loader,
  },
  props: {
    product: {
      type: Product,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: {
        new_stock_count: this.product.stock_count,
      },
      loading: false,
    };
  },

  methods: {
    async save() {
      this.loading = true;

      try {
        await this.product.updateStock(this.form.new_stock_count);
        this.$toasted.success('Stock level updated!');
        this.$emit('updated', {
          ...this.product,
          stock_count: this.form.new_stock_count,
        });
        this.handleClose();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss"></style>
