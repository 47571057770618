<template>
  <AuthBox>
    <h4>Reset password</h4>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submit)" class="form">
        <VInput
          v-model="form.password"
          class="form-group"
          name="New Password"
          vid="password"
          rules="required|secure_password|min:8"
          type="password"
        />
        <VInput
          v-model="form.password_confirmation"
          class="form-group"
          name="New Password Confirmation"
          vid="password_confirmation"
          rules="required|confirmed:password"
          type="password"
        />
        <LoadingBtn
          :loading="loading"
          :disabled="invalid"
          class="btn btn--lg"
          type="submit"
          >Reset
        </LoadingBtn>
      </form>
    </ValidationObserver>
  </AuthBox>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import VInput from '~/components/form/VInput';
import LoadingBtn from '~/components/LoadingBtn';
import AuthBox from '~/pages/auth/AuthBox';

export default {
  components: {
    ValidationObserver,
    VInput,
    LoadingBtn,
    AuthBox,
  },
  data() {
    return {
      loading: false,
      form: null,
    };
  },
  created() {
    this.form = this.getForm();
  },
  mounted() {
    let query = this.parseQuery(window.location.search);
    this.form.email = query.email;
    this.form.token = query.token;
  },
  methods: {
    parseQuery(queryString) {
      let query = {};
      let pairs = (queryString[0] === '?'
        ? queryString.substr(1)
        : queryString
      ).split('&');
      let i;
      for (i = 0; i < pairs.length; i++) {
        let pair = pairs[i].split('=');
        query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
      }
      return query;
    },
    async submit() {
      this.loading = true;

      try {
        // Make request to store csrf cookie.
        await this.$axios.get('sanctum/csrf-cookie', {
          baseURL: this.url(),
        });
        const response = await this.$axios.post(
          '/auth/password/reset',
          this.form
        );

        this.$toasted.success('Password has been reset successfully!');

        setTimeout(() => {
          window.location.href = this.route('login').toString();
        }, 1000);
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    getForm() {
      return {
        email: '',
        token: '',
        password: '',
        password_confirmation: '',
      };
    },
  },
};
</script>
