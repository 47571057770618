<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M22 10h-1.231a8.943 8.943 0 00-1.156-2.785l.872-.872a2 2 0 00-2.828-2.828l-.872.872A8.943 8.943 0 0014 3.231V2a2 2 0 10-4 0v1.231a8.943 8.943 0 00-2.785 1.156l-.872-.872a2 2 0 00-2.828 2.828l.872.872A8.943 8.943 0 003.231 10H2a2 2 0 100 4h1.231a8.943 8.943 0 001.156 2.785l-.872.872a2 2 0 002.828 2.828l.872-.872c.856.54 1.798.931 2.785 1.156V22a2 2 0 104 0v-1.231a8.943 8.943 0 002.785-1.156l.872.872a2 2 0 002.828-2.828l-.872-.872A8.943 8.943 0 0020.769 14H22a2 2 0 100-4zm-10 6a4 4 0 110-8 4 4 0 010 8z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
