var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "metric-value" }, [
    _c("h6", { staticClass: "metric-value__title" }, [
      _vm._v(_vm._s(_vm.title))
    ]),
    _vm._v(" "),
    _c(
      "h6",
      { staticClass: "metric-value__value" },
      [
        _vm._v("\n    " + _vm._s(_vm.formattedValue) + "\n    "),
        _vm.loading ? _c("Loader") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }