<template>
  <Datatable
    ref="datatable"
    :get-base-query="getBaseQuery"
    :query-custom-params="queryCustomParams"
  >
    <template v-slot:cell(actions)="{ item }">
      <div class="table__row-actions">
        <UpdateConnectionStatus
          :connection="connectionFromRow(item)"
          @updated="onConnectionUpdated"
        />
      </div>
    </template>
  </Datatable>
</template>
<script>
import Connection from '~/models/Connection';
import UpdateConnectionStatus from '~/components/UpdateConnectionStatus';
import Datatable from '~/components/datatable/Datatable';

export default {
  components: {
    Datatable,
    UpdateConnectionStatus,
  },
  data() {
    return {
      queryCustomParams: {
        table_id: 'connections',
      },
    };
  },
  methods: {
    getBaseQuery() {},
    refresh() {
      this.$refs.datatable.fetchResources(true);
    },
    onConnectionUpdated(connection) {
      this.$emit('updated', connection);
    },
    connectionFromRow(item) {
      return new Connection({
        id: item._meta.id,
        status: item._meta.status,
      });
    },
  },
};
</script>
