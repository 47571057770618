var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pd__add-to-cart-form" },
    [
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              var invalid = ref.invalid
              var errors = ref.errors
              return [
                _c(
                  "form",
                  {
                    staticClass: "form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.add)
                      }
                    }
                  },
                  [
                    _vm.product.colors.length
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "form-group pd__add-to-cart-form__color"
                          },
                          [
                            _c("label", [
                              _c("span", [_vm._v("Choose a colour")])
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "pd__add-to-cart-form__color__samples"
                              },
                              _vm._l(_vm.product.colors, function(color) {
                                return _c("a", {
                                  key: color,
                                  staticClass:
                                    "pd__add-to-cart-form__color__sample",
                                  class: {
                                    "pd__add-to-cart-form__color__sample--selected":
                                      color === _vm.form.color
                                  },
                                  style: { backgroundColor: "#" + color },
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.form.color = color
                                    }
                                  }
                                })
                              }),
                              0
                            )
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.product.question
                      ? _c("VInput", {
                          attrs: {
                            label: _vm.product.question,
                            vid: "answer",
                            rules: "required|min:1",
                            disabled: _vm.outOfStock
                          },
                          model: {
                            value: _vm.form.answer,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "answer", $$v)
                            },
                            expression: "form.answer"
                          }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pd__add-to-cart-form__qty-and-btn" },
                      [
                        _c("VQtyInput", {
                          attrs: {
                            name: "quantity",
                            vid: "qty",
                            "no-label": "",
                            rules: "numeric|min_value:1",
                            disabled: _vm.outOfStock
                          },
                          model: {
                            value: _vm.form.qty,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "qty", $$v)
                            },
                            expression: "form.qty"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "LoadingBtn",
                          {
                            staticClass: "btn btn--lg btn--black",
                            attrs: {
                              loading: _vm.loading,
                              disabled:
                                invalid ||
                                !!(
                                  _vm.product.colors.length && !_vm.form.color
                                ),
                              type: "submit"
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.outOfStock ? "Out of stock" : "Add to cart"
                              ) +
                                " -\n          " +
                                _vm._s(
                                  _vm
                                    .Price(_vm.product.price, _vm.vatRate)
                                    .gross()
                                    .multiply(_vm.form.qty)
                                    .format()
                                ) +
                                "\n        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        errors.qty && errors.qty.length
                          ? _c(
                              "span",
                              { staticClass: "pd__add-to-cart-form__error" },
                              [_vm._v(_vm._s(errors.qty[0]))]
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }