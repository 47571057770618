var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "metric-section" }, [
    _c("div", { staticClass: "metric-section__content" }, [
      _c("h6", { staticClass: "metric-section__title" }, [
        _vm._v("\n      " + _vm._s(_vm.title) + "\n      "),
        _vm.link
          ? _c("a", { attrs: { href: _vm.link.href } }, [
              _vm._v(_vm._s(_vm.link.title))
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "metric-section__body" }, [_vm._t("default")], 2)
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }