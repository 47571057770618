<template>
  <div class="performance-metric">
    <div class="performance-metric__filters" v-if="hasTimeFilter">
      <div class="performance-metric__filters__margin-helper">
        <div class="filter">
          <VSelect
            v-if="options"
            v-model="selectedValue"
            name=""
            :options="options"
            class="filter-select"
          />
        </div>
        <div class="filter" v-if="hasCategoryFilter">
          <CategoryFilter
            v-model="selectedCategory"
            filter-by-auth
            filter-select
            no-label
          />
        </div>
        <TimeFilter
          v-if="hasTimeFilter"
          @input="changeTimeFilter"
          :selected="selectedTimeFrame"
        ></TimeFilter>
      </div>
    </div>
    <div class="performance-metric__list">
      <div class="performance-metric__fixed-header-bg"></div>
      <div class="performance-metric__scroll">
        <table>
          <thead>
            <th v-for="column in columns" :key="column.label">
              <span class="text">{{ column.label }}</span>
            </th>
          </thead>
          <tbody>
            <tr v-for="row in values" :key="row.id">
              <td v-for="column in columns" :key="row.id + column.label">
                <span class="description-column" v-if="column.has_logo">
                  <UserLogo
                    :logo="row[column.logo] ? row[column.logo] : row.logo"
                    :colour="
                      row[column.logoColour]
                        ? row[column.logoColour]
                        : row.logo_colour
                    "
                    :business-name="row[column.value]"
                    :size="48"
                  />
                  <span v-if="column.is_link">
                    <a :href="route(column.route, row[column.routeValue])">{{
                      row[column.value]
                    }}</a>
                  </span>
                  <span v-else>
                    {{ row[column.value] }}
                  </span>
                </span>

                <span v-else-if="column.is_stock">
                  <StockStatusBadge :count="row[column.value]"
                /></span>

                <span v-else-if="column.is_stock_update" class="edit-column">
                  <IconAdd
                    v-tooltip="{
                      boundariesElement: 'body',
                      content: 'Update stock count',
                    }"
                    @click.native="updateProductStock(row)"
                  />
                </span>
                <span
                  v-else-if="column.is_connection"
                  class="update-connection-status-cell"
                >
                  <UpdateConnectionStatus
                    @updated="sync"
                    :connection="connectionFromRow(row)"
                  ></UpdateConnectionStatus>
                </span>
                <a
                  class="btn btn--secondary"
                  v-else-if="column.is_button"
                  :href="route(column.route, row[column.value])"
                >
                  View
                </a>
                <span v-else> {{ displayRow(row, column) }}</span>
              </td>
            </tr>
            <tr v-if="!values.length">
              <td :colspan="(columnsCount)"><span>No data available</span></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="performance-metric__loader-placeholder" v-show="loading">
        <div
          class="performance-metric__loading"
          :class="{ 'performance-metric--skeleton': loading }"
        ></div>
      </div>
    </div>
    <UpdateProductStockModal
      v-if="showStockModal"
      :product="productToUpdateStock"
      @close="showStockModal = false"
      @updated="handleProductStockUpdated"
    />
  </div>
</template>
<script>
import { formatMoney } from '~/utils';
import UserLogo from '~/components/UserLogo';
import VSelect from '~/components/form/VSelect';
import TimeFilter from '~/components/metrics/TimeFilter';
import UpdateProductStockModal from '~/components/UpdateProductStockModal';
import IconAdd from '~/components/icons/IconAdd';
import Product from '~/models/Product';
import UpdateConnectionStatus from '~/components/UpdateConnectionStatus';
import Connection from '~/models/Connection';
import StockStatusBadge from '~/components/StockStatusBadge';
import CategoryFilter from '~/components/sectors-fields/CategoryFilter';

export default {
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: false,
    },
    hasCategoryFilter: {
      type: Boolean,
      default: false,
    },
    hasTimeFilter: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    CategoryFilter,
    StockStatusBadge,
    UpdateConnectionStatus,
    IconAdd,
    TimeFilter,
    UserLogo,
    VSelect,
    UpdateProductStockModal,
  },
  computed: {
    columnsCount() {
      return Object.keys(this.columns).length;
    },
  },
  data() {
    return {
      selectedTimeFrame: 'ALL',
      loading: true,
      selectedValue: this.options ? this.options[0].value : '',
      values: [],
      columns: {},
      selectedCategory: '',
      showStockModal: false,
      productToUpdateStock: false,
    };
  },
  watch: {
    selectedValue() {
      this.valueChange();
    },
    selectedCategory() {
      this.sync();
    },
  },
  mounted() {
    this.sync();
  },
  methods: {
    formatMoney,
    async sync() {
      if (!this.endpoint) {
        this.loading = false;
        return;
      }
      this.loading = true;
      let query = this.query();
      try {
        let response = await this.$axios.get(
          `v1/stats/${this.endpoint}${query}`
        );
        this.values = response.data.values;
        this.columns = response.data.columns;
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },

    connectionFromRow(item) {
      return new Connection({
        id: item.id,
        applicant: item.applicant,
        status: item.status,
      });
    },

    query() {
      let query = '';

      const prepend = () => {
        return query === '' ? '?' : '&';
      };
      if (this.selectedCategory) {
        query += prepend() + `category=${this.selectedCategory}`;
      }

      if (this.selectedValue) {
        query += prepend() + `value=${this.selectedValue}`;
      }

      if (this.selectedTimeFrame) {
        query += prepend() + `timeframe=${this.selectedTimeFrame}`;
      }
      return query;
    },

    displayRow(row, column) {
      let value = row[column.value];

      if (column.is_money) {
        return formatMoney(value);
      }

      return value;
    },

    handleProductStockUpdated() {
      this.sync();
    },

    valueChange() {
      this.sync();
    },

    changeTimeFilter(value) {
      this.selectedTimeFrame = value;
      this.sync();
    },

    updateProductStock(data) {
      this.productToUpdateStock = new Product(data);
      this.showStockModal = true;
    },
  },
};
</script>
<style lang="scss">
.performance-metric {
  $margin-top: 18px;
  $header-height: 54px;
  min-width: 100%;

  &__fixed-header-bg {
    height: $header-height;
    background-color: #fff;
    width: calc(100% - 2px);
    position: absolute;
    margin-top: -$header-height;
    box-sizing: content-box;
    border: 1px solid $gray-500;
  }

  &__list {
    position: relative;
    margin-top: $header-height + $margin-top;
    width: 100%;
  }

  &__scroll {
    max-height: 350px;
    overflow: auto;
    width: 100%;

    table {
      border: 1px solid $gray-500;

      th {
        .text {
          position: absolute;
          top: -$header-height;
          z-index: 2;
        }
      }

      tr {
        td {
          img {
            width: 48px;
          }

          > span {
            font-size: $text-lg;
            line-height: $text-3xl;
            font-weight: 300;
            padding: 15px 24px;

            &.description-column {
              padding-right: 0;

              span {
                padding-left: 24px;
              }
            }

            &.edit-column {
              color: $gray-800;
              opacity: 0.25;
              cursor: pointer;

              &:hover {
                opacity: 0.5;
              }
            }
          }

          > a {
            color: $gray-800;
            border: 0 solid #fff;
            margin-right: 10px;
          }
        }
      }
    }
  }

  &__filters {
    margin: 0 0 18px;

    &__margin-helper {
      margin-bottom: -10px;
      display: flex;
      flex-wrap: wrap;
    }

    .filter {
      padding-right: 10px;
      margin-bottom: 10px;

      .form-group {
        display: inline-block;
        padding-bottom: 0;

        select {
          text-align-last: left;
        }
      }
    }

    .time-filter {
      margin-left: auto;
      margin-bottom: 10px;
    }
  }

  &__loader-placeholder {
    position: absolute;
    height: 100%;
    bottom: 0;
    width: 100%;
  }

  &__loading {
    height: 100%;
    width: 100%;
  }

  &--skeleton {
    @include component-placeholder();
    z-index: 999999;
  }

  .update-connection-status-cell {
    justify-content: flex-end;

    // copied from the table__row-actions.
    // todo: maybe dry
    a {
      color: $gray-800;
      opacity: 0.25;
      transition: opacity 0.15s ease-out;
      margin-left: $s-3;

      @at-root {
        .no-touchevents {
          .update-connection-status-cell a:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .performance-metric {
    &__list {
      margin-left: -#{$container-padding};
      margin-right: -#{$container-padding};
      border-right: 0;
      border-left: 0;
      width: auto;
    }

    &__filters {
      .time-filter {
        margin-left: 0;
      }
    }

    .btn {
      display: none;
    }

    &__scroll {
      table {
        tr {
          td {
            > span {
              padding: 15px 15px;
            }
          }
        }
      }
    }
  }
}
</style>
