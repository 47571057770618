var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Configuration" } },
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            { attrs: { name: "Sectors", selected: "" } },
            [_c("SectorsTable")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Categories", selected: "" } },
            [_c("CategoriesTable")],
            1
          ),
          _vm._v(" "),
          _vm.hasFeatureServices()
            ? _c(
                "Tab",
                { attrs: { name: "Services", selected: "" } },
                [_c("ServicesTable")],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }