var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      staticClass: "back-button",
      attrs: { href: "#" },
      on: {
        click: function($event) {
          $event.preventDefault()
          return _vm.back($event)
        }
      }
    },
    [
      _c("IconDropLeft", { attrs: { width: "18", height: "18" } }),
      _vm._v(" "),
      _c("span", [_vm._v("Back")])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }