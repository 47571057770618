<template>
  <FieldWrapper
    v-slot="{ inputId, errors }"
    :rules="rules"
    :name="name"
    :label="label"
    :vid="vid"
    :label-info-text="labelInfoText"
  >
    <textarea
      :id="inputId"
      v-model="currentValue"
      :placeholder="placeholder"
      :name="vid"
      class="form-control"
    />
  </FieldWrapper>
</template>

<script>
import InputMixin from '~/components/form/InputMixin';

export default {
  mixins: [InputMixin],
  props: {
    value: {
      type: String,
      default: '',
    },
  },
};
</script>
