var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "business-more-details business-more-details--company",
      class: _vm.classes
    },
    [
      _c(
        "div",
        { staticClass: "business-more-details__item" },
        [
          _c("IconProducts", { attrs: { width: _vm.size, height: _vm.size } }),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(_vm.countString(_vm.company.products_count, "products"))
            )
          ])
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "business-more-details__item" },
        [
          _c("IconPartners", { attrs: { width: _vm.size, height: _vm.size } }),
          _vm._v(" "),
          _c("span", [
            _vm._v(
              _vm._s(
                _vm.countString(
                  _vm.company.partners_accepted_count,
                  "connections"
                )
              )
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }