<template>
  <DashboardPage title="Products">
    <Tabs>
      <Tab name="Active" selected>
        <ProductsTable ref="productsTable">
          <template v-slot:row-actions="{ item }">
            <a href="#" @click.prevent.stop="hide(item._meta)">
              <IconArchive
                v-tooltip="{
                  boundariesElement: 'body',
                  content: 'Archive product',
                }"
              />
            </a>
          </template>
        </ProductsTable>
      </Tab>
      <Tab name="Inactive">
        <ProductsTable ref="hiddenProductsTable" hidden>
          <template v-slot:row-actions="{ item }">
            <a href="#" @click.prevent.stop="show(item._meta)">
              <IconUnArchive
                v-tooltip="{
                  boundariesElement: 'body',
                  content: 'Unarchive product',
                }"
              />
            </a>
          </template>
        </ProductsTable>
      </Tab>
    </Tabs>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Tabs from '~/components/Tabs';
import Tab from '~/components/Tab';
import DashboardPage from '~/components/DashboardPage';
import ProductsTable from '~/pages/dashboard/partner/partials/products/ProductsTable';
import PartnerProduct from '~/models/PartnerProduct';
import IconArchive from '~/components/icons/IconArchive';
import IconUnArchive from '~/components/icons/IconUnArchive';

export default {
  extends: Page,
  components: {
    IconUnArchive,
    IconArchive,
    ProductsTable,
    DashboardPage,
    Tabs,
    Tab,
  },
  data() {
    return {};
  },
  methods: {
    async updateHidden(partnerProduct, hidden) {
      let subtitle = hidden
        ? 'Archived products are no longer available for sale on the platform.'
        : 'This product will be available for sale on the platform.';
      const { confirmed, stopLoading } = await this.$confirm({
        subtitle: subtitle,
        withLoading: true,
        danger: true,
      });

      if (!confirmed) {
        return;
      }

      partnerProduct = new PartnerProduct(partnerProduct);
      partnerProduct.hidden = hidden;
      partnerProduct.saveOnly(['hidden']);

      stopLoading();

      await this.$refs.productsTable.refresh();
      await this.$refs.hiddenProductsTable.refresh();
    },
    async show(product) {
      return this.updateHidden(product, false);
    },
    async hide(product) {
      return this.updateHidden(product, true);
    },
  },
};
</script>
<style lang="scss"></style>
