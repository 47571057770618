var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.show
    ? _c("div", { staticClass: "incomplete-profile-banner" }, [
        _c("div", { staticClass: "incomplete-profile-banner__left" }, [
          _c("span", { staticClass: "incomplete-profile-banner__label" }, [
            _vm._v("Almost there")
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "incomplete-profile-banner__action" }, [
            _vm._v(_vm._s(_vm.message))
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "incomplete-profile-banner__right" }, [
          _c(
            "a",
            {
              staticClass: "btn btn--transparent-white-button",
              attrs: { href: _vm.settingsUrl }
            },
            [_vm._v("Complete profile")]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }