var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("Modal", { on: { "modal-close": _vm.handleClose } }, [
    _c("div", { staticClass: "modal__header" }, [
      _c("h3", { staticClass: "modal__title" }, [
        _vm._v(_vm._s(_vm.isEdit ? "Edit" : "Add") + " agent")
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "modal__close",
          attrs: { type: "button" },
          on: { click: _vm.handleClose }
        },
        [_c("IconClose")],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "modal__body" },
      [
        _vm.loading
          ? _c("Loader", { staticClass: "modal__body__loader" })
          : _c("ValidationObserver", {
              ref: "form",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.save)
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group" },
                            [
                              _c("SingleUploader", {
                                attrs: {
                                  media: _vm.form.user.avatar,
                                  for: _vm.MediaModelEnum.USER,
                                  collection: _vm.MediaCollectionEnum.AVATAR
                                },
                                on: { "upload-success": _vm.onAvatarChange },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "preview",
                                      fn: function(ref) {
                                        var media = ref.media
                                        return [
                                          _c("UserAvatar", {
                                            attrs: { avatar: media, size: 64 }
                                          })
                                        ]
                                      }
                                    },
                                    {
                                      key: "text",
                                      fn: function() {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "single-uploader__title"
                                            },
                                            [_vm._v("Change avatar")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "single-uploader__subtitle"
                                            },
                                            [_vm._v("Max size 20mb")]
                                          )
                                        ]
                                      },
                                      proxy: true
                                    }
                                  ],
                                  null,
                                  true
                                )
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("VInput", {
                                attrs: {
                                  name: "Name",
                                  vid: "user.name",
                                  rules: "required",
                                  "label-info-text": "- add your agent’s name"
                                },
                                model: {
                                  value: _vm.form.user.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form.user, "name", $$v)
                                  },
                                  expression: "form.user.name"
                                }
                              }),
                              _vm._v(" "),
                              _c("VInput", {
                                attrs: {
                                  name: "Email",
                                  vid: "user.email",
                                  rules: "required|email",
                                  "label-info-text": "- add your agent’s email"
                                },
                                model: {
                                  value: _vm.form.user.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form.user, "email", $$v)
                                  },
                                  expression: "form.user.email"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("VInput", {
                                staticClass: "form-group",
                                attrs: {
                                  name: _vm.isEdit
                                    ? "New Password"
                                    : "Password",
                                  vid: "user.password",
                                  rules: _vm.passwordRules,
                                  type: "password",
                                  "label-info-text":
                                    "- set your agent’s password"
                                },
                                model: {
                                  value: _vm.form.user.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form.user, "password", $$v)
                                  },
                                  expression: "form.user.password"
                                }
                              }),
                              _vm._v(" "),
                              _c("VInput", {
                                attrs: {
                                  name: "Phone Number",
                                  vid: "contact_number",
                                  rules: "required",
                                  "label-info-text":
                                    "- set agent’s mobile phone number"
                                },
                                model: {
                                  value: _vm.form.contact_number,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "contact_number", $$v)
                                  },
                                  expression: "form.contact_number"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("VSelect", {
                                attrs: {
                                  name: "Is this agent an employee?",
                                  vid: "is_employee",
                                  options: _vm.yesNoOptions,
                                  placeholder: "Choose option",
                                  rules: "required"
                                },
                                model: {
                                  value: _vm.form.is_employee,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "is_employee", $$v)
                                  },
                                  expression: "form.is_employee"
                                }
                              }),
                              _vm._v(" "),
                              _c("VInput", {
                                attrs: {
                                  name: "VAT registration number",
                                  vid: "vat_no",
                                  disabled: _vm.form.is_employee === true,
                                  "info-text":
                                    "If set, this agent will not be charged VAT on commission"
                                },
                                model: {
                                  value: _vm.form.vat_no,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "vat_no", $$v)
                                  },
                                  expression: "form.vat_no"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("VPercent", {
                                attrs: {
                                  name: "Commission rate (%)",
                                  vid: "commission_rate",
                                  rules:
                                    "required|decimal|max_value:100|min_value:0",
                                  "info-text":
                                    "Set a unique commission rate for all sales by this agent"
                                },
                                model: {
                                  value: _vm.commissionRatePercentage,
                                  callback: function($$v) {
                                    _vm.commissionRatePercentage = $$v
                                  },
                                  expression: "commissionRatePercentage"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal__btn-wrapper" },
                            [
                              _c(
                                "LoadingBtn",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    loading: _vm.saving,
                                    disabled: invalid,
                                    type: "submit"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.isEdit ? "Update" : "Create") +
                                      " agent\n          "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }