import { render, staticRenderFns } from "./SimilarProducts.vue?vue&type=template&id=2710d469&"
import script from "./SimilarProducts.vue?vue&type=script&lang=js&"
export * from "./SimilarProducts.vue?vue&type=script&lang=js&"
import style0 from "./SimilarProducts.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/forge/staging.thatsthe1.com/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2710d469')) {
      api.createRecord('2710d469', component.options)
    } else {
      api.reload('2710d469', component.options)
    }
    module.hot.accept("./SimilarProducts.vue?vue&type=template&id=2710d469&", function () {
      api.rerender('2710d469', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/pages/store/partials/SimilarProducts.vue"
export default component.exports