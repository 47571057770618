var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "approve-or-decline" }, [
    _c(
      "a",
      {
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.approve($event)
          }
        }
      },
      [
        _c("IconApprove", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                boundariesElement: "body",
                content: _vm.approveString
              },
              expression:
                "{\n        boundariesElement: 'body',\n        content: approveString,\n      }"
            }
          ]
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            $event.stopPropagation()
            return _vm.decline($event)
          }
        }
      },
      [
        _c("IconDecline", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: {
                boundariesElement: "body",
                content: _vm.declineString
              },
              expression:
                "{\n        boundariesElement: 'body',\n        content: declineString,\n      }"
            }
          ]
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }