<template>
  <div class="table">
    <table>
      <thead>
        <tr>
          <th v-for="field in fields">
            <span>
              <span>{{ field.label }}</span>
              <span v-if="field.sortable" class="table__sortable">
                <a
                  href="#"
                  @click.prevent="sort('asc', field.key)"
                  :class="{ active: activeOrderBy === field.key }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="5"
                    viewBox="0 0 6 5"
                  >
                    <g>
                      <path d="M3 .5l3 4H0l3-4z" />
                    </g>
                  </svg>
                </a>
                <a
                  href="#"
                  @click.prevent="sort('desc', field.key)"
                  :class="{ active: activeOrderBy === `-${field.key}` }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="6"
                    height="5"
                    viewBox="0 0 6 5"
                  >
                    <g>
                      <path d="M3 4.5l3-4H0l3 4z" />
                    </g>
                  </svg>
                </a>
              </span>
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <slot name="body" v-bind:items="items">
          <tr v-for="(item, index) in items" :key="index">
            <td class="table__cell" v-for="field in fields" :key="field.key">
              <TableCell
                :item="item"
                :field="field"
                :cell="item[field.key]"
                @open-invoices-modal="openInvoicesModal"
              >
                <template v-slot:[`cell(${field.key})`]="{ item }">
                  <slot :name="`cell(${field.key})`" v-bind:item="item" />
                </template>
              </TableCell>
            </td>
          </tr>
        </slot>
      </tbody>
    </table>
    <div v-if="loading" class="table__loader"></div>
    <Portal to="modals">
      <DownloadInvoiceModal
        v-if="showInvoicesModal"
        :invoices="invoicesForModal"
        @close="showInvoicesModal = false"
      />
    </Portal>
  </div>
</template>
<script>
import DownloadInvoiceModal from '~/components/DownloadInvoiceModal';
import TableCell from '~/components/TableCell';
export default {
  components: {
    TableCell,
    DownloadInvoiceModal,
  },
  props: {
    fields: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    activeOrderBy: {
      type: String,
      required: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      invoicesForModal: false,
      showInvoicesModal: false,
    };
  },
  methods: {
    sort(key, direction) {
      this.$emit('sort', key, direction);
    },
    openInvoicesModal(invoices) {
      this.invoicesForModal = invoices;
      this.showInvoicesModal = true;
    },
  },
};
</script>
<style lang="scss">
.table {
  $header-height: 58px;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  th,
  td {
    > span,
    > a {
      white-space: nowrap;
    }
  }

  &__sortable {
    margin-left: 5px;
    display: flex;
    flex-direction: column;

    a {
      display: inline-block;
      opacity: 0.1;
      margin-bottom: 2px;
      line-height: 0;

      transition: color 0.15s ease-out, opacity 0.15s ease-out;

      svg {
        fill: black;
      }

      &.active {
        opacity: 1;

        svg {
          fill: $primary;
        }
      }
    }

    @at-root {
      .no-touchevents {
        .table__sortable a:hover {
          opacity: 0.5;
        }
      }
    }
  }

  &__loader {
    @include component-placeholder();
    height: 100%;
    width: 100%;
    position: absolute;
    top: $header-height + 1px;
    left: 0;
  }

  &__row-actions {
    display: flex;
    width: 100%;
    justify-content: flex-end;

    > div {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }

    a {
      color: $gray-800;
      opacity: 0.25;
      transition: opacity 0.15s ease-out;
      margin-left: $s-3;

      @at-root {
        .no-touchevents {
          .table__row-actions > a:hover {
            opacity: 0.5;
          }

          .table__row-actions > .approve-or-decline > a:hover {
            opacity: 0.5;
          }
        }
      }
    }

    svg {
      fill: $gray-800;
    }
  }
}

table {
  width: 100%;
  background-color: $white;

  tr {
    width: 100%;
    clear: both;
    border-bottom: 1px solid $border-color;
  }

  th,
  td {
    > span,
    > a {
      display: flex;
      align-items: center;
      padding: 18px 24px;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      font-weight: 500;
    }
  }

  th {
    > span {
      font-size: 12px;
      line-height: 20px;
      color: rgb($black, 0.5);
    }
  }

  td {
    a {
      color: $primary;
    }
  }
}

@include media-breakpoint-down(sm) {
  table {
    th,
    td {
      > span,
      > a {
        padding: 18px 15px;
      }
    }
  }
}
</style>
