<template>
  <StorePageWrapper
    :type="type"
    :business="business"
    class="store-page--checkout"
  >
    <div class="store-page-checkout">
      <div class="store__container">
        <BackButton />
        <div class="store-checkout">
          <h5 v-if="!cartCount" class="store-checkout__empty-cart">
            Empty cart.
          </h5>
          <div v-else class="store-checkout__steps">
            <div class="store-checkout__step">
              <h5 class="store-checkout__step__title">Contact information</h5>
              <div
                v-if="currentStep === STEPS.CONTACT_INFO"
                class="store-checkout__form"
              >
                <ValidationObserver
                  ref="formContactInfo"
                  v-slot="{ handleSubmit, invalid }"
                >
                  <form
                    @submit.prevent="
                      handleSubmit(() => validateStep(STEPS.CONTACT_INFO))
                    "
                    class="form"
                  >
                    <VInput
                      v-model="customer.name"
                      name="Name"
                      vid="name"
                      rules="required"
                      dark
                    />
                    <div class="form-2-col">
                      <VInput
                        v-model="customer.email"
                        name="Email address"
                        vid="email"
                        rules="required|email"
                        dark
                      />
                      <VInput
                        v-model="customer.phone"
                        name="Telephone number"
                        vid="phone"
                        rules="required"
                        dark
                      />
                    </div>
                    <div class="store-checkout__form__btn-wrapper">
                      <LoadingBtn
                        :loading="loadingStep[STEPS.CONTACT_INFO]"
                        :disabled="invalid"
                        class="btn btn--black"
                        type="submit"
                      >
                        Next
                      </LoadingBtn>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
              <div v-else-if="customer.name" class="store-checkout__step__list">
                <div class="store-checkout__step__list__left">
                  <span>{{ customer.name }}</span>
                  <span>{{ customer.email }}</span>
                  <span>{{ customer.phone }}</span>
                </div>
                <div class="store-checkout__step__list__right">
                  <a href="#" @click.prevent="edit(STEPS.CONTACT_INFO)">Edit</a>
                </div>
              </div>
            </div>
            <div class="store-checkout__step">
              <h5 class="store-checkout__step__title">Shipping</h5>
              <div
                v-if="currentStep === STEPS.SHIPPING"
                class="store-checkout__form"
              >
                <ValidationObserver
                  ref="formShipping"
                  v-slot="{ handleSubmit, invalid }"
                >
                  <form
                    @submit.prevent="
                      handleSubmit(() => validateStep(STEPS.SHIPPING))
                    "
                    class="form"
                  >
                    <div class="form-2-col">
                      <VInput
                        v-model="customer.address1"
                        name="Address line 1"
                        vid="address1"
                        rules="required"
                        dark
                      />
                      <VInput
                        v-model="customer.address2"
                        name="Address line 2"
                        vid="address2"
                        label-info-text="- optional"
                        dark
                      />
                    </div>
                    <div class="form-2-col">
                      <VInput
                        v-model="customer.city"
                        name="Town/City"
                        vid="city"
                        rules="required"
                        dark
                      />
                      <VInput
                        v-model="customer.zip"
                        name="Postcode"
                        vid="zip"
                        rules="required"
                        dark
                      />
                    </div>
                    <VSelect
                      v-model="customer.country_id"
                      name="Country"
                      vid="country_id"
                      value-key="id"
                      label-key="name"
                      :options="orderedCountries"
                      @input="onChangeCountry"
                      dark
                    />
                    <div class="store-checkout__form__btn-wrapper">
                      <LoadingBtn
                        :loading="loadingStep[STEPS.SHIPPING]"
                        :disabled="invalid"
                        class="btn btn--black"
                        type="submit"
                      >
                        Next
                      </LoadingBtn>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
              <div
                v-else-if="
                  currentStep === STEPS.AGENT || currentStep === STEPS.PAYMENT
                "
                class="store-checkout__step__list"
              >
                <div class="store-checkout__step__list__left">
                  <span>{{ customer.address1 }} {{ customer.address2 }}</span>
                  <span>{{ customer.city }}</span>
                  <span>{{ customer.zip }}</span>
                  <span>{{ customerCountry.name }}</span>
                </div>
                <div class="store-checkout__step__list__right">
                  <a href="#" @click.prevent="edit(STEPS.SHIPPING)">Edit</a>
                </div>
              </div>
            </div>
            <div
              v-if="agents.length && !fromAgentLink"
              class="store-checkout__step store-checkout__step--agent"
            >
              <h5 class="store-checkout__step__title">Agent</h5>
              <div
                v-if="currentStep === STEPS.AGENT"
                class="store-checkout__form"
              >
                <ValidationObserver
                  ref="formAgent"
                  v-slot="{ handleSubmit, invalid }"
                >
                  <form
                    @submit.prevent="
                      handleSubmit(() => validateStep(STEPS.AGENT))
                    "
                    class="form"
                  >
                    <VSelect
                      v-model="agent"
                      name="Agent"
                      vid="agent_id"
                      value-key="id"
                      empty-value-label="Select agent..."
                      :options="agentsOptions"
                      :loading="loadingAgents"
                      object
                      label-info-text="- Optional"
                      dark
                    />
                    <div class="store-checkout__form__btn-wrapper">
                      <LoadingBtn
                        :loading="loadingStep[STEPS.AGENT]"
                        :disabled="invalid"
                        class="btn btn--black"
                        type="submit"
                      >
                        Next
                      </LoadingBtn>
                    </div>
                  </form>
                </ValidationObserver>
              </div>
              <div
                v-else-if="agent || currentStep === STEPS.PAYMENT"
                class="store-checkout__step__list"
              >
                <div class="store-checkout__step__list__left">
                  <span>{{ agent ? agent.user.name : 'No agent' }}</span>
                </div>
                <div class="store-checkout__step__list__right">
                  <a href="#" @click.prevent="edit(STEPS.AGENT)">Edit</a>
                </div>
              </div>
            </div>
            <div class="store-checkout__step store-checkout__step--payment">
              <h5 class="store-checkout__step__title">Payment</h5>
              <div
                v-if="currentStep === STEPS.PAYMENT"
                class="store-checkout__form"
              >
                <ValidationObserver
                  ref="formPayment"
                  v-slot="{ handleSubmit, invalid }"
                >
                  <form
                    @submit.prevent="handleSubmit(() => checkout(business))"
                    class="form"
                  >
                    <CardInput ref="card" />
                    <VCheckbox
                      v-model="tc_accepted"
                      :value="true"
                      vid="tc_accepted"
                      class="v-checkbox--black"
                      >I accept the
                      <a :href="route('terms-and-conditions')" target="_blank"
                        >Terms</a
                      >
                      and
                      <a :href="route('privacy-policy')" target="_blank"
                        >Privacy Policy</a
                      ></VCheckbox
                    >
                    <LoadingBtn
                      :loading="paying"
                      :disabled="invalid || !tc_accepted"
                      class="btn btn--lg btn--black"
                      type="submit"
                    >
                      Pay now - {{ grossTotal.format() }}
                    </LoadingBtn>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="store__footer-cart">
      <div class="store__container">
        <Cart :business="business" :type="type" display-only />
      </div>
    </div>
  </StorePageWrapper>
</template>
<script>
import StorePageWrapper from '~/pages/store/StorePageWrapper';
import { ValidationObserver } from 'vee-validate';
import LoadingBtn from '~/components/LoadingBtn';
import VInput from '~/components/form/VInput';
import VSelect from '~/components/form/VSelect';
import { mapGetters, mapState } from 'vuex';
import CardInput from '~/components/CardInput';
import VCheckbox from '~/components/form/VCheckbox';
import {
  createPaymentMethod,
  handleCardAction,
} from 'vue-stripe-elements-plus';
import log from '~/log';
import Agent from '~/models/Agent';
import StorePageMixin from '~/pages/store/StorePageMixin';
import PersistentStorage from '~/persistent-storage';
import BackButton from '~/components/BackButton';
import Cart from '~/components/Cart';

const STEPS = {
  CONTACT_INFO: 'contactInfo',
  SHIPPING: 'shipping',
  AGENT: 'agent',
  PAYMENT: 'payment',
};
export default {
  mixins: [StorePageMixin],
  components: {
    BackButton,
    CardInput,
    StorePageWrapper,
    ValidationObserver,
    LoadingBtn,
    VSelect,
    VInput,
    VCheckbox,
    Cart,
  },

  data() {
    return {
      STEPS,
      paying: false,
      loadingStep: {
        [STEPS.CONTACT_INFO]: false,
        [STEPS.SHIPPING]: false,
        [STEPS.AGENT]: false,
        [STEPS.PAYMENT]: false,
      },
      currentStep: STEPS.CONTACT_INFO,
      customer: null,
      tc_accepted: false,
      agent: '',
      loadingAgents: true,
      agents: [],
      fromAgentLink: false,
    };
  },

  async created() {
    this.customer = this.getCustomer();
    try {
      this.business = await this.fetchBusiness();
      this.agents = await Agent.where('partner_id', this.business.id)
        .where('has_profile_complete', 1)
        .where('has_stripe_connected', 1)
        .include('user')
        .params({ per_page: 10000 })
        .$get();

      // Try to pre-select if the user has come from an agent link.
      // eslint-disable-next-line nuxt/no-globals-in-created
      let agentId = window.sessionStorage.getItem('from-agent-link');
      if (agentId) {
        this.fromAgentLink = true;

        this.agent =
          this.agentsOptions.find(
            (a) => parseInt(a.id) === parseInt(agentId)
          ) || '';

        // The agent from the link does not have a complete profile or stripe connected
        // so we treat the checkout normally.
        if (!this.agent) {
          this.fromAgentLink = false;
        }
      }
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loadingAgents = false;
    }
  },

  computed: {
    ...mapState('global', ['countries', 'country']),
    ...mapGetters('global', ['getCountryById', 'orderedCountries']),
    ...mapGetters('cart', ['cartCount', 'grossTotal', 'cartPayload']),
    customerCountry() {
      return this.getCountryById(this.customer.country_id);
    },
    agentsOptions() {
      return this.agents.map((agent) => {
        return {
          ...agent,
          label: agent.user.name,
        };
      });
    },
  },

  methods: {
    async checkout() {
      this.paying = true;
      try {
        let cardElement = this.$refs.card.getStripeEl();
        let response = await createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: this.customer.name,
          },
        });

        if (response.error) {
          this.handleCheckoutError(response.error, response.error.message);
          return;
        }

        let paymentMethodId = response.paymentMethod.id;
        response = await this.pay({
          payment_method_id: paymentMethodId,
        });

        response = response.data;

        if (!response.requires_action) {
          this.handleCheckoutSuccess();
          return;
        }

        let clientSecret = response.payment_intent_client_secret;
        response = await handleCardAction(clientSecret);

        if (response.error) {
          this.handleCheckoutError(
            response.error,
            'Your card was not authenticated, please try again'
          );
        } else if (response.paymentIntent.status === 'requires_confirmation') {
          response = await this.pay({
            payment_method_id: paymentMethodId,
            payment_intent_id: response.paymentIntent.id,
          });

          this.handleCheckoutSuccess();
        }
      } catch (e) {
        this.$axios.handleError(e, this.$refs.formPayment);
      } finally {
        this.paying = false;
      }
    },
    async pay(paymentAttributes) {
      return this.$axios.post(`/v1/checkout/${this.business.id}`, {
        ...paymentAttributes,
        agent_id: this.agent?.id,
        partner_id: this.business.id,
        cart: this.cartPayload,
        customer: this.customer,
      });
    },
    handleCheckoutSuccess() {
      this.$toasted.success('Your order has been placed.');
      this.$store.dispatch('cart/clear');
      PersistentStorage.setCustomerData(this.customer);
      setTimeout(() => {
        window.location.href = this.route(`store.${this.type}`, {
          [this.type]: this.business.slug,
        });
      }, 1000);
    },
    handleCheckoutError(e, message) {
      log(e);
      this.$toasted.error(message);
    },
    async validateStep(step) {
      this.loadingStep[step] = true;

      let $form = this.getStepForm(step);
      let valid = await $form.validate();
      if (valid) {
        this.currentStep = this.getNextStep(step);
      }
      this.loadingStep[step] = false;
    },
    edit(step) {
      this.currentStep = step;
    },
    onChangeCountry() {
      this.$store.dispatch('global/setCountry', this.customerCountry);
    },
    getCustomer() {
      const defaultCustomer = {
        name: '',
        email: '',
        phone: '',
        address1: '',
        address2: '',
        city: '',
        zip: '',
        state: '',
        country_id: '',
      };

      let savedCustomerData = PersistentStorage.getCustomerData();

      return {
        ...defaultCustomer,
        ...savedCustomerData,
        country_id: this.country.id,
      };
    },
    getStepForm(step) {
      switch (step) {
        case STEPS.CONTACT_INFO:
          return this.$refs.formContactInfo;
        case STEPS.SHIPPING:
          return this.$refs.formShipping;
        case STEPS.AGENT:
          return this.$refs.formAgent;
        case STEPS.PAYMENT:
          return this.$refs.formPayment;
      }
    },
    getNextStep(step) {
      switch (step) {
        case STEPS.CONTACT_INFO:
          return STEPS.SHIPPING;
        case STEPS.SHIPPING:
          if (!this.agents.length || this.fromAgentLink) {
            return STEPS.PAYMENT;
          }
          return STEPS.AGENT;
        case STEPS.AGENT:
          return STEPS.PAYMENT;
        case STEPS.PAYMENT:
          return STEPS.PAYMENT;
      }
    },
  },
};
</script>
<style lang="scss">
.store-page-checkout {
  margin: $s-10 auto 0;

  .store__container {
    position: relative;

    .back-button {
      position: absolute;
      left: $container-padding;
      top: 0;
      z-index: 2;
    }
  }
}

.store-checkout {
  position: relative;
  max-width: 824px + 30px;
  padding: 0 $container-padding;
  margin: 0 auto;

  &__empty-cart {
    color: rgba($black, 0.5);
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    text-align: center;
    margin: $s-20 0 $s-32;
  }

  &__steps {
    margin-bottom: $s-24;
  }
  &__step {
    border-bottom: 1px solid $gray-500;
    padding: $s-6 0;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &__title {
      color: rgba($black, 0.5);
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      text-align: center;
    }

    &__list {
      margin-top: $s-10;
      margin-bottom: $s-4;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__left {
        span {
          display: block;
          font-weight: 300;
          margin-top: $s-2;
          color: $gray-800;
          line-height: 24px;

          &:first-child {
            margin-top: 0;
          }
        }
      }

      &__right {
        a {
          color: rgba($gray-800, 0.25);
        }
      }
    }
  }

  &__form {
    margin-top: $s-16;
    margin-bottom: $s-2;

    &__btn-wrapper {
      display: flex;
      justify-content: center;
    }
  }

  &__step {
    &--agent {
      .store-checkout {
        &__form {
          margin-top: $s-10;
        }
      }
    }

    &--payment {
      .store-checkout {
        &__form {
          margin-top: $s-10;

          .btn {
            width: 100%;
          }
        }
      }
    }
  }
}

.store {
  &__footer-cart {
    width: 100%;
    background-color: $gray-800;
    border-bottom: 1px solid rgba($white, 0.25);

    .cart {
      padding: $s-6 0 $s-12;
    }
  }
}
@include media-breakpoint-down(md) {
  .store-page-checkout {
    margin: $s-4 auto 0;
  }

  .store-checkout {
    padding: 0;
    &__step {
      &:first-child {
        .store-checkout__step__list {
          margin-top: $s-16;
        }
      }
    }
  }

  .store {
    &__footer-cart {
      .cart {
        padding: $s-10 0 $s-8;
      }
    }
  }
}

@include media-breakpoint-down(xxs) {
  .store-checkout {
    &__step {
      &:first-child {
        padding-top: $s-12;
      }
    }
  }
}
</style>
