<script>
import MarketplaceShow from '~/pages/dashboard/marketplace/MarketplaceShow';
import UserEnum from '~/enums/UserEnum';
import Connection from '~/models/Connection';
import Partner from '~/models/Partner';

export default {
  extends: MarketplaceShow,
  data() {
    return {
      type: UserEnum.PARTNER,
    };
  },
  methods: {
    getConnection() {
      return Connection.where('company_id', this.userable.id)
        .where('partner_id', this.business.id)
        .firstOrNull();
    },
    getBaseQuery() {
      return Partner.include('agentsCount', 'companiesAcceptedCount');
    },
  },
};
</script>
