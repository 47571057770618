var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "marketing-page marketing-page-terms-and-conditions" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "marketing-page__content" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _vm.isCas
              ? [
                  _c("h5", [_vm._v("Welcome to caskadepro")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          caskadepro is an online Professional Affiliate Platform designed\n          specifically to allow you, the Professional, to monetise the power\n          of your professional recommendations and advice.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          Ever wondered what your bank balance would look like if you had a\n          euro for every time you have given direction on a product previously\n          to someone and received nothing in return for it? Wonder no more.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          Our outstanding new concept is something that allows you to provide\n          a unique level of professional service to your clients and be\n          rewarded at the same time.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          In today's marketplace the number of advertising channels employed\n          by Brands to expand their footprint and drive sales continues to\n          grow exponentially. caskadepro recognises that Professional\n          expertise remains the most effective and valuable way of educating\n          the end consumer pre purchase.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "Monetise the Power of your Professional Recommendation."
                    )
                  ])
                ]
              : [
                  _c("h5", [_vm._v("Welcome to That’sThe1")]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          That’sThe1 is an affiliate platform for every type of online content\n          creator inclusive of Influencers, Bloggers and Youtubers. Partner up\n          with leading brands from the Hair, Beauty, Skin Care, Lifestyle and\n          Health & Fitness industries and monetise the power of your\n          recommendations.\n        "
                    )
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _vm._v(
                      "\n          That’sThe1 affiliate links will enhance the impact of your content\n          and allow you to build relationships with your favourite brands,\n          while promoting and connecting these brands with your followers.\n        "
                    )
                  ])
                ]
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "marketing-page__banner" }, [
      _c("div", { staticClass: "container" }, [
        _c("h2", { staticClass: "marketing-page__title" }, [_vm._v("About us")])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }