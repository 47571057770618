var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          var invalid = ref.invalid
          return [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.submit)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("ValidationProvider", {
                      attrs: {
                        name: "services_ids_per_sector",
                        vid: "services_ids_per_sector"
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function(ref) {
                              var errors = ref.errors
                              return [
                                _c("label", [_vm._v("Sectors & Services")]),
                                _vm._v(" "),
                                _c("ServicesBySectorSelect", {
                                  on: {
                                    input: function($event) {
                                      return _vm.$refs.form.reset()
                                    }
                                  },
                                  model: {
                                    value: _vm.form.services_ids_per_sector,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.form,
                                        "services_ids_per_sector",
                                        $$v
                                      )
                                    },
                                    expression: "form.services_ids_per_sector"
                                  }
                                }),
                                _vm._v(" "),
                                errors[0]
                                  ? _c(
                                      "span",
                                      { staticClass: "form-group__error" },
                                      [_vm._v(_vm._s(errors[0]))]
                                    )
                                  : _vm._e()
                              ]
                            }
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "LoadingBtn",
                  {
                    staticClass: "btn",
                    attrs: {
                      loading: _vm.loading,
                      disabled: invalid,
                      type: "submit"
                    }
                  },
                  [_vm._v("\n      Save\n    ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }