<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M21 2H3a3 3 0 00-3 3v14a3 3 0 003 3h18a3 3 0 003-3V5a3 3 0 00-3-3zM8.207 15.207l-2.5 2.5a1 1 0 01-1.414-1.414l2.5-2.5 1.414 1.414zm11.5 2.5a1 1 0 01-1.414 0l-2.5-2.5 1.414-1.414 2.5 2.5a1 1 0 010 1.414zm0-10l-7 7a1 1 0 01-1.414 0l-7-7a1 1 0 011.414-1.414L12 12.586l6.293-6.293a1 1 0 111.414 1.414z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
