var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading && _vm.testimonials.length
    ? _c(
        "div",
        { staticClass: "testimonials-slider" },
        [
          _c(
            "a",
            {
              staticClass:
                "testimonials-slider__nav testimonials-slider__nav--prev",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.previous($event)
                }
              }
            },
            [_c("IconChevronLeft", { attrs: { width: "40", height: "40" } })],
            1
          ),
          _vm._v(" "),
          _c(
            "flickity",
            {
              ref: "flickity",
              staticClass: "testimonials-slider__inner",
              attrs: { options: _vm.flickityOptions }
            },
            _vm._l(_vm.testimonials, function(testimonial) {
              return _c(
                "div",
                {
                  staticClass: "carousel-cell testimonials-slider__testimonial"
                },
                [
                  _c(
                    "div",
                    { staticClass: "testimonials-slider__testimonial__rating" },
                    [
                      _c("IconStar"),
                      _vm._v(" "),
                      _c("IconStar"),
                      _vm._v(" "),
                      _c("IconStar"),
                      _vm._v(" "),
                      _c("IconStar"),
                      _vm._v(" "),
                      _c("IconStar")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "h4",
                    { staticClass: "testimonials-slider__testimonial__text" },
                    [
                      _vm._v(
                        "\n        " + _vm._s(testimonial.text) + "\n      "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "h6",
                    { staticClass: "testimonials-slider__testimonial__author" },
                    [
                      _vm._v(
                        "\n        " + _vm._s(testimonial.author) + "\n      "
                      )
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass:
                "testimonials-slider__nav testimonials-slider__nav--next",
              attrs: { href: "#" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.next($event)
                }
              }
            },
            [_c("IconChevronRight", { attrs: { width: "40", height: "40" } })],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }