var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Companies" } },
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            {
              attrs: {
                name: "Requests",
                tooltip: "Connection Requests sent by you",
                selected: ""
              }
            },
            [_c("ConnectionsRequested")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            {
              attrs: {
                name: "Pending",
                tooltip: "Connection Requests awaiting your response"
              }
            },
            [
              _c("ConnectionsPending", {
                ref: "connectionsPendingTable",
                on: { updated: _vm.onConnectionUpdated }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            {
              attrs: {
                name: "Live",
                tooltip: "Current Live Partner Connections"
              }
            },
            [_c("ConnectionsLive", { ref: "connectionsLiveTable" })],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            {
              attrs: {
                name: "Declined",
                tooltip: "Declined Connection Requests"
              }
            },
            [_c("ConnectionsDeclined", { ref: "connectionsDeclinedTable" })],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }