<template>
  <div class="settings-inner">
    <Tabs>
      <slot />
    </Tabs>
  </div>
</template>
<script>
import Tabs from '~/components/Tabs';

export default {
  components: {
    Tabs,
  },
};
</script>
<style lang="scss">
.settings-inner {
  background-color: $white;
  border: 1px solid $gray-500;

  .tabs {
    &__nav {
      padding: 22px 32px 0;
    }

    &__pane {
      padding: 35px 32px;
      max-width: 440px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .settings-inner {
    margin-top: -$s-6;
    background-color: $white;
    border: none;
    margin-left: -#{$container-padding};
    margin-right: -#{$container-padding};

    .tabs {
      &__nav {
        padding: 22px $container-padding 0;
      }

      &__pane {
        padding: $s-4 $container-padding;
        max-width: 440px;
      }
    }
  }
}
</style>
