var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "single-uploader" },
    [
      _c("input", {
        directives: [
          { name: "show", rawName: "v-show", value: false, expression: "false" }
        ],
        ref: "file",
        attrs: { type: "file", multiple: false, accept: _vm.allowedFileTypes }
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "single-uploader__preview" },
        [_vm._t("preview", null, { media: _vm.media })],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "single-uploader__text" }, [_vm._t("text")], 2),
      _vm._v(" "),
      _c(
        "LoadingBtn",
        {
          staticClass: "btn btn--secondary",
          attrs: { skeleton: _vm.loadingUppy, loading: _vm.uploading },
          on: { click: _vm.onClickUpload }
        },
        [_vm._v("Upload\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }