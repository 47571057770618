var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-avatar" },
    [
      _vm.avatar
        ? _c("img", {
            style: { maxWidth: _vm.size + "px" },
            attrs: { src: _vm.avatar.urls.thumb }
          })
        : [
            _c(
              "svg",
              {
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  width: _vm.size,
                  height: _vm.size,
                  viewBox: "0 0 40 40"
                }
              },
              [
                _c("g", { attrs: { fill: _vm.colour, opacity: _vm.opacity } }, [
                  _c("path", {
                    attrs: {
                      d:
                        "M20 36.667c-4.055 0-7.775-1.46-10.668-3.877 1.398-2.638 4.14-4.457 7.335-4.457h6.666c3.195 0 5.937 1.819 7.335 4.457A16.592 16.592 0 0 1 20 36.667zm-6.667-20C13.333 12.985 16.407 10 20 10s6.667 2.985 6.667 6.667v1.666C26.667 22.015 23.593 25 20 25s-6.667-2.985-6.667-6.667zM20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                    }
                  })
                ])
              ]
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }