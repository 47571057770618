<template>
  <DashboardPage title="Configuration">
    <Tabs>
      <Tab name="Sectors" selected>
        <SectorsTable />
      </Tab>
      <Tab name="Categories" selected>
        <CategoriesTable />
      </Tab>
      <Tab v-if="hasFeatureServices()" name="Services" selected>
        <ServicesTable />
      </Tab>
    </Tabs>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import DashboardPage from '~/components/DashboardPage';
import Tabs from '~/components/Tabs';
import Tab from '~/components/Tab';
import CategoriesTable from '~/pages/dashboard/admin/partials/CategoriesTable';
import SectorsTable from '~/pages/dashboard/admin/partials/SectorsTable';
import ServicesTable from '~/pages/dashboard/admin/partials/ServicesTable';
import { hasFeatureServices } from '~/utils';

export default {
  extends: Page,
  components: {
    ServicesTable,
    SectorsTable,
    CategoriesTable,
    DashboardPage,

    Tabs,
    Tab,
  },
  methods: {
    hasFeatureServices,
  },
};
</script>
<style lang="scss"></style>
