<script>
import Listing from '~/pages/store/partials/Listing';
import Product from '~/models/Product';

export default {
  extends: Listing,
  methods: {
    getBaseQuery() {
      return new Product()
        .include('category.sector', 'image', 'company')
        .where('listingPartners.id', this.business.id);
    },
    getProductUrl(product) {
      return this.route('store.partner.product', {
        partner: this.business.slug,
        product: product.slug,
      });
    },
  },
};
</script>
<style lang="scss"></style>
