import Vue from 'vue';
import log from '~/log';

const getValidationErrors = (e) => {
  const data = e.response.data;
  return data.errors;
};

const handleUnknownError = (e) => {
  const message = 'An unknown error has occurred. Please try again later.';
  Vue.toasted.error(message);
  log(e);
};

const handleError = (e, { errorsUiContainer }) => {
  if (!e.response) {
    handleUnknownError(e);
    return;
  }

  const statusCode = parseInt(e.response?.status);

  if (statusCode >= 500) {
    handleUnknownError(e);
    return;
  }

  if (statusCode === 422) {
    if (errorsUiContainer) {
      errorsUiContainer.setErrors(getValidationErrors(e));
    }
    return;
  }

  const message = e.response?.data?.message;
  if (message) {
    Vue.toasted.error(message);
    return;
  }

  handleUnknownError(e);
};

export default function () {
  return (e, errorsUiContainer) => {
    handleError(e, { errorsUiContainer });
  };
}
