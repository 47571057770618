var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ModalWithSteps",
    {
      staticClass: "modal--with-steps",
      on: { "modal-close": _vm.handleClose }
    },
    [
      _c("div", { staticClass: "modal__header" }, [
        _c("h3", { staticClass: "modal__title" }, [
          _vm._v("Add a new product")
        ]),
        _vm._v(" "),
        _c("h3", { staticClass: "modal__title modal__steps__number" }, [
          _vm._v(
            "\n      Step " +
              _vm._s(_vm.currentStep) +
              " of " +
              _vm._s(_vm.totalSteps) +
              "\n    "
          )
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "modal__close",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_c("IconClose")],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__steps__progress" }, [
        _c("div", { style: { width: _vm.progressPercentage + "%" } })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__body" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 1,
                expression: "currentStep === 1"
              }
            ],
            staticClass: "modal__step"
          },
          [
            _c("ValidationObserver", {
              ref: "formOne",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              handleSubmit(function() {
                                return _vm.validateStep(1)
                              })
                            }
                          }
                        },
                        [
                          _c("VInput", {
                            attrs: {
                              name: "Product name",
                              vid: "name",
                              rules: "required|min:3"
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("SectorField", {
                                attrs: { "filter-by-auth": "" },
                                model: {
                                  value: _vm.form.sector_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "sector_id", $$v)
                                  },
                                  expression: "form.sector_id"
                                }
                              }),
                              _vm._v(" "),
                              _c("CategoryField", {
                                attrs: {
                                  "sector-id": _vm.form.sector_id,
                                  "filter-by-auth": ""
                                },
                                on: {
                                  "update:sectorId": function($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "sector_id",
                                      $event
                                    )
                                  },
                                  "update:sector-id": function($event) {
                                    return _vm.$set(
                                      _vm.form,
                                      "sector_id",
                                      $event
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.form.category_id,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "category_id", $$v)
                                  },
                                  expression: "form.category_id"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("VTextarea", {
                            attrs: {
                              name: "Description",
                              vid: "description",
                              "label-info-text":
                                "General description of this product - optional"
                            },
                            model: {
                              value: _vm.form.description,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "description", $$v)
                              },
                              expression: "form.description"
                            }
                          }),
                          _vm._v(" "),
                          _c("VTextarea", {
                            attrs: {
                              name: "Features",
                              vid: "features",
                              "label-info-text":
                                "Specific features unique to this product - optional"
                            },
                            model: {
                              value: _vm.form.features,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "features", $$v)
                              },
                              expression: "form.features"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal__step__actions" },
                            [
                              _c(
                                "LoadingBtn",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    loading: _vm.loadingStep[1],
                                    disabled: invalid,
                                    type: "submit"
                                  }
                                },
                                [_vm._v("\n              Next\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 2,
                expression: "currentStep === 2"
              }
            ],
            staticClass: "modal__step"
          },
          [
            _c("ValidationObserver", {
              ref: "formTwo",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              handleSubmit(function() {
                                return _vm.validateStep(2)
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("VInput", {
                                attrs: {
                                  name: "Weight",
                                  vid: "weight",
                                  "label-info-text": "- Optional"
                                },
                                model: {
                                  value: _vm.form.weight,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "weight", $$v)
                                  },
                                  expression: "form.weight"
                                }
                              }),
                              _vm._v(" "),
                              _c("VInput", {
                                attrs: {
                                  name: "Dimensions",
                                  vid: "dimensions",
                                  placeholder: "Length x Width x Height",
                                  "label-info-text": "- Optional"
                                },
                                model: {
                                  value: _vm.form.dimensions,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "dimensions", $$v)
                                  },
                                  expression: "form.dimensions"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("VInput", {
                                attrs: {
                                  name: "Size",
                                  vid: "size",
                                  "label-info-text":
                                    "- Only 1 size supported - optional"
                                },
                                model: {
                                  value: _vm.form.size,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "size", $$v)
                                  },
                                  expression: "form.size"
                                }
                              }),
                              _vm._v(" "),
                              _c("VInput", {
                                attrs: {
                                  label: "Available colours",
                                  name: "Colours",
                                  vid: "colors_string",
                                  "label-info-text":
                                    "- Hex values, comma separated - optional"
                                },
                                model: {
                                  value: _vm.form.colors_string,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "colors_string", $$v)
                                  },
                                  expression: "form.colors_string"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("VInput", {
                                attrs: {
                                  name: "SKU code",
                                  vid: "sku",
                                  rules: "required",
                                  "info-text":
                                    "Existing SKU code for this product"
                                },
                                model: {
                                  value: _vm.form.sku,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "sku", $$v)
                                  },
                                  expression: "form.sku"
                                }
                              }),
                              _vm._v(" "),
                              _c("VInput", {
                                attrs: {
                                  name: "Stock count",
                                  vid: "initial_stock_count",
                                  rules: "required|integer|min:0",
                                  "info-text":
                                    "Quantity of this product available on platform"
                                },
                                model: {
                                  value: _vm.form.initial_stock_count,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "initial_stock_count",
                                      $$v
                                    )
                                  },
                                  expression: "form.initial_stock_count"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal__step__actions" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.currentStep = 1
                                    }
                                  }
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "LoadingBtn",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    loading: _vm.loadingStep[2],
                                    disabled: invalid,
                                    type: "submit"
                                  }
                                },
                                [_vm._v("\n              Next\n            ")]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 3,
                expression: "currentStep === 3"
              }
            ],
            staticClass: "modal__step"
          },
          [
            _c("ValidationObserver", {
              ref: "formThree",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              handleSubmit(function() {
                                return _vm.validateStep(3)
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-2-col" },
                            [
                              _c("VMoney", {
                                attrs: {
                                  name: "Price (€)",
                                  vid: "price",
                                  rules: "required|decimal|min_value:0.1",
                                  "label-info-text": "This price is excl. VAT"
                                },
                                model: {
                                  value: _vm.priceInMajorUnits,
                                  callback: function($$v) {
                                    _vm.priceInMajorUnits = $$v
                                  },
                                  expression: "priceInMajorUnits"
                                }
                              }),
                              _vm._v(" "),
                              _c("VMoney", {
                                attrs: {
                                  name: "Delivery (€)",
                                  vid: "delivery_cost",
                                  rules: "required|decimal|min_value:0",
                                  "label-info-text": "Excl. VAT."
                                },
                                model: {
                                  value: _vm.deliveryCostInMajorUnits,
                                  callback: function($$v) {
                                    _vm.deliveryCostInMajorUnits = $$v
                                  },
                                  expression: "deliveryCostInMajorUnits"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("VPercent", {
                            attrs: {
                              name: "Default commission (%)",
                              vid: "default_commission_rate",
                              rules:
                                "required|decimal|max_value:100|min_value:0",
                              "info-text":
                                "Commission rate based on product RRP"
                            },
                            model: {
                              value: _vm.defaultCommissionPercentage,
                              callback: function($$v) {
                                _vm.defaultCommissionPercentage = $$v
                              },
                              expression: "defaultCommissionPercentage"
                            }
                          }),
                          _vm._v(" "),
                          _c("VTextarea", {
                            attrs: {
                              name: "Commission details",
                              vid: "commission_details",
                              "label-info-text":
                                "Shown to partners in the marketplace."
                            },
                            model: {
                              value: _vm.form.commission_details,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "commission_details", $$v)
                              },
                              expression: "form.commission_details"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal__step__actions" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.currentStep = 2
                                    }
                                  }
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "LoadingBtn",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    loading: _vm.loadingStep[3],
                                    disabled: invalid,
                                    type: "submit"
                                  }
                                },
                                [_vm._v("\n              Next\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 4,
                expression: "currentStep === 4"
              }
            ],
            staticClass: "modal__step"
          },
          [
            _c("ValidationObserver", {
              ref: "form",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.submit)
                            }
                          }
                        },
                        [
                          _c("VInput", {
                            attrs: {
                              name: "Product video URL",
                              vid: "video_url",
                              rules: "url",
                              "label-info-text": "- Optional",
                              "info-text":
                                "Standard youtube and vimeo URLs supported. These videos are displayed to partners and customers."
                            },
                            model: {
                              value: _vm.form.video_url,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "video_url", $$v)
                              },
                              expression: "form.video_url"
                            }
                          }),
                          _vm._v(" "),
                          _c("VInput", {
                            attrs: {
                              name: "Product question",
                              vid: "question",
                              "label-info-text": "- Optional",
                              "info-text":
                                "Support for customisable products. Shown to customers on checkout. Answer received on the Receipt. 255 character limit."
                            },
                            model: {
                              value: _vm.form.question,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "question", $$v)
                              },
                              expression: "form.question"
                            }
                          }),
                          _vm._v(" "),
                          _c("ValidationProvider", {
                            attrs: { name: "images", vid: "images" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("label", [
                                            _c("span", [
                                              _vm._v(
                                                "Add photos of the product"
                                              )
                                            ]),
                                            _c(
                                              "span",
                                              { staticClass: "optional-label" },
                                              [
                                                _vm._v(
                                                  "- Minimum 1 required. 880x880px jpeg for best results"
                                                )
                                              ]
                                            )
                                          ]),
                                          _vm._v(" "),
                                          _c("MultiUploader", {
                                            attrs: {
                                              for: _vm.mediaFor,
                                              collection:
                                                _vm.MediaCollectionEnum.IMAGES,
                                              count: _vm.form.images.length,
                                              limit: _vm.imagesLimit
                                            },
                                            on: {
                                              "upload-success": _vm.onMediaAdded
                                            }
                                          }),
                                          _vm._v(" "),
                                          _vm.form.images.length
                                            ? _c("MediaList", {
                                                attrs: {
                                                  list: _vm.form.images
                                                },
                                                on: {
                                                  remove: _vm.handleRemoveMedia
                                                }
                                              })
                                            : _vm._e(),
                                          _vm._v(" "),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-group__error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal__step__actions" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.currentStep = 3
                                    }
                                  }
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "LoadingBtn",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: invalid,
                                    type: "submit"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              Add your product\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }