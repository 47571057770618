var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.onClickaway,
          expression: "onClickaway"
        }
      ],
      staticClass: "drawer",
      class: _vm.classes
    },
    [
      _c("div", { staticClass: "drawer__top" }, [
        _c(
          "a",
          {
            staticClass: "drawer__logo",
            attrs: { href: _vm.route("dashboard") }
          },
          [_vm.condensed ? _c("LogoMark") : _c("Logo")],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "drawer__toggle",
            on: {
              click: function($event) {
                return _vm.$emit("toggle-condensed")
              }
            }
          },
          [_c("IconChevronLeft", { attrs: { width: 20, height: 20 } })],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "drawer__content" }, [
        _c(
          "ul",
          { staticClass: "drawer__menu" },
          _vm._l(_vm.items, function(item) {
            return _c("li", [
              _c(
                "a",
                {
                  staticClass: "drawer__menu__link",
                  class: { active: _vm.route().current(item.route) },
                  attrs: { href: _vm.route(item.route) }
                },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: _vm.getMenuTooltip(item),
                          expression: "getMenuTooltip(item)"
                        }
                      ],
                      staticClass: "drawer__menu__icon"
                    },
                    [
                      _c(item.icon, {
                        tag: "component",
                        attrs: { width: 20, height: 20 }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "drawer__menu__label" }, [
                    _vm._v(_vm._s(item.label))
                  ])
                ]
              )
            ])
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "drawer__bottom" },
        [
          _c(
            "VPopover",
            {
              attrs: {
                placement: "right-end",
                "boundaries-element": "body",
                "popover-class": "tooltip-with-menu"
              }
            },
            [
              _c(
                "span",
                { staticClass: "drawer__user" },
                [
                  _c("UserAvatar", {
                    attrs: {
                      avatar: _vm.user.avatar,
                      colour: _vm.user.avatar_colour
                    }
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.user.name))])
                ],
                1
              ),
              _vm._v(" "),
              _c("template", { slot: "popover" }, [
                _c("div", { staticClass: "tooltip-menu" }, [
                  (!_vm.isTt1 && _vm.userType === _vm.UserEnum.PARTNER) ||
                  _vm.userType === _vm.UserEnum.COMPANY
                    ? _c(
                        "a",
                        {
                          staticClass: "tooltip-menu__item",
                          attrs: { href: _vm.profileUrl, target: "_blank" }
                        },
                        [_vm._v("\n            View profile\n          ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.userType === _vm.UserEnum.PARTNER ||
                    _vm.userType === _vm.UserEnum.AGENT) &&
                  _vm.userable.has_profile_complete &&
                  _vm.userable.has_stripe_connected
                    ? _c(
                        "button",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.storeUrl,
                              expression: "storeUrl",
                              arg: "copy"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.onUrlCopied,
                              expression: "onUrlCopied",
                              arg: "success"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:error",
                              value: _vm.onUrlCopyFailed,
                              expression: "onUrlCopyFailed",
                              arg: "error"
                            }
                          ],
                          staticClass: "tooltip-menu__item"
                        },
                        [_vm._v("\n            Copy store URL\n          ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "tooltip-menu__item",
                      attrs: { href: this.route("logout") }
                    },
                    [_vm._v("Log out")]
                  )
                ])
              ])
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }