<template>
  <Datatable
    ref="datatable"
    :get-base-query="getBaseQuery"
    :query-custom-params="queryCustomParams"
  >
    <template v-slot:actions>
      <slot name="actions"></slot>
    </template>
    <template v-slot:cell(actions)="{ item }">
      <div class="table__row-actions">
        <slot name="row-actions" :item="item" />
      </div>
    </template>
  </Datatable>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import DatatableModel from '~/models/Datatable';

export default {
  extends: Page,
  components: {
    DashboardPage,
    Datatable,
  },
  props: {
    approval: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      queryCustomParams: {
        table_id: 'admin-companies',
      },
    };
  },
  methods: {
    getBaseQuery() {
      return new DatatableModel().where('approval', this.approval);
    },
    refresh() {
      this.$refs.datatable.fetchResources(true);
    },
  },
};
</script>
