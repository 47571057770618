<template>
  <button
    :type="type"
    :data-style="dataStyle"
    @click="handleClick"
    class="ladda-button"
    :class="classes"
    :disabled="disabled"
  >
    <span class="ladda-label"><slot>Submit</slot></span>
  </button>
</template>
<script>
export default {
  props: {
    // use vue props validation to make sure "data-style" is given. (ladda need it)
    dataStyle: {
      type: String,
      required: false,
      default: 'zoom-in',
    },
    loading: {
      type: Boolean,
      required: true,
    },
    skeleton: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classes() {
      let classes = [];
      if (this.skeleton) {
        classes.push('ladda-button--skeleton');
      }

      return classes;
    },
  },
  watch: {
    loading(loading) {
      if (this.ladda) {
        loading ? this.ladda.start() : this.ladda.stop();
      }
    },
  },
  async mounted() {
    const { create } = await import(/* webpackChunkName: 'ladda' */ `ladda`);
    this.ladda = create(this.$el);
    this.loading ? this.ladda.start() : this.ladda.stop();
  },
  beforeDestroy() {
    if (this.ladda) {
      this.ladda.remove();
      delete this.ladda;
    }
  },
  methods: {
    handleClick(e) {
      this.$emit('click', e);
    },
  },
};
</script>
<style lang="scss">
@import '~ladda/css/ladda.scss';

.btn.ladda-button--skeleton {
  @include component-placeholder();
  width: auto;
}
</style>
