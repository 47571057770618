const state = () => ({
  countries: [],
  country: null,
});

const getters = {
  vatRate(state) {
    return state.country?.vat_rate;
  },
  getCountryById: (state) => (id) => {
    return state.countries.find((c) => c.id === id);
  },
  orderedCountries(state) {
    let countries = [...state.countries];
    let gbIndex = countries.findIndex((c) => c.code === 'GB');
    countries.unshift(countries.splice(gbIndex, 1)[0]);

    let ieIndex = countries.findIndex((c) => c.code === 'IE');
    countries.unshift(countries.splice(ieIndex, 1)[0]);

    return countries;
  },
};

const mutations = {
  setCountries(state, countries) {
    state.countries = countries;
  },
  setCountry(state, country) {
    state.country = country;
  },
};

const actions = {
  setCountry({ commit }, country) {
    commit('setCountry', country);
    commit('cart/setVatRate', country.vat_rate, { root: true });
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
};
