<script>
import ConnectionStatusEnum from '~/enums/ConnectionStatusEnum';
import Connections from '~/pages/dashboard/partner/partials/connections/Connections';
import DatatableModel from '~/models/Datatable';

export default {
  extends: Connections,
  methods: {
    getBaseQuery() {
      return DatatableModel.where('partner_id', this.$global.userable.id).where(
        'status',
        ConnectionStatusEnum.DECLINED
      );
    },
  },
};
</script>
