<template>
  <div class="page page-components">
    <div class="container">
      <Datatable />
    </div>
  </div>
</template>
<script>
import Page from '~/pages/Page';
import Modal from '~/components/Modal';
import Datatable from '~/components/datatable/Datatable';

export default {
  components: { Datatable, Modal },
  extends: Page,
};
</script>
<style lang="scss">
.page-components {
  .container {
    max-width: 800px;
    margin: 0 auto;
  }
}
</style>
