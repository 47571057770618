var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          var invalid = ref.invalid
          return [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.submit)
                  }
                }
              },
              [
                _c("VInput", {
                  attrs: {
                    name: "Old Password",
                    vid: "old_password",
                    type: "password",
                    rules: "required"
                  },
                  model: {
                    value: _vm.form.old_password,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "old_password", $$v)
                    },
                    expression: "form.old_password"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "New Password",
                    vid: "new_password",
                    type: "password",
                    rules: "required"
                  },
                  model: {
                    value: _vm.form.new_password,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "new_password", $$v)
                    },
                    expression: "form.new_password"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "New Password Confirmation",
                    vid: "new_password_confirmation",
                    type: "password",
                    rules: "required|min:6"
                  },
                  model: {
                    value: _vm.form.new_password_confirmation,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "new_password_confirmation", $$v)
                    },
                    expression: "form.new_password_confirmation"
                  }
                }),
                _vm._v(" "),
                _c(
                  "LoadingBtn",
                  {
                    staticClass: "btn",
                    attrs: {
                      loading: _vm.loading,
                      disabled: invalid,
                      type: "submit"
                    }
                  },
                  [_vm._v("Save\n    ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }