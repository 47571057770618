<template>
  <Modal size="sm" @modal-close="handleClose">
    <div class="modal__header">
      <h3 class="modal__title">{{ isEdit ? 'Edit' : 'Add' }} sector</h3>
      <button type="button" class="modal__close" @click="handleClose">
        <IconClose />
      </button>
    </div>
    <div class="modal__body">
      <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
        <form class="form" @submit.prevent="handleSubmit(save)">
          <VInput v-model="form.name" name="Name" vid="name" rules="required" />
          <div class="modal__btn-wrapper">
            <LoadingBtn
              :loading="saving"
              :disabled="invalid"
              class="btn"
              type="submit"
            >
              {{ isEdit ? 'Update' : 'Create' }} sector
            </LoadingBtn>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </Modal>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import LoadingBtn from '~/components/LoadingBtn';
import Modal from '~/components/Modal';
import VInput from '~/components/form/VInput';
import VSelect from '~/components/form/VSelect';
import IconClose from '~/components/icons/IconClose';
import Loader from '~/components/Loader';
import Sector from '~/models/Sector';

export default {
  components: {
    VInput,
    LoadingBtn,
    Modal,
    ValidationObserver,
    VSelect,
    IconClose,
    Loader,
  },
  props: {
    model: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      form: this.getForm(this.model),
      saving: false,
      sectors: [],
      loadingSectors: false,
    };
  },

  computed: {
    isEdit() {
      return this.model;
    },
  },

  methods: {
    getForm(model) {
      return {
        name: model ? model.name : '',
      };
    },

    async save() {
      this.saving = true;

      try {
        if (this.isEdit) {
          await this.update();
        } else {
          await this.create();
        }

        this.$toasted.success(
          this.isEdit ? 'Sector updated!' : 'Sector added!'
        );
        this.$emit('saved');
        this.handleClose();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.saving = false;
      }
    },

    update() {
      return new Sector({
        id: this.model.id,
        ...this.form,
      }).save();
    },

    create() {
      return new Sector({
        ...this.form,
      }).save();
    },

    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss"></style>
