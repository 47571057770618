<template>
  <DashboardPage title="Settings">
    <SettingsWrapper>
      <Tab name="Business Details">
        <TabCompanyOrPartnerBusinessDetails />
      </Tab>
      <Tab name="Account">
        <TabAccount />
      </Tab>
      <Tab name="Change Password">
        <TabChangePassword />
      </Tab>
      <Tab name="Bank Details">
        <TabBankDetails />
      </Tab>
      <Tab name="VAT Settings">
        <TabCompanyOrPartnerVat />
      </Tab>
      <Tab name="Sectors">
        <TabSectors />
      </Tab>
    </SettingsWrapper>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import Tab from '~/components/Tab';
import SettingsWrapper from '~/pages/dashboard/common/settings/SettingsWrapper';
import TabCompanyOrPartnerBusinessDetails from '~/pages/dashboard/common/settings/TabCompanyOrPartnerBusinessDetails';
import TabBankDetails from '~/pages/dashboard/common/settings/TabBankDetails';
import TabAccount from '~/pages/dashboard/common/settings/TabAccount';
import TabChangePassword from '~/pages/dashboard/common/settings/TabChangePassword';
import TabCompanyOrPartnerVat from '~/pages/dashboard/common/settings/TabCompanyOrPartnerVat';
import TabSectors from '~/pages/dashboard/common/settings/TabSectors';

export default {
  extends: Page,
  components: {
    TabSectors,
    DashboardPage,
    Datatable,
    Tab,
    SettingsWrapper,
    TabCompanyOrPartnerBusinessDetails,
    TabBankDetails,
    TabAccount,
    TabChangePassword,
    TabCompanyOrPartnerVat,
  },
};
</script>
