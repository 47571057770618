<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M12.889 6.682l4.43 4.43-8.013 8.012c-.09.09-.2.157-.32.197l-4.91 1.636a.818.818 0 01-1.034-1.034l1.637-4.909c.04-.12.107-.23.197-.32l8.013-8.012zM16.33 3.24a.818.818 0 011.157 0l3.272 3.272c.32.32.32.838 0 1.157l-2.285 2.286-4.43-4.43z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
