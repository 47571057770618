<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M13.16 0c2.224.005 2.679.022 3.788.072 1.95.089 3.663.567 5.038 1.942 1.375 1.375 1.853 3.088 1.942 5.038.052 1.152.069 1.598.072 4.052v1.792c-.003 2.454-.02 2.9-.072 4.052-.089 1.95-.567 3.663-1.942 5.038-1.375 1.375-3.088 1.853-5.038 1.942-1.152.052-1.598.069-4.052.072h-1.792c-2.454-.003-2.9-.02-4.052-.072-1.95-.089-3.663-.567-5.038-1.942C.639 20.611.161 18.898.072 16.948c-.05-1.11-.067-1.564-.071-3.788v-2.32C.005 8.616.02 8.16.072 7.052c.089-1.95.567-3.663 1.942-5.038C3.389.639 5.102.161 7.052.072 8.162.022 8.616.005 10.84.001zm-.556 2.162h-1.208c-2.655.002-3.064.016-4.245.07-1.366.062-2.633.336-3.608 1.311-.975.975-1.249 2.242-1.311 3.608-.054 1.18-.068 1.59-.07 4.245v1.208c.002 2.655.016 3.064.07 4.245.062 1.366.336 2.633 1.311 3.608.975.975 2.242 1.249 3.608 1.311 1.096.05 1.528.066 3.71.07h2.279c2.181-.004 2.613-.02 3.709-.07 1.366-.062 2.633-.336 3.608-1.311.975-.975 1.249-2.242 1.311-3.608.05-1.096.066-1.528.07-3.71V10.86c-.004-2.181-.02-2.613-.07-3.709-.062-1.366-.336-2.633-1.311-3.608-.975-.975-2.242-1.249-3.608-1.311-1.18-.054-1.59-.068-4.245-.07zM12 5.838a6.162 6.162 0 110 12.324 6.162 6.162 0 010-12.324zM12 8a4 4 0 100 8 4 4 0 000-8zm6.406-3.846a1.44 1.44 0 110 2.88 1.44 1.44 0 010-2.88z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
