<template>
  <div class="metric-value">
    <h6 class="metric-value__title">{{ title }}</h6>
    <h6 class="metric-value__value">
      {{ formattedValue }}
      <Loader v-if="loading"></Loader>
    </h6>
  </div>
</template>
<script>
import { formatMoney } from '~/utils';
import Loader from '~/components/Loader';

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: Number,
      required: false,
    },
    format: {
      type: String,
      default: 'currency',
    },
    endpoint: {
      type: String,
      default: '',
    },
  },
  components: {
    Loader,
  },
  data() {
    return {
      actualValue: this.value,
      loading: false,
    };
  },
  mounted() {
    this.sync();
  },
  computed: {
    formattedValue() {
      if (!this.actualValue) {
        return '-';
      }
      switch (this.format) {
        case 'currency':
          return formatMoney({ amount: this.actualValue });
        case 'integer':
          return this.actualValue;
      }
    },
  },
  methods: {
    formatMoney,
    async sync() {
      if (!this.endpoint) {
        this.loading = false;
        return;
      }
      this.loading = true;

      try {
        let response = await this.$axios.get(`v1/stats/${this.endpoint}`);

        this.actualValue = response.data.value;
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.metric-value {
  flex: 1;
  background: $white;
  @include collapsable-border(1px, $gray-500);
  text-align: center;
  padding: 56px;

  &__title {
    font-size: $text-base;
    color: $gray-800;
    line-height: $text-2xl;
  }

  &__value {
    color: $primary;
    font-size: $text-4xl;
    line-height: $text-5xl;
    padding: 12px 5px 0;

    .loader {
      font-size: $text-xl;
      color: $primary;
    }
  }
}

@include media-breakpoint-down(sm) {
  .metric-value {
    box-shadow: none;
    margin-left: -#{$container-padding};
    margin-right: -#{$container-padding};
    width: auto;
    flex: 1 1 100%;
    border-bottom: 1px solid $gray-500;

    &:first-child {
      border-top: 1px solid $gray-500;
    }
  }
}
</style>
