var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ModalWithSteps",
    {
      staticClass: "modal--with-steps register-modal",
      on: { "modal-close": _vm.handleClose }
    },
    [
      _c("div", { staticClass: "modal__header" }, [
        _c("h3", { staticClass: "modal__title" }, [_vm._v("Sign up")]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "modal__close",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_c("IconClose")],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__steps__progress" }, [
        _c("div", { style: { width: _vm.progressPercentage + "%" } })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "modal__body" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 1,
                expression: "currentStep === 1"
              }
            ],
            staticClass: "modal__step"
          },
          [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.validateStep(1)
                  }
                }
              },
              [
                _c("h6", { staticClass: "register-modal__question" }, [
                  _vm._v(
                    "\n          Select the option that best describes your business?\n        "
                  )
                ]),
                _vm._v(" "),
                _c("UserTypeSelect", {
                  model: {
                    value: _vm.typeAndIsIndividual,
                    callback: function($$v) {
                      _vm.typeAndIsIndividual = $$v
                    },
                    expression: "typeAndIsIndividual"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal__step__actions" },
                  [
                    _c(
                      "LoadingBtn",
                      {
                        staticClass: "btn",
                        attrs: {
                          loading: _vm.loadingStep[1],
                          disabled: !_vm.form.type,
                          type: "submit"
                        }
                      },
                      [_vm._v("\n            Next\n          ")]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 2,
                expression: "currentStep === 2"
              }
            ],
            staticClass: "modal__step"
          },
          [
            _c("ValidationObserver", {
              ref: "formTwo",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              handleSubmit(function() {
                                return _vm.validateStep(2)
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "h6",
                            { staticClass: "register-modal__question" },
                            [
                              _vm._v(
                                "\n            Select which sectors are relevant to your business\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("ValidationProvider", {
                            attrs: { name: "sectors_ids", vid: "sectors_ids" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "form-group" },
                                        [
                                          _c("MultiplePillSelect", {
                                            attrs: {
                                              loading: _vm.loadingSectors,
                                              options: _vm.sectors,
                                              "value-key": "id",
                                              "label-key": "name"
                                            },
                                            model: {
                                              value: _vm.form.sectors_ids,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "sectors_ids",
                                                  $$v
                                                )
                                              },
                                              expression: "form.sectors_ids"
                                            }
                                          }),
                                          _vm._v(" "),
                                          errors[0]
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "form-group__error"
                                                },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              true
                            )
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal__step__actions" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.currentStep = 1
                                    }
                                  }
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "LoadingBtn",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    loading: _vm.loadingStep[2],
                                    disabled: invalid,
                                    type: "submit"
                                  }
                                },
                                [_vm._v("\n              Next\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 3,
                expression: "currentStep === 3"
              }
            ],
            staticClass: "modal__step"
          },
          [
            _c("ValidationObserver", {
              ref: "formThree",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    var invalid = ref.invalid
                    return [
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              handleSubmit(function() {
                                return _vm.validateStep(3)
                              })
                            }
                          }
                        },
                        [
                          _c(
                            "h6",
                            { staticClass: "register-modal__question" },
                            [
                              _vm._v(
                                "\n            Select which services you offer for each sector\n          "
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c("ServicesBySectorSelect", {
                            attrs: { "only-sectors-ids": _vm.form.sectors_ids },
                            model: {
                              value: _vm.form.services_ids_per_sector,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "services_ids_per_sector",
                                  $$v
                                )
                              },
                              expression: "form.services_ids_per_sector"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal__step__actions" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.currentStep = 2
                                    }
                                  }
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "LoadingBtn",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    loading: _vm.loadingStep[3],
                                    disabled: invalid,
                                    type: "submit"
                                  }
                                },
                                [_vm._v("\n              Next\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentStep === 4,
                expression: "currentStep === 4"
              }
            ],
            staticClass: "modal__step"
          },
          [
            _c("ValidationObserver", {
              ref: "form",
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(ref) {
                    var handleSubmit = ref.handleSubmit
                    var invalid = ref.invalid
                    return [
                      _c("h6", { staticClass: "register-modal__question" }, [
                        _vm._v(
                          "\n          Almost there! We just need a few more details about your business\n        "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "form",
                        {
                          staticClass: "form",
                          on: {
                            submit: function($event) {
                              $event.preventDefault()
                              return handleSubmit(_vm.submit)
                            }
                          }
                        },
                        [
                          _c("VInput", {
                            attrs: {
                              label: "Your name",
                              name: "Name",
                              vid: "name",
                              rules: "required|min:3"
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "name", $$v)
                              },
                              expression: "form.name"
                            }
                          }),
                          _vm._v(" "),
                          _c("VInput", {
                            attrs: {
                              label: "Your email",
                              name: "Email",
                              vid: "email",
                              rules: "required|email"
                            },
                            model: {
                              value: _vm.form.email,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email"
                            }
                          }),
                          _vm._v(" "),
                          _c("VInput", {
                            attrs: {
                              name: "Business name",
                              vid: "business_name",
                              rules: "required|min:3"
                            },
                            model: {
                              value: _vm.form.business_name,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "business_name", $$v)
                              },
                              expression: "form.business_name"
                            }
                          }),
                          _vm._v(" "),
                          _c("VInput", {
                            attrs: {
                              label: "Business Email",
                              name: "Business Email",
                              vid: "business_email",
                              rules: "email",
                              "label-info-text": "- Optional"
                            },
                            model: {
                              value: _vm.form.business_email,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "business_email", $$v)
                              },
                              expression: "form.business_email"
                            }
                          }),
                          _vm._v(" "),
                          _c("VInput", {
                            staticClass: "form-group",
                            attrs: {
                              name: "Password",
                              vid: "password",
                              rules: "required|secure_password|min:8",
                              type: "password"
                            },
                            model: {
                              value: _vm.form.password,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "password", $$v)
                              },
                              expression: "form.password"
                            }
                          }),
                          _vm._v(" "),
                          _c("VInput", {
                            staticClass: "form-group",
                            attrs: {
                              name: "Password Confirmation",
                              vid: "password_confirmation",
                              rules: "required|confirmed:password",
                              type: "password"
                            },
                            model: {
                              value: _vm.form.password_confirmation,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "password_confirmation", $$v)
                              },
                              expression: "form.password_confirmation"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "VCheckbox",
                            {
                              staticClass: "auth-box__accept",
                              attrs: { value: true, vid: "tc_accepted" },
                              model: {
                                value: _vm.form.tc_accepted,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "tc_accepted", $$v)
                                },
                                expression: "form.tc_accepted"
                              }
                            },
                            [
                              _vm._v("I accept the\n            "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.route("terms-and-conditions"),
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Terms")]
                              ),
                              _vm._v("\n            and\n            "),
                              _c(
                                "a",
                                {
                                  attrs: {
                                    href: _vm.route("privacy-policy"),
                                    target: "_blank"
                                  }
                                },
                                [_vm._v("Privacy Policy")]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal__step__actions" },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "btn btn--secondary",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      _vm.currentStep = 3
                                    }
                                  }
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "LoadingBtn",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    loading: _vm.loading,
                                    disabled: invalid,
                                    type: "submit"
                                  }
                                },
                                [_vm._v("\n              Next\n            ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }