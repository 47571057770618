var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M12 10.68c-.732 0-1.32.588-1.32 1.32 0 .732.588 1.32 1.32 1.32.732 0 1.32-.588 1.32-1.32 0-.732-.588-1.32-1.32-1.32zM12 0C5.376 0 0 5.376 0 12s5.376 12 12 12 12-5.376 12-12S18.624 0 12 0zm2.628 14.628L4.8 19.2l4.572-9.828L19.2 4.8l-4.572 9.828z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }