var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0zm-1 3h2v3h-2V3zM6 13H3v-2h3v2zm6 2c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L4.909 6.323l1.414-1.414 4.391 4.392A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3zm3.536-7.95l2.121-2.121 1.414 1.414-2.121 2.121-1.414-1.414zM18 13v-2h3v2h-3z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }