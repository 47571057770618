var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.hide,
          expression: "hide"
        }
      ],
      staticClass: "dropdown"
    },
    [
      _vm._t(
        "button",
        [
          _c(
            "button",
            { staticClass: "btn btn--white", on: { click: _vm.toggle } },
            [_vm._v("\n      " + _vm._s(_vm.btnLabel) + "\n    ")]
          )
        ],
        { toggle: _vm.toggle }
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "dropdown-transition" } }, [
        _vm.isOpen
          ? _c(
              "div",
              { staticClass: "dropdown__menu" },
              [_vm._t("items", null, { hide: _vm.hide })],
              2
            )
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }