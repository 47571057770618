<template>
  <div class="pd__intro">
    <h3 class="pd__intro__title">
      {{ product.company.business_name }} - {{ product.name }}
    </h3>
    <h5 class="pd__intro__size" v-if="product.size">{{ product.size }}</h5>
    <div v-if="showTags" class="pd__intro__tags">
      <CardTag type="sector">{{ product.category.sector.name }}</CardTag>
      <CardTag type="category">{{ product.category.name }}</CardTag>
    </div>
    <div class="pd__intro__description">
      <TextareaText>{{ product.description }}</TextareaText>
    </div>
  </div>
</template>
<script>
import CardTag from '~/components/CardTag';
import TextareaText from '~/components/TextareaText';

export default {
  components: {
    CardTag,
    TextareaText,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    showTags: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.pd__intro {
  &__title {
    font-size: 36px;
    font-weight: 300;
    line-height: 44px;
    color: $gray-800;
  }

  &__size {
    font-size: 24px;
    line-height: 32px;
    font-weight: 300;
    color: rgba($gray-800, 0.5);
    margin-top: $s-3;
  }

  &__tags {
    margin-top: $s-4;
    display: flex;
    flex-wrap: wrap;

    .card-tag {
      margin-right: $s-3;
      margin-bottom: $s-2;
    }
  }

  &__description {
    margin-top: $s-4;

    p {
      font-size: 18px;
      line-height: 28px;
      font-weight: 300;
      color: rgba($gray-800, 0.5);
    }
  }
}
</style>
