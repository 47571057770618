<template>
  <div class="testimonial-form">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submit)" class="form">
        <VInput
          v-model="form.author"
          name="Name"
          vid="author"
          info-text="Displayed to brands on the marketplace"
        />
        <VTextarea v-model="form.text" name="Testimonial" vid="text" />
        <div class="testimonial-form__actions">
          <LoadingBtn
            :loading="saving"
            :disabled="invalid"
            class="btn"
            type="submit"
            >Save
          </LoadingBtn>
          <LoadingBtn
            v-if="testimonial.id"
            :loading="removing"
            class="btn btn--accent-red"
            @click="remove"
            >Remove
          </LoadingBtn>
          <a
            v-if="last"
            href="#"
            class="btn btn--secondary"
            @click="$emit('add')"
            >Add another</a
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import VTextarea from '~/components/form/VTextarea';
import VInput from '~/components/form/VInput';
import { ValidationObserver } from 'vee-validate';
import LoadingBtn from '~/components/LoadingBtn';
import Testimonial from '~/models/Testimonial';
import Partner from '~/models/Partner';

export default {
  components: {
    ValidationObserver,
    LoadingBtn,
    VTextarea,
    VInput,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
    testimonial: {
      type: Object,
      required: true,
    },
    last: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      saving: false,
      removing: false,
      form: {
        author: this.testimonial.author,
        text: this.testimonial.text,
      },
    };
  },
  computed: {
    persisted() {
      return !this.testimonial.unsaved;
    },
  },
  methods: {
    async submit() {
      this.saving = true;

      try {
        const partner = new Partner(this.partner);
        const testimonial = await new Testimonial(this.getAttributes())
          .for(partner)
          .save();

        this.$toasted.success('Testimonial saved.');

        this.$emit('updated', testimonial);
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.saving = false;
      }
    },
    async remove() {
      this.removing = true;

      try {
        if (this.persisted) {
          const partner = new Partner(this.partner);
          await new Testimonial({
            id: this.testimonial.id,
          })
            .for(partner)
            .delete();
        }

        this.$emit('removed', this.testimonial);
        this.$toasted.success('Testimonial removed.');
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.removing = false;
      }
    },
    getAttributes() {
      let attributes = {
        ...this.form,
      };

      if (this.persisted) {
        attributes.id = this.testimonial.id;
      }

      return attributes;
    },
  },
};
</script>
<style lang="scss">
.testimonial-form {
  &__actions {
    display: flex;
    align-items: center;

    .btn {
      margin-right: $s-4;
    }
  }
}
</style>
