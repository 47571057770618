<template>
  <div class="trend-metric">
    <div class="trend-metric__filters">
      <div class="trend-metric__filters__margin-helper">
        <div v-if="options.length > 1" class="filter">
          <VSelect
            v-model="selectedValue"
            :options="options"
            no-label
            class="filter-select"
          />
        </div>
        <div class="filter" v-if="hasServiceFilter && hasFeatureServices()">
          <ServiceFilter
            v-model="selectedService"
            filter-by-auth
            filter-select
            no-label
          />
        </div>
        <div class="filter" v-if="hasPartnerFilter">
          <VSelect
            v-model="selectedPartner"
            :loading="loadingPartners"
            :options="partnerOptions"
            no-label
            class="filter-select"
          />
        </div>
        <div class="filter" v-if="hasCategoryFilter">
          <CategoryFilter
            v-model="selectedCategory"
            filter-by-auth
            filter-select
            no-label
          />
        </div>
        <div class="filter" v-if="hasProductFilter">
          <VSelect
            v-model="selectedProduct"
            :loading="loadingProducts"
            :options="productOptions"
            class="filter-select"
            no-label
          />
        </div>
        <TimeFilter :selected="selectedTimeFrame" @input="changeTimeFilter" />
      </div>
    </div>
    <div class="trend-metric__chart">
      <OurLineChart
        v-if="!loading"
        :chart-data="chartData"
        :selected-time-frame="selectedTimeFrame"
      />
    </div>
    <div class="trend-metric__loader-placeholder" v-show="loading">
      <div
        class="trend-metric__loading"
        :class="{ 'trend-metric--skeleton': loading }"
      ></div>
    </div>
  </div>
</template>
<script>
import LineChart from '~/components/metrics/LineChart';
import TimeFilter from '~/components/metrics/TimeFilter';
import VSelect from '~/components/form/VSelect';
import { parseISO, format } from 'date-fns';
import CategoryFilter from '~/components/sectors-fields/CategoryFilter';
import OurLineChart from '~/components/metrics/OurLineChart';
import Service from '~/models/Service';
import { mapGetters, mapState } from 'vuex';
import UserEnum from '~/enums/UserEnum';
import ServiceFilter from '~/components/sectors-fields/ServiceFilter';
import { hasFeatureServices } from '~/utils';

export default {
  components: {
    ServiceFilter,
    OurLineChart,
    LineChart,
    VSelect,
    TimeFilter,
    CategoryFilter,
  },
  props: {
    endpoint: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    hasServiceFilter: {
      type: Boolean,
      default: false,
    },
    hasCategoryFilter: {
      type: Boolean,
      default: false,
    },
    hasPartnerFilter: {
      type: Boolean,
      default: false,
    },
    hasProductFilter: {
      type: Boolean,
      default: false,
    },
    partnersEndpoint: {
      type: String,
      default: '',
    },
    productsEndpoint: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      loadingPartners: false,
      loadingProducts: false,
      selectedTimeFrame: '1W',
      selectedValue: this.options[0].value,
      selectedCategory: '',
      selectedService: '',
      selectedPartner: 'ALL',
      selectedProduct: 'ALL',
      partnerOptions: [{ label: 'All', value: 'ALL' }],
      availableCompaniesOptions: [],
      availableAgentsOptions: [],
      productOptions: [],
      currency: 'EUR',
      chartData: {
        labels: [],
        datasets: [],
      },
    };
  },
  watch: {
    // TODO: in some cases, due to multiple watchers
    // the sync is run twice. i.e. when one value resets another
    selectedValue() {
      this.valueChange();
    },
    selectedCategory() {
      this.sync();
    },
    selectedService() {
      this.sync();
    },
    selectedPartner() {
      // if this is not a manual reset
      if (!(this.selectedPartner === 'ALL' && this.loading)) {
        this.sync();
      }
    },
    selectedProduct() {
      this.sync();
    },
  },
  async created() {
    if (this.hasPartnerFilter) {
      await this.syncPartners();
    }

    if (this.hasProductFilter) {
      await this.syncProducts();
    }

    await this.sync();
  },
  methods: {
    async sync() {
      if (!this.endpoint) {
        this.loading = false;
        return;
      }
      this.loading = true;
      let query = this.query();
      try {
        let response = await this.$axios.get(
          `v1/stats/${this.endpoint}${query}`
        );
        this.chartData.labels = response.data.labels;
        this.chartData.datasets = this.prepareDatasets(response.data.datasets);
        this.primaryChartColor = response.data.primaryChartColor;
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },

    async syncPartners() {
      this.loadingPartners = true;
      try {
        let response = await this.$axios.get(
          `v1/stats/${this.partnersEndpoint}`
        );
        this.availableCompaniesOptions = [
          { label: 'All', value: 'ALL' },
          ...response.data.availableCompanies.map((value) => {
            return {
              value: value.id,
              label: value.business_name,
            };
          }),
        ];
        this.availableAgentsOptions = [
          { label: 'All', value: 'ALL' },
          ...response.data.availableAgents.map((value) => {
            return {
              value: value.id,
              label: value.user.name,
            };
          }),
        ];
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loadingPartners = false;
        this.setPartnerOptions();
      }
    },

    async syncProducts() {
      this.loadingProducts = true;
      try {
        let response = await this.$axios.get(
          `v1/stats/${this.productsEndpoint}`
        );
        this.productOptions = [
          { label: 'All', value: 'ALL' },
          ...response.data.availableProducts.map((value) => {
            return {
              value: value.id,
              label: value.name,
            };
          }),
        ];
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loadingProducts = false;
        this.setPartnerOptions();
      }
    },

    setPartnerOptions() {
      this.selectedPartner = 'ALL';
      if (this.selectedValue === 'commission') {
        this.partnerOptions = this.availableCompaniesOptions;
      } else {
        this.partnerOptions = this.availableAgentsOptions;
      }
    },

    prepareDatasets(datasets) {
      datasets = datasets.map((dataset) => {
        dataset.data = dataset.data.map((value) => {
          if (value.currency) {
            this.currency = value.currency;
            return value.amount;
          } else {
            this.currency = false;
          }
          return value;
        });
        return dataset;
      });
      return datasets;
    },
    formatDateLabel(value) {
      switch (this.selectedTimeFrame) {
        case 'ALL':
        case '1Y':
          value = parseISO(value.toString(), 'yyyy-MM');
          return format(value, 'MMM yyyy');
        case '1W':
        case '1M':
          value = parseISO(value.toString(), 'yyyy-MM-dd');
          return format(value, 'MMM d');
        case '24H':
          value = parseISO(value.toString(), 'yyyy-MM-dd HH');
          return format(value, 'HH:mm');
        default:
          return value;
      }
    },
    query() {
      let query = '';

      const prepend = () => {
        return query === '' ? '?' : '&';
      };
      if (this.selectedService) {
        query += prepend() + `partnerService=${this.selectedService}`;
      }

      if (this.selectedValue) {
        query += prepend() + `value=${this.selectedValue}`;
      }

      if (this.selectedCategory) {
        query += prepend() + `category=${this.selectedCategory}`;
      }

      if (this.selectedProduct) {
        query += prepend() + `product=${this.selectedProduct}`;
      }

      if (this.selectedTimeFrame) {
        query += prepend() + `timeframe=${this.selectedTimeFrame}`;
      }

      if (this.selectedPartner) {
        query += prepend() + `partner=${this.selectedPartner}`;
      }

      return query;
    },
    changeTimeFilter(value) {
      this.selectedTimeFrame = value;
      this.sync();
    },
    valueChange() {
      this.setPartnerOptions();
      this.sync();
    },
    hasFeatureServices,
  },
};
</script>
<style lang="scss">
.trend-metric {
  $chart-height: 408px;
  $total-height: $chart-height + 4px;

  min-width: 100%;
  position: relative;

  &__filters {
    margin: 0 0 18px;

    &__margin-helper {
      margin-bottom: -10px;
      display: flex;
      flex-wrap: wrap;
    }

    .filter {
      padding-right: 10px;
      margin-bottom: 10px;

      .form-group {
        display: inline-block;
        padding-bottom: 0;

        select {
          text-align-last: left;
        }
      }
    }

    .time-filter {
      margin-left: auto;
      margin-bottom: 10px;
    }
  }

  &__chart {
    position: relative;
    height: $total-height;
    background-color: #fff;
    box-sizing: content-box;
    border: 1px solid $gray-500;

    &:after {
      content: ' ';
      position: absolute;
      height: 1px;
      left: 0;
      right: 0;
      background-color: $gray-500;
      bottom: 42px;
    }
  }

  &__loader-placeholder {
    position: absolute;
    height: $total-height + 2px;
    bottom: 0;
    width: 100%;
  }

  &__loading {
    height: 100%;
    width: 100%;
  }

  &--skeleton {
    @include component-placeholder();
    z-index: 999999;
  }
}

@include media-breakpoint-down(sm) {
  .trend-metric {
    &__chart {
      margin-left: -#{$container-padding};
      margin-right: -#{$container-padding};
      border-right: 0;
      border-left: 0;
    }

    &__filters {
      .time-filter {
        margin-left: 0;
      }
    }
  }
}
</style>
