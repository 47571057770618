<template>
  <VSelect
    v-model="currentValue"
    name="Select a sector"
    vid="sector_id"
    value-key="id"
    label-key="name"
    :options="sectors"
    placeholder="Select..."
    :loading="loading"
  />
</template>
<script>
import VSelect from '~/components/form/VSelect';
import Sector from '~/models/Sector';
import InputMixin from '~/components/form/InputMixin';
import UserEnum from '~/enums/UserEnum';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [InputMixin],
  components: {
    VSelect,
  },
  props: {
    categoryId: {
      validator: (prop) =>
        typeof prop === 'number' || prop === null || prop === '',
      required: false,
      default: null,
    },
    serviceId: {
      validator: (prop) =>
        typeof prop === 'number' || prop === null || prop === '',
      required: false,
      default: null,
    },
    onlySectors: {
      type: Array,
      required: false,
      default: () => [],
    },
    filterByAuth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      sectors: [],
    };
  },

  computed: {
    ...mapState('auth', ['user', 'userable']),
    ...mapGetters('auth', ['userType']),
  },
  async created() {
    try {
      if (this.onlySectors && this.onlySectors.length) {
        this.sectors = this.onlySectors;
      } else {
        this.sectors = await Sector.params({ per_page: 10000 }).$get();
      }

      if (this.filterByAuth) {
        this.sectors = this.filterByAuthCallback(this.sectors);
      }

      this.sectors = this.sectors.sort((a, b) => a.name.localeCompare(b.name));
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    filterByAuthCallback(values) {
      if (this.userType === UserEnum.ADMIN) {
        return values;
      }

      if (
        this.userType === UserEnum.COMPANY ||
        this.userType === UserEnum.PARTNER
      ) {
        const sectorsIds = this.userable.sectors.map((s) => s.id);
        values = values.filter((s) => sectorsIds.includes(s.id));
      }

      if (this.userType === UserEnum.AGENT) {
        const sectorsIds = this.userable.partner.sectors.map((s) => s.id);
        values = values.filter((s) => sectorsIds.includes(s.id));
      }

      return values;
    },
  },
};
</script>
