var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M15 8a3 3 0 013 3v6h-3v6a1 1 0 01-1 1h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6zm-3-8a3 3 0 012.995 2.824L15 3a3 3 0 01-2.824 2.995L12 6a3 3 0 01-2.995-2.824L9 3A3 3 0 0111.824.005L12 0z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }