var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DashboardPage", [
    _c(
      "div",
      { staticClass: "mp-pd-detail" },
      [
        _vm.loading
          ? _c("Loader")
          : [
              _c(
                "MarketplaceBreadcrumbs",
                { attrs: { type: "companies" } },
                [
                  _c("a", { attrs: { href: _vm.companyMarketplaceUrl } }, [
                    _vm._v(_vm._s(_vm.product.company.business_name))
                  ]),
                  _vm._v(" "),
                  _c("IconDropRight", { attrs: { width: "18", height: "18" } }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.product.name))])
                ],
                1
              ),
              _vm._v(" "),
              _c("ProductDetail", {
                scopedSlots: _vm._u([
                  {
                    key: "left",
                    fn: function() {
                      return [
                        _c("ProductDetailIntro", {
                          attrs: { product: _vm.product, "show-tags": "" }
                        }),
                        _vm._v(" "),
                        _c("ProductPriceAndCommission", {
                          attrs: { product: _vm.product }
                        }),
                        _vm._v(" "),
                        _c("ProductDetailColorsList", {
                          attrs: { product: _vm.product }
                        }),
                        _vm._v(" "),
                        _c("ProductDetailWatchVideo", {
                          attrs: { product: _vm.product }
                        }),
                        _vm._v(" "),
                        _c("ProductDetailAccordions", {
                          attrs: {
                            product: _vm.product,
                            "show-commission-details": ""
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "right",
                    fn: function() {
                      return [
                        _c("ProductDetailGallery", {
                          attrs: { product: _vm.product }
                        })
                      ]
                    },
                    proxy: true
                  }
                ])
              })
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }