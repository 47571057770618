var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    { attrs: { size: "sm" }, on: { "modal-close": _vm.handleClose } },
    [
      _c("div", { staticClass: "modal__header" }, [
        _c("h3", { staticClass: "modal__title" }, [
          _vm._v(_vm._s(_vm.isEdit ? "Edit" : "Add") + " category")
        ]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "modal__close",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_c("IconClose")],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal__body" },
        [
          _c("ValidationObserver", {
            ref: "form",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var handleSubmit = ref.handleSubmit
                  var invalid = ref.invalid
                  return [
                    _c(
                      "form",
                      {
                        staticClass: "form",
                        on: {
                          submit: function($event) {
                            $event.preventDefault()
                            return handleSubmit(_vm.save)
                          }
                        }
                      },
                      [
                        _c("VInput", {
                          attrs: {
                            name: "Name",
                            vid: "name",
                            rules: "required"
                          },
                          model: {
                            value: _vm.form.name,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "name", $$v)
                            },
                            expression: "form.name"
                          }
                        }),
                        _vm._v(" "),
                        _c("VSelect", {
                          attrs: {
                            name: "Sector",
                            vid: "sector_id",
                            loading: _vm.loadingSectors,
                            options: _vm.sectors,
                            placeholder: "Choose sector",
                            rules: "required",
                            "value-key": "id",
                            "label-key": "name"
                          },
                          model: {
                            value: _vm.form.sector_id,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "sector_id", $$v)
                            },
                            expression: "form.sector_id"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal__btn-wrapper" },
                          [
                            _c(
                              "LoadingBtn",
                              {
                                staticClass: "btn",
                                attrs: {
                                  loading: _vm.saving,
                                  disabled: invalid,
                                  type: "submit"
                                }
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.isEdit ? "Update" : "Create") +
                                    " category\n          "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }