<template>
  <div class="user-logo" :style="{ maxWidth: `${size}px` }">
    <img v-if="logo" :src="logo.urls.thumb" />
    <img v-else :src="placeholderSrc" />
  </div>
</template>
<script>
export default {
  props: {
    logo: {
      validator: (prop) =>
        prop === null || prop === undefined || typeof prop === 'object',
      required: true,
    },
    colour: {
      type: String,
      required: false,
      default: '#000',
    },
    businessName: {
      type: String,
      required: true,
    },
    size: {
      type: Number,
      required: false,
      default: 40,
    },
  },
  data() {
    return {
      placeholderSrc: '',
    };
  },
  created() {
    if (!this.logo) {
      this.placeholderSrc = this.generateUrl();
    }
  },
  computed: {
    opacity() {
      return this.colour === '#000' ? 0.1 : 1;
    },
    initials() {
      let nameSplit = String(this.businessName).toUpperCase().split(' ');
      if (nameSplit.length === 1) {
        return nameSplit[0] ? nameSplit[0].charAt(0) : '?';
      } else {
        return nameSplit[0].charAt(0) + nameSplit[1].charAt(0);
      }
    },
  },
  methods: {
    generateUrl() {
      let canvas = document.createElement('canvas');

      let size = this.size;
      if (window.devicePixelRatio) {
        size = size * window.devicePixelRatio;
      }

      canvas.width = size;
      canvas.height = size;
      let context = canvas.getContext('2d');

      context.fillStyle = this.colour;
      context.fillRect(0, 0, canvas.width, canvas.height);
      context.font = Math.round(canvas.width / 2) + 'px Arial';
      context.textAlign = 'center';
      context.fillStyle = '#FFF';
      context.fillText(this.initials, size / 2, size / 1.5);

      return canvas.toDataURL();
    },
  },
};
</script>
<style lang="scss">
.user-logo {
  img {
    max-width: 100%;
  }

  &__placeholder {
    width: 100%;
    height: 100%;
  }
}
</style>
