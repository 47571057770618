<template>
  <DashboardPage>
    <div class="mp-pd-detail">
      <Loader v-if="loading" />
      <template v-else>
        <MarketplaceBreadcrumbs type="companies">
          <a :href="companyMarketplaceUrl">{{
            product.company.business_name
          }}</a>
          <IconDropRight width="18" height="18" />
          <span>{{ product.name }}</span>
        </MarketplaceBreadcrumbs>
        <ProductDetail>
          <template v-slot:left>
            <ProductDetailIntro :product="product" show-tags />
            <ProductPriceAndCommission :product="product" />
            <ProductDetailColorsList :product="product" />
            <ProductDetailWatchVideo :product="product" />
            <ProductDetailAccordions
              :product="product"
              show-commission-details
            />
          </template>
          <template v-slot:right>
            <ProductDetailGallery :product="product" />
          </template>
        </ProductDetail>
      </template>
    </div>
  </DashboardPage>
</template>
<script>
import Loader from '~/components/Loader';
import MarketplaceBreadcrumbs from '~/components/marketplace/MarketplaceBreadcrumbs';
import ProductDetail from '~/components/product/ProductDetail';
import DashboardPage from '~/components/DashboardPage';
import Product from '~/models/Product';
import IconDropRight from '~/components/icons/IconDropRight';
import PersistentStorage from '~/persistent-storage';
import ProductDetailColorsList from '~/components/product/ProductDetailColorsList';
import ProductDetailAccordions from '~/components/product/ProductDetailAccordions';
import ProductDetailGallery from '~/components/product/ProductDetailGallery';
import ProductDetailWatchVideo from '~/components/product/ProductDetailWatchVideo';
import ProductDetailIntro from '~/components/product/ProductDetailIntro';
import ProductPriceAndCommission from '~/components/ProductPriceAndCommission';

export default {
  components: {
    DashboardPage,
    Loader,
    ProductDetail,
    MarketplaceBreadcrumbs,
    IconDropRight,
    ProductDetailColorsList,
    ProductDetailAccordions,
    ProductDetailGallery,
    ProductDetailWatchVideo,
    ProductDetailIntro,
    ProductPriceAndCommission,
  },
  props: {
    slug: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      loading: false,
      loader: false,
      product: null,
    };
  },

  computed: {
    companyMarketplaceUrl() {
      let url = PersistentStorage.getLastCompanyMarketplaceUrl();

      if (!url) {
        url = this.route(
          'marketplace.companies.show',
          this.product.company.slug
        ).url();
      }

      return url;
    },
  },

  async created() {
    this.loading = true;
    try {
      this.product = await this.fetch();
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    fetch() {
      return Product.include('images', 'category.sector', 'company').find(
        this.slug
      );
    },
  },
};
</script>
<style lang="scss">
.mp-pd-detail {
  .loader {
    margin: $s-20 0;
  }

  .pd {
    margin-top: $s-20;
  }

  .pd__colors-list {
    margin-top: $s-10;
  }

  .product-price-and-commission {
    margin-top: $s-10;
  }

  .pd__watch-video {
    margin-top: $s-20;
  }

  .pd__accordions {
    margin-top: $s-16;
  }
}

@include media-breakpoint-down(md) {
  .mp-pd-detail {
    .pd {
      margin-top: $s-8;
    }
    .pd__watch-video {
      margin-top: $s-16;
    }

    .pd__accordions {
      margin-top: $s-12;
    }
  }
}

@media (max-width: 440px) {
  .mp-pd-detail {
    .mp__breadcrumbs {
      margin-bottom: 0;
    }

    .pd {
      margin-top: 0;
    }

    .pd__gallery {
      margin: 0 -#{$container-padding};
      width: auto;

      &__slider-wrapper {
        border: none;
        border-bottom: 1px solid $gray-500;
      }
    }
  }
}
</style>
