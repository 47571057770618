<template>
  <FieldWrapper
    v-slot="{ inputId, errors }"
    :rules="rules"
    :name="name"
    :label="label"
    :vid="vid"
    :label-info-text="labelInfoText"
    :info-text="infoText"
    :disabled="disabled"
    :readonly="readonly"
    :dark="dark"
  >
    <input
      :id="inputId"
      v-model="currentValue"
      :type="type"
      :placeholder="placeholder"
      :name="vid"
      class="form-control"
      :class="{ 'is-invalid': errors[0] }"
      :disabled="disabled"
      :readonly="readonly"
    />
  </FieldWrapper>
</template>
<script>
import InputMixin from '~/components/form/InputMixin';

export default {
  mixins: [InputMixin],
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
  },
};
</script>
