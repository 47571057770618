<template>
  <div class="metric-total-breakdown">
    <div class="metric-total-breakdown__line" v-for="value in values">
      <div class="label">
        <span class="name">{{ value.label }}</span>
        <span class="actual-value">{{ value.value }}</span>
      </div>
      <div class="progress">
        <progress-line :value="value.percent"></progress-line>
      </div>
    </div>
    <div class="metric-total-breakdown__loader-placeholder" v-show="loading">
      <div
        class="metric-total-breakdown__loading"
        :class="{ 'metric-total-breakdown--skeleton': loading }"
      ></div>
    </div>
  </div>
</template>
<script>
import ProgressLine from '~/components/metrics/ProgressLine';

export default {
  components: { ProgressLine },
  props: {
    endpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      values: [],
    };
  },
  mounted() {
    this.sync();
  },
  methods: {
    async sync() {
      if (!this.endpoint) {
        this.loading = false;
        return;
      }
      this.loading = true;
      try {
        let response = await this.$axios.get(`v1/stats/${this.endpoint}`);
        this.values = response.data.values;
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.metric-total-breakdown {
  width: 100%;
  background-color: $white;
  border: 1px solid $gray-500;
  min-height: 285px;
  position: relative;

  &__line {
    padding: 20px 32px;
    border-top: 1px solid $gray-500;
    display: flex;

    &:first-child {
      border: none;
    }

    .label {
      flex: 1 1 50%;
      padding-right: $s-4;

      .name {
        font-size: $text-lg;
        line-height: $text-3xl;
        color: $gray-800;
      }

      .actual-value {
        padding-left: 10px;
        opacity: 0.5;
        color: $gray-800;
        font-size: $text-xs;
        font-weight: 500;
      }
    }

    .progress {
      flex: 1 1 50%;
      padding-left: $s-4;
    }
  }

  &__loader-placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  &__loading {
    height: 100%;
    width: 100%;
  }

  &--skeleton {
    @include component-placeholder();
    z-index: 999999;
  }
}

@include media-breakpoint-down(sm) {
  .metric-total-breakdown {
    margin-left: -#{$container-padding};
    margin-right: -#{$container-padding};
    width: auto;
    border-right: 0;
    border-left: 0;

    &__line {
      padding: 20px 15px;

      .label {
        flex: 1 1 40%;

        .actual-value {
          width: 100%;
          padding-left: 0;
          display: block;
        }
      }

      .progress {
        flex: 1 1 60%;
      }
    }
  }
}
</style>
