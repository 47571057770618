var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M19.314 15.36a3.546 3.546 0 00-2.28.847L12.89 12.97V8.533c1.79-.481 2.957-2.274 2.724-4.184-.232-1.91-1.793-3.342-3.642-3.342-1.848 0-3.409 1.432-3.642 3.342-.233 1.91.935 3.703 2.725 4.184v4.437l-4.145 3.237a3.544 3.544 0 00-4.56.023c-1.329 1.115-1.742 3.038-.995 4.635.747 1.598 2.455 2.445 4.117 2.043 1.662-.403 2.836-1.948 2.83-3.725 0-.499-.094-.993-.276-1.455l3.946-3.086 3.947 3.086c-.615 1.557-.18 3.35 1.071 4.415a3.555 3.555 0 004.367.217c1.343-.937 1.94-2.678 1.47-4.288-.47-1.611-1.896-2.712-3.512-2.713z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }