<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M0 7.575l12 7v9L.496 16.864A1.001 1.001 0 010 16V7.575zm24 1.017V16a.999.999 0 01-.427.819L14 23.52v-8.953l10-5.975zM10.485.143a1 1 0 011.019-.007l11.512 6.715-10.022 5.988L.993 5.838z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
