<template>
  <Modal @modal-close="handleClose" size="sm">
    <div class="modal__header">
      <h3 class="modal__title">Download invoice</h3>
      <button type="button" class="modal__close" @click="handleClose">
        <IconClose />
      </button>
    </div>
    <div class="modal__body">
      <VSelect
        v-model="selectedInvoiceLink"
        name="Select type"
        value-key="link"
        :options="invoices"
      />
      <div class="modal__btn-wrapper">
        <a :href="selectedInvoiceLink" class="btn">Download</a>
      </div>
    </div>
  </Modal>
</template>
<script>
import LoadingBtn from '~/components/LoadingBtn';
import Modal from '~/components/Modal';
import VInput from '~/components/form/VInput';
import VSelect from '~/components/form/VSelect';
import IconClose from '~/components/icons/IconClose';
import VPercent from '~/components/form/VPercent';
export default {
  components: {
    VInput,
    LoadingBtn,
    Modal,
    VSelect,
    IconClose,
    VPercent,
  },
  props: {
    invoices: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedInvoiceLink: this.invoices[0].link,
    };
  },

  methods: {
    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
