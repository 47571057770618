var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "marketing-page marketing-page-terms-and-conditions" },
      [
        _c("div", { staticClass: "marketing-page__banner" }, [
          _c("div", { staticClass: "container" }, [
            _c("h2", { staticClass: "marketing-page__title" }, [
              _vm._v("Delivery and Shipping")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "marketing-page__content" }, [
          _c("div", { staticClass: "container" }, [
            _c("p", [
              _vm._v(
                "\n        The delivery options from each brand for each product are listed on\n        the relevant product pages. Each product is sent to you directly by\n        the brand / company / partner we have listed on our site. Partners can\n        choose their own delivery options and each delivery option can vary\n        from partner to partner.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Always check the relevant product page for accurate delivery\n        information. By purchasing an item you agree to the stated delivery\n        date. If you have any queries about the product or delivery options of\n        that product you will need to email the partner / brand directly. All\n        contact details for the partners / brands are on their profile pages\n        or on your receipt.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        If you need more information about the delivery of product that you’ve\n        already ordered, please contact the partner / brand directly by using\n        the contact details on your order confirmation email.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        If you require further assistance please get in touch with us – we’ll\n        be happy to assist.\n        "
              ),
              _c("a", { attrs: { href: "mailto:caskade@lanaicreative.com" } }, [
                _vm._v("caskade@lanaicreative.com")
              ])
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }