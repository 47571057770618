var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Sales" } },
    [
      _c("Datatable", {
        attrs: {
          "get-base-query": _vm.getBaseQuery,
          "query-custom-params": _vm.queryCustomParams
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }