var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout layout-dashboard", class: _vm.classes },
    [
      _c("DashboardDrawer", {
        attrs: {
          open: _vm.drawerOpen,
          condensed: _vm.drawerCondensed && _vm.breakpoints.is === "xl"
        },
        on: {
          clickaway: _vm.onDrawerClickaway,
          "toggle-condensed": _vm.toggleDrawerCondensed
        }
      }),
      _vm._v(" "),
      _vm.breakpoints.is !== "xl"
        ? _c("DashboardHeader", { on: { "toggle-open": _vm.toggleOpen } })
        : _vm._e(),
      _vm._v(" "),
      _c("IncompleteProfileBanner"),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "layout-dashboard__content" },
        [_vm._t("default")],
        2
      ),
      _vm._v(" "),
      _c("ConfirmModal"),
      _vm._v(" "),
      _c("PortalTarget", { attrs: { name: "modals", multiple: "" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }