<script>
export default {
  data() {
    return {
      showFilterSlideout: false,
    };
  },
};
</script>
<style lang="scss">
.mobile-sort-and-filter {
  display: flex;
  background-color: $white;
  margin: 0 -#{$container-padding} $s-4;
  border-bottom: 1px solid $gray-500;

  > div {
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 56px;

    > span {
      font-size: 14px;
      font-weight: 500;
    }

    &:first-child {
      border-right: 1px solid rgba($gray-800, 0.1);
    }
  }

  .filter-select {
    border: none;
    width: 100%;

    select {
      border: none;
    }
  }
}

.slideout-filters {
  background-color: $white;

  &__title {
    color: $black;
    font-weight: 500;
    padding: $s-5 $container-padding;
    border-bottom: 1px solid $border-color;
  }

  &__body {
    padding: $s-6 $container-padding 0;

    .form-group {
      padding-bottom: $s-4;
      width: 100%;
    }

    .btn {
      margin-top: $s-2;
      width: 100%;
    }
  }

  .slideout__close {
    color: rgba($gray-800, 0.25);
  }
}

.slideout-filters--black {
  background-color: $black;

  .slideout-filters__title {
    color: $white;
    border-bottom: 1px solid rgba($white, 0.25);
  }

  .slideout__close {
    color: rgba($white, 0.5);
  }
}
</style>
