var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "tabs", class: _vm.classes }, [
      _c(
        "ul",
        { staticClass: "tabs__nav" },
        _vm._l(_vm.tabs, function(tab) {
          return _c(
            "li",
            {
              staticClass: "tabs__nav-item",
              class: { "tabs__nav-item--active": tab.isActive }
            },
            [
              tab.tooltip
                ? _c(
                    "a",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip",
                          value: {
                            boundariesElement: "body",
                            content: tab.tooltip
                          },
                          expression:
                            "{\n            boundariesElement: 'body',\n            content: tab.tooltip,\n          }"
                        }
                      ],
                      attrs: { href: tab.href },
                      on: {
                        click: function($event) {
                          return _vm.selectTab(tab)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tab.name))]
                  )
                : _c(
                    "a",
                    {
                      attrs: { href: tab.href },
                      on: {
                        click: function($event) {
                          return _vm.selectTab(tab)
                        }
                      }
                    },
                    [_vm._v(_vm._s(tab.name))]
                  )
            ]
          )
        }),
        0
      )
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "tabs__content" }, [_vm._t("default")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }