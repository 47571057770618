var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Transition",
    {
      attrs: { name: "modal-transition", appear: "" },
      on: { "after-leave": _vm.afterTransitionEnds }
    },
    [
      _c("div", { staticClass: "modal", class: "modal--" + _vm.size }, [
        _c("div", { staticClass: "modal__dialog" }, [
          _c("div", { staticClass: "modal__content" }, [_vm._t("default")], 2)
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }