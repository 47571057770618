<template>
  <AuthBox>
    <h4>Welcome back</h4>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submit)" class="form">
        <VInput
          v-model="form.email"
          name="Email"
          vid="email"
          rules="required|email"
        />
        <VInput
          v-model="form.password"
          name="Password"
          vid="password"
          type="password"
          rules="required|min:8"
          @input="onPasswordInput"
        />
        <LoadingBtn
          :loading="loading"
          :disabled="invalid"
          class="btn btn--lg"
          type="submit"
          >Log in
        </LoadingBtn>
      </form>
    </ValidationObserver>
    <p class="links">
      <a :href="route('forgot-password')">Forgotten your password?</a> •
      <a href="#" @click.prevent="showRegisterModal = true"
        >Sign up for an account</a
      >
    </p>
    <Portal to="modals">
      <RegisterModal
        v-if="showRegisterModal"
        @close="showRegisterModal = false"
      />
    </Portal>
  </AuthBox>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import VInput from '~/components/form/VInput';
import LoadingBtn from '~/components/LoadingBtn';
import AuthBox from '~/pages/auth/AuthBox';
import RegisterModal from '~/pages/auth/RegisterModal';
import ApiExceptionEnum from '~/enums/ApiExceptionEnum';
import { getPlatformName } from '~/utils';

export default {
  components: {
    ValidationObserver,
    VInput,
    LoadingBtn,
    AuthBox,
    RegisterModal,
  },
  data() {
    return {
      loading: false,
      form: null,
      showRegisterModal: false,
    };
  },
  created() {
    this.form = this.getForm();
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        // Make request to store csrf cookie.
        await this.$axios.get('sanctum/csrf-cookie', {
          baseURL: this.url(),
        });
        await this.$axios.post('auth/login', this.form);
        window.location.reload();
      } catch (e) {
        const data = e?.response?.data ?? {};
        if (data.code === ApiExceptionEnum.ACCOUNT_NOT_APPROVED) {
          this.$confirm({
            title: `Your account is not yet activated`,
            subtitle: `Next steps and account activation details will be sent to you as soon as your details are checked and approved.`,
            withLoading: false,
            onlyOk: true,
          });
          return;
        }

        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    getForm() {
      return {
        email: '',
        password: '',
      };
    },

    onPasswordInput() {
      this.$refs.form.validate();
    },
  },
};
</script>
