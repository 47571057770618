import parseJSON from 'date-fns/parseJSON';
import format from 'date-fns/format';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import formatDistance from 'date-fns/formatDistance';
import PlatformSlugEnum from '~/enums/PlatformSlugEnum';
import { Ziggy } from '~/ziggy';

const debounce = function (func, wait, immediate) {
  let timeout;
  return function () {
    let context = this,
      args = arguments;
    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    let callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

const throttleAsync = (action) => {
  let isRunning = false;
  return function () {
    if (isRunning) return;
    isRunning = true;
    window.requestAnimationFrame(() => {
      action().finally(() => {
        isRunning = false;
      });
    });
  };
};

const formatMoney = ({ amount, currency }) => {
  if (!currency) {
    currency = 'EUR';
  }
  amount = amount / 100;

  if (typeof Intl == 'object' && typeof Intl.NumberFormat == 'function') {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currency,
    }).format(amount);
  }

  return `${amount} ${currency}`;
};

const formatDate = (dateStr) => {
  let date = parseJSON(dateStr);

  return format(date, 'dd/MM/yyyy');
};

const formatDateTime = (dateStr) => {
  let date = parseJSON(dateStr);

  return format(date, 'dd/MM/yyyy hh:ss');
};

const timeFromNow = (createdAt) => {
  const date = parseJSON(createdAt);
  return formatDistance(date, new Date(), { addSuffix: true });
};

const generateUniqueId = () => {
  return '_' + Math.random().toString(36).substr(2, 9);
};

const majorToMinorUnits = (amount) => {
  return amount * 100;
};
const percentageToFraction = (percentage) => {
  // Apply the correction factor to avoid floating point issues.
  // @https://docs.oracle.com/cd/E19957-01/806-3568/ncg_goldberg.html
  // As we have the percentage with a max of 2 decimals, the correction factor is 100.
  // We then divide by 10000 to get the fractional rate.
  return (percentage * 100) / 10000;
};

const fractionToPercentage = (fraction) => {
  return parseFloat(fraction) * 100;
};

const toNumber = (val) => {
  let n = parseFloat(val);
  return isNaN(n) ? val : n;
};

const cloneDeep = (val) => {
  return JSON.parse(JSON.stringify(val));
};

const formatRateToPercentage = (rate) => {
  let percentage = fractionToPercentage(rate);

  return `${percentage.toFixed(2)}%`;
};

const formatRateToPercentageRounded = (rate) => {
  let percentage = fractionToPercentage(rate);

  return `${Math.round(percentage)}%`;
};

const countString = (count, plural, singular = null) => {
  singular = singular ? singular : plural.slice(0, -1);

  return `${count} ${count === 1 ? singular : plural}`;
};

const isEmptyObject = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
};

const hash = (s) => {
  /* Simple hash function. */
  let a = 1,
    c = 0,
    h,
    o;
  if (s) {
    a = 0;
    /*jshint plusplus:false bitwise:false*/
    for (h = s.length - 1; h >= 0; h--) {
      o = s.charCodeAt(h);
      a = ((a << 6) & 268435455) + o + (o << 14);
      c = a & 266338304;
      a = c !== 0 ? a ^ (c >> 21) : a;
    }
  }

  return String(a);
};

const loadScript = (src) => {
  return new Promise(function (resolve, reject) {
    let shouldAppend = false;
    let el = document.querySelector('script[src="' + src + '"]');
    if (!el) {
      el = document.createElement('script');
      el.type = 'text/javascript';
      el.async = true;
      el.src = src;
      shouldAppend = true;
    } else if (el.hasAttribute('data-loaded')) {
      resolve(el);
      return;
    }

    el.addEventListener('error', reject);
    el.addEventListener('abort', reject);
    el.addEventListener('load', function loadScriptHandler() {
      el.setAttribute('data-loaded', true);
      resolve(el);
    });

    if (shouldAppend) document.head.appendChild(el);
  });
};

const closest = (el, fn) => {
  while (el) {
    if (fn(el)) return el;
    el = el.parentNode;
  }
};

function areArraysEqualSets(a1, a2) {
  const superSet = {};
  for (const i of a1) {
    const e = i + typeof i;
    superSet[e] = 1;
  }

  for (const i of a2) {
    const e = i + typeof i;
    if (!superSet[e]) {
      return false;
    }
    superSet[e] = 2;
  }

  for (let e in superSet) {
    if (superSet[e] === 1) {
      return false;
    }
  }

  return true;
}

const hasFeatureServices = () => {
  return process.env.MIX_PLATFORM_FEATURES_SERVICES === '1';
};

const isProduction = () => {
  return process.env.MIX_APP_ENV === 'production';
};

const isCas = () => {
  return process.env.MIX_PLATFORM_SLUG === PlatformSlugEnum.CAS;
};

const isTt1 = () => {
  return process.env.MIX_PLATFORM_SLUG === PlatformSlugEnum.TT1;
};

const getPlatformName = () => {
  return process.env.MIX_APP_NAME;
};

const url = (path = '') => `${Ziggy.url}/${path}`;

const getProductPlaceholderImg = () => {
  return url('images/product-placeholder.png');
};

export {
  debounce,
  formatMoney,
  formatDate,
  formatDateTime,
  generateUniqueId,
  timeFromNow,
  toNumber,
  percentageToFraction,
  fractionToPercentage,
  majorToMinorUnits,
  cloneDeep,
  formatRateToPercentage,
  formatRateToPercentageRounded,
  throttleAsync,
  countString,
  isEmptyObject,
  hash,
  loadScript,
  closest,
  areArraysEqualSets,
  hasFeatureServices,
  isTt1,
  isCas,
  url,
  getProductPlaceholderImg,
  isProduction,
  getPlatformName,
};
