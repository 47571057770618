<template>
  <a href="#" class="back-button" @click.prevent="back">
    <IconDropLeft width="18" height="18" />
    <span>Back</span>
  </a>
</template>
<script>
import IconDropLeft from '~/components/icons/IconDropLeft';
export default {
  components: { IconDropLeft },
  methods: {
    back() {
      window.history.back();
    },
  },
};
</script>
<style lang="scss">
.back-button {
  display: inline-flex;
  align-items: center;
  color: rgba($black, 0.5);

  span {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    margin-left: $s-3;
  }

  @at-root {
    .no-touchevents {
      .back-button:hover {
        color: rgba($black, 0.75);
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .back-button {
    span {
      margin-left: $s-1;
    }
  }
}
</style>
