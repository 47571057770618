import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

import auth from '~/store/auth';
import global from '~/store/global';
import cart from '~/store/cart';

export function createStore() {
  return new Vuex.Store({
    modules: {
      auth,
      global,
      cart,
    },
  });
}
