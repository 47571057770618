<template>
  <div>
    <template v-if="userable.stripe_details_submitted_at">
      <div class="stripe-details">
        <p>You are connected to Stripe</p>
        <p>
          Account ID: <span>{{ userable.stripe_id }}</span>
        </p>
      </div>
      <a
        :href="route('express-dashboard')"
        style="margin-top: 20px;"
        class="btn btn--with-icon"
        target="_blank"
        rel="noopener noreferrer"
      >
        <IconStripe />
        <span>View Stripe Account</span>
      </a>
    </template>
    <template v-else>
      <div class="connect-stripe">
        <h6>
          {{ userable.stripe_id ? 'Complete Linking Of' : 'Link' }} Your Bank
          Account
        </h6>
        <p>
          Cascade uses Stripe to process payments directly and securely into
          your bank account. Stripe is certified to PCI Service Provider Level
          1, the most stringent level of certification. Please click on the
          button below to link your bank account.
        </p>
        <p>
          NOTE: Stripe will ask for your website during setup. If you do not
          have a website, please use '{{ url() }}'.
        </p>
        <a
          :href="route('connect-onboarding.redirect')"
          class="btn btn--with-icon"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IconStripe />
          <span>Connect With Stripe</span>
        </a>
      </div>
    </template>
  </div>
</template>
<script>
import IconStripe from '~/components/icons/IconStripe';

export default {
  components: { IconStripe },
  computed: {
    userable() {
      return this.$global.userable;
    },
  },
};
</script>
<style lang="scss">
.stripe-details {
  padding: 20px 24px;
  border-radius: $border-radius;
  background-color: rgba($black, 0.05);
  color: $gray-800;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;

  p {
    margin-bottom: 4px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  span {
    color: rgba($gray-800, 0.5);
  }
}

.connect-stripe {
  h6 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  p {
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
    color: rgba($gray-800, 0.5);
    margin: 0 0 10px;
  }

  .btn {
    margin-top: 8px;
  }
}
</style>
