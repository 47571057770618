var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M12,0C5.383,0,0,5.383,0,12s5.383,12,12,12s12-5.383,12-12S18.617,0,12,0z M6,8c0-1.105,0.895-2,2-2 s2,0.895,2,2c0,1.105-0.895,2-2,2S6,9.105,6,8z M8,17.414L6.586,16L16,6.586L17.414,8L8,17.414z M16,18c-1.105,0-2-0.895-2-2 c0-1.105,0.895-2,2-2s2,0.895,2,2C18,17.105,17.105,18,16,18z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }