import { events } from '~/components/confirm-modal/events';

const ConfirmModalPlugin = {
  install(Vue, args = {}) {
    if (this.installed) {
      return;
    }

    this.installed = true;
    this.params = args;

    let currentResolve = null;

    const stopLoading = () => {
      events.$emit('stop-loading');
    };

    events.$on('yes', function () {
      currentResolve({
        confirmed: true,
        stopLoading,
      });
    });

    events.$on('no', function () {
      currentResolve({
        confirmed: false,
      });

      stopLoading();
    });

    const confirm = (
      {
        subtitle,
        title,
        withLoading,
        danger,
        onlyOk,
        okButtonLabel,
        hideCloseBtn,
      } = {
        title: '',
        subtitle: '',
        withLoading: false,
        danger: true,
        onlyOk: false,
        okButtonLabel: 'Ok',
        hideCloseBtn: false,
      }
    ) => {
      return new Promise((resolve) => {
        events.$emit('show', {
          title,
          subtitle,
          withLoading,
          danger,
          onlyOk,
          okButtonLabel,
          hideCloseBtn,
        });

        currentResolve = resolve;
      });
    };

    const name = 'confirm';

    Vue.prototype['$' + name] = confirm;
    Vue[name] = confirm;
  },
};

export default ConfirmModalPlugin;
