var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FieldWrapper", {
    attrs: {
      rules: _vm.rules,
      name: _vm.name,
      label: _vm.label,
      vid: _vm.vid,
      "label-info-text": _vm.labelInfoText,
      "info-text": _vm.infoText,
      disabled: _vm.disabled,
      readonly: _vm.readonly,
      dark: _vm.dark
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var inputId = ref.inputId
          var errors = ref.errors
          return [
            _vm.type === "checkbox"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentValue,
                      expression: "currentValue"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": errors[0] },
                  attrs: {
                    id: inputId,
                    placeholder: _vm.placeholder,
                    name: _vm.vid,
                    disabled: _vm.disabled,
                    readonly: _vm.readonly,
                    type: "checkbox"
                  },
                  domProps: {
                    checked: Array.isArray(_vm.currentValue)
                      ? _vm._i(_vm.currentValue, null) > -1
                      : _vm.currentValue
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.currentValue,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.currentValue = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.currentValue = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.currentValue = $$c
                      }
                    }
                  }
                })
              : _vm.type === "radio"
              ? _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentValue,
                      expression: "currentValue"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": errors[0] },
                  attrs: {
                    id: inputId,
                    placeholder: _vm.placeholder,
                    name: _vm.vid,
                    disabled: _vm.disabled,
                    readonly: _vm.readonly,
                    type: "radio"
                  },
                  domProps: { checked: _vm._q(_vm.currentValue, null) },
                  on: {
                    change: function($event) {
                      _vm.currentValue = null
                    }
                  }
                })
              : _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.currentValue,
                      expression: "currentValue"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "is-invalid": errors[0] },
                  attrs: {
                    id: inputId,
                    placeholder: _vm.placeholder,
                    name: _vm.vid,
                    disabled: _vm.disabled,
                    readonly: _vm.readonly,
                    type: _vm.type
                  },
                  domProps: { value: _vm.currentValue },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.currentValue = $event.target.value
                    }
                  }
                })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }