<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M23 1a1 1 0 011 1v20a1 1 0 01-1 1H1a1 1 0 01-1-1V2a1 1 0 011-1zm-1 6H2v14h20V7zM8 9v10H4V9h4zm12 0v10H10V9h10zM7 3a1 1 0 100 2 1 1 0 000-2zM3 3a1 1 0 100 2 1 1 0 000-2z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
