<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M20 9a4 4 0 014 4v9a1 1 0 01-1 1h-6a1 1 0 01-1-1v-7.163a9.575 9.575 0 01-2.104 2.012 2.244 2.244 0 00-.984-2.387 10.349 10.349 0 001.838-2.138C15.641 11.065 17.125 9 20 9zM4 9c2.875 0 4.359 2.065 5.25 3.324a7.9 7.9 0 003.018 2.907 1.251 1.251 0 01-1.119 2.237A9.126 9.126 0 018 14.837V22a1 1 0 01-1 1H1a1 1 0 01-1-1v-9a4 4 0 014-4zm1-7.5a3 3 0 110 6 3 3 0 010-6zm14 0a3 3 0 110 6 3 3 0 010-6z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
