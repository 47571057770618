<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M15.461 14.023C19.106 14.262 22 17.296 22 21v1.73l-.695.223C21.171 22.995 17.962 24 12 24c-5.703 0-8.887-.92-9.27-1.036l-.035-.011L2 22.73V21c0-3.704 2.894-6.738 6.539-6.977C9.576 14.632 10.742 15 12 15s2.424-.368 3.461-.977zM12 0c3.309 0 6 2.691 6 6 0 3.237-2.621 7-6 7S6 9.237 6 6c0-3.309 2.691-6 6-6z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
