<template>
  <DashboardPage title="Agents">
    <Datatable
      ref="table"
      :get-base-query="getBaseQuery"
      :query-custom-params="queryCustomParams"
    >
      <template v-slot:actions>
        <a href="#" class="btn btn--with-icon" @click="showModal = true">
          <IconAdd width="18" height="18" />
          <span>Add new</span>
        </a>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="table__row-actions">
          <a href="#" @click.prevent.stop="edit(item._meta)">
            <IconEdit
              v-tooltip="{
                boundariesElement: 'body',
                content: 'Edit agent',
              }"
            />
          </a>
        </div>
      </template>
    </Datatable>
    <Portal to="modals">
      <AgentModal
        v-if="showModal"
        :model-id="agentToEditId"
        @saved="handleSaved"
        @close="
          showModal = false;
          agentToEditId = null;
        "
      />
    </Portal>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import IconAdd from '~/components/icons/IconAdd';
import AgentModal from '~/components/AgentModal';
import IconEdit from '~/components/icons/IconEdit';
import DatatableModel from '~/models/Datatable';

export default {
  extends: Page,
  components: {
    AgentModal,
    DashboardPage,
    Datatable,
    IconAdd,
    IconEdit,
  },
  data() {
    return {
      showModal: false,
      queryCustomParams: {
        table_id: 'partner-agents',
      },
      agentToEditId: null,
    };
  },
  methods: {
    getBaseQuery() {
      return DatatableModel.where('partner_id', this.$global.userable.id);
    },
    edit(agent) {
      this.agentToEditId = agent.id;
      this.showModal = true;
    },
    async handleSaved(agent) {
      await this.$refs.table.fetchResources(true);
    },
  },
};
</script>
