var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "metric-single-status" }, [
    _c("div", { staticClass: "metric-single-status__total" }, [
      _c("span", { staticClass: "label" }, [_vm._v(_vm._s(_vm.totalLabel))]),
      _vm._v(" "),
      _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.total))])
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "metric-single-status__proportions" }, [
      _c(
        "div",
        { staticClass: "metric-single-status__proportions__positive" },
        [
          _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.positiveLabel))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.positive))])
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "metric-single-status__proportions__negative" },
        [
          _c("span", { staticClass: "label" }, [
            _vm._v(_vm._s(_vm.negativeLabel))
          ]),
          _vm._v(" "),
          _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.negative))])
        ]
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "metric-single-status__loader-placeholder"
      },
      [
        _c("div", {
          staticClass: "metric-single-status__loading",
          class: { "metric-single-status--skeleton": _vm.loading }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }