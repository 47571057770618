var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "progressLine" }, [
    _c("span", { staticClass: "line" }, [
      _c("span", {
        staticClass: "completed",
        style: { width: _vm.value + "%" }
      })
    ]),
    _vm._v(" "),
    _c("span", { staticClass: "value" }, [_vm._v(_vm._s(_vm.value) + "%")])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }