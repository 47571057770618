<template>
  <div class="gallery" :class="`gallery--${countClass}-images`">
    <div
      v-if="images[0]"
      class="gallery__img-wrapper gallery__img-wrapper--first"
      @click="($event) => openLightbox($event, 1)"
    >
      <div class="gallery__img" :style="getStyle(images[0])"></div>
    </div>
    <div
      v-if="images[1]"
      class="gallery__img-wrapper gallery__img-wrapper--second"
      @click="($event) => openLightbox($event, 2)"
    >
      <div class="gallery__img" :style="getStyle(images[1])"></div>
    </div>
    <div
      v-if="images[2]"
      class="gallery__img-wrapper gallery__img-wrapper--third"
      @click="($event) => openLightbox($event, 3)"
    >
      <div class="gallery__img" :style="getStyle(images[2])"></div>
    </div>
  </div>
</template>
<script>
import BigPicture from 'bigpicture';

export default {
  props: {
    images: {
      type: Array,
      required: true,
    },
  },
  computed: {
    countClass() {
      let count = this.images.length;

      if (count > 3) {
        return 3;
      }

      return count;
    },
  },
  methods: {
    getStyle(image) {
      return `background-image: url(${image.urls.medium})`;
    },

    openLightbox(event, position) {
      BigPicture({
        el: event.target,
        gallery: this.images.map((image) => {
          return {
            src: image.urls.large,
          };
        }),
        noLoader: false,
        position: position - 1,
      });
    },
  },
};
</script>
<style lang="scss">
.gallery {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: $border-radius-lg;
  overflow: hidden;
  min-height: 420px;

  &__img {
    background: no-repeat center center;
    background-size: cover;
    position: absolute;
    left: 1px;
    top: 1px;
    right: 1px;
    bottom: 1px;
  }

  &__img-wrapper {
    padding: 1px;
    position: absolute;
    cursor: pointer;
  }

  &--1-images {
    .gallery__img-wrapper {
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
  }

  &--2-images {
    .gallery__img-wrapper {
      top: 0;
      height: 100%;
      width: 50%;
    }

    .gallery__img-wrapper--first {
      left: 0;
    }

    .gallery__img-wrapper--second {
      left: 50%;
    }
  }

  &--3-images {
    .gallery__img-wrapper {
      width: 50%;
    }

    .gallery__img-wrapper--first {
      top: 0;
      left: 0;
      height: 100%;
    }

    .gallery__img-wrapper--second {
      top: 0;
      left: 50%;
      height: 50%;
    }

    .gallery__img-wrapper--third {
      top: 50%;
      left: 50%;
      height: 50%;
    }
  }
}

#bp_container {
  #bp_count {
    color: $white;
    font-size: 18px;
  }

  .bp-lr {
    svg {
      height: 50px;
    }
  }
}

#bp_aud,
#bp_container img,
#bp_sv,
#bp_vid {
  box-shadow: none !important;
  max-height: 90% !important;
  max-width: 90% !important;
}

@include media-breakpoint-down(sm) {
  .gallery {
    min-height: 220px;
  }
}
</style>
