<template>
  <div class="marketing-page marketing-page-terms-and-conditions">
    <div class="marketing-page__banner">
      <div class="container">
        <h2 class="marketing-page__title">TERMS & CONDITIONS</h2>
      </div>
    </div>
    <div class="marketing-page__content">
      <div class="container">
        <h5>IMPORTANT NOTE</h5>
        <p>
          As well as reading the following Terms & Conditions to which you have
          agreed by using this Site, you should also refer to the terms and
          conditions of each individual company ("company”) on their
          caskadepro.com before making any order.
        </p>
        <h5>Acceptance of terms</h5>
        <p>
          These provisions (including the Privacy Policy together with any other
          documents referred to in these provisions) set out the terms and
          conditions on which you may make use of the Thatsthe1.com /
          caskadepro.com websites (the “Sites”) (Web App) together the
          "Service", whether as a guest or registered user.
        </p>
        <p>
          We may update these Customer Terms (and the documents referred to in
          them) and any aspect of the Service from time to time and will notify
          such changes to you by uploading details of them on the Site. You
          should review the Customer Terms periodically for changes. By using
          the Site you agree to be bound by these Customer Terms. If you do not
          agree to these Customer Terms then please do not use the Services or
          any part of them.
        </p>
        <h5>1. About us</h5>
        <p>
          The Services are operated by KJB Hair Limited ("we"). We are
          registered in Ireland with our registered office address at Ard Na
          Greine, Knapton Court, York Road, DunLaoghaire, Co.Dublin, Ireland.
          Our VAT number is 3429476WH
        </p>
        <h5>2. Accessing our Service</h5>
        <p>
          Access to the Services is permitted on a temporary basis, and we
          reserve the right to withdraw or amend the services we provide through
          the Site without notice. We will not be liable if for any reason any
          of the Services are unavailable at any time or for any period.
        </p>
        <p>
          From time to time, we may restrict access to any or all of the
          Services, to users who have registered with us. Any personal data and
          other information provided by you is processed by us in accordance
          with our Privacy Policy. By providing any such personal data or other
          information you agree to the terms of our Privacy Policy.
        </p>
        <p>
          If you choose, or you are provided with, a user identification code,
          password or any other piece of information as part of our security
          procedures, you must treat such information as confidential, and you
          must not disclose it to any third party. We have the right to disable
          any user identification code or password, whether chosen by you or
          allocated by us, at any time, if in our opinion you have failed to
          comply with any of the provisions of these Customer Terms.
        </p>
        <p>
          It is your responsibility to ensure that all information (including
          your name and address) you upload to the Site, is correct and
          accurate. Ensure that you check all information before making a
          purchase.
        </p>

        <h5>3. Use of the Web App</h5>
        <p>
          (a) We grant you the right to use the Web App only for your personal
          use on an iOS product that you own or control. You may not: (a)
          modify, copy, publish, license, sell or otherwise commercialise the
          App or any information or software associated with it; (b) rent, lease
          or otherwise transfer rights to the App; or (c) use the App in any way
          that could impair our Site or Service in any way or interfere with any
          party’s use or enjoyment of our Site or Service.
        </p>
        <p>
          (b) Neither we nor any of our licensors or service providers has any
          obligation to provide any maintenance and support services with
          respect to the App or any other part of the Service.
        </p>
        <p>
          (c) In using the App through an iOS product, you confirm that you have
          agreed to the applicable Apple Terms and Conditions relating to such
          use.
        </p>
        <p>
          If you breach any of these Customer Terms, your right to use the Site
          and/or Service will cease immediately and you must, at our option,
          return and/or destroy any copies of the content you have made.
        </p>

        <h5>4. Intellectual property rights</h5>
        <p>
          We own, or are the licensee to, all right, title and interest in and
          to the Service, including all rights under patent, copyright, trade
          secret or trademark law, and any and all other proprietary rights,
          including all applications, renewals, extensions and restorations
          thereof. You will not modify, adapt, translate, prepare derivative
          works from, decompile, reverse-engineer, disassemble or otherwise
          attempt to derive source code from the WebApp or any other part of the
          Service.
        </p>
        <p>
          You must not extract or otherwise use any of the content on the Site
          and/or Service for commercial purposes.
        </p>
        <p>
          We respect the intellectual property rights of others and we ask our
          Companies and Influencers to do the same. If you are aware that any of
          your intellectual property rights have been infringed on the Site,
          please contact us here to report the concern.
        </p>

        <h5>5. Description of Thatsthe1.com / caskadepro.com service</h5>
        <p>
          Please note that when you decide to purchase goods and/or services the
          resulting legal contract is between you and that Company and such
          contract shall compromise of these Customer Terms, the email
          confirmation of your order and the applicable details on the product
          page and you agree to be bound by all such provisions.
        </p>

        <p>
          You should carefully review the Customer Terms, the email confirmation
          of your order and the applicable details on the product page from that
          company in relation to the order.
        </p>

        <p>
          We cannot give any undertaking, that goods and/or services you
          purchase from companies through the Site will be of satisfactory
          quality, and this and any other such warranties (whether express or
          implied) are disclaimed by us absolutely to the fullest extent
          permitted by law. This disclaimer does not affect your statutory
          rights against the Company. Where you order goods and/or services
          through the Site we may disclose your customer information related to
          that transaction to the relevant Company.
        </p>

        <p>
          We do not review or control, and are not responsible in any way for,
          listings provided by Companies and at no time do we possess any items
          offered for sale by Companies through the Site.
        </p>
        <h5>6. Disclaimer of Warranties and limitation of liability</h5>
        <p>
          (a)To the fullest extent permitted by applicable laws, we disclaim
          responsibility for any harm resulting from your use of any part of the
          Service.
        </p>
        <p>
          (b) The Site and webApp are provided “as is” and “as available” and we
          expressly disclaim to the fullest extent permitted by law all express,
          implied and statutory warranties.
        </p>
        <p>
          (c) You download and use the webApp at your own discretion and risk,
          and you are solely responsible for any damages to your hardware
          device(s) or loss of data that results from the download or use of the
          webApp.
        </p>
        <p>
          (d) Neither we nor our licensors are liable to you or any user for any
          use or misuse of the webApp. Such limitation: (a) includes direct
          damages, whether such claim is based on warranty, contract, tort or
          otherwise (even if we have been advised of the possibility of such
          damages); (b) includes indirect, incidental, consequential, special,
          exemplary and punitive damages, whether such claim is based on
          warranty, contract, tort or otherwise (even if we have been advised of
          the possibility of such damages); (c) applies whether damages arise
          from use or misuse of and reliance on this webApp, from inability to
          use the webApp, or from the interruption, suspension or termination of
          the webApp (including any damages incurred by third parties).
        </p>
        <p>
          (e) Nothing in these Customer Terms shall limit or exclude our
          liability for fraudulent misrepresentation, for death or personal
          injury resulting from our negligence or the negligence of our agents
          or employees or for any other liability that cannot be limited or
          excluded by law.
        </p>

        <h5>
          7. How contracts are formed between you (end consumer) and Companies.
        </h5>
        <p>
          Each order you place shall be deemed to be an offer by you to purchase
          the goods and/or services specified within it subject to the Customer
          Terms and the applicable details on the product page.
        </p>
        <p>
          No order shall be deemed to be accepted by the Company until we
          (acting as the commercial agent / sales platform of the Company) issue
          an email acknowledgement of order. The contract between you and a
          Company will relate only to those goods and/or services notified in
          the email acknowledgement of order.
        </p>

        <h5>8. Payment methods</h5>
        <p>
          Purchases for goods and/or services you make with Companies may only
          be paid for using the payment methods we make available from time to
          time through our payment facility or by redeeming an email gift
          voucher or physical gift card (individually a “Gift Card”, together
          “Gift Cards”) against us to put towards your purchase of goods and/or
          services on the Site. Except as otherwise described in the Gift Card
          special terms and conditions below, in accepting or otherwise
          processing your payments related to the purchase of items from
          sellers, we act in the capacity as commercial agent of the Seller. In
          respect of all payment methods including Gift Cards, the Seller
          acknowledges and agrees that the valid payment by you to us or
          redemption of a Gift Card for the purchase of item(s) between you and
          the Seller will satisfy your obligation to pay the Seller for the
          relevant item(s) and consequently, any debt obligations owed by you to
          the Seller for the purchase of such items shall be extinguished at
          that time. You acknowledge that these Customer Terms, and/or any
          transaction made by you via notothehighstreet.com, do not create or
          imply any partnership, joint venture or trust relationship between us,
          you and/or the Seller. The Seller further agrees that it will not seek
          recourse (legal or otherwise) against you for payment of items if you
          have validly paid us. All prices shall be shown in the applicable
          currency and payable in that currency. You accept that some banks may
          charge you an additional fee for certain transactions (for example,
          international transactions). You accept that item prices in the
          currencies displayed do not vary according to your location; delivery
          charges will vary depending on the destination to choose to have the
          item delivered. For delivery destinations within the EU prices include
          VAT or other sales taxes where applicable. For delivery destinations
          outside the EU, prices do not include VAT or other sales taxes.
        </p>

        <h5>9. Refusal of transaction</h5>
        <p>
          We may refuse to process a transaction for any reason or refuse
          service to anyone at any time at our sole discretion. We will not be
          liable to you or any third party by reason of so refusing or by reason
          of unwinding or suspending any transaction after processing has begun.
        </p>

        <h5>10. Delivery arrangements</h5>
        <p>
          Your shopping basket on the Site displays the goods you have chosen,
          the Company who shall provide them and details of postage and packing.
          The delivery costs for each Company vary according to the delivery
          methods they offer. Any delivery times quoted are in working days.
        </p>

        <h5>11. Import regulations and duty</h5>
        <p>
          If you order goods from our Site for delivery outside the Ireland/ UK
          , they may be subject to import duties and taxes which are levied when
          the delivery reaches the specified destination. You will be
          responsible for payment of any such import duties and taxes. Please
          note that we have no control over these charges and cannot predict
          their amount. Please contact your local customs office for further
          information before placing your order.
        </p>

        <h5>12. Returns</h5>
        <p>
          Please see our Returns & Refunds Policy if you wish to discuss or
          organise a return, replacement or refund of an item purchased through
          the Site. Unfortunately some items are non-cancelable and
          non-refundable, please see our Returns & Refunds Policy for more
          information. All refunds and returns are subject to the companies own
          returns policy.
        </p>

        <h5>13. Links</h5>
        <p>
          You may link to your profile in order to sell the products, provided
          you do so in a way that is fair and legal and does not damage our
          reputation or take advantage of it, but you must not establish a link
          in such a way as to suggest any form of association, approval or
          endorsement on our part where none exists. The Company expressly
          reserves the right to revoke the right granted in this clause for
          breach of these Customer Terms and to take any action it deems
          appropriate.
        </p>
        <p>
          Our Site must not be framed on any other website, nor may you create a
          link to any part of our Site other than the home page. We reserve the
          right to withdraw linking permission at any time.
        </p>
        <p>
          The Site provides links to other websites for your information. If you
          use these links, you leave the Site. We have not reviewed these third
          party websites and we have no control over such sites or resources. We
          therefore do not endorse or make any representations about them, or
          any material found there, or any results that may be obtained from
          using them. If you decide to access any of the third party websites
          linked to the Site, you do so entirely at your own risk. We accept no
          responsibility for third party websites or for any loss or damage that
          may arise from your use of them including the group gifting
          application which is provided by a third party.
        </p>

        <h5>14. Uploading material to the Site</h5>
        <p>
          Uploaded material must not: :info: be defamatory of any person; (ii)
          contain material which is obscene, discriminatory, offensive, hateful,
          threatening or inflammatory; (iii) infringe any copyright, database
          right or trade mark of any other person; (iv) be likely to deceive any
          person; (v) promote any illegal activity; (vi) be likely to harass,
          upset, embarrass, alarm or annoy any other person; or (vii) be used to
          impersonate any person, or to misrepresent your identity or
          affiliation with any person.
        </p>
        <p>
          Any material a user uploads to the Site will be considered
          non-confidential and non-proprietary, and we have the right to use,
          copy, distribute and disclose to third parties any such material for
          any purpose. We also have the right to disclose a user’s identity to
          any third party who is claiming that any material posted or uploaded
          by such user constitutes a violation of their intellectual property
          rights, or of their right to privacy. We will not be responsible, or
          liable to any third party, for the content or accuracy of any
          materials posted by users. We have the right to remove any material or
          posting a user makes on the Site.
        </p>

        <h5>15. Viruses, hacking and other offences</h5>
        <p>
          You must not misuse our Site or any part of the Service by introducing
          viruses, Trojans, worms, logic bombs or other material which is
          malicious or technologically harmful. You must not attempt to gain
          unauthorised access to our Site, the server on which our Site is
          stored or any server, computer or database connected to our Site. You
          must not attack our Site via a denial-of-service attack or a
          distributed denial-of-service attack.
        </p>
        <p>
          By breaching this provision, you would commit a criminal offence under
          the Computer Misuse Act 1990. We will report any such breach to the
          relevant law enforcement authorities and we will co-operate with those
          authorities by disclosing your identity to them. In the event of such
          a breach, your right to use our Site will cease immediately.
        </p>

        <h5>16. Waiver</h5>
        <p>
          If we fail at any time to insist upon strict performance of any of
          your obligations under these Customer Terms, or if we fail to exercise
          any of the rights or remedies to which we are entitled under them,
          this shall not constitute a waiver of such rights or remedies and
          shall not relieve you from compliance with such obligations. A waiver
          by us of any default shall not constitute a waiver of any subsequent
          default. No waiver by us of any of these Customer Terms shall be
          effective unless it is expressly stated to be a waiver and is
          communicated to you in writing.
        </p>

        <h5>17. Severability</h5>
        <p>
          If any of these Customer Terms are determined by any competent
          authority to be invalid, unlawful or unenforceable to any extent, such
          term, condition or provision will to that extent be severed from the
          remaining terms, conditions and provisions which will continue to be
          valid to the fullest extent permitted by law.
        </p>

        <h5>18. Entire agreement</h5>
        <p>
          These Customer Terms and any documents expressly referred to in them
          constitute the entire agreement between us and supersedes and
          extinguishes all previous drafts, agreements, arrangements and
          understandings between us, whether written or oral, relating to its
          subject matter. Each of us agrees that neither we nor you shall have
          any remedies in respect of any representation or warranty (whether
          made innocently or negligently) that is not set out in these Customer
          Terms or any documents expressly referred to in them. Neither of us
          shall have any claim for innocent or negligent misrepresentation based
          upon any statement in these Customer Terms and any documents expressly
          referred to in them.
        </p>

        <h5>19. Force majeure</h5>
        <p>
          Where we or a Company are prevented from or delayed in carrying out
          obligations under these Customer Terms due to circumstances beyond our
          or the Companies reasonable control including, without limitation,
          acts of God, governmental actions, war or national emergency, riot,
          civil commotion, fire, explosion, flood, inclement weather, epidemic,
          lock-outs, strikes or other labour disputes (whether or not relating
          to the Seller's workforce), or restraints or delays affecting carriers
          or an inability or delay in obtaining supplies of adequate or suitable
          materials then either our or the Companies (as the case may be)
          performance of its obligations shall be postponed for the period of
          time that the circumstances continue.
        </p>

        <h5>20. Rights of Third Parties</h5>
        <p>
          No provision of these Customer Terms shall be enforceable by any third
          party (which includes for these purposes any third party: employee,
          officer, agent, representative or sub-contractor of either CaskdaePRo
          / Thatsthe1 or the Company) under the Contracts (Rights of Third
          Parties) Act 1999 or otherwise. Nothing in this clause excludes the
          rights of CaskdaePRo / Thatsthe1 when acting as commercial agent of
          any Company.
        </p>

        <h5>21. Law and jurisdiction</h5>
        <p>
          Contracts for the purchase of goods or services through our Site or
          the webApp shall be governed by Irish law. Any dispute arising from,
          or related to, such contracts shall be subject to the exclusive
          jurisdiction of the courts of Ireland.
        </p>

        <h5>22. Feedback and Complaints</h5>
        <p>
          General comments about the Site are welcome, please contact us here.
          Complaints about a specific Company, goods or services must be
          directed to the Company / Brand concerned through the contact details
          provided on the order confirmation email that you receive for
          resolution directly with them.
        </p>

        <h5>23. Other applicable terms and conditions</h5>
        <p>
          Additional terms and conditions may apply to certain of our products
          and services. These products and services, and the terms and
          conditions which apply to them, are set out below.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Page from '~/pages/Page';

export default {
  extends: Page,
};
</script>
<style lang="scss">
.marketing-page {
  &__banner {
    background-color: $primary;
  }

  &__title {
    line-height: 32px;
    font-weight: 600;
    font-size: 32px;
    padding: $s-10;
    color: $white;
    text-align: center;
  }
}

.terms-and-conditions-content {
  padding: $s-20 0;

  .container {
    max-width: 768px;
  }

  h5 {
    font-weight: 500;
    margin-bottom: $s-3;
  }

  p {
    font-size: 14px;
    line-height: 20px;
    color: rgba($gray-800, 0.5);
    margin-bottom: $s-10;
  }
}
</style>
