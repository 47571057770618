var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          var invalid = ref.invalid
          return [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.submit)
                  }
                }
              },
              [
                _c("VInput", {
                  attrs: {
                    value: _vm.userable.partner.code,
                    name: "Marketplace ID",
                    readonly: "",
                    "info-text":
                      "Share this unique ID with your clients so they can easily find your store on " +
                      _vm.route("home")
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    label:
                      "Contact Number (with country code eg. +353861802036)",
                    name: "Contact Number",
                    vid: "contact_number"
                  },
                  model: {
                    value: _vm.form.contact_number,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "contact_number", $$v)
                    },
                    expression: "form.contact_number"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "VAT Number",
                    vid: "vat_no",
                    placeholder: "Enter VAT Number"
                  },
                  model: {
                    value: _vm.form.vat_no,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "vat_no", $$v)
                    },
                    expression: "form.vat_no"
                  }
                }),
                _vm._v(" "),
                _c("AddressTypeahead", {
                  model: {
                    value: _vm.place,
                    callback: function($$v) {
                      _vm.place = $$v
                    },
                    expression: "place"
                  }
                }),
                _vm._v(" "),
                _c(
                  "LoadingBtn",
                  {
                    staticClass: "btn",
                    attrs: {
                      loading: _vm.loading,
                      disabled: invalid,
                      type: "submit"
                    }
                  },
                  [_vm._v("Save\n    ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }