<template>
  <div class="marketing-page marketing-page-terms-and-conditions">
    <div class="marketing-page__banner">
      <div class="container">
        <h2 class="marketing-page__title">Returns and Refunds</h2>
      </div>
    </div>
    <div class="marketing-page__content">
      <div class="container">
        <h5>How to return or exchange or cancel your order:</h5>
        <ul>
          <li>
            Contact the Company where the product comes from directly to let
            them know that you wish to return, exchange or cancel your order.
          </li>
          <li>
            You do this through the contact details of the Company / Brand on
            your invoice / confirmation email you received with your order.
          </li>
          <li>
            Under Irish Consumer Law – you have 14 days to Cancel / Return /
            Exchange your product.
          </li>
          <li>
            Anything past the 14 day cooling-off period cannot be returned /
            cancelled or refunded.
          </li>
          <li>
            All of our brands / partners agree and comply.
          </li>
          <li>
            If you have any further questions, please get in touch with us
            through email
            <a href="mailto:caskade@lanaicreative.com"
              >caskade@lanaicreative.com</a
            >
            or call us on <a href="tel:35315688888">+353 (1) 5688888</a>
          </li>
        </ul>
        <h5>What you cannot send back or cancel</h5>
        <p>Unfortunately, some items are non-cancellable and non-refundable:</p>
        <ol>
          <li>
            Anything that is personalised or otherwise can't be resold due to a
            bespoke element
          </li>
          <li>Hairbrushes cannot be returned for hygiene reasons</li>
          <li>Liquid products cannot be returned once opened.</li>
        </ol>
        <p>
          To avoid disappointment, please check whether an item is cancellable
          or non-cancellable before ordering.
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style lang="scss"></style>
