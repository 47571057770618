var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.userable.stripe_details_submitted_at
        ? [
            _c("div", { staticClass: "stripe-details" }, [
              _c("p", [_vm._v("You are connected to Stripe")]),
              _vm._v(" "),
              _c("p", [
                _vm._v("\n        Account ID: "),
                _c("span", [_vm._v(_vm._s(_vm.userable.stripe_id))])
              ])
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                staticClass: "btn btn--with-icon",
                staticStyle: { "margin-top": "20px" },
                attrs: {
                  href: _vm.route("express-dashboard"),
                  target: "_blank",
                  rel: "noopener noreferrer"
                }
              },
              [
                _c("IconStripe"),
                _vm._v(" "),
                _c("span", [_vm._v("View Stripe Account")])
              ],
              1
            )
          ]
        : [
            _c("div", { staticClass: "connect-stripe" }, [
              _c("h6", [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.userable.stripe_id ? "Complete Linking Of" : "Link"
                    ) +
                    " Your Bank\n        Account\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        Cascade uses Stripe to process payments directly and securely into\n        your bank account. Stripe is certified to PCI Service Provider Level\n        1, the most stringent level of certification. Please click on the\n        button below to link your bank account.\n      "
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v(
                  "\n        NOTE: Stripe will ask for your website during setup. If you do not\n        have a website, please use '" +
                    _vm._s(_vm.url()) +
                    "'.\n      "
                )
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "btn btn--with-icon",
                  attrs: {
                    href: _vm.route("connect-onboarding.redirect"),
                    target: "_blank",
                    rel: "noopener noreferrer"
                  }
                },
                [
                  _c("IconStripe"),
                  _vm._v(" "),
                  _c("span", [_vm._v("Connect With Stripe")])
                ],
                1
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }