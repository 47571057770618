var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "services-table" },
    [
      _c("Datatable", {
        ref: "datatable",
        attrs: {
          "get-base-query": _vm.getBaseQuery,
          "query-custom-params": _vm.queryCustomParams
        },
        scopedSlots: _vm._u(
          [
            {
              key: "actions",
              fn: function() {
                return [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn--with-icon",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          _vm.showModal = true
                        }
                      }
                    },
                    [
                      _c("IconAdd", { attrs: { width: "18", height: "18" } }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Add new")])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._t("actions")
                ]
              },
              proxy: true
            },
            {
              key: "cell(actions)",
              fn: function(ref) {
                var item = ref.item
                return [
                  _c("div", { staticClass: "table__row-actions" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.edit(item._meta)
                          }
                        }
                      },
                      [_c("IconEdit")],
                      1
                    )
                  ])
                ]
              }
            }
          ],
          null,
          true
        )
      }),
      _vm._v(" "),
      _c(
        "Portal",
        { attrs: { to: "modals" } },
        [
          _vm.showModal
            ? _c("CreateOrEditServiceModal", {
                attrs: { model: _vm.serviceToEdit },
                on: {
                  saved: _vm.handleSaved,
                  close: function($event) {
                    _vm.showModal = false
                    _vm.serviceToEdit = null
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }