<template>
  <div class="services-by-sector-select">
    <Loader v-if="loading" />
    <template v-else>
      <div
        v-for="group in sectorsToList"
        class="services-by-sector-select__group"
      >
        <ValidationProvider
          :name="`services_ids_per_sector.${group.id}`"
          :vid="`services_ids_per_sector.${group.id}`"
          v-slot="{ errors }"
        >
          <a
            href="#"
            class="services-by-sector-select__group__header"
            @click.prevent="toggle(group)"
          >
            <div class="services-by-sector-select__group__label">
              {{ group.name }}
            </div>
            <span
              v-if="errors[0]"
              class="services-by-sector-select__group__error"
              >{{ errors[0] }}</span
            >
            <div
              v-show="currentValue[group.id].length"
              class="services-by-sector-select__group__check"
            >
              <IconTick width="24" height="24" />
            </div>
            <div class="services-by-sector-select__group__icon">
              <IconDropUp
                v-if="toggledGroupId === group.id"
                width="16"
                height="16"
              />
              <IconDropDown v-else width="16" height="16" />
            </div>
          </a>
          <div
            v-show="toggledGroupId === group.id"
            class="services-by-sector-select__group__options"
          >
            <template v-if="!group.services.length">
              <span
                class="services-by-sector-select__group__options__no-options"
                >No services for this sector.</span
              >
            </template>
            <MultiplePillSelect
              v-else
              v-model="currentValue[group.id]"
              :options="group.services"
              select-label="services"
              value-key="id"
              label-key="name"
              show-select-all
            />
          </div>
        </ValidationProvider>
      </div>
    </template>
  </div>
</template>
<script>
import Loader from '~/components/Loader';
import Sector from '~/models/Sector';
import MultiplePillSelect from '~/components/form/MultiplePillSelect';
import IconTick from '~/components/icons/IconTick';
import IconDropDown from '~/components/icons/IconDropDown';
import IconDropUp from '~/components/icons/IconDropUp';
import InputMixin from '~/components/form/InputMixin';
import { areArraysEqualSets } from '~/utils';
import ValidationProvider from '~/components/form/ValidationProvider';

export default {
  mixins: [InputMixin],
  components: {
    MultiplePillSelect,
    Loader,
    IconTick,
    IconDropUp,
    IconDropDown,
    ValidationProvider,
  },
  props: {
    onlySectorsIds: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      currentValue: this.value ? this.value : {},
      sectors: [],
      services: [],
      loading: false,
      toggledGroupId: null,
    };
  },
  computed: {
    sectorsToList() {
      if (this.onlySectorsIds.length) {
        return this.sectors.filter((s) => this.onlySectorsIds.includes(s.id));
      }

      return this.sectors;
    },
  },

  async created() {
    try {
      this.sectors = await Sector.params({ per_page: 10000 })
        .include('services')
        .$get();

      this.sectors = this.sectors.sort((a, b) => a.name.localeCompare(b.name));
      this.toggledGroupId = this.sectors[0].id;
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },
  watch: {
    currentValue: {
      handler(value) {
        this.$emit('input', value);
      },
      deep: true,
    },
    value: {
      handler(val) {
        if (!this.isSame(val, this.currentValue)) {
          this.currentValue = val;
        }
      },
      deep: true,
    },
    sectorsToList: {
      handler() {
        let sectorsToListIds = this.sectorsToList.map((s) => s.id);
        this.sectors.forEach((s) => {
          if (!sectorsToListIds.includes(s.id)) {
            this.$delete(this.currentValue, s.id);
            return;
          }

          if (!this.currentValue[s.id]) {
            this.$set(this.currentValue, s.id, []);
          }
        });
      },
      immediate: true,
    },
  },
  methods: {
    isSame(val1, val2) {
      let val1Keys = Object.keys(val1);
      let val2Keys = Object.keys(val2);

      if (val1Keys.length !== val2Keys.length) {
        return false;
      }

      // Check if all the arrays are equal.
      for (let i = 0; i <= val1Keys.length; i++) {
        let key = val1Keys[i];
        if (!val2Keys[key]) {
          return false;
        }
        if (!areArraysEqualSets(val1[key], val2[key])) {
          return false;
        }
      }

      return true;
    },
    toggle(group) {
      if (this.toggledGroupId === group.id) {
        this.toggledGroupId = null;
        return;
      }

      this.toggledGroupId = group.id;
    },
  },
};
</script>
<style lang="scss">
.services-by-sector-select {
  &__group {
    border-bottom: 1px solid rgba($black, 0.05);

    &:first-child {
      border-top: 1px solid rgba($black, 0.05);
    }

    &__header {
      display: flex;
      align-items: center;
      padding: $s-5 $s-6;
    }

    &__label {
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
      color: $gray-800;
    }

    &__error {
      font-size: 10px;
      font-weight: 300;
      line-height: 16px;
      color: $primary;
      margin-left: $s-4;
    }

    &__check {
      margin-left: $s-4;
      color: $primary;
    }

    &__icon {
      margin-left: auto;

      svg g {
        fill: rgba($gray-800, 0.25);
      }
    }

    &__options {
      padding-bottom: $s-4;
      &__no-options {
        text-align: center;
        display: block;
        color: rgba($gray-800, 0.5);
        padding-bottom: $s-4;
      }
    }
  }
}
</style>
