<template>
  <Modal
    v-if="visible"
    class="confirm-modal"
    size="sm"
    @modal-close="handleClose"
  >
    <div class="modal__header">
      <h3
        class="modal__title"
        :class="{ 'confirm-modal__title--no-close': hideCloseBtn }"
      >
        {{ title }}
      </h3>
      <button
        v-if="!hideCloseBtn"
        type="button"
        class="modal__close"
        @click="handleClose"
      >
        <IconClose />
      </button>
    </div>
    <div class="modal__body">
      <p v-if="subtitle" v-html="subtitle"></p>
      <template v-if="onlyOk">
        <LoadingBtn :loading="loading" class="btn" @click.prevent="handleYes"
          >{{ okButtonLabel }}
        </LoadingBtn>
      </template>
      <template v-else>
        <LoadingBtn
          :loading="loading"
          class="btn"
          :class="{ 'btn--accent-red': danger }"
          @click.prevent="handleYes"
          >Yes, I'm sure
        </LoadingBtn>
        <a href="#" class="btn btn--secondary" @click.prevent="handleNo"
          >Cancel</a
        >
      </template>
    </div>
  </Modal>
</template>
<script>
import LoadingBtn from '~/components/LoadingBtn';
import Modal from '~/components/Modal';
import { events } from '~/components/confirm-modal/events';
import IconClose from '~/components/icons/IconClose';

export default {
  components: {
    IconClose,
    Modal,
    LoadingBtn,
  },
  data() {
    return {
      loading: false,
      subtitle: '',
      visible: false,
      withLoading: false,
      danger: true,
      onlyOk: false,
      okButtonLabel: 'Ok',
      hideCloseBtn: false,
    };
  },
  mounted() {
    events.$on('show', this.show);
    events.$on('stop-loading', () => {
      this.loading = false;
      this.handleClose();
    });
  },
  methods: {
    show({
      subtitle,
      title,
      withLoading,
      danger,
      onlyOk,
      okButtonLabel,
      hideCloseBtn,
    }) {
      this.title = title || 'Are you sure?';
      this.subtitle = subtitle || '';
      this.withLoading = withLoading;
      this.danger = danger;
      this.onlyOk = onlyOk;
      this.okButtonLabel = okButtonLabel || 'Ok';
      this.hideCloseBtn = hideCloseBtn || false;

      this.visible = true;
    },

    handleClose() {
      this.visible = false;
      events.$emit('close');
    },

    handleNo() {
      this.handleClose();
      events.$emit('no');
    },

    handleYes() {
      if (this.withLoading) {
        this.loading = true;
      } else {
        this.handleClose();
      }

      events.$emit('yes');
    },
  },
};
</script>
<style lang="scss">
.confirm-modal {
  text-align: center;

  &__title--no-close {
    text-align: center;
    width: 100%;
    padding-right: 0;
  }

  .btn {
    margin-right: $s-4;

    &:last-child {
      margin-right: 0;
    }
  }

  p {
    font-size: 18px;
    line-height: 28px;
    font-weight: 300;
    color: rgba($gray-800, 0.5);
    margin-bottom: $s-8;
    text-align: center;
  }
}
@include media-breakpoint-down(md) {
  .confirm-modal {
    .modal__dialog {
      height: auto;
    }

    .modal__body {
      padding-bottom: $s-12;
    }
  }
}
</style>
