<template>
  <div class="card-tag" :class="`card-tag--${type}`">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.card-tag {
  padding: 8px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 8px;
  font-size: 12px;
  border-radius: 2px;
  margin-right: $s-3;
  margin-bottom: $s-2;

  &--sector {
    color: $accent-purple;
    background-color: rgba($accent-purple, 0.2);
  }

  &--service {
    color: $gray-800;
    background-color: rgba($black, 0.05);
  }

  &--category {
    color: $accent-pink;
    background-color: rgba($accent-pink, 0.2);
  }
}
</style>
