<template>
  <ValidationProvider
    v-slot="{ errors }"
    :class="classes"
    :rules="rules"
    :name="name || label"
    :vid="vid"
    tag="div"
    class="form-group"
  >
    <label v-if="!noLabel" :for="name" @click="$refs.input.focus()">
      <span>{{ label || name }}</span>
      <span v-if="labelInfoText" class="optional-label">{{
        labelInfoText
      }}</span>
    </label>
    <div
      class="form-control-wrapper"
      :class="{ 'form-control-wrapper--with-info-icon': infoText }"
    >
      <slot :input-id="inputId" :errors="errors" />
      <div v-if="infoText" class="form-control-info" v-tooltip="infoText">
        <IconMoreInfo width="20" height="20" />
      </div>
    </div>
    <span v-if="errors[0]" class="form-group__error">{{ errors[0] }}</span>
  </ValidationProvider>
</template>

<script>
import { generateUniqueId } from '~/utils';
import FieldWrapperPropsMixin from '~/components/form/FieldWrapperPropsMixin';
import ValidationProvider from '~/components/form/ValidationProvider';
import IconMoreInfo from '~/components/icons/IconMoreInfo';

export default {
  components: {
    ValidationProvider,
    IconMoreInfo,
  },
  mixins: [FieldWrapperPropsMixin],
  data() {
    return {
      inputId: `_${generateUniqueId()}_${this.vid}-input`,
    };
  },
  computed: {
    classes() {
      let classes = [];
      if (this.disabled) {
        classes.push('form-group--disabled');
      }

      if (this.readonly) {
        classes.push('form-group--readonly');
      }

      if (this.dark) {
        classes.push('form-group--dark');
      }

      return classes;
    },
  },
};
</script>
<style lang="scss">
.form-control-wrapper {
  position: relative;

  &--with-info-icon {
    .form-control {
      padding-right: 18 * 2 + 20;
    }
  }

  .form-control-info {
    position: absolute;
    right: 18px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      g {
        fill: rgba($gray-900, 0.25);
      }
    }
  }
}
</style>
