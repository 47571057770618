var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24"
      }
    },
    [
      _c("path", { attrs: { d: "M0 0h24v24H0z", fill: "none" } }),
      _vm._v(" "),
      _c("path", { attrs: { d: "M12 2l-5.5 9h11z" } }),
      _vm._v(" "),
      _c("circle", { attrs: { cx: "17.5", cy: "17.5", r: "4.5" } }),
      _vm._v(" "),
      _c("path", { attrs: { d: "M3 13.5h8v8H3z" } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }