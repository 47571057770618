<template>
  <div v-if="product.colors.length" class="pd__colors-list">
    <div class="pd__colors-list__title">Available colours</div>
    <div class="pd__colors-list__samples">
      <span
        v-for="color in product.colors"
        :key="color"
        class="pd__colors-list__sample"
        :style="{ backgroundColor: `#${color}` }"
      ></span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.pd__colors-list {
  &__title {
    font-size: 12px;
    font-weight: 500;
    color: rgba($gray-800, 0.5);
    line-height: 18px;
  }

  &__samples {
    margin-top: $s-3;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: -$s-4;
  }

  &__sample {
    width: 40px;
    height: 40px;
    margin-right: $s-4;
    margin-bottom: $s-4;
    border-radius: 50%;
  }
}
</style>
