<template>
  <div
    class="business-more-details business-more-details--company"
    :class="classes"
  >
    <div class="business-more-details__item">
      <IconProducts :width="size" :height="size" />
      <span>{{ countString(company.products_count, 'products') }}</span>
    </div>
    <div class="business-more-details__item">
      <IconPartners :width="size" :height="size" />
      <span>{{
        countString(company.partners_accepted_count, 'connections')
      }}</span>
    </div>
  </div>
</template>
<script>
import { countString } from '~/utils';
import IconPartners from '~/components/icons/IconPartners';
import IconProducts from '~/components/icons/IconProducts';
import BusinessMoreDetails from '~/pages/dashboard/marketplace/partials/BusinessMoreDetails';

export default {
  extends: BusinessMoreDetails,
  components: {
    IconProducts,
    IconPartners,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
};
</script>
