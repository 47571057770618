var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "landing-and-marketing-menu" },
    [
      _c("li", [
        _c("a", { attrs: { href: _vm.route("about-us") } }, [
          _vm._v("About us")
        ])
      ]),
      _vm._v(" "),
      _vm.user
        ? [
            _c("li", [
              _c("a", { attrs: { href: _vm.route("dashboard") } }, [
                _vm._v("Dashboard")
              ])
            ])
          ]
        : [
            _c("li", [
              _c("a", { attrs: { href: _vm.route("login") } }, [
                _vm._v("Sign in")
              ])
            ]),
            _vm._v(" "),
            _c("li", [
              _c(
                "a",
                {
                  staticClass: "btn btn--transparent-white-button",
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      _vm.showRegisterModal = true
                    }
                  }
                },
                [_vm._v("Get started")]
              )
            ])
          ],
      _vm._v(" "),
      _c(
        "Portal",
        { attrs: { to: "modals" } },
        [
          _vm.showRegisterModal
            ? _c("RegisterModal", {
                on: {
                  close: function($event) {
                    _vm.showRegisterModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }