<template>
  <div class="testimonials-forms">
    <a
      v-if="!testimonials.length"
      href="#"
      class="btn btn--secondary"
      @click="onAdd"
      >Add testimonial</a
    >
    <TestimonialForm
      v-for="(testimonial, index) in testimonials"
      :key="testimonial.id"
      :partner="userable"
      :testimonial="testimonial"
      :last="index === testimonials.length - 1"
      @add="onAdd"
      @updated="onUpdated"
      @removed="onRemoved"
    />
  </div>
</template>
<script>
import VInput from '~/components/form/VInput';
import VTextarea from '~/components/form/VTextarea';
import TestimonialForm from '~/pages/dashboard/partner/settings/partials/TestimonialForm';
import Testimonial from '~/models/Testimonial';
import Partner from '~/models/Partner';
import { generateUniqueId } from '~/utils';

export default {
  components: {
    TestimonialForm,
    VTextarea,
    VInput,
  },
  data() {
    return {
      loading: false,
      testimonials: [],
    };
  },
  async created() {
    try {
      const partner = new Partner(this.userable);
      this.testimonials = await new Testimonial().for(partner).$get();

      if (!this.testimonials.length) {
        this.onAdd();
      }
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },
  computed: {
    id() {
      return this.userable.id;
    },
    userable() {
      return this.$global.userable;
    },
  },
  methods: {
    onAdd() {
      this.testimonials.push({
        author: '',
        text: '',
        id: generateUniqueId(),
        unsaved: true,
      });
    },
    onUpdated(testimonial) {
      let index = this.testimonials.findIndex((t) => t.id === testimonial.id);
      this.testimonials.splice(index, 1, testimonial);
    },
    onRemoved(testimonial) {
      let index = this.testimonials.findIndex((t) => t.id === testimonial.id);
      this.testimonials.splice(index, 1);
    },
  },
};
</script>
<style lang="scss">
.testimonials-forms {
  .testimonial-form {
    margin-top: $s-12;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
