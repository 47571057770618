<template>
  <div>
    <div class="tabs" :class="classes">
      <ul class="tabs__nav">
        <li
          v-for="tab in tabs"
          class="tabs__nav-item"
          :class="{ 'tabs__nav-item--active': tab.isActive }"
        >
          <a
            v-if="tab.tooltip"
            :href="tab.href"
            @click="selectTab(tab)"
            v-tooltip="{
              boundariesElement: 'body',
              content: tab.tooltip,
            }"
            >{{ tab.name }}</a
          >
          <a v-else :href="tab.href" @click="selectTab(tab)">{{ tab.name }}</a>
        </li>
      </ul>
    </div>
    <div class="tabs__content">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    pill: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return { tabs: [] };
  },

  computed: {
    classes() {
      let classes = [];
      if (this.pill) {
        classes.push('tabs--pill');
      }

      return classes;
    },
  },
  created() {
    this.tabs = this.$children;
  },

  mounted() {
    this.onHashChange();

    if (!this.hasTabSelected()) {
      this.tabs[0].isActive = true;
    }

    window.addEventListener('hashchange', this.onHashChange);
  },

  destroyed() {
    window.removeEventListener('hashchange', this.onHashChange);
  },

  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        tab.isActive = tab.href === selectedTab.href;
      });
    },
    hasTabSelected() {
      return this.tabs.some((tab) => tab.isActive);
    },
    onHashChange() {
      if (window.location.hash) {
        let href = window.location.hash;
        this.selectTab({ href });
      }
    },
  },
};
</script>
<style lang="scss">
.tabs {
  &__nav {
    align-items: center;
    border-bottom: 1px solid rgba($black, 0.1);
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }

  &__nav-item {
    display: block;

    a {
      display: inline-block;
      position: relative;
      align-items: center;
      color: rgba($black, 0.25);
      padding: 8px 0;
      vertical-align: top;
      transition: color 0.15s ease-out;
      margin: 0 18px;
      font-size: 14px;
      white-space: nowrap;

      &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $primary;
        opacity: 0;
        transition: opacity 0.15s ease-out;
      }

      &:first-child {
        margin-left: 0;
      }

      @at-root {
        .no-touchevents {
          .tabs__nav-item:not(.tabs__nav-item--active) a:hover {
            color: rgba($black, 1);
          }
        }
      }
    }

    &--active {
      a {
        color: rgba($black, 1);

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &__content {
    margin-top: 16px;
  }

  &--pill {
    .tabs {
      &__nav {
        padding: 10px 6px;
        justify-content: space-between;
        align-items: center;
        border-radius: 32px;
        border: none;
        background-color: rgba($black, 0.05);
      }

      &__nav-item {
        a {
          padding: 8px 42px;
          margin: 0 4px;
          border-radius: 18px;
          background-color: transparent;
          transition: color 0.15s ease-out, background-color 0.15s ease-out;
          color: rgba($black, 0.5);
          line-height: 20px;

          &:after {
            display: none;
          }

          @at-root {
            .no-touchevents {
              .tabs--pill .tabs__nav-item:not(.tabs__nav-item--active) a:hover {
                color: rgba($black, 0.5);
                background-color: rgba($black, 0.05);
              }
            }
          }
        }

        &--active {
          a {
            color: $white;
            background-color: $primary;
          }
        }
      }
    }
  }
}
</style>
