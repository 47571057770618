<template>
  <FieldWrapper
    v-slot="{ inputId, errors }"
    :rules="rules"
    :name="name"
    :label="label"
    :vid="vid"
    :label-info-text="labelInfoText"
  >
    <Money v-model="currentValue" v-bind="money" />
  </FieldWrapper>
</template>
<script>
import InputMixin from '~/components/form/InputMixin';
import { Money } from 'v-money';

export default {
  mixins: [InputMixin],
  components: {
    Money,
  },
  data() {
    return {
      money: {
        decimal: '.',
        thousands: '',
        prefix: '',
        suffix: '%',
        precision: 2,
        masked: false /* doesn't work with directive */,
      },
    };
  },
};
</script>
