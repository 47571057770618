var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pd__accordion" }, [
    _c(
      "a",
      {
        staticClass: "pd__accordion__header",
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggle($event)
          }
        }
      },
      [
        _c("span", [_vm._v(_vm._s(_vm.title))]),
        _vm._v(" "),
        !_vm.expanded
          ? _c("IconAdd", { attrs: { width: "18", height: "18" } })
          : _vm._e(),
        _vm._v(" "),
        _vm.expanded
          ? _c("IconClose", { attrs: { width: "18", height: "18" } })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.expanded,
            expression: "expanded"
          }
        ],
        staticClass: "pd__accordion__body"
      },
      [_vm._t("default")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }