<template>
  <DashboardPage title="Dashboard">
    <MetricSection title="Overview">
      <div class="metric-section__tiles">
        <MetricValue title="Total Sales" endpoint="sales/company" />
        <MetricValue
          title="Commission Paid"
          endpoint="partnerCommission/company"
        />
        <MetricValue
          title="Connections"
          format="integer"
          endpoint="partnersCount/company"
        />
        <MetricValue
          title="Total Orders"
          format="integer"
          endpoint="ordersCount/company"
        />
      </div>
    </MetricSection>
    <MetricSection
      title="Analytics"
      :link="{ href: route('company.sales'), title: 'All Sales' }"
    >
      <MetricTrend
        endpoint="analytics/company"
        has-service-filter
        has-category-filter
        :options="[
          { label: 'Sales', value: 'sales' },
          { label: 'Commission', value: 'commission' },
          { label: 'Orders', value: 'transactions' },
        ]"
      ></MetricTrend>
    </MetricSection>
    <div class="metric-row">
      <div class="metric-row__half-column">
        <MetricSection
          title="Partners"
          :link="{ href: route('company.partners'), title: 'All Partners' }"
        >
          <MetricPerformance
            :options="[
              { label: 'Sales', value: 'sales' },
              { label: 'Commission', value: 'commission' },
              { label: 'Transactions', value: 'transactions' },
            ]"
            endpoint="topPartners/company"
          ></MetricPerformance>
        </MetricSection>
      </div>
      <div class="metric-row__half-column">
        <MetricSection
          title="Product Performance"
          :link="{ href: route('company.products'), title: 'All Products' }"
        >
          <MetricPerformance
            :options="[
              { label: 'Sales', value: 'sales' },
              { label: 'Commission', value: 'commission' },
              { label: 'Transactions', value: 'transactions' },
            ]"
            has-category-filter
            endpoint="topProducts/company"
          ></MetricPerformance>
        </MetricSection>
      </div>
    </div>
    <div class="metric-row">
      <div class="metric-row__half-column">
        <MetricSection
          title="Stock Status"
          :link="{ href: route('company.products'), title: 'All Products' }"
        >
          <MetricPerformance
            :has-time-filter="false"
            endpoint="stock/company"
          ></MetricPerformance>
        </MetricSection>
      </div>
      <div class="metric-row__half-column">
        <MetricSection
          title="Pending Partners"
          :link="{ href: route('company.partners'), title: 'All Partners' }"
        >
          <MetricPerformance
            :has-time-filter="false"
            endpoint="connections/company"
          ></MetricPerformance>
        </MetricSection>
      </div>
    </div>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import MetricSection from '~/components/MetricSection';
import MetricValue from '~/components/metrics/MetricValue';
import MetricPerformance from '~/components/metrics/MetricPerformance';
import MetricTrend from '~/components/metrics/MetricTrend';
import MetricPageMixin from '~/pages/dashboard/common/MetricPageMixin';

export default {
  extends: Page,
  mixins: [MetricPageMixin],
  components: {
    DashboardPage,
    Datatable,
    MetricSection,
    MetricValue,
    MetricPerformance,
    MetricTrend,
  },
};
</script>
