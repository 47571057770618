import PageComponents from '~/pages/Components';

import PageLanding from '~/pages/Landing';

import PageLogin from '~/pages/auth/Login';
import PageForgotPassword from '~/pages/auth/ForgotPassword';
import PageResetPassword from '~/pages/auth/ResetPassword';

import PageAboutUs from '~/pages/AboutUs';
import PageTermsAndConditions from '~/pages/TermsAndConditions';
import PagePrivacyPolicy from '~/pages/PrivacyPolicy';
import PageDelivery from '~/pages/Delivery';
import PageReturns from '~/pages/Returns';

import PageDashboardAdminIndex from '~/pages/dashboard/admin/Index';
import PageDashboardAdminCompanies from '~/pages/dashboard/admin/Companies';
import PageDashboardAdminPartners from '~/pages/dashboard/admin/Partners';
import PageDashboardAdminSales from '~/pages/dashboard/admin/Sales';
import PageDashboardAdminConnect from '~/pages/dashboard/admin/Connect';
import PageDashboardAdminConfiguration from '~/pages/dashboard/admin/Configuration';
import PageDashboardAdminSettings from '~/pages/dashboard/admin/Settings';
import PageDashboardCompanyIndex from '~/pages/dashboard/company/Index';
import PageDashboardCompanyPartners from '~/pages/dashboard/company/Partners';
import PageDashboardCompanySales from '~/pages/dashboard/company/Sales';
import PageDashboardCompanyProducts from '~/pages/dashboard/company/Products';
import PageDashboardCompanySettings from '~/pages/dashboard/company/Settings';
import PageDashboardPartnerIndex from '~/pages/dashboard/partner/Index';
import PageDashboardPartnerSales from '~/pages/dashboard/partner/Sales';
import PageDashboardPartnerCompanies from '~/pages/dashboard/partner/Companies';
import PageDashboardPartnerProducts from '~/pages/dashboard/partner/Products';
import PageDashboardPartnerAgents from '~/pages/dashboard/partner/Agents';
import PageDashboardPartnerSettings from '~/pages/dashboard/partner/Settings';
import PageDashboardAgentIndex from '~/pages/dashboard/agent/Index';
import PageDashboardAgentSales from '~/pages/dashboard/agent/Sales';
import PageDashboardAgentSettings from '~/pages/dashboard/agent/Settings';
import PageDashboardMarketplaceCompaniesIndex from '~/pages/dashboard/marketplace/CompaniesIndex';
import PageDashboardMarketplaceCompaniesShow from '~/pages/dashboard/marketplace/CompaniesShow';
import PageDashboardMarketplacePartnersIndex from '~/pages/dashboard/marketplace/PartnersIndex';
import PageDashboardMarketplacePartnersShow from '~/pages/dashboard/marketplace/PartnersShow';
import PageDashboardMarketplaceProductShow from '~/pages/dashboard/marketplace/ProductShow';

import PageStoreCompany from '~/pages/store/StoreCompany';
import PageStorePartner from '~/pages/store/StorePartner';
import PageStoreProduct from '~/pages/store/StoreProduct';
import PageStoreCheckout from '~/pages/store/StoreCheckout';

export default {
  'page-components': PageComponents,
  'page-landing': PageLanding,
  'page-auth-login': PageLogin,
  'page-auth-forgot-password': PageForgotPassword,
  'page-auth-reset-password': PageResetPassword,
  'page-about-us': PageAboutUs,
  'page-terms-and-conditions': PageTermsAndConditions,
  'page-privacy-policy': PagePrivacyPolicy,
  'page-delivery': PageDelivery,
  'page-returns': PageReturns,
  'page-dashboard-admin-index': PageDashboardAdminIndex,
  'page-dashboard-admin-companies': PageDashboardAdminCompanies,
  'page-dashboard-admin-partners': PageDashboardAdminPartners,
  'page-dashboard-admin-sales': PageDashboardAdminSales,
  'page-dashboard-admin-connect': PageDashboardAdminConnect,
  'page-dashboard-admin-configuration': PageDashboardAdminConfiguration,
  'page-dashboard-admin-settings': PageDashboardAdminSettings,
  'page-dashboard-company-index': PageDashboardCompanyIndex,
  'page-dashboard-company-sales': PageDashboardCompanySales,
  'page-dashboard-company-products': PageDashboardCompanyProducts,
  'page-dashboard-company-partners': PageDashboardCompanyPartners,
  'page-dashboard-company-settings': PageDashboardCompanySettings,
  'page-dashboard-partner-index': PageDashboardPartnerIndex,
  'page-dashboard-partner-sales': PageDashboardPartnerSales,
  'page-dashboard-partner-companies': PageDashboardPartnerCompanies,
  'page-dashboard-partner-products': PageDashboardPartnerProducts,
  'page-dashboard-partner-agents': PageDashboardPartnerAgents,
  'page-dashboard-partner-settings': PageDashboardPartnerSettings,
  'page-dashboard-agent-index': PageDashboardAgentIndex,
  'page-dashboard-agent-sales': PageDashboardAgentSales,
  'page-dashboard-agent-settings': PageDashboardAgentSettings,
  'page-dashboard-marketplace-companies-index': PageDashboardMarketplaceCompaniesIndex,
  'page-dashboard-marketplace-companies-show': PageDashboardMarketplaceCompaniesShow,
  'page-dashboard-marketplace-partners-index': PageDashboardMarketplacePartnersIndex,
  'page-dashboard-marketplace-partners-show': PageDashboardMarketplacePartnersShow,
  'page-dashboard-marketplace-product-show': PageDashboardMarketplaceProductShow,
  'page-store-company': PageStoreCompany,
  'page-store-partner': PageStorePartner,
  'page-store-product': PageStoreProduct,
  'page-store-checkout': PageStoreCheckout,
};
