var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M22.065 3A1.935 1.935 0 0124 4.935v13.936a1.935 1.935 0 01-1.935 1.935H1.935A1.935 1.935 0 010 18.871V4.935A1.935 1.935 0 011.935 3zm-6.997 6.924a1.29 1.29 0 00-.896.357l-.06-.283h-1.006v5.328l1.142-.242.005-1.293c.229.185.514.287.809.287.818 0 1.563-.658 1.564-2.106-.005-1.326-.759-2.048-1.558-2.048zm-9.75 0c-.928 0-1.545.485-1.545 1.294 0 1.26 1.737 1.062 1.737 1.604 0 .21-.183.279-.439.279a2.845 2.845 0 01-1.248-.366v1.087c.394.171.819.26 1.248.261.951 0 1.605-.47 1.605-1.289-.004-1.36-1.746-1.12-1.746-1.631 0-.178.146-.247.388-.247.395.008.783.108 1.133.293v-1.074a3.02 3.02 0 00-1.133-.21zm2.701-.912l-1.114.237-.005 3.66a1.148 1.148 0 001.184 1.174c.274.013.548-.039.8-.15v-.93c-.147.06-.869.27-.869-.406v-1.623h.868v-.97h-.868l.004-.992zm10.557.912c-1.088 0-1.746.923-1.746 2.084 0 1.376.777 2.07 1.892 2.07.44.013.877-.09 1.266-.297v-.914a2.429 2.429 0 01-1.12.252c-.443 0-.836-.156-.886-.695h2.235c0-.06.009-.297.009-.405 0-1.171-.562-2.095-1.65-2.095zm-5.894.078h-1.147V14h1.146l.001-3.997zm-1.427 0a.774.774 0 00-.95.338l-.074-.338h-.987V14h1.143v-2.71a.806.806 0 01.868-.237zm3.537.937c.416 0 .704.466.704 1.065 0 .612-.283 1.069-.704 1.069a.683.683 0 01-.54-.215l-.004-1.695a.68.68 0 01.544-.224zm3.78-.087c.28 0 .575.215.575.731h-1.179c0-.516.316-.73.603-.73zM12.68 8.481l-1.147.241v.931l1.147-.247v-.925z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }