<template>
  <div class="pd__accordions">
    <ProductDetailAccordion v-if="product.features" title="Features">
      <TextareaText>{{ product.features }}</TextareaText>
    </ProductDetailAccordion>
    <ProductDetailAccordion
      v-if="product.dimensions || product.weight"
      title="Specifications"
    >
      <p>
        <span>Dimensions:</span>
        <span style="font-weight: 500;">{{ product.dimensions }}</span>
      </p>
      <p>
        <span>Weight:</span>
        <span style="font-weight: 500;">{{ product.weight }}</span>
      </p>
    </ProductDetailAccordion>
    <ProductDetailAccordion
      v-if="product.commission_details && showCommissionDetails"
      title="Commission Details"
    >
      <TextareaText>{{ product.commission_details }}</TextareaText>
    </ProductDetailAccordion>
  </div>
</template>
<script>
import ProductDetailAccordion from '~/components/product/ProductDetailAccordion';
import TextareaText from '~/components/TextareaText';

export default {
  components: { ProductDetailAccordion, TextareaText },
  props: {
    product: {
      type: Object,
      required: true,
    },
    showCommissionDetails: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.pd__accordions {
  .pd__accordion {
    margin-bottom: $s-4;
  }
}
</style>
