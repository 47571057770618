var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AuthBox",
    [
      _c("h4", [_vm._v("Welcome back")]),
      _vm._v(" "),
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  {
                    staticClass: "form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.submit)
                      }
                    }
                  },
                  [
                    _c("VInput", {
                      attrs: {
                        name: "Email",
                        vid: "email",
                        rules: "required|email"
                      },
                      model: {
                        value: _vm.form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email"
                      }
                    }),
                    _vm._v(" "),
                    _c("VInput", {
                      attrs: {
                        name: "Password",
                        vid: "password",
                        type: "password",
                        rules: "required|min:8"
                      },
                      on: { input: _vm.onPasswordInput },
                      model: {
                        value: _vm.form.password,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "password", $$v)
                        },
                        expression: "form.password"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "LoadingBtn",
                      {
                        staticClass: "btn btn--lg",
                        attrs: {
                          loading: _vm.loading,
                          disabled: invalid,
                          type: "submit"
                        }
                      },
                      [_vm._v("Log in\n      ")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("p", { staticClass: "links" }, [
        _c("a", { attrs: { href: _vm.route("forgot-password") } }, [
          _vm._v("Forgotten your password?")
        ]),
        _vm._v(" •\n    "),
        _c(
          "a",
          {
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                _vm.showRegisterModal = true
              }
            }
          },
          [_vm._v("Sign up for an account")]
        )
      ]),
      _vm._v(" "),
      _c(
        "Portal",
        { attrs: { to: "modals" } },
        [
          _vm.showRegisterModal
            ? _c("RegisterModal", {
                on: {
                  close: function($event) {
                    _vm.showRegisterModal = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }