var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "multiple-pill-select" },
    [
      _vm.loading
        ? _c("Loader")
        : [
            _vm._l(_vm.options, function(option) {
              return _c(
                "a",
                {
                  staticClass: "multiple-pill-select__choice",
                  class: {
                    "multiple-pill-select__choice--selected": _vm.isSelected(
                      option
                    )
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggle(option)
                    }
                  }
                },
                [_vm._v("\n      " + _vm._s(option[_vm.labelKey]) + "\n    ")]
              )
            }),
            _vm._v(" "),
            _vm.showSelectAll
              ? _c(
                  "a",
                  {
                    staticClass: "multiple-pill-select__choice",
                    class: {
                      "multiple-pill-select__choice--selected": _vm.allSelected
                    },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.selectAll($event)
                      }
                    }
                  },
                  [_vm._v("Select all" + _vm._s(_vm.selectLabelWithSpace))]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showSelectAllAndFuture
              ? _c(
                  "a",
                  {
                    staticClass: "multiple-pill-select__choice",
                    class: {
                      "multiple-pill-select__choice--selected": _vm.allAndFuture
                    },
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.toggleAllAndFuture($event)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "Select all current and future" +
                        _vm._s(_vm.selectLabelWithSpace)
                    )
                  ]
                )
              : _vm._e()
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }