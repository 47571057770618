<script>
import Modal from '~/components/Modal';

export default {
  extends: Modal,
};
</script>
<style lang="scss">
.modal {
  &--with-steps {
    .modal__header {
      border-bottom: 0;
    }
  }
}

.modal__steps {
  &__number {
    color: rgba($gray-800, 0.5);
    font-weight: 300;
    margin-left: $s-4;
  }

  &__progress {
    position: relative;
    width: 100%;
    height: 4px;
    background-color: rgba($black, 0.05);

    > div {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      transition: ease-out 0.15s ease-out;
      background-color: $primary;
    }
  }
}

.modal__step {
  &__actions {
    display: flex;

    .btn {
      margin-left: auto;
    }

    .btn--secondary {
      margin-left: 0;
      margin-right: auto;
    }
  }
}
</style>
