var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout layout-auth" },
    [
      _c("div", { staticClass: "logo-wrapper" }, [
        _c("a", { attrs: { href: _vm.route("home") } }, [_c("Logo")], 1)
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("PortalTarget", { attrs: { name: "modals", multiple: "" } }),
      _vm._v(" "),
      _c("ConfirmModal")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }