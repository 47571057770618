<template>
  <div class="metric-section">
    <div class="metric-section__content">
      <h6 class="metric-section__title">
        {{ title }}
        <a v-if="link" :href="link.href">{{ link.title }}</a>
      </h6>
      <div class="metric-section__body">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    link: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style lang="scss">
.metric-section {
  &__title {
    margin-bottom: 18px;
    margin-top: 42px;
    font-weight: 500;
    font-size: $text-sm;
    position: relative;
    padding: 7px 0;

    &:after {
      position: absolute;
      bottom: 0;
      content: ' ';
      height: 1px;
      background: $gray-800;
      opacity: 0.1;
      width: 100%;
      left: 0;
    }

    a {
      float: right;
      color: $gray-800;
      opacity: 0.25;

      &:hover {
        opacity: 1;
        cursor: pointer;
      }
    }
  }

  &__body {

  }
}

@include media-breakpoint-down(sm) {
  .metric-section {
    &__title {
      &:after {
        left: -$container-padding;
        right: -$container-padding;
        width: auto;
      }
    }
  }
}
</style>
