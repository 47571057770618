var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "AuthBox",
    [
      _c("h4", [_vm._v("Forgot Password")]),
      _vm._v(" "),
      _c("ValidationObserver", {
        ref: "form",
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function(ref) {
              var handleSubmit = ref.handleSubmit
              var invalid = ref.invalid
              return [
                _c(
                  "form",
                  {
                    staticClass: "form",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                        return handleSubmit(_vm.submit)
                      }
                    }
                  },
                  [
                    _c("VInput", {
                      attrs: {
                        name: "Email",
                        vid: "email",
                        rules: "required|email"
                      },
                      model: {
                        value: _vm.form.email,
                        callback: function($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "LoadingBtn",
                      {
                        staticClass: "btn btn--lg",
                        attrs: {
                          loading: _vm.loading,
                          disabled: invalid,
                          type: "submit"
                        }
                      },
                      [_vm._v("Send Password Reset Link\n      ")]
                    )
                  ],
                  1
                )
              ]
            }
          }
        ])
      }),
      _vm._v(" "),
      _c("p", { staticClass: "links" }, [
        _c("a", { attrs: { href: _vm.route("login") } }, [
          _vm._v("Remembered it?")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }