<template>
  <div class="db-page">
    <div class="db-page__content">
      <h5 v-if="title" class="db-page__title">{{ title }}</h5>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
<style lang="scss">
.db-page {
  max-width: 1376px + 30px;
  padding: 0 $container-padding;
  margin: 0 auto;

  &__title {
    margin-bottom: 24px;
    font-weight: 500;
  }
}
@include media-breakpoint-down(lg) {
  .db-page {
    &__title {
      padding: $s-5 $container-padding;
      background-color: $white;
      margin: 0 -#{$container-padding} $s-6;
      border-bottom: 1px solid $gray-500;
    }
  }
}
</style>
