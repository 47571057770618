<template>
  <div class="stock-status-badge" :class="classes">
    {{ label }}
  </div>
</template>
<script>
import StockStatusEnum from '~/enums/StockStatusEnum';

export default {
  props: {
    count: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    classes() {
      return [`stock-status-badge--${this.status}`];
    },
    status() {
      if (this.count > 10) {
        return StockStatusEnum.LOOKING_GOOD;
      }

      if (this.count > 0) {
        return StockStatusEnum.RUNNING_LOW;
      }

      return StockStatusEnum.OUT_OF_STOCK;
    },

    label() {
      switch (this.status) {
        case StockStatusEnum.LOOKING_GOOD:
          return 'Looking good';
        case StockStatusEnum.RUNNING_LOW:
          return 'Running low';
        case StockStatusEnum.OUT_OF_STOCK:
          return 'Out of stock';
      }
    },
  },
};
</script>
<style lang="scss">
.stock-status-badge {
  color: white;
  border-radius: 2px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  line-height: 14px;

  &--out_of_stock {
    background-color: $accent-red;
  }

  &--running_low {
    background-color: $accent-yellow;
  }
  &--looking_good {
    background-color: $accent-green;
  }
}
</style>
