<template>
  <DashboardPage title="Sales">
    <Datatable
      :get-base-query="getBaseQuery"
      :query-custom-params="queryCustomParams"
    >
    </Datatable>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import DatatableModel from '~/models/Datatable';

export default {
  extends: Page,
  components: {
    DashboardPage,
    Datatable,
  },
  data() {
    return {
      queryCustomParams: {
        table_id: 'agent-sales',
      },
    };
  },
  methods: {
    getBaseQuery() {
      return DatatableModel.where('agent_id', this.$global.userable.id);
    },
  },
};
</script>
