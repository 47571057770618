var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "marketing-header" }, [
    _c("div", { staticClass: "container" }, [
      _c(
        "a",
        {
          staticClass: "marketing-header__logo",
          attrs: { href: _vm.route("home") }
        },
        [_c("LogoMarkWhite"), _vm._v(" "), _c("LogoWhite")],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "marketing-header__menu" },
        [_c("LandingAndMarketingMenu")],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }