<template>
  <DashboardPage title="Settings">
    <SettingsWrapper>
      <Tab name="Account">
        <TabAccount />
      </Tab>
      <Tab name="Change Password">
        <TabChangePassword />
      </Tab>
    </SettingsWrapper>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import Tab from '~/components/Tab';
import SettingsWrapper from '~/pages/dashboard/common/settings/SettingsWrapper';
import TabChangePassword from '~/pages/dashboard/common/settings/TabChangePassword';
import TabAccount from '~/pages/dashboard/common/settings/TabAccount';

export default {
  extends: Page,
  components: {
    DashboardPage,
    Datatable,
    Tab,
    SettingsWrapper,
    TabChangePassword,
    TabAccount,
  },
};
</script>
