var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-logo", style: { maxWidth: _vm.size + "px" } },
    [
      _vm.logo
        ? _c("img", { attrs: { src: _vm.logo.urls.thumb } })
        : _c("img", { attrs: { src: _vm.placeholderSrc } })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }