<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form @submit.prevent="handleSubmit(submit)" class="form">
      <div class="form-group">
        <SingleUploader
          :media="form.logo"
          :for="mediaFor"
          :collection="MediaCollectionEnum.LOGO"
          @upload-success="onLogoChange"
        >
          <template v-slot:preview="{ media }">
            <UserLogo
              :logo="media"
              :colour="userable.logo_colour"
              :business-name="userable.business_name"
              :size="100"
            />
          </template>
          <template v-slot:text>
            <span class="single-uploader__title">Change logo</span>
            <span class="single-uploader__subtitle"
              >400x400px jpeg with max size 20MB.</span
            >
          </template>
        </SingleUploader>
      </div>
      <template v-if="userType === UserEnum.PARTNER">
        <VInput
          :value="userable.code"
          name="Marketplace ID"
          readonly
          :info-text="`Share this unique ID with your clients so they can easily find your store on ${route(
            'home'
          )}`"
        />
      </template>
      <VInput
        v-model="form.business_name"
        name="Business Name"
        vid="business_name"
        placeholder="Your busines name..."
        rules="required"
        label-info-text="- displayed on your marketplace profile"
      />
      <VInput
        v-model="form.business_email"
        name="Business Email"
        vid="business_email"
        label-info-text="- for invoices and official communications"
      />
      <VInput v-model="form.owner_name" name="Owner Name" vid="owner_name" />
      <VInput
        v-model="form.contact_number"
        label="Contact Number (with country code eg. +353861802036)"
        name="Contact Number"
        vid="contact_number"
      />
      <AddressTypeahead v-model="place" />
      <VInput
        v-model="form.trading_name"
        name="Registered Company Name"
        vid="trading_name"
        placeholder="Enter your Registered Company Name"
      />
      <VInput
        v-model="form.reg_no"
        name="Company Reg. No."
        vid="reg_no"
        placeholder="Enter your registration..."
      />
      <VTextarea
        v-model="form.about"
        name="About"
        vid="about"
        label-info-text="- displayed on your marketplace profile"
      />
      <VInput
        v-model="form.tagline"
        name="Tagline"
        vid="tagline"
        placeholder="Your company tagline"
        label-info-text="- displayed on your marketplace profile"
      />
      <VInput
        v-model="form.website"
        name="Website"
        vid="website"
        placeholder="http://www.mywebsite.com"
      />
      <VInput
        v-model="form.facebook"
        name="Facebook Url"
        vid="facebook"
        rules="url"
      />
      <VInput
        v-model="form.twitter"
        name="Twitter Url"
        vid="twitter"
        rules="url"
      />
      <VInput
        v-model="form.instagram"
        name="Instagram Url"
        vid="instagram"
        rules="url"
      />
      <div v-if="userType === UserEnum.PARTNER" class="form-group">
        <label><span>Add photos to your gallery</span></label>
        <MultiUploader
          :for="mediaFor"
          :collection="MediaCollectionEnum.IMAGES"
          :count="form.images.length"
          :limit="imagesLimit"
          @upload-success="onMediaAdded"
        />
        <MediaList :list="form.images" @remove="handleRemoveMedia" />
      </div>
      <LoadingBtn
        :loading="loading"
        :disabled="invalid"
        class="btn"
        type="submit"
        >Save
      </LoadingBtn>
    </form>
  </ValidationObserver>
</template>
<script>
import VInput from '~/components/form/VInput';
import VTextarea from '~/components/form/VTextarea';
import TabCompanyOrPartnerBase from '~/pages/dashboard/common/settings/TabCompanyOrPartnerBase';
import VSelect from '~/components/form/VSelect';
import SingleUploader from '~/components/upload/SingleUploader';
import MultiUploader from '~/components/upload/MultiUploader';
import MediaList from '~/components/MediaList';
import AddressTypeahead from '~/components/AddressTypeahead';
import UserEnum from '~/enums/UserEnum';
import MediaCollectionEnum from '~/enums/MediaCollectionEnum';
import SavesAddressMixin from '~/pages/dashboard/common/settings/SavesAddressMixin';
import UserLogo from '~/components/UserLogo';
import IconMoreInfo from '~/components/icons/IconMoreInfo';

export default {
  extends: TabCompanyOrPartnerBase,
  mixins: [SavesAddressMixin],
  components: {
    UserLogo,
    AddressTypeahead,
    MediaList,
    MultiUploader,
    VSelect,
    VTextarea,
    VInput,
    SingleUploader,
  },
  data() {
    return {
      MediaCollectionEnum,
      imagesLimit: 8,
      UserEnum,
    };
  },
  computed: {
    userType() {
      return this.$global.userType;
    },
  },

  methods: {
    getForm() {
      return {
        logo: this.userable.logo,
        business_name: this.userable.business_name,
        business_email: this.userable.business_email,
        owner_name: this.userable.owner_name,
        trading_name: this.userable.trading_name,
        reg_no: this.userable.reg_no,
        contact_number: this.userable.contact_number,
        about: this.userable.about,
        tagline: this.userable.tagline,
        website: this.userable.website,
        facebook: this.userable.facebook,
        instagram: this.userable.instagram,
        twitter: this.userable.twitter,
        images: this.userable.images ?? [],
        place_id: this.userable.place_id,
        place_description: this.userable.place_description,
      };
    },

    onLogoChange(file, response) {
      this.form.logo = response.body;
    },

    onMediaAdded(file, response) {
      let tempMedia = response.body;
      this.form.images.push(tempMedia);
    },

    handleRemoveMedia(media) {
      this.form.images = this.form.images.filter(
        (image) => image.id !== media.id
      );
    },
  },
};
</script>
<style lang="scss">
.temp-logo-placeholder {
  width: 80px;
  height: 80px;
}
</style>
