var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Products" } },
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            { attrs: { name: "Live", selected: "" } },
            [
              _c("ProductsTable", {
                ref: "productsTable",
                scopedSlots: _vm._u([
                  {
                    key: "actions",
                    fn: function() {
                      return [
                        _c(
                          "a",
                          {
                            staticClass: "btn btn--with-icon",
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                _vm.showCreateModal = true
                              }
                            }
                          },
                          [
                            _c("IconAdd", {
                              attrs: { width: "18", height: "18" }
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("Add new")])
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "row-actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.updateStock(item._meta)
                              }
                            }
                          },
                          [
                            _c("IconAdd", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    boundariesElement: "body",
                                    content: "Update stock amount"
                                  },
                                  expression:
                                    "{\n                boundariesElement: 'body',\n                content: 'Update stock amount',\n              }"
                                }
                              ]
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.edit(item._meta)
                              }
                            }
                          },
                          [
                            _c("IconEdit", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    boundariesElement: "body",
                                    content: "Edit product"
                                  },
                                  expression:
                                    "{\n                boundariesElement: 'body',\n                content: 'Edit product',\n              }"
                                }
                              ]
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.archive(item._meta)
                              }
                            }
                          },
                          [
                            _c("IconArchive", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    boundariesElement: "body",
                                    content: "Archive product"
                                  },
                                  expression:
                                    "{\n                boundariesElement: 'body',\n                content: 'Archive product',\n              }"
                                }
                              ]
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Archived" } },
            [
              _c("ProductsTable", {
                ref: "archivedProductsTable",
                attrs: { archived: "" },
                scopedSlots: _vm._u([
                  {
                    key: "row-actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.edit(item._meta)
                              }
                            }
                          },
                          [
                            _c("IconEdit", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    boundariesElement: "body",
                                    content: "Edit product"
                                  },
                                  expression:
                                    "{\n                boundariesElement: 'body',\n                content: 'Edit product',\n              }"
                                }
                              ]
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        item._meta.archived
                          ? _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return _vm.unarchive(item._meta)
                                  }
                                }
                              },
                              [
                                _c("IconUnArchive", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        boundariesElement: "body",
                                        content: "Unarchive product"
                                      },
                                      expression:
                                        "{\n                boundariesElement: 'body',\n                content: 'Unarchive product',\n              }"
                                    }
                                  ]
                                })
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "Portal",
        { attrs: { to: "modals" } },
        [
          _vm.showCreateModal
            ? _c("CreateProductModal", {
                on: {
                  close: function($event) {
                    _vm.showCreateModal = false
                  },
                  created: _vm.handleProductCreated
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showEditModal
            ? _c("EditProductModal", {
                attrs: { "product-id": _vm.productToEditId },
                on: {
                  close: function($event) {
                    _vm.showEditModal = false
                  },
                  updated: _vm.handleProductUpdated
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showStockModal
            ? _c("UpdateProductStockModal", {
                attrs: { product: _vm.productToUpdateStock },
                on: {
                  close: function($event) {
                    _vm.showStockModal = false
                  },
                  updated: _vm.handleProductStockUpdated
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }