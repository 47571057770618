var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select-dropdown" },
    [
      _c(
        "button",
        { staticClass: "select-dropdown__btn", on: { click: _vm.toggle } },
        [
          _c("span", [_vm._v(_vm._s(_vm.selected.label))]),
          _vm._v(" "),
          _c("IconDropDown", { attrs: { width: "20", height: "20" } })
        ],
        1
      ),
      _vm._v(" "),
      _c("transition", { attrs: { name: "dropdown-transition" } }, [
        _vm.isOpen
          ? _c(
              "div",
              { staticClass: "select-dropdown__menu" },
              _vm._l(_vm.items, function(item) {
                return _c(
                  "a",
                  {
                    staticClass: "select-dropdown__item",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.onSelect(item)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.label))]
                )
              }),
              0
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }