var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M20 14v2h-2v7a1 1 0 01-1 1H7a1 1 0 01-1-1v-7H4v-2h16zm-5-7a2 2 0 012 2v3H7V9a2 2 0 012-2h6zm-3-7a3.05 3.05 0 013.044 2.87l.005.179a3.049 3.049 0 01-2.848 3.044L12 6.1a3.049 3.049 0 01-3.042-2.85L8.95 3.05a3.05 3.05 0 012.87-3.044L12 0z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }