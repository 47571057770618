var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pd__accordions" },
    [
      _vm.product.features
        ? _c(
            "ProductDetailAccordion",
            { attrs: { title: "Features" } },
            [_c("TextareaText", [_vm._v(_vm._s(_vm.product.features))])],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.product.dimensions || _vm.product.weight
        ? _c("ProductDetailAccordion", { attrs: { title: "Specifications" } }, [
            _c("p", [
              _c("span", [_vm._v("Dimensions:")]),
              _vm._v(" "),
              _c("span", { staticStyle: { "font-weight": "500" } }, [
                _vm._v(_vm._s(_vm.product.dimensions))
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _c("span", [_vm._v("Weight:")]),
              _vm._v(" "),
              _c("span", { staticStyle: { "font-weight": "500" } }, [
                _vm._v(_vm._s(_vm.product.weight))
              ])
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.product.commission_details && _vm.showCommissionDetails
        ? _c(
            "ProductDetailAccordion",
            { attrs: { title: "Commission Details" } },
            [
              _c("TextareaText", [
                _vm._v(_vm._s(_vm.product.commission_details))
              ])
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }