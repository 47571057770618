var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-type-select" }, [
    _c(
      "a",
      {
        staticClass: "user-type-select__option",
        class: {
          "user-type-select__option--selected": _vm.isSelected({
            type: _vm.UserEnum.PARTNER,
            is_individual: true
          })
        },
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.select({
              type: _vm.UserEnum.PARTNER,
              is_individual: true
            })
          }
        }
      },
      [
        _c("div", { staticClass: "user-type-select__icon" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 42 46"
              }
            },
            [
              _c("g", [
                _c("path", {
                  attrs: {
                    d:
                      "M16 5c0-2.76142 2.23858-5 5-5s5 2.23858 5 5-2.23858 5-5 5-5-2.23858-5-5zm26 9c0 1.10457-.89543 2-2 2H27v27.5c0 1.38071-1.11929 2.5-2.5 2.5S22 44.88071 22 43.5V29h-2v14.5c0 1.38071-1.11929 2.5-2.5 2.5S15 44.88071 15 43.5V16H2c-1.10457 0-2-.89543-2-2s.89543-2 2-2h38c1.10457 0 2 .89543 2 2z"
                  }
                })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "user-type-select__text" }, [
          _c("h5", { staticClass: "user-type-select__title" }, [
            _vm._v(_vm._s(_vm.individualPartnerLabel))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "user-type-select__subtitle" }, [
            _vm._v(
              "\n        There is only you working for your business\n      "
            )
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "user-type-select__option",
        class: {
          "user-type-select__option--selected": _vm.isSelected({
            type: _vm.UserEnum.PARTNER,
            is_individual: false
          })
        },
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.select({
              type: _vm.UserEnum.PARTNER,
              is_individual: false
            })
          }
        }
      },
      [
        _c("div", { staticClass: "user-type-select__icon" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 46 44"
              }
            },
            [
              _c("g", [
                _c("path", {
                  attrs: {
                    d:
                      "M42 43c0 .552-.448 1-1 1h-8c-.552 0-1-.448-1-1V32h-7v11c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V22h38zM18 28c0-.553-.448-1-1-1h-6c-.552 0-1 .447-1 1v6c0 .553.448 1 1 1h6c.552 0 1-.447 1-1zm27.759-14.65c.156.18.241.412.241.65v5c0 .553-.448 1-1 1H1c-.552 0-1-.447-1-1v-5c0-.238.085-.47.241-.65L5.683 7h34.634zM6 5V1c0-.553.448-1 1-1h32c.552 0 1 .447 1 1v4z"
                  }
                })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "user-type-select__text" }, [
          _c("h5", { staticClass: "user-type-select__title" }, [
            _vm._v(_vm._s(_vm.partnerLabel))
          ]),
          _vm._v(" "),
          _c("p", { staticClass: "user-type-select__subtitle" }, [
            _vm._v("\n        You run a business with employees\n      ")
          ])
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "user-type-select__option",
        class: {
          "user-type-select__option--selected": _vm.isSelected({
            type: _vm.UserEnum.COMPANY
          })
        },
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.select({ type: _vm.UserEnum.COMPANY })
          }
        }
      },
      [
        _c("div", { staticClass: "user-type-select__icon" }, [
          _c(
            "svg",
            {
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                viewBox: "0 0 46 44"
              }
            },
            [
              _c("g", [
                _c("path", {
                  attrs: {
                    d:
                      "M6 22.00021v22H1c-.55228 0-1-.44772-1-1v-20c0-.55228.44772-1 1-1zm40-15v36c0 .55229-.44772 1-1 1H30v-29c0-1.65685-1.34315-3-3-3h-5v-11c-.00006-.31168.14521-.60558.39288-.79481s.56941-.25216.87012-.17019l22 6c.43519.11863.73709.51393.737.965zm-10 6c0-.55228-.44772-1-1-1s-1 .44772-1 1v22c0 .55228.44772 1 1 1s1-.44772 1-1zm6 2c0-.55228-.44772-1-1-1s-1 .44772-1 1v20c0 .55228.44772 1 1 1s1-.44772 1-1zm-14 0v28c0 .55228-.44772 1-1 1H9c-.55228 0-1-.44772-1-1v-28c0-.55228.44772-1 1-1h18c.55228 0 1 .44772 1 1zm-5 20c0-.55228-.44772-1-1-1h-8c-.55228 0-1 .44772-1 1s.44772 1 1 1h8c.55228 0 1-.44772 1-1zm0-6c0-.55228-.44772-1-1-1h-8c-.55228 0-1 .44772-1 1s.44772 1 1 1h8c.55228 0 1-.44772 1-1zm0-6c0-.55228-.44772-1-1-1h-8c-.55228 0-1 .44772-1 1s.44772 1 1 1h8c.55228 0 1-.44772 1-1z"
                  }
                })
              ])
            ]
          )
        ]),
        _vm._v(" "),
        _vm._m(0)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "user-type-select__text" }, [
      _c("h5", { staticClass: "user-type-select__title" }, [_vm._v("Brand")]),
      _vm._v(" "),
      _c("p", { staticClass: "user-type-select__subtitle" }, [
        _vm._v("\n        You manufacture and sell products\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }