<template>
  <div class="card-input-wrapper">
    <div class="form-group">
      <label>Card</label>
      <Card
        v-if="loaded"
        ref="stripeCard"
        class="stripe-card"
        :class="{ complete }"
        :stripe="stripeKey"
        :options="options"
        @change="complete = $event.complete"
      />
    </div>
  </div>
</template>
<script>
import { Card, createPaymentMethod } from 'vue-stripe-elements-plus';
import { loadScript } from '~/utils';

const stripeKey = process.env.MIX_STRIPE_KEY;
export default {
  components: {
    Card,
  },
  data() {
    return {
      loaded: false,
      complete: false,
      stripeKey: stripeKey,
      options: {
        elements: {
          fonts: [
            {
              cssSrc:
                'https://fonts.googleapis.com/css2?family=Inter:wght@300&display=swap',
            },
          ],
        },
        style: {
          base: {
            backgroundColor: '#fff',
            iconColor: '#1c1d1d',
            color: '#1c1d1d',
            fontWeight: 300,
            fontFamily: 'Inter',
            fontSize: '16px',
            fontSmoothing: 'antialiased',
            '::placeholder': {
              color: '#1c1d1d',
              opacity: 0.25,
            },
          },
          invalid: {
            iconColor: '#fb4747',
            color: '#fb4747',
          },
        },
      },
    };
  },
  async created() {
    await loadScript('https://js.stripe.com/v3/');
    this.loaded = true;
  },

  methods: {
    getStripeEl() {
      return this.$refs.stripeCard.$refs.element._element;
    },
  },
};
</script>
<style lang="scss">
.StripeElement {
  height: $input-height;
  padding: $input-padding-y + 1px $input-padding-x;

  border: 1px solid $input-border-color;
  background-color: #fff;

  transition: border 0.15s ease-out, opacity 0.15s ease-out;
}

.StripeElement--focus {
  border: 1px solid $gray-800;
}

.StripeElement--invalid {
  border-color: $accent-error;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.card-input-wrapper {
}
</style>
