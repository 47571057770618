<template>
  <div class="single-uploader">
    <input
      v-show="false"
      ref="file"
      type="file"
      :multiple="false"
      :accept="allowedFileTypes"
    />
    <div class="single-uploader__preview">
      <slot name="preview" :media="media" />
    </div>
    <div class="single-uploader__text">
      <slot name="text" />
    </div>
    <LoadingBtn
      :skeleton="loadingUppy"
      :loading="uploading"
      class="btn btn--secondary"
      @click="onClickUpload"
      >Upload
    </LoadingBtn>
  </div>
</template>
<script>
import UserAvatar from '~/components/UserAvatar';
import LoadingBtn from '~/components/LoadingBtn';
import log from '~/log';
import InitializeUppyMixin from '~/components/upload/InitializeUppyMixin';
import { generateUniqueId } from '~/utils';

export default {
  mixins: [InitializeUppyMixin],
  components: { LoadingBtn, UserAvatar },
  props: {
    media: {
      validator: (prop) => prop === null || typeof prop === 'object',
      required: false,
    },
    for: {
      type: String,
      required: true,
    },
    collection: {
      type: String,
      required: true,
    },
    allowedFileTypes: {
      type: Array,
      required: false,
      default: () => ['image/jpeg'],
    },
  },
  data() {
    return {
      uniqueId: generateUniqueId(),
      uploading: false,
    };
  },

  async mounted() {
    this.$_uppy = await this.initUppy({ id: this.uniqueId });
    this.addInputEvent();
  },

  methods: {
    addInputEvent() {
      this.$refs.file.addEventListener('change', (e) => {
        const files = [...e.target['files']];

        if (files.length > 0) {
          this.upload(files[0]);
        }
      });
    },
    onClickUpload() {
      this.$refs.file.click();
    },
    async upload(file) {
      try {
        this.uploading = true;
        this.$_uppy.addFile({
          name: file.name,
          type: file.type,
          data: file,
          meta: {
            for: this.for,
            collection: this.collection,
          },
        });
        await this.$_uppy.upload();
      } catch (e) {
        this.$toasted.error('Unknown error.');
        log(e);
      } finally {
        this.uploading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.single-uploader {
  display: flex;
  align-items: center;

  &__text {
    margin-left: $s-4;

    span {
      display: block;
    }
  }

  &__title {
    font-size: 14px;
  }

  &__subtitle {
    font-size: 12px;
    font-weight: 300;
    color: rgba($black, 0.5);
    margin-top: $s-1;
  }

  .btn {
    margin-left: $s-8;
    flex-shrink: 0;
  }
}
</style>
