<template>
  <VSelect
    v-model="currentValue"
    name="Select a service"
    vid="service_id"
    value-key="id"
    label-key="name"
    :options="options"
    placeholder="Select..."
    :loading="loading"
  />
</template>
<script>
import Service from '~/models/Service';
import RelatedSectorFieldBase from '~/components/sectors-fields/RelatedSectorFieldBase';
import UserEnum from '~/enums/UserEnum';

export default {
  extends: RelatedSectorFieldBase,

  async created() {
    try {
      this.values = await Service.params({ per_page: 10000 }).$get();
      this.values = this.values.sort((a, b) => a.name.localeCompare(b.name));
      this.afterFetching();
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    filterByAuthCallback(values) {
      if (this.userType === UserEnum.ADMIN) {
        return values;
      }

      if (this.userType === UserEnum.COMPANY) {
        const sectorsIds = this.userable.sectors.map((s) => s.id);
        values = values.filter((s) => sectorsIds.includes(s.sector_id));
      }

      if (this.userType === UserEnum.PARTNER) {
        let servicesIds = this.userable.services.map((s) => s.id);
        values = values.filter((s) => servicesIds.includes(s.id));
      }

      if (this.userType === UserEnum.AGENT) {
        let servicesIds = this.userable.partner.services.map((s) => s.id);
        values = values.filter((s) => servicesIds.includes(s.id));
      }

      return values;
    },
  },
};
</script>
