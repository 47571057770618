var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "companies-products" },
    [
      _c("div", { staticClass: "companies-products__top-bar" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "companies-products__top-bar__right" },
          [
            _c("VSelect", {
              staticClass: "filter-select",
              attrs: { name: "Order by", options: _vm.orderByOptions },
              model: {
                value: _vm.orderBy,
                callback: function($$v) {
                  _vm.orderBy = $$v
                },
                expression: "orderBy"
              }
            }),
            _vm._v(" "),
            _c("SearchInput", {
              model: {
                value: _vm.filters.search,
                callback: function($$v) {
                  _vm.$set(_vm.filters, "search", $$v)
                },
                expression: "filters.search"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _vm.loadingItems
        ? _c("div", { staticClass: "companies-products__loading" }, [
            _vm._v("\n    Loading...\n  ")
          ])
        : [
            !_vm.items.length
              ? _c("div", { staticClass: "companies-products__no-items" }, [
                  _vm._v("\n      No products.\n    ")
                ])
              : _c(
                  "div",
                  { staticClass: "companies-products__items" },
                  _vm._l(_vm.items, function(product) {
                    return _c(
                      "a",
                      {
                        key: product.id,
                        staticClass: "companies-products__item",
                        attrs: {
                          href: _vm.route(
                            "marketplace.product.show",
                            product.slug
                          )
                        }
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "companies-products__item__image" },
                          [
                            _c("img", {
                              attrs: {
                                src: product.image
                                  ? product.image.urls.medium
                                  : _vm.getProductPlaceholderImg(),
                                alt: "",
                                width: "640",
                                height: "640"
                              }
                            })
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "companies-products__item__title" },
                          [
                            _c(
                              "VClamp",
                              { attrs: { autoresize: "", "max-lines": 1 } },
                              [_vm._v(_vm._s(product.name))]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "companies-products__item__tags" },
                          [
                            _c("CardTag", { attrs: { type: "sector" } }, [
                              _vm._v(_vm._s(product.category.sector.name))
                            ]),
                            _vm._v(" "),
                            _c("CardTag", { attrs: { type: "category" } }, [
                              _vm._v(_vm._s(product.category.name))
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "companies-products__item__description"
                          },
                          [
                            _c(
                              "VClamp",
                              { attrs: { autoresize: "", "max-lines": 3 } },
                              [_vm._v(_vm._s(product.description))]
                            )
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("ProductPriceAndCommission", {
                          attrs: { product: product }
                        })
                      ],
                      1
                    )
                  }),
                  0
                )
          ],
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "companies-products__pagination" },
        [
          _c("SimplePagination", {
            attrs: { page: _vm.page, "is-last-page": _vm.isLastPage },
            on: { prev: _vm.prev, next: _vm.next }
          })
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "companies-products__top-bar__left" }, [
      _c("h6", { staticClass: "companies-products__title" }, [
        _vm._v("Products")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }