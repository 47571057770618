<template>
  <div class="marketing-page marketing-page-terms-and-conditions">
    <div class="marketing-page__banner">
      <div class="container">
        <h2 class="marketing-page__title">Delivery and Shipping</h2>
      </div>
    </div>
    <div class="marketing-page__content">
      <div class="container">
        <p>
          The delivery options from each brand for each product are listed on
          the relevant product pages. Each product is sent to you directly by
          the brand / company / partner we have listed on our site. Partners can
          choose their own delivery options and each delivery option can vary
          from partner to partner.
        </p>
        <p>
          Always check the relevant product page for accurate delivery
          information. By purchasing an item you agree to the stated delivery
          date. If you have any queries about the product or delivery options of
          that product you will need to email the partner / brand directly. All
          contact details for the partners / brands are on their profile pages
          or on your receipt.
        </p>
        <p>
          If you need more information about the delivery of product that you’ve
          already ordered, please contact the partner / brand directly by using
          the contact details on your order confirmation email.
        </p>
        <p>
          If you require further assistance please get in touch with us – we’ll
          be happy to assist.
          <a href="mailto:caskade@lanaicreative.com"
            >caskade@lanaicreative.com</a
          >
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
};
</script>
<style lang="scss"></style>
