<template>
  <DashboardPage title="Connect">
    <div class="connect-inner">
      <div class="connect-inner__pane">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <form @submit.prevent="handleSubmit(submit)" class="form">
            <VSelect
              v-model="form.company_id"
              name="Company"
              vid="company_id"
              value-key="id"
              label-key="business_name"
              empty-value-label="Select company..."
              rules="required"
              :options="companies"
              :loading="loading"
            />
            <VSelect
              v-model="form.partner_id"
              name="Partner"
              vid="partner_id"
              value-key="id"
              label-key="business_name"
              empty-value-label="Select partner..."
              rules="required"
              :options="partners"
              :loading="loading"
            />
            <LoadingBtn
              :loading="connecting"
              :disabled="invalid"
              class="btn"
              type="submit"
              >Connect
            </LoadingBtn>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import DashboardPage from '~/components/DashboardPage';
import VSelect from '~/components/form/VSelect';
import Company from '~/models/Company';
import Partner from '~/models/Partner';
import { ValidationObserver } from 'vee-validate';
import LoadingBtn from '~/components/LoadingBtn';
import Connection from '~/models/Connection';
import ConnectionApplicantEnum from '~/enums/ConnectionApplicantEnum';
import ConnectionStatusEnum from '~/enums/ConnectionStatusEnum';
import ApprovalEnum from "~/enums/ApprovalEnum";

export default {
  extends: Page,
  components: {
    DashboardPage,
    VSelect,
    ValidationObserver,
    LoadingBtn,
  },
  data() {
    return {
      loading: true,
      connecting: false,
      companies: [],
      partners: [],
      form: {
        company_id: '',
        partner_id: '',
      },
    };
  },
  async created() {
    this.loading = true;
    try {
      this.companies = await Company.where('has_profile_complete', 1)
        .where('has_stripe_connected', 1)
        .where('approval', ApprovalEnum.APPROVED)
        .params({ per_page: 10000 })
        .$get();
      this.partners = await Partner.where('has_profile_complete', 1)
        .where('has_stripe_connected', 1)
        .where('approval', ApprovalEnum.APPROVED)
        .params({ per_page: 10000 })
        .$get();
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async submit() {
      this.connecting = true;

      try {
        let existingConnection = await Connection.where(
          'company_id',
          this.form.company_id
        )
          .where('partner_id', this.form.partner_id)
          .firstOrNull();

        if (
          existingConnection &&
          existingConnection.status === ConnectionStatusEnum.ACCEPTED
        ) {
          this.$toasted.error('Connection already established.');
          return;
        }

        if (existingConnection) {
          existingConnection.status = ConnectionStatusEnum.ACCEPTED;
          existingConnection.save();
        } else {
          let connection = new Connection({
            applicant: ConnectionApplicantEnum.COMPANY,
            status: ConnectionStatusEnum.ACCEPTED,
            ...this.form,
          });
          await connection.save();
        }

        this.form.company_id = '';
        this.form.partner_id = '';
        this.$refs.form.reset();
        this.$toasted.success('Connection established.');
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.connecting = false;
      }
    },
  },
};
</script>
<style lang="scss">
.connect-inner {
  background-color: $white;
  border: 1px solid $gray-500;

  &__pane {
    padding: 35px 32px;
    max-width: 440px;
  }
}
</style>
