var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "StorePageWrapper",
    { attrs: { type: _vm.type, business: _vm.business } },
    [
      _c(
        "div",
        { staticClass: "store__container" },
        [_c("PartnerListing", { attrs: { business: _vm.business } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }