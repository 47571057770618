<script>
import ApproveOrDeclineBase from '~/components/ApproveOrDeclineBase';
import UserEnum from '~/enums/UserEnum';
import ApprovalEnum from '~/enums/ApprovalEnum';

export default {
  extends: ApproveOrDeclineBase,
  props: {
    businessType: {
      type: String,
      required: true,
    },
    business: {
      type: Object,
      required: true,
    },
  },
  computed: {
    typeString() {
      return this.businessType === UserEnum.COMPANY ? 'company' : 'partner';
    },
    approveString() {
      return `Approve ${this.typeString}`;
    },
    declineString() {
      return `Decline ${this.typeString}`;
    },
  },
  methods: {
    async approve() {
      await this.approveOrDecline(true);
    },
    async decline() {
      await this.approveOrDecline(false);
    },
    async approveOrDecline(approved) {
      const subtitle = approved ? this.approveString : this.declineString;
      const { confirmed, stopLoading } = await this.$confirm({
        subtitle: `${subtitle}?`,
        withLoading: true,
        danger: !approved,
      });

      if (!confirmed) {
        return;
      }

      try {
        this.business.approval = approved
          ? ApprovalEnum.APPROVED
          : ApprovalEnum.DECLINED;
        await this.business.save();

        this.$emit('updated', this.business);

        const message = approved
          ? `Approved ${this.typeString}`
          : `Declined ${this.typeString}`;
        this.$toasted.success(`${message}!`);
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        stopLoading();
      }
    },
  },
};
</script>
