<template>
  <Datatable
    ref="datatable"
    :get-base-query="getBaseQuery"
    :query-custom-params="queryCustomParams"
  >
    <template v-slot:actions>
      <slot name="actions"></slot>
    </template>
    <template v-slot:cell(actions)="{ item }">
      <div class="table__row-actions">
        <slot name="row-actions" :item="item"></slot>
      </div>
    </template>
  </Datatable>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import StockStatusBadge from '~/components/StockStatusBadge';
import DatatableModel from '~/models/Datatable';

export default {
  extends: Page,
  components: {
    StockStatusBadge,
    DashboardPage,
    Datatable,
  },
  props: {
    hidden: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      queryCustomParams: {
        table_id: 'partner-products',
      },
    };
  },
  methods: {
    getBaseQuery() {
      return DatatableModel.where('partner_id', this.$global.userable.id)
        .where('hidden', this.hidden)
        .where('product.archived', 0);
    },
    refresh() {
      this.$refs.datatable.fetchResources(true);
    },
  },
};
</script>
