<template>
  <div class="user-type-select">
    <a
      href="#"
      class="user-type-select__option"
      :class="{
        'user-type-select__option--selected': isSelected({
          type: UserEnum.PARTNER,
          is_individual: true,
        }),
      }"
      @click.prevent="select({ type: UserEnum.PARTNER, is_individual: true })"
    >
      <div class="user-type-select__icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 42 46">
          <g>
            <path
              d="M16 5c0-2.76142 2.23858-5 5-5s5 2.23858 5 5-2.23858 5-5 5-5-2.23858-5-5zm26 9c0 1.10457-.89543 2-2 2H27v27.5c0 1.38071-1.11929 2.5-2.5 2.5S22 44.88071 22 43.5V29h-2v14.5c0 1.38071-1.11929 2.5-2.5 2.5S15 44.88071 15 43.5V16H2c-1.10457 0-2-.89543-2-2s.89543-2 2-2h38c1.10457 0 2 .89543 2 2z"
            />
          </g>
        </svg>
      </div>
      <div class="user-type-select__text">
        <h5 class="user-type-select__title">{{ individualPartnerLabel }}</h5>
        <p class="user-type-select__subtitle">
          There is only you working for your business
        </p>
      </div>
    </a>
    <a
      href="#"
      class="user-type-select__option"
      :class="{
        'user-type-select__option--selected': isSelected({
          type: UserEnum.PARTNER,
          is_individual: false,
        }),
      }"
      @click.prevent="select({ type: UserEnum.PARTNER, is_individual: false })"
    >
      <div class="user-type-select__icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 44">
          <g>
            <path
              d="M42 43c0 .552-.448 1-1 1h-8c-.552 0-1-.448-1-1V32h-7v11c0 .552-.448 1-1 1H5c-.552 0-1-.448-1-1V22h38zM18 28c0-.553-.448-1-1-1h-6c-.552 0-1 .447-1 1v6c0 .553.448 1 1 1h6c.552 0 1-.447 1-1zm27.759-14.65c.156.18.241.412.241.65v5c0 .553-.448 1-1 1H1c-.552 0-1-.447-1-1v-5c0-.238.085-.47.241-.65L5.683 7h34.634zM6 5V1c0-.553.448-1 1-1h32c.552 0 1 .447 1 1v4z"
            />
          </g>
        </svg>
      </div>
      <div class="user-type-select__text">
        <h5 class="user-type-select__title">{{ partnerLabel }}</h5>
        <p class="user-type-select__subtitle">
          You run a business with employees
        </p>
      </div>
    </a>
    <a
      href="#"
      class="user-type-select__option"
      :class="{
        'user-type-select__option--selected': isSelected({
          type: UserEnum.COMPANY,
        }),
      }"
      @click.prevent="select({ type: UserEnum.COMPANY })"
    >
      <div class="user-type-select__icon">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 46 44">
          <g>
            <path
              d="M6 22.00021v22H1c-.55228 0-1-.44772-1-1v-20c0-.55228.44772-1 1-1zm40-15v36c0 .55229-.44772 1-1 1H30v-29c0-1.65685-1.34315-3-3-3h-5v-11c-.00006-.31168.14521-.60558.39288-.79481s.56941-.25216.87012-.17019l22 6c.43519.11863.73709.51393.737.965zm-10 6c0-.55228-.44772-1-1-1s-1 .44772-1 1v22c0 .55228.44772 1 1 1s1-.44772 1-1zm6 2c0-.55228-.44772-1-1-1s-1 .44772-1 1v20c0 .55228.44772 1 1 1s1-.44772 1-1zm-14 0v28c0 .55228-.44772 1-1 1H9c-.55228 0-1-.44772-1-1v-28c0-.55228.44772-1 1-1h18c.55228 0 1 .44772 1 1zm-5 20c0-.55228-.44772-1-1-1h-8c-.55228 0-1 .44772-1 1s.44772 1 1 1h8c.55228 0 1-.44772 1-1zm0-6c0-.55228-.44772-1-1-1h-8c-.55228 0-1 .44772-1 1s.44772 1 1 1h8c.55228 0 1-.44772 1-1zm0-6c0-.55228-.44772-1-1-1h-8c-.55228 0-1 .44772-1 1s.44772 1 1 1h8c.55228 0 1-.44772 1-1z"
            />
          </g>
        </svg>
      </div>
      <div class="user-type-select__text">
        <h5 class="user-type-select__title">Brand</h5>
        <p class="user-type-select__subtitle">
          You manufacture and sell products
        </p>
      </div>
    </a>
  </div>
</template>
<script>
import UserEnum from '~/enums/UserEnum';
import { isTt1 } from '~/utils';

export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      UserEnum,
    };
  },
  computed: {
    individualPartnerLabel() {
      if (isTt1()) {
        return 'Content Creator';
      }

      return 'Self Employed';
    },
    partnerLabel() {
      if (isTt1()) {
        return 'Agency';
      }

      return 'Employer';
    },
  },
  methods: {
    isSelected(val) {
      return (
        this.value.type === val.type &&
        this.value.is_individual === val.is_individual
      );
    },
    select(val) {
      this.$emit('input', val);
    },
  },
};
</script>
<style lang="scss">
.user-type-select {
  &__option {
    border: 1px solid rgba($gray-800, 0.1);
    padding: $s-6 $s-8;
    transition: background-color 0.15s ease-out;

    display: flex;
    align-items: center;

    margin-bottom: $s-4;
    border-radius: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    width: 46px;
    margin-right: $s-8;

    svg {
      transition: fill 0.15s ease-out, opacity 0.15s ease-out;
      opacity: 0.25;
      fill: $gray-800;
    }
  }

  &__title {
    color: $gray-800;
    font-size: 22px;
    font-weight: 500;
    line-height: 32px;
    transition: color 0.15s ease-out;
  }

  &__subtitle {
    color: rgba($gray-800, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition: color 0.15s ease-out;
  }

  &__option {
    &:hover:not(.user-type-select__option--selected) {
      background-color: rgba($black, 0.05);
    }

    &--selected {
      background-color: $primary;

      .user-type-select {
        &__icon {
          svg {
            opacity: 1;
            fill: $white;
          }
        }

        &__title {
          color: $white;
        }
        &__subtitle {
          color: rgba($white, 0.5);
        }
      }
    }
  }
}
</style>
