var render, staticRenderFns
var script = {}
import style0 from "./MetricPageMixin.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "resources/js/pages/dashboard/common/MetricPageMixin.vue"
export default component.exports