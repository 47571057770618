import isURL from 'validator/lib/isURL';

export default {
  validate: (value) => {
    if (value === null || value === undefined || value === '') {
      return false;
    }

    return isURL(value);
  },
  params: [],
  message: 'The {_field_} field must be a valid URL',
};
