var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DashboardPage", [
    _c(
      "div",
      { staticClass: "mp-detail" },
      [
        _vm.loading
          ? _c("Loader")
          : [
              _c(
                "MarketplaceBreadcrumbs",
                {
                  attrs: {
                    type:
                      _vm.type === _vm.UserEnum.PARTNER
                        ? "partners"
                        : "companies"
                  }
                },
                [_c("span", [_vm._v(_vm._s(_vm.business.business_name))])]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mp-detail__top" }, [
                _c("div", { staticClass: "mp-detail__logo-and-info" }, [
                  _c(
                    "div",
                    { staticClass: "mp-detail__logo" },
                    [
                      _c("UserLogo", {
                        attrs: {
                          logo: _vm.business.logo,
                          colour: _vm.business.logo_colour,
                          "business-name": _vm.business.business_name,
                          size: 200
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mp-detail__info" },
                    [
                      _c("div", { staticClass: "mp-detail__title" }, [
                        _vm._v(_vm._s(_vm.business.business_name))
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "mp-detail__tagline" }, [
                        _vm._v(_vm._s(_vm.business.tagline))
                      ]),
                      _vm._v(" "),
                      !_vm.hideConnectButton
                        ? [
                            _c(
                              "div",
                              { staticClass: "mp-detail__connection-wrapper" },
                              [
                                !_vm.connection
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "btn btn--white mp-detail__connection",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function($event) {
                                            $event.preventDefault()
                                            return _vm.connect($event)
                                          }
                                        }
                                      },
                                      [
                                        _c("IconConnect", {
                                          attrs: { width: "16", height: "16" }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [_vm._v("Connect")])
                                      ],
                                      1
                                    )
                                  : _c(
                                      "div",
                                      {
                                        staticClass: "mp-detail__connection",
                                        class:
                                          "mp-detail__connection--" +
                                          _vm.connection.statusClassName()
                                      },
                                      [
                                        _c("IconConnect", {
                                          attrs: { width: "16", height: "16" }
                                        }),
                                        _vm._v(" "),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.connection.statusLabel())
                                          )
                                        ])
                                      ],
                                      1
                                    )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mp-detail__about mp-detail__about--mobile" },
                  [
                    _c("h5", [_vm._v("About")]),
                    _vm._v(" "),
                    _c(
                      "VClamp",
                      {
                        attrs: { autoresize: "", "max-lines": 4 },
                        scopedSlots: _vm._u([
                          {
                            key: "after",
                            fn: function(ref) {
                              var toggle = ref.toggle
                              var expanded = ref.expanded
                              return [
                                _c(
                                  "a",
                                  {
                                    staticClass: "mp-detail__about__read-more",
                                    class: {
                                      "mp-detail__about__read-more--expanded": expanded
                                    },
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        return toggle($event)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "Read " +
                                        _vm._s(expanded ? "less" : "more")
                                    )
                                  ]
                                )
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.business.about) +
                            "\n            "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mp-detail__more-details" },
                  [
                    _vm.type === _vm.UserEnum.PARTNER
                      ? _c("PartnerMoreDetails", {
                          attrs: { partner: _vm.business, big: "", all: "" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.type === _vm.UserEnum.COMPANY
                      ? _c("CompanyMoreDetails", {
                          attrs: { company: _vm.business, big: "", all: "" }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mp-detail__about-and-extra-details" }, [
                _c(
                  "div",
                  { staticClass: "mp-detail__about mp-detail__about--desktop" },
                  [
                    _c("h5", [_vm._v("About")]),
                    _vm._v(" "),
                    _c("TextareaText", [_vm._v(_vm._s(_vm.business.about))])
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mp-detail__extra-details-and-socials" },
                  [
                    _c("div", { staticClass: "mp-detail__extra-details" }, [
                      _vm.business.owner_name
                        ? _c(
                            "span",
                            { staticClass: "mp-detail__extra-detail" },
                            [
                              _c("IconOwner"),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.business.owner_name))
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.business.geocoded_address
                        ? _c(
                            "a",
                            {
                              staticClass: "mp-detail__extra-detail",
                              attrs: {
                                href: _vm.business.geocoded_address.maps_url,
                                rel: "noopener noreferrer nofollow",
                                target: "_blank"
                              }
                            },
                            [
                              _c("IconAddress"),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.business.place_description))
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.business.contact_number
                        ? _c(
                            "a",
                            {
                              staticClass: "mp-detail__extra-detail",
                              attrs: {
                                href: "tel:" + _vm.business.contact_number
                              }
                            },
                            [
                              _c("IconTelephone"),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.business.contact_number))
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.business.website
                        ? _c(
                            "a",
                            {
                              staticClass: "mp-detail__extra-detail",
                              attrs: {
                                href: _vm.business.website,
                                rel: "noopener noreferrer nofollow",
                                target: "_blank"
                              }
                            },
                            [
                              _c("IconWebsite"),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.business.website))])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.business.business_email
                        ? _c(
                            "a",
                            {
                              staticClass: "mp-detail__extra-detail",
                              attrs: {
                                href: "mailto:" + _vm.business.business_email
                              }
                            },
                            [
                              _c("IconEmail"),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(_vm._s(_vm.business.business_email))
                              ])
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.business.vat_no
                        ? _c(
                            "span",
                            {
                              staticClass: "mp-detail__extra-detail",
                              attrs: { href: "#" }
                            },
                            [
                              _c("IconRegistration"),
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(_vm.business.vat_no))])
                            ],
                            1
                          )
                        : _vm._e()
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "mp-detail__socials" }, [
                      _vm.business.facebook
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.business.facebook,
                                rel: "noopener noreferrer nofollow",
                                target: "_blank"
                              }
                            },
                            [_c("IconFacebook")],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.business.twitter
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.business.twitter,
                                rel: "noopener noreferrer nofollow",
                                target: "_blank"
                              }
                            },
                            [_c("IconTwitter")],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.business.instagram
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.business.instagram,
                                rel: "noopener noreferrer nofollow",
                                target: "_blank"
                              }
                            },
                            [_c("IconInstagram")],
                            1
                          )
                        : _vm._e()
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _vm.type === _vm.UserEnum.PARTNER &&
              (_vm.business.images.length || _vm.business.geocoded_address)
                ? _c("div", { staticClass: "mp-detail__gallery-and-map" }, [
                    _vm.business.images.length
                      ? _c(
                          "div",
                          { staticClass: "mp-detail__gallery" },
                          [
                            _c("Gallery", {
                              attrs: { images: _vm.business.images }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.business.geocoded_address
                      ? _c(
                          "div",
                          { staticClass: "mp-detail__map" },
                          [
                            _c("StaticMap", {
                              attrs: { address: _vm.business.geocoded_address }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.type === _vm.UserEnum.PARTNER
                ? _c(
                    "div",
                    { staticClass: "mp-detail__testimonials" },
                    [
                      _c("TestimonialsSlider", {
                        attrs: { partner: _vm.business }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.type === _vm.UserEnum.COMPANY
                ? _c(
                    "div",
                    { staticClass: "mp-detail__products" },
                    [
                      _c("CompaniesProducts", {
                        attrs: { company: _vm.business }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }