var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.product.images.length
    ? _c("div", { staticClass: "pd__gallery" }, [
        _c("div", { staticClass: "pd__gallery__slider-wrapper" }, [
          _c(
            "div",
            { staticClass: "pd__gallery__slider-ratio-helper" },
            [
              _c(
                "flickity",
                {
                  ref: "flickity",
                  staticClass: "pd__gallery__slider",
                  attrs: { options: _vm.flickityOptions }
                },
                _vm._l(_vm.images, function(image) {
                  return _c("img", {
                    key: image.id,
                    staticClass: "carousel-cell-image",
                    attrs: {
                      "data-flickity-lazyload": image.urls.large,
                      width: "880",
                      height: "880"
                    }
                  })
                }),
                0
              ),
              _vm._v(" "),
              _vm.images.length > 1
                ? _c("div", { staticClass: "pd__gallery__slider-mobile-nav" }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(_vm.currentIndex + 1) +
                          " of " +
                          _vm._s(_vm.images.length)
                      )
                    ])
                  ])
                : _vm._e()
            ],
            1
          )
        ]),
        _vm._v(" "),
        _vm.images.length > 1
          ? _c(
              "div",
              { staticClass: "pd__gallery__nav" },
              _vm._l(_vm.images, function(image, index) {
                return _c(
                  "a",
                  {
                    staticClass: "pd__gallery__nav__item",
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.select(index)
                      }
                    }
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: image.urls.thumb,
                        width: "180",
                        height: "180"
                      }
                    })
                  ]
                )
              }),
              0
            )
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }