<template>
  <div class="business-more-details" :class="classes">
    <div
      v-if="!partner.is_individual && partner.stores_count !== null"
      class="business-more-details__item"
    >
      <IconStores :width="size" :height="size" />
      <span>{{ countString(partner.stores_count, 'locations') }}</span>
    </div>
    <div
      v-if="!partner.is_individual && partner.sections_count !== null"
      class="business-more-details__item"
    >
      <IconSections :width="size" :height="size" />
      <span>{{ countString(partner.sections_count, 'sections') }}</span>
    </div>
    <div v-if="!partner.is_individual" class="business-more-details__item">
      <IconAgent :width="size" :height="size" />
      <span>{{ countString(partner.agents_count, 'agents') }}</span>
    </div>
    <div class="business-more-details__item">
      <IconPartners :width="size" :height="size" />
      <span>{{
        countString(partner.companies_accepted_count, 'connections')
      }}</span>
    </div>
    <div v-if="all && partner.has_wifi" class="business-more-details__item">
      <IconWiFi :width="size" :height="size" />
      <span>Free wi-fi</span>
    </div>
    <div
      v-if="all && partner.is_front_of_house"
      class="business-more-details__item"
    >
      <IconDesk :width="size" :height="size" />
      <span>Front of house</span>
    </div>
  </div>
</template>
<script>
import IconPartners from '~/components/icons/IconPartners';
import IconSections from '~/components/icons/IconSections';
import IconStores from '~/components/icons/IconStores';
import IconAgent from '~/components/icons/IconAgent';
import BusinessMoreDetails from '~/pages/dashboard/marketplace/partials/BusinessMoreDetails';
import IconWiFi from '~/components/icons/IconWiFi';
import IconDesk from '~/components/icons/IconDesk';

export default {
  extends: BusinessMoreDetails,
  components: {
    IconDesk,
    IconWiFi,
    IconPartners,
    IconSections,
    IconStores,
    IconAgent,
  },
  props: {
    partner: {
      type: Object,
      required: true,
    },
  },
};
</script>
