import Vue from 'vue';

import Toasted from 'vue-toasted';

Vue.use(Toasted, {
  iconPack: 'custom-class',
  duration: 5000,
  action: {
    icon: 'toast-close',
    onClick: (e, toastObject) => {
      toastObject.goAway(0);
    },
  },
  className: 'toasted-ply',
  containerClass: 'toasted-container-ply',
});

// Overwrite helpers to add the icon.
const toasted = Vue.toasted;

const oldError = toasted.error;
toasted.error = (message, options) => {
  return oldError(message, {
    ...options,
    // icon: 'error',
  });
};

const oldSuccess = toasted.success;
toasted.success = (message, options) => {
  return oldSuccess(message, {
    ...options,
    // icon: 'check',
  });
};

const oldInfo = toasted.info;
toasted.info = (message, options) => {
  return oldInfo(message, {
    ...options,
    // icon: 'warning',
  });
};

Vue.toasted = Vue.prototype.$toasted = toasted;
