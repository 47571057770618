import Vue from 'vue';
import { debounce } from '~/utils';

const screens = {
  xxs: 0,
  xs: 359,
  sm: 414,
  md: 768,
  lg: 1024,
  xl: 1280,
};

const xxs = (val) => val >= 0 && val <= screens.xs;
const xs = (val) => val >= screens.xxs && val <= screens.sm;
const sm = (val) => val >= screens.sm && val <= screens.md;
const md = (val) => val >= screens.md && val <= screens.lg;
const lg = (val) => val >= screens.lg && val <= screens.xl;
const xl = (val) => val >= screens.xl;

const getBreakpoint = (w) => {
  if (xxs(w)) return 'xxs';
  else if (xs(w)) return 'xs';
  else if (sm(w)) return 'sm';
  else if (md(w)) return 'md';
  else if (lg(w)) return 'lg';
  else if (xl(w)) return 'xl';
  else return 'all';
};

const breakpoints = Vue.observable({
  w: window.innerWidth,
  h: window.innerHeight,
  is: getBreakpoint(window.innerWidth),
});

window.addEventListener(
  'resize',
  debounce(() => {
    breakpoints.w = window.innerWidth;
    breakpoints.h = window.innerHeight;
    breakpoints.is = getBreakpoint(window.innerWidth);
  }, 200),
  false
);

export default breakpoints;
