<template>
  <div v-if="!hidePrev || !hideNext" class="simple-pagination">
    <a
      href="#"
      class="simple-pagination__arrow"
      :class="{ 'simple-pagination__arrow--disabled': hidePrev }"
      @click.prevent="onPrev"
    >
      <IconChevronLeft width="30" height="30" />
    </a>
    <span>Page {{ page }}</span>
    <a
      href="#"
      class="simple-pagination__arrow"
      :class="{ 'simple-pagination__arrow--disabled': hideNext }"
      @click.prevent="onNext"
    >
      <IconChevronRight width="30" height="30" />
    </a>
  </div>
</template>
<script>
import IconChevronLeft from '~/components/icons/IconChevronLeft';
import IconChevronRight from '~/components/icons/IconChevronRight';
export default {
  components: {
    IconChevronRight,
    IconChevronLeft,
  },
  props: {
    page: {
      type: Number,
      required: true,
    },
    isLastPage: {
      type: Boolean,
      required: true,
    },
  },

  computed: {
    hidePrev() {
      return this.page === 1;
    },
    hideNext() {
      return this.isLastPage;
    },
  },
  methods: {
    onPrev() {
      if (this.hidePrev) return;
      this.$emit('prev');
    },
    onNext() {
      if (this.hideNext) return;
      this.$emit('next');
    },
  },
};
</script>
<style lang="scss">
.simple-pagination {
  max-width: 355px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > span {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: rgba($gray-800, 0.5);
  }

  &__arrow {
    padding: 5px;
    color: rgba($gray-800, 0.25);

    @at-root {
      .no-touchevents {
        .simple-pagination > a:hover {
          color: rgba($gray-800, 0.5);
        }
      }
    }

    &--disabled {
      cursor: default;

      svg {
        opacity: 0;
      }
    }
  }
}
</style>
