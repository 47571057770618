<template>
  <LineChart
    ref="lineChart"
    :chart-data="chartData"
    :color="primaryChartColor"
    :options="chartOptions"
    :styles="chartStyles"
  />
</template>
<script>
import LineChart from '~/components/metrics/LineChart';
import { formatMoney } from '~/utils';
import { format, parseISO } from 'date-fns';

export default {
  components: {
    LineChart,
  },
  props: {
    selectedTimeFrame: {
      type: String,
      required: true,
    },
    chartData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      primaryChartColor: process.env.MIX_PLATFORM_PRIMARY_COLOR,
      currency: 'EUR',
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false,
        },
        layout: {
          padding: {
            left: 50,
            right: 50,
            top: 50,
            bottom: 0,
          },
        },
        tooltips: {
          enabled: false,
          custom: (tooltipModel) => {
            let chart = this.$refs.lineChart.getChartObject();
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
              tooltipEl = document.createElement('div');
              tooltipEl.id = 'chartjs-tooltip';
              tooltipEl.innerHTML = '<table></table>';
              document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
              tooltipEl.style.opacity = 0;
              return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
              tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
              tooltipEl.classList.add('no-transform');
            }

            function getBody(bodyItem) {
              return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
              let titleLines = tooltipModel.title || [];
              let bodyLines = tooltipModel.body.map(getBody);

              let innerHtml = '<thead>';

              // values
              let currency = this.currency;
              bodyLines.forEach(function (body, i) {
                if (currency) {
                  body = formatMoney({
                    amount: parseInt(body[0]),
                    currency: currency,
                  });
                }
                innerHtml += '<tr><th>' + body + '</th></tr>';
              });
              innerHtml += '</thead><tbody>';

              // labels
              titleLines.forEach((title) => {
                innerHtml +=
                  '<tr><td>' + this.formatDateLabel(title) + '</td></tr>';
              });
              innerHtml += '</tbody>';

              let tableRoot = tooltipEl.querySelector('table');
              tableRoot.innerHTML = innerHtml;
            }

            // `this` will be the overall tooltip
            let position = chart.canvas.getBoundingClientRect();

            // Display, position, and set styles for font
            tooltipEl.style.opacity = 1;
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left =
              position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top =
              position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.pointerEvents = 'none';
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                padding: 18,
                lineHeight: 1.4,
                fontSize: 12,
                fontWeight: 500,
                fontFamily: 'Inter',
                color: '#1c1d1d',
                // text opacity and top border and color change on hover are not suported by default
                callback: (value, index, values) => {
                  return this.formatDateLabel(value).toUpperCase();
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                display: false,
              },
            },
          ],
        },
      },
      chartStyles: {
        height: '408px',
        width: '100%',
        position: 'relative',
      },
    };
  },
  methods: {
    formatDateLabel(value) {
      switch (this.selectedTimeFrame) {
        case 'ALL':
        case '1Y':
          value = parseISO(value.toString(), 'yyyy-MM');
          return format(value, 'MMM yyyy');
        case '1W':
        case '1M':
          value = parseISO(value.toString(), 'yyyy-MM-dd');
          return format(value, 'MMM d');
        case '24H':
          value = parseISO(value.toString(), 'yyyy-MM-dd HH');
          return format(value, 'HH:mm');
        default:
          return value;
      }
    },
  },
};
</script>
