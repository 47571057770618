var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          var invalid = ref.invalid
          return [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.submit)
                  }
                }
              },
              [
                _c("VInput", {
                  attrs: {
                    name: "VAT Number",
                    vid: "vat_no",
                    placeholder: "Enter VAT Number"
                  },
                  model: {
                    value: _vm.form.vat_no,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "vat_no", $$v)
                    },
                    expression: "form.vat_no"
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("SingleUploader", {
                      attrs: {
                        media: _vm.form.vat_certificate,
                        for: _vm.mediaFor,
                        collection: _vm.MediaCollectionEnum.VAT_CERTIFICATE,
                        "allowed-file-types": ["application/pdf"]
                      },
                      on: { "upload-success": _vm.onVatCertificateChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "preview",
                            fn: function(ref) {
                              var media = ref.media
                              return [
                                !media
                                  ? [
                                      _c(
                                        "svg",
                                        {
                                          staticClass:
                                            "temp-vat-certificate-placeholder",
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            viewBox: "0 0 40 40"
                                          }
                                        },
                                        [
                                          _c("g", [
                                            _c(
                                              "g",
                                              { attrs: { opacity: ".1" } },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M20 36.667c-4.055 0-7.775-1.46-10.668-3.877 1.398-2.638 4.14-4.457 7.335-4.457h6.666c3.195 0 5.937 1.819 7.335 4.457A16.592 16.592 0 0 1 20 36.667zm-6.667-20C13.333 12.985 16.407 10 20 10s6.667 2.985 6.667 6.667v1.666C26.667 22.015 23.593 25 20 25s-6.667-2.985-6.667-6.667zM20 0C8.972 0 0 8.972 0 20s8.972 20 20 20 20-8.972 20-20S31.028 0 20 0z"
                                                  }
                                                })
                                              ]
                                            )
                                          ])
                                        ]
                                      )
                                    ]
                                  : _c("span", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(media.file_name) +
                                          "\n          "
                                      )
                                    ])
                              ]
                            }
                          },
                          {
                            key: "text",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "single-uploader__title" },
                                  [_vm._v("Add VAT Certificate")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "single-uploader__subtitle" },
                                  [_vm._v("PDF | Max 20mb")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "LoadingBtn",
                  {
                    staticClass: "btn",
                    attrs: {
                      loading: _vm.loading,
                      disabled: invalid,
                      type: "submit"
                    }
                  },
                  [_vm._v("\n      Save\n    ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }