<script>
import { ValidationObserver } from 'vee-validate';
import LoadingBtn from '~/components/LoadingBtn';
import Modal from '~/components/Modal';
import VInput from '~/components/form/VInput';
import VTextarea from '~/components/form/VTextarea';
import VSelect from '~/components/form/VSelect';
import IconClose from '~/components/icons/IconClose';
import MediaCollectionEnum from '~/enums/MediaCollectionEnum';
import MediaList from '~/components/MediaList';
import MultiUploader from '~/components/upload/MultiUploader';
import MediaModelEnum from '~/enums/MediaModelEnum';
import VMoney from '~/components/form/VMoney';
import VPercent from '~/components/form/VPercent';
import { majorToMinorUnits, percentageToFraction } from '~/utils';
import ValidationProvider from '~/components/form/ValidationProvider';
import SectorField from '~/components/sectors-fields/SectorField';
import CategoryField from '~/components/sectors-fields/CategoryField';
import { mapState } from 'vuex';

export default {
  components: {
    VPercent,
    VMoney,
    VInput,
    VTextarea,
    LoadingBtn,
    Modal,
    ValidationObserver,
    VSelect,
    IconClose,
    MultiUploader,
    MediaList,
    ValidationProvider,
    SectorField,
    CategoryField,
  },
  data() {
    return {
      MediaCollectionEnum,
      imagesLimit: 8,

      form: {
        company_id: this.$global.userable.id,

        name: '',
        category_id: '',
        description: '',
        features: '',

        weight: '',
        dimensions: '',
        size: '',
        colors: '',
        sku: '',
        initial_stock_count: '',

        price: '',
        delivery_cost: '',
        default_commission_rate: '',
        commission_details: '',

        video_url: '',
        question: '',

        images: [],
      },
      priceInMajorUnits: '',
      deliveryCostInMajorUnits: '',
      defaultCommissionPercentage: '',
      loading: false,
    };
  },

  watch: {
    priceInMajorUnits() {
      this.form.price = majorToMinorUnits(this.priceInMajorUnits);
    },
    deliveryCostInMajorUnits() {
      this.form.delivery_cost = majorToMinorUnits(
        this.deliveryCostInMajorUnits
      );
    },
    defaultCommissionPercentage() {
      this.form.default_commission_rate = percentageToFraction(
        this.defaultCommissionPercentage
      );
    },
  },

  computed: {
    ...mapState('auth', ['user', 'userable']),
    mediaFor() {
      return MediaModelEnum.PRODUCT;
    },
    sectors() {
      return this.userable.sectors;
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    onMediaAdded(file, response) {
      let tempMedia = response.body;
      this.form.images.push(tempMedia);
      this.$refs.form.validate();
    },
    handleRemoveMedia(media) {
      this.form.images = this.form.images.filter(
        (image) => image.id !== media.id
      );
    },
  },
};
</script>
<style lang="scss">
@include media-breakpoint-down(sm) {
}
</style>
