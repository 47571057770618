<template>
  <div class="marketing-page marketing-page-terms-and-conditions">
    <div class="marketing-page__banner">
      <div class="container">
        <h2 class="marketing-page__title">Privacy and Cookies</h2>
      </div>
    </div>
    <div class="marketing-page__content">
      <div class="container">
        <h5>KJB Hair Limited Trading as CaskadePRO</h5>
        <p>
          We need to process your personal information to run our business and
          provide you with our services. By accepting our Terms & Conditions,
          you are confirming that you have read and understand this policy
          including how and why we use your information. If you don’t want us to
          collect or process your personal information in the ways described in
          this policy, you shouldn’t use our services.
        </p>
        <p>
          Your privacy is of the utmost importance to us, and we will never give
          your personal details to any outside company for mailing or marketing
          purposes.
        </p>
        <p>
          When you buy from our website / sales platform, we have to collect
          certain personal information from you (for example: your name, email
          address, payment address and card details). You may review, change, or
          remove that name through your account settings. All this information
          is held on secure servers. KJB Hair Ltd complies fully with all
          applicable Data Protection and consumer legislation, and will treat
          all your personal information as completely confidential.
        </p>
        <p>
          We know that our customers value having control over their own
          information, so KJB Hair Ltd gives you the choice of providing,
          editing or removing certain information. You may change or correct
          your KJB Hair Limited account information through your account
          settings or by contacting us. You can also request the deletion of the
          personal information in your account by emailing
          <a href="mailto:caskade@lanaicreative.com"
            >caskade@lanaicreative.com</a
          >
        </p>
        <p>
          Upon request, KJB Hair Limited will provide you with information about
          whether we hold any of your personal information. In certain cases
          where we process your information, you may also have a right to
          restrict or limit the ways in which we use your personal information.
          In certain circumstances, you also have the right to request the
          deletion of your personal information, and to obtain a copy of your
          personal information in an easily accessible format.
        </p>
        <ol>
          <li>
            You may also have certain rights in regards to your information,
            such as: data access and portability (including the right to obtain
            a copy of your personal data you provided to KJB Hair Limited, by
            contacting us); data correction (including the ability to update
            your personal data, by contacting us)
          </li>
          <li>
            data deletion (including the right to have KJB Hair Limited delete
            your personal information, except information we are required to
            retain, by contacting us)
          </li>
        </ol>
        <p>
          On occasion, KJB Hair Limited may need to contact you. Primarily,
          these messages are delivered by email. Some messages from KJB Hair
          Limited are service-related and necessary for members.
        </p>
        <p>
          You agree that KJB Hair Limited can send you non-marketing emails or
          messages, such as those related to transactions, delivery or security.
        </p>
        <p>
          You can unsubscribe at any time from marketing emails through the
          opt-out link included in all marketing emails.
        </p>
        <p>
          We principally rely on consent to send marketing messages and for
          third-party data sharing related to advertising. You can opt in on
          receiving such emails when you sign up or register with KJB Hair
          Limited.
        </p>
        <p>
          We respect your privacy. KJB Hair Limited will not disclose your name,
          email address or other personal information to third parties without
          your consent, except as specified in this policy.
        </p>
        <p>
          KJB Hair Limited also needs to engage third-party companies and
          individuals (such as companies / brands who you are purchasing
          products from, payment processors, and analytics and security
          providers and courier companies) to help us operate, provide, and
          market the services. These third parties have only limited access to
          your information, may use your information only to perform these tasks
          on our behalf, and are obligated to KJB Hair Limited not to disclose
          or use your information for other purposes.
        </p>
        <p>
          If you no longer want KJB Hair Limited to use your information to
          provide the services to you, you may close your account. KJB Hair
          Limited will retain and use your information to the extent necessary
          to comply with our legal obligations.
        </p>
        <p>
          To give you the best and most efficient service, we use reputable
          third-party banking and distribution institutions to handle our credit
          card transactions and order fulfilment. They receive the information
          needed to verify and authorise your payment card and to process your
          order. All these organisations are under strict obligation to keep
          your personal information private.
        </p>
        <p>
          If you have any further questions in regards to our privacy policy,
          please don’t hesitate to contact us on
          <a href="mailto:caskade@lanaicreative.com"
            >caskade@lanaicreative.com</a
          >
        </p>

        <h5>Security</h5>
        <p>
          We realise how important it is to store any information that you
          provide as securely as possible, and KJB Hair Ltd maintains the
          highest levels of security. Our site uses high-level SSL encryption
          technology, the most advanced security software currently available
          for online transactions.
        </p>
        <p>
          When a page is secure, ‘https’ will replace the ‘http’ at the front of
          the website address in your browser address window. A small locked
          padlock will also appear in the bottom bar of your browser window.
        </p>
        <p>
          Your account information is protected by a password. It is important
          that you protect against unauthorised access to your account and
          information by choosing your password carefully and by keeping your
          password and computer secure, such as by signing out after using the
          Services. If you have any questions about the security of your
          personal information, you can contact us at
          <a href="mailto:caskade@lanaicreative.com"
            >caskade@lanaicreative.com</a
          >
        </p>
        <p>
          Unfortunately, no method of transmission over the internet or method
          of electronic storage is 100% secure. Therefore, while we strive to
          protect your personal information, we can’t guarantee its absolute
          security.
        </p>

        <h5>Cookies</h5>
        <p>
          Cookies are tiny text files stored on your computer when you visit
          certain web pages. KJB Hair LTD uses cookies to keep track of what you
          have in your basket and to remember you when you return to our site.
          To shop at KJB Hair Ltd, you need to have all cookies enabled
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import Page from '~/pages/Page';

export default {
  extends: Page,
};
</script>
<style lang="scss"></style>
