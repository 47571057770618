<script>
import MarketplaceListing from '~/pages/dashboard/marketplace/MarketplaceListing';
import Connection from '~/models/Connection';
import Company from '~/models/Company';
import UserEnum from '~/enums/UserEnum';

export default {
  extends: MarketplaceListing,
  data() {
    return {
      orderBy: '-products_count',
      orderByOptions: [
        // {
        //   value: '-net_total',
        //   label: 'Sort: Most sales',
        // },
        {
          value: '-products_count',
          label: 'Sort: Most products',
        },
        {
          value: '-partners_accepted_count',
          label: 'Sort: Most connections',
        },
      ],
      queryCustomParams: {
        with_net_total: true,
        filter_for_auth_user: true,
      },
      introText: 'Browse and connect with the top brands in the industry',
      type: UserEnum.COMPANY,
      pluralLabel: 'companies',
    };
  },
  methods: {
    getConnectionsBaseQuery() {
      return Connection.where('partner_id', this.userable.id);
    },

    getConnection(business) {
      return this.connections.find((c) => c.company_id === business.id);
    },

    getBaseQuery() {
      return new Company().include(
        'productsCount',
        'partnersAcceptedCount',
        'sectors'
      );
    },
  },
};
</script>
