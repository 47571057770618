var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ButtonDropdown", {
    staticClass: "create-report-dropdown",
    attrs: { items: _vm.options },
    scopedSlots: _vm._u([
      {
        key: "button",
        fn: function(ref) {
          var toggle = ref.toggle
          return [
            _c(
              "LoadingBtn",
              {
                staticClass: "btn btn--white",
                attrs: { loading: _vm.loading },
                on: { click: toggle }
              },
              [_vm._v("\n      Create report\n    ")]
            )
          ]
        }
      },
      {
        key: "items",
        fn: function(ref) {
          var hide = ref.hide
          return _vm._l(_vm.options, function(option) {
            return _c(
              "a",
              {
                key: option.value,
                staticClass: "dropdown__item",
                attrs: {
                  href: _vm.getUrlForReport(option.value),
                  target: "_blank"
                },
                on: {
                  click: function($event) {
                    return hide()
                  }
                }
              },
              [_vm._v("\n      " + _vm._s(option.label) + "\n    ")]
            )
          })
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }