<template>
  <VSelect
    v-model="currentValue"
    name="Select a category"
    vid="category_id"
    value-key="id"
    label-key="name"
    :options="options"
    placeholder="Select..."
    :loading="loading"
  />
</template>
<script>
import Category from '~/models/Category';
import RelatedSectorFieldBase from '~/components/sectors-fields/RelatedSectorFieldBase';
import UserEnum from '~/enums/UserEnum';

export default {
  extends: RelatedSectorFieldBase,

  async created() {
    try {
      this.values = await Category.params({ per_page: 10000 }).$get();
      this.values = this.values.sort((a, b) => a.name.localeCompare(b.name));
      this.afterFetching();
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    filterByAuthCallback(values) {
      if (this.userType === UserEnum.ADMIN) {
        return values;
      }

      let sectorsIds = [];
      if (
        this.userType === UserEnum.COMPANY ||
        this.userType === UserEnum.PARTNER
      ) {
        sectorsIds = this.userable.sectors.map((s) => s.id);
      }

      if (this.userType === UserEnum.AGENT) {
        sectorsIds = this.userable.partner.sectors.map((s) => s.id);
      }

      values = values.filter((s) => sectorsIds.includes(s.sector_id));

      return values;
    },
  },
};
</script>
