<template>
  <AuthBox>
    <h4>Forgot Password</h4>
    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submit)" class="form">
        <VInput
          v-model="form.email"
          name="Email"
          vid="email"
          rules="required|email"
        />
        <LoadingBtn
          :loading="loading"
          :disabled="invalid"
          class="btn btn--lg"
          type="submit"
          >Send Password Reset Link
        </LoadingBtn>
      </form>
    </ValidationObserver>
    <p class="links"><a :href="route('login')">Remembered it?</a></p>
  </AuthBox>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import VInput from '~/components/form/VInput';
import LoadingBtn from '~/components/LoadingBtn';
import AuthBox from '~/pages/auth/AuthBox';

export default {
  components: {
    AuthBox,
    ValidationObserver,
    VInput,
    LoadingBtn,
  },
  data() {
    return {
      loading: false,
      form: null,
    };
  },
  created() {
    this.form = this.getForm();
  },
  methods: {
    async submit() {
      this.loading = true;

      try {
        await this.$axios.get('sanctum/csrf-cookie', {
          baseURL: this.url(),
        });
        await this.$axios.post('auth/password/send-email', this.form);

        this.$toasted.success('Email has been sent. Check your inbox.');
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    getForm() {
      return {
        email: '',
      };
    },
  },
};
</script>
