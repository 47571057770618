<template>
  <a
    :href="getProductUrl(product)"
    :key="product.id"
    class="store-product-card"
  >
    <div class="store-product-card__image">
      <img
        :src="
          product.image ? product.image.urls.medium : getProductPlaceholderImg()
        "
        alt=""
        width="640"
        height="640"
      />
    </div>
    <div class="store-product-card__business-name">
      <VClamp autoresize :max-lines="1">{{
        product.company.business_name
      }}</VClamp>
    </div>
    <div class="store-product-card__name">
      <VClamp autoresize :max-lines="1">{{ product.name }}</VClamp>
    </div>
    <a
      :href="getProductUrl(product)"
      class="btn btn--black store-product-card__btn"
      :class="{ disabled: outOfStock }"
    >
      <template v-if="outOfStock">
        Out of stock
      </template>
      <template v-else>View<span> product</span></template> -
      {{ Price(product.price, this.vatRate).gross().format() }}</a
    >
  </a>
</template>
<script>
import VClamp from 'vue-clamp';
import Price from '~/price';
import { mapGetters } from 'vuex';
import { getProductPlaceholderImg } from '~/utils';

export default {
  components: {
    VClamp,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
    getProductUrl: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      Price,
    };
  },
  computed: {
    ...mapGetters('global', ['vatRate']),
    outOfStock() {
      return this.product.stock_count === 0;
    },
  },
  methods: {
    getProductPlaceholderImg,
  },
};
</script>
<style lang="scss">
.store-product-card {
  width: 100%;

  &__image {
    border: 1px solid $gray-500;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__business-name {
    font-size: 12px;
    color: rgba($gray-800, 0.5);
    font-weight: 500;
    margin-top: $s-3;
    text-align: center;
  }

  &__company-name {
    font-size: 12px;
    font-weight: 500;
    line-height: 8px;
    color: rgba($gray-800, 0.5);
    text-align: center;
  }

  &__name {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: $black;
    text-align: center;
  }

  &__btn {
    width: 100%;
    margin-top: $s-3;
  }
}

@include media-breakpoint-down(sm) {
  .store-product-card {
    &__btn {
      > span {
        display: none;
      }
    }
  }
}
</style>
