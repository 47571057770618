var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Dashboard" } },
    [
      _c("MetricSection", { attrs: { title: "Overview" } }, [
        _c(
          "div",
          { staticClass: "metric-section__tiles" },
          [
            _c("MetricValue", {
              attrs: { title: "Total Sales", endpoint: "sales/partner" }
            }),
            _vm._v(" "),
            _c("MetricValue", {
              attrs: { title: "Commission", endpoint: "commission/partner" }
            }),
            _vm._v(" "),
            _c("MetricValue", {
              attrs: {
                title: "Connections",
                format: "integer",
                endpoint: "companiesCount/partner"
              }
            }),
            _vm._v(" "),
            _c("MetricValue", {
              attrs: {
                title: "Total Orders",
                format: "integer",
                endpoint: "ordersCount/partner"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "MetricSection",
        {
          attrs: {
            title: "Analytics",
            link: { href: _vm.route("partner.sales"), title: "All Sales" }
          }
        },
        [
          _c("MetricTrend", {
            attrs: {
              endpoint: "analytics/partner",
              "partners-endpoint": "availablePartners/partner",
              "has-partner-filter": "",
              "has-category-filter": "",
              options: [
                { label: "Commission", value: "commission" },
                { label: "Agents", value: "agents" }
              ]
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "metric-row" }, [
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              {
                attrs: {
                  title: "Companies",
                  link: {
                    href: _vm.route("partner.companies"),
                    title: "All Companies"
                  }
                }
              },
              [
                _c("MetricPerformance", {
                  attrs: {
                    options: [
                      { label: "Sales", value: "sales" },
                      { label: "Transactions", value: "transactions" }
                    ],
                    endpoint: "topCompanies/partner"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              {
                attrs: {
                  title: "Product Performance",
                  link: {
                    href: _vm.route("partner.products"),
                    title: "All Products"
                  }
                }
              },
              [
                _c("MetricPerformance", {
                  attrs: {
                    options: [
                      { label: "Sales", value: "sales" },
                      { label: "Commission", value: "commission" },
                      { label: "Transactions", value: "transactions" }
                    ],
                    "has-category-filter": "",
                    endpoint: "topProducts/partner"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "metric-row" }, [
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              {
                attrs: {
                  title: "Stock Status",
                  link: {
                    href: _vm.route("partner.products"),
                    title: "All Products"
                  }
                }
              },
              [
                _c("MetricPerformance", {
                  attrs: { "has-time-filter": false, endpoint: "stock/partner" }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              {
                attrs: {
                  title: "Pending Companies",
                  link: {
                    href: _vm.route("partner.companies"),
                    title: "All Companies"
                  }
                }
              },
              [
                _c("MetricPerformance", {
                  attrs: {
                    "has-time-filter": false,
                    endpoint: "connections/partner"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }