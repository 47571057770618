export default class PersistentStorage {
  static set(key, value) {
    return window.localStorage.setItem(key, value);
  }

  static get(key) {
    return window.localStorage.getItem(key);
  }

  static getDrawerStatus() {
    return PersistentStorage.get('drawerStatus') || false;
  }

  static setDrawerStatus(value) {
    return PersistentStorage.set('drawerStatus', value);
  }

  static getLastMarketplaceUrl() {
    return PersistentStorage.get('lastMarketplaceUrl');
  }

  static setLastMarketplaceUrl(url) {
    return PersistentStorage.set('lastMarketplaceUrl', url);
  }

  static getLastCompanyMarketplaceUrl() {
    return PersistentStorage.get('lastCompanyMarketplaceUrl');
  }

  static setLastCompanyMarketplaceUrl(url) {
    return PersistentStorage.set('lastCompanyMarketplaceUrl', url);
  }

  static getCartItems() {
    try {
      return JSON.parse(PersistentStorage.get('cartItems') || '[]');
    } catch (e) {
      return [];
    }
  }

  static setCartItems(items) {
    return PersistentStorage.set('cartItems', JSON.stringify(items));
  }

  static getCustomerData() {
    try {
      return JSON.parse(PersistentStorage.get('customerData') || '[]');
    } catch (e) {
      return {};
    }
  }

  static setCustomerData(customerData) {
    return PersistentStorage.set('customerData', JSON.stringify(customerData));
  }
}
