var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "time-filter" },
    [
      _c(
        "ul",
        { staticClass: "time-options" },
        _vm._l(_vm.options, function(item) {
          return _c(
            "li",
            {
              key: item.value,
              class: { active: item.value === _vm.selected },
              on: {
                click: function($event) {
                  return _vm.setActive(item.value)
                }
              }
            },
            [_vm._v("\n      " + _vm._s(item.label) + "\n    ")]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("VSelect", {
        staticClass: "filter-select",
        attrs: { value: _vm.selected, options: _vm.options },
        on: { input: _vm.setActive }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }