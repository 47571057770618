<script>
import { extend, ValidationProvider } from 'vee-validate';
import {
  required,
  email,
  min,
  max,
  numeric,
  integer,
  confirmed,
  min_value,
  max_value,
} from 'vee-validate/dist/rules';
import url from '~/validation-rules/url';
import decimal from '~/validation-rules/decimal';
import { messages } from 'vee-validate/dist/locale/en.json';
import secure_password from '~/validation-rules/secure_password';

extend('required', {
  ...required,
  message: messages.required,
});
extend('email', {
  ...email,
  message: messages.email,
});
extend('min', {
  ...min,
  message: messages.min,
});
extend('max', {
  ...max,
  message: messages.max,
});
extend('numeric', {
  ...numeric,
  message: messages.numeric,
});
extend('integer', {
  ...integer,
  message: messages.integer,
});
extend('min_value', {
  ...min_value,
  message: messages.min_value,
});
extend('max_value', {
  ...max_value,
  message: messages.max_value,
});
extend('confirmed', {
  ...confirmed,
  message: 'Password confirmation does not match.',
});
extend('min', min);
extend('min_value', min_value);
extend('max_value', max_value);
extend('url', url);

// extend('percentage', {
//   validate: (value) => {
//     return /[0-9]*.?[0-9]*/.test(String(value));
//   },
//   message: 'The {_field_} field must be a valid percentage.',
// });

extend('decimal', decimal);

extend('secure_password', secure_password);

export default ValidationProvider;
</script>
