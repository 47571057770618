var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "layout layout-landing" },
    [
      _c("header", { staticClass: "landing-header" }, [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "landing-header__left-placeholder" }, [
            _vm._v("\n         \n      ")
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "landing-header__menu" },
            [_c("LandingAndMarketingMenu")],
            1
          )
        ])
      ]),
      _vm._v(" "),
      _vm._t("default"),
      _vm._v(" "),
      _c("footer", { staticClass: "landing-footer" }, [
        _c("div", { staticClass: "container" }, [
          _c("a", { attrs: { href: _vm.route("privacy-policy") } }, [
            _vm._v("Privacy policy")
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: _vm.route("terms-and-conditions") } }, [
            _vm._v("Terms and conditions")
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: _vm.route("delivery") } }, [
            _vm._v("Delivery")
          ]),
          _vm._v(" "),
          _c("a", { attrs: { href: _vm.route("returns") } }, [
            _vm._v("Returns")
          ])
        ])
      ]),
      _vm._v(" "),
      _vm.isCas
        ? _c("div", { staticClass: "supported-by" }, [
            _c("div", { staticClass: "container" }, [
              _c("img", { attrs: { src: _vm.url("images/supported-by.png") } })
            ])
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("PortalTarget", { attrs: { name: "modals", multiple: "" } }),
      _vm._v(" "),
      _c("ConfirmModal")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }