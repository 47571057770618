import isDecimal from 'validator/lib/isDecimal';

export default {
  validate: (value, { decimal_digits = 2, locale = 'en-GB' } = {}) => {
    if (value === null || value === undefined || value === '') {
      debugger;
      return false;
    }

    let is = isDecimal(value.toFixed(2), {
      decimal_digits,
      locale,
      force_decimal: false,
    });

    if (!is) {
      debugger;
    }
    return is;
  },
  params: [],
  message:
    'The {_field_} must be a valid number with 2 max decimals of precision.',
};
