<template>
  <div class="approve-or-decline">
    <a href="#" @click.prevent.stop="approve">
      <IconApprove
        v-tooltip="{
          boundariesElement: 'body',
          content: approveString,
        }"
      />
    </a>
    <a href="#" @click.prevent.stop="decline">
      <IconDecline
        v-tooltip="{
          boundariesElement: 'body',
          content: declineString,
        }"
      />
    </a>
  </div>
</template>
<script>
import IconApprove from '~/components/icons/IconApprove';
import IconDecline from '~/components/icons/IconDecline';

export default {
  components: {
    IconApprove,
    IconDecline,
  },
  computed: {
    approveString() {
      return '';
    },
    declineString() {
      return '';
    },
  },
  methods: {
    async approve() {
      // Abstract.
    },
    async decline() {
      // Abstract.
    },
  },
};
</script>
