<template>
  <DashboardPage title="Products">
    <Tabs>
      <Tab name="Live" selected>
        <ProductsTable ref="productsTable">
          <template v-slot:actions>
            <a
              href="#"
              class="btn btn--with-icon"
              @click="showCreateModal = true"
            >
              <IconAdd width="18" height="18" />
              <span>Add new</span>
            </a>
          </template>
          <template v-slot:row-actions="{ item }">
            <a href="#" @click.prevent.stop="updateStock(item._meta)">
              <IconAdd
                v-tooltip="{
                  boundariesElement: 'body',
                  content: 'Update stock amount',
                }"
              />
            </a>
            <a href="#" @click.prevent.stop="edit(item._meta)">
              <IconEdit
                v-tooltip="{
                  boundariesElement: 'body',
                  content: 'Edit product',
                }"
              />
            </a>
            <a href="#" @click.prevent.stop="archive(item._meta)">
              <IconArchive
                v-tooltip="{
                  boundariesElement: 'body',
                  content: 'Archive product',
                }"
              />
            </a>
          </template>
        </ProductsTable>
      </Tab>
      <Tab name="Archived">
        <ProductsTable ref="archivedProductsTable" archived>
          <template v-slot:row-actions="{ item }">
            <a href="#" @click.prevent.stop="edit(item._meta)">
              <IconEdit
                v-tooltip="{
                  boundariesElement: 'body',
                  content: 'Edit product',
                }"
              />
            </a>
            <a
              v-if="item._meta.archived"
              href="#"
              @click.prevent.stop="unarchive(item._meta)"
            >
              <IconUnArchive
                v-tooltip="{
                  boundariesElement: 'body',
                  content: 'Unarchive product',
                }"
              />
            </a>
          </template>
        </ProductsTable>
      </Tab>
    </Tabs>
    <Portal to="modals">
      <CreateProductModal
        v-if="showCreateModal"
        @close="showCreateModal = false"
        @created="handleProductCreated"
      />
      <EditProductModal
        v-if="showEditModal"
        :product-id="productToEditId"
        @close="showEditModal = false"
        @updated="handleProductUpdated"
      />
      <UpdateProductStockModal
        v-if="showStockModal"
        :product="productToUpdateStock"
        @close="showStockModal = false"
        @updated="handleProductStockUpdated"
      />
    </Portal>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Tabs from '~/components/Tabs';
import Tab from '~/components/Tab';
import DashboardPage from '~/components/DashboardPage';
import ProductsTable from '~/pages/dashboard/company/partials/products/ProductsTable';
import Product from '~/models/Product';
import IconArchive from '~/components/icons/IconArchive';
import IconUnArchive from '~/components/icons/IconUnArchive';
import IconAdd from '~/components/icons/IconAdd';
import CreateProductModal from '~/components/product-modal/CreateProductModal';
import EditProductModal from '~/components/product-modal/EditProductModal';
import IconEdit from '~/components/icons/IconEdit';
import UpdateProductStockModal from '~/components/UpdateProductStockModal';

export default {
  extends: Page,
  components: {
    UpdateProductStockModal,
    IconEdit,
    EditProductModal,
    CreateProductModal,
    ProductsTable,
    DashboardPage,
    Tabs,
    Tab,
    IconArchive,
    IconUnArchive,
    IconAdd,
  },
  data() {
    return {
      showCreateModal: false,
      showEditModal: false,
      productToEditId: null,
      showStockModal: false,
      productToUpdateStock: null,
    };
  },
  methods: {
    async updateArchived(product, archived) {
      const { confirmed, stopLoading } = await this.$confirm({
        subtitle: archived
          ? 'Archived products are no longer available for sale on the platform.'
          : 'This product will be available for sale on the platform.',
        withLoading: true,
        danger: true,
      });

      if (!confirmed) {
        return;
      }

      product = new Product(product);
      product.archived = archived;
      await product.saveOnly(['archived']);

      stopLoading();

      await this.$refs.productsTable.refresh();
      await this.$refs.archivedProductsTable.refresh();
    },
    async archive(product) {
      return this.updateArchived(product, true);
    },
    async unarchive(product) {
      return this.updateArchived(product, false);
    },
    async handleProductCreated() {
      await this.$refs.productsTable.refresh();
    },

    edit(product) {
      this.productToEditId = product.id;
      this.showEditModal = true;
    },
    async handleProductUpdated() {
      await this.$refs.productsTable.refresh();
    },

    updateStock(product) {
      this.productToUpdateStock = new Product({
        id: product.id,
        name: product.name,
        stock_count: product.stock_count,
      });
      this.showStockModal = true;
    },
    async handleProductStockUpdated() {
      await this.$refs.productsTable.refresh();
    },
  },
};
</script>
<style lang="scss"></style>
