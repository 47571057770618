var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "performance-metric" },
    [
      _vm.hasTimeFilter
        ? _c("div", { staticClass: "performance-metric__filters" }, [
            _c(
              "div",
              { staticClass: "performance-metric__filters__margin-helper" },
              [
                _c(
                  "div",
                  { staticClass: "filter" },
                  [
                    _vm.options
                      ? _c("VSelect", {
                          staticClass: "filter-select",
                          attrs: { name: "", options: _vm.options },
                          model: {
                            value: _vm.selectedValue,
                            callback: function($$v) {
                              _vm.selectedValue = $$v
                            },
                            expression: "selectedValue"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.hasCategoryFilter
                  ? _c(
                      "div",
                      { staticClass: "filter" },
                      [
                        _c("CategoryFilter", {
                          attrs: {
                            "filter-by-auth": "",
                            "filter-select": "",
                            "no-label": ""
                          },
                          model: {
                            value: _vm.selectedCategory,
                            callback: function($$v) {
                              _vm.selectedCategory = $$v
                            },
                            expression: "selectedCategory"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.hasTimeFilter
                  ? _c("TimeFilter", {
                      attrs: { selected: _vm.selectedTimeFrame },
                      on: { input: _vm.changeTimeFilter }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "performance-metric__list" }, [
        _c("div", { staticClass: "performance-metric__fixed-header-bg" }),
        _vm._v(" "),
        _c("div", { staticClass: "performance-metric__scroll" }, [
          _c("table", [
            _c(
              "thead",
              _vm._l(_vm.columns, function(column) {
                return _c("th", { key: column.label }, [
                  _c("span", { staticClass: "text" }, [
                    _vm._v(_vm._s(column.label))
                  ])
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c(
              "tbody",
              [
                _vm._l(_vm.values, function(row) {
                  return _c(
                    "tr",
                    { key: row.id },
                    _vm._l(_vm.columns, function(column) {
                      return _c("td", { key: row.id + column.label }, [
                        column.has_logo
                          ? _c(
                              "span",
                              { staticClass: "description-column" },
                              [
                                _c("UserLogo", {
                                  attrs: {
                                    logo: row[column.logo]
                                      ? row[column.logo]
                                      : row.logo,
                                    colour: row[column.logoColour]
                                      ? row[column.logoColour]
                                      : row.logo_colour,
                                    "business-name": row[column.value],
                                    size: 48
                                  }
                                }),
                                _vm._v(" "),
                                column.is_link
                                  ? _c("span", [
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: _vm.route(
                                              column.route,
                                              row[column.routeValue]
                                            )
                                          }
                                        },
                                        [_vm._v(_vm._s(row[column.value]))]
                                      )
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(row[column.value]) +
                                          "\n                "
                                      )
                                    ])
                              ],
                              1
                            )
                          : column.is_stock
                          ? _c(
                              "span",
                              [
                                _c("StockStatusBadge", {
                                  attrs: { count: row[column.value] }
                                })
                              ],
                              1
                            )
                          : column.is_stock_update
                          ? _c(
                              "span",
                              { staticClass: "edit-column" },
                              [
                                _c("IconAdd", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip",
                                      value: {
                                        boundariesElement: "body",
                                        content: "Update stock count"
                                      },
                                      expression:
                                        "{\n                    boundariesElement: 'body',\n                    content: 'Update stock count',\n                  }"
                                    }
                                  ],
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.updateProductStock(row)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : column.is_connection
                          ? _c(
                              "span",
                              { staticClass: "update-connection-status-cell" },
                              [
                                _c("UpdateConnectionStatus", {
                                  attrs: {
                                    connection: _vm.connectionFromRow(row)
                                  },
                                  on: { updated: _vm.sync }
                                })
                              ],
                              1
                            )
                          : column.is_button
                          ? _c(
                              "a",
                              {
                                staticClass: "btn btn--secondary",
                                attrs: {
                                  href: _vm.route(
                                    column.route,
                                    row[column.value]
                                  )
                                }
                              },
                              [_vm._v("\n                View\n              ")]
                            )
                          : _c("span", [
                              _vm._v(" " + _vm._s(_vm.displayRow(row, column)))
                            ])
                      ])
                    }),
                    0
                  )
                }),
                _vm._v(" "),
                !_vm.values.length
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: _vm.columnsCount } }, [
                        _c("span", [_vm._v("No data available")])
                      ])
                    ])
                  : _vm._e()
              ],
              2
            )
          ])
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading"
              }
            ],
            staticClass: "performance-metric__loader-placeholder"
          },
          [
            _c("div", {
              staticClass: "performance-metric__loading",
              class: { "performance-metric--skeleton": _vm.loading }
            })
          ]
        )
      ]),
      _vm._v(" "),
      _vm.showStockModal
        ? _c("UpdateProductStockModal", {
            attrs: { product: _vm.productToUpdateStock },
            on: {
              close: function($event) {
                _vm.showStockModal = false
              },
              updated: _vm.handleProductStockUpdated
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }