<template>
  <Transition
    v-on:after-leave="afterTransitionEnds"
    name="modal-transition"
    appear
  >
    <div class="modal" :class="`modal--${size}`">
      <div class="modal__dialog">
        <div class="modal__content">
          <slot />
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import composedPath from '~/polyfills/composedPath';

const ClassName = {
  OPEN: 'modal-open',
};

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    classWhitelist: [Array, String],
    size: {
      type: String,
      required: false,
      default: 'md',
    },
  },

  mounted() {
    document.addEventListener('keydown', this.handleEscape);

    document.body.classList.add(ClassName.OPEN);
    disableBodyScroll(this.$el, {
      reserveScrollBarGap: true,
    });
  },

  beforeDestroy() {
    enableBodyScroll(this.$el);
  },

  destroyed() {
    document.removeEventListener('keydown', this.handleEscape);
  },

  methods: {
    handleEscape(e) {
      e.stopPropagation();

      if (e.keyCode === 27) {
        this.close();
      }
    },

    back() {
      this.close();
    },

    close(e) {
      const classArray = Array.isArray(this.classWhitelist)
        ? this.classWhitelist
        : [this.classWhitelist];

      if (
        e &&
        classArray.filter((className) => pathIncludesClass(e, className))
          .length > 0
      ) {
        return;
      }

      this.$emit('modal-close', e);
    },

    afterTransitionEnds() {
      document.body.classList.remove(ClassName.OPEN);
    },
  },
};

function pathIncludesClass(event, className) {
  return composedPath(event)
    .filter((el) => el !== document && el !== window)
    .reduce((acc, e) => acc.concat([...e.classList]), [])
    .includes(className);
}
</script>
<style lang="scss">
.modal-open {
  .modal {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.modal {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: $z-index-modal;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  -webkit-overflow-scrolling: touch;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  background-color: rgba(#000, 0.5);

  &__dialog {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 92%;
    padding: 0;
    margin: 0;
    pointer-events: none;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%; // Ensure `.modal-content` extends the full width of the parent `.modal-dialog`
    // counteract the pointer-events: none; in the .modal-dialog
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    // Remove focus outline from opened modal
    outline: 0;

    overflow: hidden;
    min-height: 100%;
  }

  &__header {
    overflow: hidden;
    border-bottom: 1px solid $gray-500;
    display: flex;
    align-items: center;
    padding: 20px 15px;
    margin: 0;
  }

  &__title {
    margin-top: 0;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    text-align: left;
    padding-right: $s-4;
  }

  &__close {
    background-color: transparent;
    color: $gray-700;
    padding: 0;
    line-height: $text-2xl;
    z-index: 2;
    transition: 0.15s ease-out;
    margin-left: auto;

    i {
      font-size: $text-2xl;
    }

    @at-root {
      .no-touchevents {
        .modal__close:hover {
          color: $gray-900;
        }
      }
    }
  }

  &__title {
    margin-bottom: 0;
  }

  &__body {
    position: relative;
    // Enable `flex-grow: 1` so that the body take up as much space as possible
    // when should there be a fixed height on `.modal-dialog`.
    flex: 1 1 auto;
    padding: 15px;

    &__loader {
      margin: 60px auto;
    }
  }

  &__footer {
    display: flex;
    align-items: center; // vertically center
    justify-content: flex-end; // Right align buttons with flex property because text-align doesn't work on flex items

    margin-top: 20px;
    padding: 0;
    border: 0;

    // Easily place margin between footer elements
    > :not(:first-child) {
      margin-left: 0.25rem;
    }

    > :not(:last-child) {
      margin-right: 0.25rem;
    }

    a.button,
    button {
      margin-left: 20px;

      &.btn-block {
        margin-left: 0;
      }
    }
  }

  &__btn-wrapper {
    display: flex;
    justify-content: center;
  }
}

@include media-breakpoint-up(md) {
  .modal {
    &__header {
      padding: 22px 22px 22px 32px;
    }

    &__content {
      border: 1px solid $gray-500;
    }

    &__body {
      padding: $modal-body-padding;
    }
  }
}
// Scale up the modal
@include media-breakpoint-up(md) {
  .modal {
    &__dialog {
      margin: 60px auto;
      height: auto;
      position: relative;
      bottom: auto;
      left: auto;
    }

    &--sm {
      .modal__dialog {
        max-width: 392px;
      }
    }

    &--md {
      .modal__dialog {
        max-width: 736px;
      }
    }

    &--lg {
      .modal__dialog {
        max-width: 1000px;
      }
    }

    &__content {
      min-height: auto;
      border-radius: $border-radius-lg;
    }
  }
}

@include media-breakpoint-down(md) {
  .modal-transition-enter-active,
  .modal-transition-leave-active {
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
  }

  .modal-transition-enter,
  .modal-transition-leave-to {
    transform: translateY(100%);
    opacity: 0;
  }
}

// Scale up the modal
@include media-breakpoint-up(md) {
  .modal-transition-enter-active,
  .modal-transition-leave-active {
    transition: opacity 0.15s ease-out;
  }

  .modal-transition-enter,
  .modal-transition-leave-to {
    opacity: 0;
  }
}
</style>
