var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "gallery", class: "gallery--" + _vm.countClass + "-images" },
    [
      _vm.images[0]
        ? _c(
            "div",
            {
              staticClass: "gallery__img-wrapper gallery__img-wrapper--first",
              on: {
                click: function($event) {
                  return _vm.openLightbox($event, 1)
                }
              }
            },
            [
              _c("div", {
                staticClass: "gallery__img",
                style: _vm.getStyle(_vm.images[0])
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.images[1]
        ? _c(
            "div",
            {
              staticClass: "gallery__img-wrapper gallery__img-wrapper--second",
              on: {
                click: function($event) {
                  return _vm.openLightbox($event, 2)
                }
              }
            },
            [
              _c("div", {
                staticClass: "gallery__img",
                style: _vm.getStyle(_vm.images[1])
              })
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.images[2]
        ? _c(
            "div",
            {
              staticClass: "gallery__img-wrapper gallery__img-wrapper--third",
              on: {
                click: function($event) {
                  return _vm.openLightbox($event, 3)
                }
              }
            },
            [
              _c("div", {
                staticClass: "gallery__img",
                style: _vm.getStyle(_vm.images[2])
              })
            ]
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }