<template>
  <DashboardPage title="Companies">
    <Tabs>
      <Tab name="Live" selected>
        <CompaniesTable
          ref="liveCompaniesTable"
          :approval="ApprovalEnum.APPROVED"
        />
      </Tab>
      <Tab name="Pending">
        <CompaniesTable
          ref="pendingCompaniesTable"
          :approval="ApprovalEnum.PENDING"
        >
          <template v-slot:row-actions="{ item }">
            <ApproveBusiness
              :business-type="UserEnum.COMPANY"
              :business="getModelFromObject(item._meta)"
              @updated="onUpdated"
            />
          </template>
        </CompaniesTable>
      </Tab>
      <Tab name="Declined">
        <CompaniesTable
          ref="declinedCompaniesTable"
          :approval="ApprovalEnum.DECLINED"
        />
      </Tab>
    </Tabs>
  </DashboardPage>
</template>
<script>
import UserEnum from '~/enums/UserEnum';
import Page from '~/pages/Page';
import DashboardPage from '~/components/DashboardPage';
import Tabs from '~/components/Tabs';
import Tab from '~/components/Tab';
import CompaniesTable from '~/pages/dashboard/admin/partials/CompaniesTable';
import ApproveBusiness from '~/components/ApproveBusiness';
import Company from '~/models/Company';
import ApprovalEnum from '~/enums/ApprovalEnum';

export default {
  extends: Page,
  components: {
    ApproveBusiness,
    CompaniesTable,
    DashboardPage,
    Tabs,
    Tab,
  },
  data() {
    return {
      UserEnum,
      ApprovalEnum,
    };
  },
  methods: {
    getModelFromObject(item) {
      return new Company(item);
    },
    async onUpdated() {
      await this.$refs.pendingCompaniesTable.refresh();
      await this.$refs.liveCompaniesTable.refresh();
      await this.$refs.declinedCompaniesTable.refresh();
    },
  },
};
</script>
