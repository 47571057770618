var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "trend-metric" }, [
    _c("div", { staticClass: "trend-metric__filters" }, [
      _c(
        "div",
        { staticClass: "trend-metric__filters__margin-helper" },
        [
          _vm.options.length > 1
            ? _c(
                "div",
                { staticClass: "filter" },
                [
                  _c("VSelect", {
                    staticClass: "filter-select",
                    attrs: { options: _vm.options, "no-label": "" },
                    model: {
                      value: _vm.selectedValue,
                      callback: function($$v) {
                        _vm.selectedValue = $$v
                      },
                      expression: "selectedValue"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasServiceFilter && _vm.hasFeatureServices()
            ? _c(
                "div",
                { staticClass: "filter" },
                [
                  _c("ServiceFilter", {
                    attrs: {
                      "filter-by-auth": "",
                      "filter-select": "",
                      "no-label": ""
                    },
                    model: {
                      value: _vm.selectedService,
                      callback: function($$v) {
                        _vm.selectedService = $$v
                      },
                      expression: "selectedService"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasPartnerFilter
            ? _c(
                "div",
                { staticClass: "filter" },
                [
                  _c("VSelect", {
                    staticClass: "filter-select",
                    attrs: {
                      loading: _vm.loadingPartners,
                      options: _vm.partnerOptions,
                      "no-label": ""
                    },
                    model: {
                      value: _vm.selectedPartner,
                      callback: function($$v) {
                        _vm.selectedPartner = $$v
                      },
                      expression: "selectedPartner"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasCategoryFilter
            ? _c(
                "div",
                { staticClass: "filter" },
                [
                  _c("CategoryFilter", {
                    attrs: {
                      "filter-by-auth": "",
                      "filter-select": "",
                      "no-label": ""
                    },
                    model: {
                      value: _vm.selectedCategory,
                      callback: function($$v) {
                        _vm.selectedCategory = $$v
                      },
                      expression: "selectedCategory"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasProductFilter
            ? _c(
                "div",
                { staticClass: "filter" },
                [
                  _c("VSelect", {
                    staticClass: "filter-select",
                    attrs: {
                      loading: _vm.loadingProducts,
                      options: _vm.productOptions,
                      "no-label": ""
                    },
                    model: {
                      value: _vm.selectedProduct,
                      callback: function($$v) {
                        _vm.selectedProduct = $$v
                      },
                      expression: "selectedProduct"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("TimeFilter", {
            attrs: { selected: _vm.selectedTimeFrame },
            on: { input: _vm.changeTimeFilter }
          })
        ],
        1
      )
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "trend-metric__chart" },
      [
        !_vm.loading
          ? _c("OurLineChart", {
              attrs: {
                "chart-data": _vm.chartData,
                "selected-time-frame": _vm.selectedTimeFrame
              }
            })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "trend-metric__loader-placeholder"
      },
      [
        _c("div", {
          staticClass: "trend-metric__loading",
          class: { "trend-metric--skeleton": _vm.loading }
        })
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }