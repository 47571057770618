<script>
import InputMixin from '~/components/form/InputMixin';
import VSelect from '~/components/form/VSelect';
import { mapGetters, mapState } from 'vuex';
import UserEnum from '~/enums/UserEnum';

export default {
  mixins: [InputMixin],
  components: {
    VSelect,
  },

  props: {
    sectorId: {
      validator: (prop) =>
        typeof prop === 'number' || prop === null || prop === '',
      required: false,
      default: null,
    },
    onlySectorsIds: {
      type: Array,
      required: false,
      default: () => [],
    },
    filterByAuth: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      values: [],
      loading: true,
    };
  },
  computed: {
    ...mapState('auth', ['user', 'userable']),
    ...mapGetters('auth', ['userType']),
    options() {
      let values = this.values;
      if (this.onlySectorsIds.length) {
        values = values.filter((c) =>
          this.onlySectorsIds.includes(c.sector_id)
        );
      }

      if (this.sectorId) {
        values = values.filter((c) => c.sector_id === this.sectorId);
      }

      if (this.filterByAuth) {
        values = this.filterByAuthCallback(values);
      }

      return values;
    },
    selectedOption() {
      return this.options.find((o) => o.id === this.currentValue);
    },
  },
  watch: {
    currentValue(value) {
      if (this.selectedOption) {
        this.$emit('update:sectorId', this.selectedOption.sector_id);
      }
    },
    sectorId() {
      if (!this.sectorId) {
        return;
      }

      let currentlySelectedOptionBelongsToSector = this.options.find(
        (o) => o.id === this.currentValue
      );

      // Reset the category.
      if (!currentlySelectedOptionBelongsToSector) {
        this.currentValue = '';
      }
    },
  },
  methods: {
    afterFetching() {
      if (this.selectedOption) {
        this.$emit('update:sectorId', this.selectedOption.sector_id);
      }
    },
    filterByAuthCallback(values) {
      return values;
    },
  },
};
</script>
