<template>
  <div class="mp__breadcrumbs">
    <a :href="marketplaceUrl">Marketplace</a>
    <IconDropRight width="18" height="18" />
    <slot></slot>
  </div>
</template>
<script>
import IconDropRight from '~/components/icons/IconDropRight';
import PersistentStorage from '~/persistent-storage';

export default {
  components: {
    IconDropRight,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  computed: {
    marketplaceUrl() {
      let url = PersistentStorage.getLastMarketplaceUrl();

      if (!url) {
        url = this.route(`marketplace.${this.type}.index`).url();
      }

      return url;
    },
  },
};
</script>
<style lang="scss">
.mp__breadcrumbs {
  margin-bottom: $s-12;
  color: rgba($black, 0.25);
  font-size: 18px;
  display: flex;
  align-items: center;
  transition: color 0.15s ease-out;

  svg {
    margin: 0 $s-2;
  }

  @at-root {
    .no-touchevents {
      .mp__breadcrumbs a:hover {
        color: rgba($black, 0.5);
      }
    }
  }
}
@include media-breakpoint-down(lg) {
  .mp__breadcrumbs {
    background-color: $white;
    margin: 0 -#{$container-padding} $s-6;
    padding: $s-4 $container-padding;
    border-bottom: 1px solid $gray-500;
  }
}
</style>
