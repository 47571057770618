<template>
  <div class="landing-page">
    <div
      class="landing-page__banner"
      :class="{
        'landing-page__banner--no-search':
          MIX_PLATFORM_FEATURES_CODE_LOOKUP === '0',
      }"
    >
      <div class="container">
        <LogoWhite />
        <h4 class="landing-page__tagline">Professional affiliate platform</h4>
        <div
          v-if="MIX_PLATFORM_FEATURES_CODE_LOOKUP !== '0'"
          class="landing-page__search"
        >
          <SearchByMarketplaceId />
        </div>
      </div>
    </div>
    <div class="landing-page__join">
      <div class="container">
        <h4 class="landing-page__join__title">
          Join our platform today and monetise the power of your professional
          recommendations
        </h4>
        <template v-if="user">
          <a
            :href="route('dashboard')"
            class="btn btn--lg btn--transparent-white-button"
            >Dashboard</a
          >
        </template>
        <template v-else>
          <a
            href="#"
            class="btn btn--lg btn--transparent-white-button"
            @click.prevent="showRegisterModal = true"
            >Get started</a
          >
        </template>
      </div>
    </div>
    <Portal to="modals">
      <RegisterModal
        v-if="showRegisterModal"
        @close="showRegisterModal = false"
      />
    </Portal>
  </div>
</template>
<script>
import Page from '~/pages/Page';
import LogoWhite from '~/components/LogoWhite';
import { mapState } from 'vuex';
import SearchByMarketplaceId from '~/components/SearchByMarketplaceId';
import RegisterModal from '~/pages/auth/RegisterModal';

export default {
  extends: Page,
  components: {
    LogoWhite,
    SearchByMarketplaceId,
    RegisterModal,
  },
  data() {
    return {
      showRegisterModal: false,
      MIX_PLATFORM_FEATURES_CODE_LOOKUP:
        process.env.MIX_PLATFORM_FEATURES_CODE_LOOKUP,
    };
  },
  computed: {
    ...mapState('auth', ['user']),
  },
};
</script>
<style lang="scss">
.landing-page {
  &__banner {
    text-align: center;
    padding-top: $s-20;
    padding-bottom: $s-24;

    .logo {
      max-width: 353px;
      margin: 0 auto;
    }

    &--no-search {
      padding-top: $s-24;
      padding-bottom: $s-32;
    }
  }

  &__tagline {
    text-align: center;
    margin-top: $s-10;
    color: rgba($white, 0.6);
    font-size: 22px;
    letter-spacing: 0.4px;
    text-transform: uppercase;
    font-weight: 300;
  }

  &__search {
    margin-top: $s-16;
  }

  &__join {
    background-color: rgba($black, 0.05);
    padding: $s-16 0;
    text-align: center;

    &__title {
      color: $white;
      font-size: 36px;
      line-height: 44px;
      font-weight: 300;
      max-width: 764px;
      margin: 0 auto $s-8;
      text-align: center;
    }
  }
}
</style>
