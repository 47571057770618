<template>
  <Modal @modal-close="handleClose">
    <div class="modal__header">
      <h3 class="modal__title">{{ isEdit ? 'Edit' : 'Add' }} agent</h3>
      <button type="button" class="modal__close" @click="handleClose">
        <IconClose />
      </button>
    </div>
    <div class="modal__body">
      <Loader class="modal__body__loader" v-if="loading" />
      <ValidationObserver v-else ref="form" v-slot="{ handleSubmit, invalid }">
        <form class="form" @submit.prevent="handleSubmit(save)">
          <div class="form-group">
            <SingleUploader
              :media="form.user.avatar"
              :for="MediaModelEnum.USER"
              :collection="MediaCollectionEnum.AVATAR"
              @upload-success="onAvatarChange"
            >
              <template v-slot:preview="{ media }">
                <UserAvatar :avatar="media" :size="64" />
              </template>
              <template v-slot:text>
                <span class="single-uploader__title">Change avatar</span>
                <span class="single-uploader__subtitle">Max size 20mb</span>
              </template>
            </SingleUploader>
          </div>
          <div class="form-2-col">
            <VInput
              v-model="form.user.name"
              name="Name"
              vid="user.name"
              rules="required"
              label-info-text="- add your agent’s name"
            />
            <VInput
              v-model="form.user.email"
              name="Email"
              vid="user.email"
              rules="required|email"
              label-info-text="- add your agent’s email"
            />
          </div>
          <div class="form-2-col">
            <VInput
              v-model="form.user.password"
              class="form-group"
              :name="isEdit ? 'New Password' : 'Password'"
              vid="user.password"
              :rules="passwordRules"
              type="password"
              label-info-text="- set your agent’s password"
            />
            <VInput
              v-model="form.contact_number"
              name="Phone Number"
              vid="contact_number"
              rules="required"
              label-info-text="- set agent’s mobile phone number"
            />
          </div>
          <div class="form-2-col">
            <VSelect
              v-model="form.is_employee"
              name="Is this agent an employee?"
              vid="is_employee"
              :options="yesNoOptions"
              placeholder="Choose option"
              rules="required"
            />
            <VInput
              v-model="form.vat_no"
              name="VAT registration number"
              vid="vat_no"
              :disabled="form.is_employee === true"
              info-text="If set, this agent will not be charged VAT on commission"
            />
          </div>
          <div class="form-2-col">
            <VPercent
              v-model="commissionRatePercentage"
              name="Commission rate (%)"
              vid="commission_rate"
              rules="required|decimal|max_value:100|min_value:0"
              info-text="Set a unique commission rate for all sales by this agent"
            />
          </div>
          <div class="modal__btn-wrapper">
            <LoadingBtn
              :loading="saving"
              :disabled="invalid"
              class="btn"
              type="submit"
            >
              {{ isEdit ? 'Update' : 'Create' }} agent
            </LoadingBtn>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </Modal>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import LoadingBtn from '~/components/LoadingBtn';
import Modal from '~/components/Modal';
import VInput from '~/components/form/VInput';
import VSelect from '~/components/form/VSelect';
import IconClose from '~/components/icons/IconClose';
import VPercent from '~/components/form/VPercent';
import { cloneDeep, fractionToPercentage, percentageToFraction } from '~/utils';
import SingleUploader from '~/components/upload/SingleUploader';
import MediaModelEnum from '~/enums/MediaModelEnum';
import MediaCollectionEnum from '~/enums/MediaCollectionEnum';
import UserAvatar from '~/components/UserAvatar';
import Agent from '~/models/Agent';
import Loader from '~/components/Loader';

export default {
  components: {
    VInput,
    LoadingBtn,
    Modal,
    ValidationObserver,
    VSelect,
    IconClose,
    VPercent,
    UserAvatar,
    SingleUploader,
    Loader,
  },
  props: {
    modelId: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      model: {},
      form: null,
      loading: true,
      saving: false,
      yesNoOptions: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
      MediaModelEnum,
      MediaCollectionEnum,
    };
  },

  computed: {
    isEdit() {
      return this.modelId;
    },
    commissionRatePercentage: {
      get() {
        return fractionToPercentage(this.form.commission_rate || 0);
      },
      set(value) {
        this.form.commission_rate = percentageToFraction(value);
      },
    },
    passwordRules() {
      let rules = ['secure_password', 'min:8'];

      if (!this.isEdit) {
        rules.push('required');
      }

      return rules.join('|');
    },
  },

  async created() {
    this.loading = true;

    try {
      if (this.isEdit) {
        this.model = await this.fetch();
      }
      this.form = this.getForm(this.model);
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    getForm(model) {
      return {
        user: {
          avatar: model.user?.avatar || null,
          name: model.user?.name || '',
          email: model.user?.email || '',
          password: '',
        },
        contact_number: model.contact_number || '',
        is_employee: model.is_employee === undefined ? '' : model.is_employee,
        vat_no: model.vat_no || '',
        commission_rate: model.commission_rate || 0,
      };
    },

    fetch() {
      return Agent.include('user').find(this.modelId);
    },

    async save() {
      this.saving = true;

      try {
        if (this.isEdit) {
          await this.update();
        } else {
          await this.create();
        }

        this.$toasted.success(this.isEdit ? 'Agent updated!' : 'Agent added!');
        this.$emit('saved');
        this.handleClose();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.saving = false;
      }
    },

    update() {
      let attributes = cloneDeep(this.form);
      let originalAttributes = this.model;

      // Don't send email if it's the same to avoid the unique validation error.
      if (attributes.user.email === originalAttributes.user.email) {
        delete attributes.user.email;
      }

      // Delete the password field if empty to avoid server side validation.
      if (!attributes.user.password) {
        delete attributes.user.password;
      }

      return new Agent({
        ...attributes,
        id: this.modelId,
      }).save();
    },

    create() {
      return new Agent({
        ...this.form,
        partner_id: this.$global.userable.id,
      }).save();
    },

    onAvatarChange(file, response) {
      this.form.user.avatar = response.body;
    },

    handleClose() {
      this.$emit('close');
    },
  },
};
</script>
<style lang="scss"></style>
