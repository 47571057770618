<template>
  <ButtonDropdown class="create-report-dropdown" :items="options">
    <template v-slot:button="{ toggle }">
      <LoadingBtn :loading="loading" class="btn btn--white" @click="toggle">
        Create report
      </LoadingBtn>
    </template>
    <template v-slot:items="{ hide }">
      <a
        v-for="option in options"
        :key="option.value"
        :href="getUrlForReport(option.value)"
        class="dropdown__item"
        target="_blank"
        @click="hide()"
      >
        {{ option.label }}
      </a>
    </template>
  </ButtonDropdown>
</template>
<script>
import LoadingBtn from '~/components/LoadingBtn';
import ButtonDropdown from '~/components/ButtonDropdown';

export default {
  components: {
    ButtonDropdown,
    LoadingBtn,
  },
  props: {
    getRequest: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      options: [
        {
          label: 'PDF',
          value: 'pdf',
        },
        {
          label: 'Excel',
          value: 'xlsx',
        },
        {
          label: 'CSV',
          value: 'csv',
        },
      ],
    };
  },
  methods: {
    getUrlForReport(type) {
      return this.getRequest({
        export_as: type,
      }).getUrl();
    },
  },
};
</script>
<style lang="scss">
.create-report-dropdown {
  margin-left: $s-4;
}
@include media-breakpoint-down(md) {
  .create-report-dropdown {
    display: none;
  }
}
</style>
