var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Companies" } },
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            { attrs: { name: "Live", selected: "" } },
            [
              _c("CompaniesTable", {
                ref: "liveCompaniesTable",
                attrs: { approval: _vm.ApprovalEnum.APPROVED }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Pending" } },
            [
              _c("CompaniesTable", {
                ref: "pendingCompaniesTable",
                attrs: { approval: _vm.ApprovalEnum.PENDING },
                scopedSlots: _vm._u([
                  {
                    key: "row-actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c("ApproveBusiness", {
                          attrs: {
                            "business-type": _vm.UserEnum.COMPANY,
                            business: _vm.getModelFromObject(item._meta)
                          },
                          on: { updated: _vm.onUpdated }
                        })
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Declined" } },
            [
              _c("CompaniesTable", {
                ref: "declinedCompaniesTable",
                attrs: { approval: _vm.ApprovalEnum.DECLINED }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }