<template>
  <DashboardPage>
    <div class="mp-detail">
      <Loader v-if="loading" />
      <template v-else>
        <MarketplaceBreadcrumbs
          :type="type === UserEnum.PARTNER ? 'partners' : 'companies'"
        >
          <span>{{ business.business_name }}</span>
        </MarketplaceBreadcrumbs>
        <div class="mp-detail__top">
          <div class="mp-detail__logo-and-info">
            <div class="mp-detail__logo">
              <UserLogo
                :logo="business.logo"
                :colour="business.logo_colour"
                :business-name="business.business_name"
                :size="200"
              />
            </div>
            <div class="mp-detail__info">
              <div class="mp-detail__title">{{ business.business_name }}</div>
              <div class="mp-detail__tagline">{{ business.tagline }}</div>
              <template v-if="!hideConnectButton">
                <div class="mp-detail__connection-wrapper">
                  <a
                    v-if="!connection"
                    href="#"
                    class="btn btn--white mp-detail__connection"
                    @click.prevent="connect"
                  >
                    <IconConnect width="16" height="16" />
                    <span>Connect</span>
                  </a>
                  <div
                    v-else
                    class="mp-detail__connection"
                    :class="`mp-detail__connection--${connection.statusClassName()}`"
                  >
                    <IconConnect width="16" height="16" />
                    <span>{{ connection.statusLabel() }}</span>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="mp-detail__about mp-detail__about--mobile">
            <h5>About</h5>
            <VClamp autoresize :max-lines="4">
              {{ business.about }}
              <template v-slot:after="{ toggle, expanded }">
                <a
                  href="#"
                  class="mp-detail__about__read-more"
                  :class="{
                    'mp-detail__about__read-more--expanded': expanded,
                  }"
                  @click.prevent="toggle"
                  >Read {{ expanded ? 'less' : 'more' }}</a
                >
              </template>
            </VClamp>
          </div>
          <div class="mp-detail__more-details">
            <PartnerMoreDetails
              v-if="type === UserEnum.PARTNER"
              :partner="business"
              big
              all
            />
            <CompanyMoreDetails
              v-if="type === UserEnum.COMPANY"
              :company="business"
              big
              all
            />
          </div>
        </div>
        <div class="mp-detail__about-and-extra-details">
          <div class="mp-detail__about mp-detail__about--desktop">
            <h5>About</h5>
            <TextareaText>{{ business.about }}</TextareaText>
          </div>
          <div class="mp-detail__extra-details-and-socials">
            <div class="mp-detail__extra-details">
              <span v-if="business.owner_name" class="mp-detail__extra-detail">
                <IconOwner />
                <span>{{ business.owner_name }}</span>
              </span>
              <a
                v-if="business.geocoded_address"
                :href="business.geocoded_address.maps_url"
                rel="noopener noreferrer nofollow"
                target="_blank"
                class="mp-detail__extra-detail"
              >
                <IconAddress />
                <span>{{ business.place_description }}</span>
              </a>
              <a
                v-if="business.contact_number"
                :href="`tel:${business.contact_number}`"
                class="mp-detail__extra-detail"
              >
                <IconTelephone />
                <span>{{ business.contact_number }}</span>
              </a>
              <a
                v-if="business.website"
                :href="business.website"
                rel="noopener noreferrer nofollow"
                target="_blank"
                class="mp-detail__extra-detail"
              >
                <IconWebsite />
                <span>{{ business.website }}</span>
              </a>
              <a
                v-if="business.business_email"
                :href="`mailto:${business.business_email}`"
                class="mp-detail__extra-detail"
              >
                <IconEmail />
                <span>{{ business.business_email }}</span>
              </a>
              <span
                v-if="business.vat_no"
                href="#"
                class="mp-detail__extra-detail"
              >
                <IconRegistration />
                <span>{{ business.vat_no }}</span>
              </span>
            </div>
            <div class="mp-detail__socials">
              <a
                v-if="business.facebook"
                :href="business.facebook"
                rel="noopener noreferrer nofollow"
                target="_blank"
                ><IconFacebook
              /></a>
              <a
                v-if="business.twitter"
                :href="business.twitter"
                rel="noopener noreferrer nofollow"
                target="_blank"
                ><IconTwitter
              /></a>
              <a
                v-if="business.instagram"
                :href="business.instagram"
                rel="noopener noreferrer nofollow"
                target="_blank"
                ><IconInstagram
              /></a>
            </div>
          </div>
        </div>
        <div
          v-if="
            type === UserEnum.PARTNER &&
            (business.images.length || business.geocoded_address)
          "
          class="mp-detail__gallery-and-map"
        >
          <div v-if="business.images.length" class="mp-detail__gallery">
            <Gallery :images="business.images" />
          </div>
          <div v-if="business.geocoded_address" class="mp-detail__map">
            <StaticMap :address="business.geocoded_address" />
          </div>
        </div>
        <div v-if="type === UserEnum.PARTNER" class="mp-detail__testimonials">
          <TestimonialsSlider :partner="business" />
        </div>
        <div v-if="type === UserEnum.COMPANY" class="mp-detail__products">
          <CompaniesProducts :company="business" />
        </div>
      </template>
    </div>
  </DashboardPage>
</template>
<script>
import UserLogo from '~/components/UserLogo';
import IconConnect from '~/components/icons/IconConnect';
import PartnerMoreDetails from '~/pages/dashboard/marketplace/partials/PartnerMoreDetails';
import DashboardPage from '~/components/DashboardPage';
import IconOwner from '~/components/icons/IconOwner';
import IconAddress from '~/components/icons/IconAddress';
import IconTelephone from '~/components/icons/IconTelephone';
import IconWebsite from '~/components/icons/IconWebsite';
import IconEmail from '~/components/icons/IconEmail';
import IconRegistration from '~/components/icons/IconRegistration';
import IconFacebook from '~/components/icons/IconFacebook';
import IconTwitter from '~/components/icons/IconTwitter';
import IconInstagram from '~/components/icons/IconInstagram';
import StaticMap from '~/pages/dashboard/marketplace/partials/StaticMap';
import Gallery from '~/components/Gallery';
import TestimonialsSlider from '~/components/TestimonialsSlider';
import IconArrowRight from '~/components/icons/IconArrowRight';
import IconDropRight from '~/components/icons/IconDropRight';
import CompaniesProducts from '~/pages/dashboard/marketplace/partials/CompaniesProducts';
import UserEnum from '~/enums/UserEnum';
import ConnectRequestMixin from '~/pages/dashboard/marketplace/partials/ConnectRequestMixin';
import CompanyMoreDetails from '~/pages/dashboard/marketplace/partials/CompanyMoreDetails';
import MarketplaceBreadcrumbs from '~/components/marketplace/MarketplaceBreadcrumbs';
import Loader from '~/components/Loader';
import VClamp from 'vue-clamp';
import TextareaText from '~/components/TextareaText';
import { mapGetters, mapState } from 'vuex';

export default {
  mixins: [ConnectRequestMixin],
  components: {
    MarketplaceBreadcrumbs,
    CompanyMoreDetails,
    CompaniesProducts,
    IconDropRight,
    IconArrowRight,
    TestimonialsSlider,
    Gallery,
    StaticMap,
    IconInstagram,
    IconTwitter,
    IconFacebook,
    IconRegistration,
    IconEmail,
    IconWebsite,
    IconTelephone,
    IconAddress,
    IconOwner,
    DashboardPage,
    PartnerMoreDetails,
    UserLogo,
    IconConnect,
    Loader,
    VClamp,
    TextareaText,
  },
  props: {
    slug: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      business: null,
      connection: null,
      aboutExpanded: false,
      testimonials: [],
      type: null,
      UserEnum,
    };
  },
  computed: {
    ...mapState('auth', ['userable']),
    ...mapGetters('auth', ['userType']),
    hideConnectButton() {
      return (
        this.userType === UserEnum.ADMIN ||
        (this.userable.id === this.business.id && this.userType === this.type)
      );
    },
  },
  async created() {
    this.loading = true;
    try {
      this.business = await this.fetch();
      this.connection = await this.getConnection();
    } catch (e) {
      this.$axios.handleError(e);
    } finally {
      this.loading = false;
    }
  },

  methods: {
    fetch() {
      return this.getBaseQuery().find(this.slug);
    },

    async connect() {
      this.connection = await this.connectRequest(
        this.getConnectionAttributes({
          type: this.type,
          business: this.business,
          applicant: this.userable,
        })
      );
    },
  },
};
</script>
<style lang="scss">
.mp-detail {
  .loader {
    margin: $s-20 0;
  }

  &__top {
    display: flex;
    justify-content: space-between;
  }

  &__logo-and-info {
    display: flex;
    align-items: center;
    padding-right: $s-6;
  }

  &__logo {
    border: 1px solid $gray-500;
    flex-shrink: 0;
  }

  &__info {
    margin-left: $s-6;
  }

  &__tagline {
    font-size: 18px;
    color: rgba($gray-800, 0.5);
    line-height: 28px;
    font-weight: 300;
    margin-top: $s-2;
  }

  &__title {
    font-size: 36px;
    line-height: 44px;
    font-weight: 300;
    color: $gray-800;
  }

  &__connection {
    margin-top: $s-4;
    padding: $btn-padding-y 18px $btn-padding-y 12px;
    font-size: $btn-font-size;
    line-height: $btn-line-height;
    border-radius: $border-radius;
    display: inline-flex;
    align-items: center;
    color: $white;

    svg {
      margin-right: 10px;
    }

    &.btn--white {
      color: rgba($gray-800, 0.5);
    }

    &--pending {
      background-color: $accent-yellow;
    }

    &--connected {
      background-color: $primary;
    }

    &--declined {
      background-color: $accent-red;
    }
  }

  .business-more-details {
    width: 408px;
    border-radius: $border-radius-lg;
    background-color: rgba($white, 0.7);
    padding: #{$s-8 - $s-4} $s-8;
    margin-top: 0;

    &--company {
      width: 228px;
    }
  }

  &__about-and-extra-details {
    margin-top: $s-12;
    display: flex;
  }

  &__about {
    width: 55%;
    padding-right: $s-20;

    h5 {
      color: rgba($black, 0.5);
      font-weight: 500;
      margin-bottom: $s-1;
    }

    p,
    span {
      font-size: 18px;
      color: rgba($gray-800, 0.5);
      line-height: 28px;
      white-space: pre-line;
    }

    &__read-more {
      color: $gray-800;
      padding-left: 2px;

      &--expanded {
        margin-top: $s-4;
      }
    }

    &--mobile {
      display: none;
    }
  }

  &__extra-details-and-socials {
    margin-top: $s-8;
  }

  &__extra-detail {
    display: flex;
    align-items: center;
    color: rgba($gray-800, 0.5);
    margin-bottom: $s-6;
    transition: color 0.15s ease-out;

    svg {
      color: rgba($gray-800, 0.25);
      transition: color 0.15s ease-out;
    }

    span {
      font-size: 18px;
      line-height: 28px;
      font-weight: 300;
      margin-left: $s-4;
    }
  }

  &__socials {
    margin-top: $s-12;

    svg {
      color: rgba($gray-800, 0.25);
      transition: color 0.15s ease-out;
    }

    a {
      color: rgba($gray-800, 0.5);
      transition: color 0.15s ease-out;
      margin-right: $s-6;
    }
  }

  @at-root {
    .no-touchevents {
      a.mp-detail__extra-detail:hover,
      .mp-detail__socials a:hover {
        color: $gray-800;

        svg {
          color: rgba($gray-800, 0.5);
        }
      }
    }
  }

  &__gallery-and-map {
    display: flex;
    margin-top: $s-20;
    align-items: stretch;

    > div:last-child {
      max-width: 45%;
      flex: 0 0 45%;
      align-self: stretch;
    }

    > div:first-child {
      max-width: 55%;
      flex: 0 0 55%;

      padding-right: $s-8;
      align-self: stretch;
    }
  }

  &__map {
    img {
      max-width: 100%;
    }
  }

  &__testimonials {
    margin-top: $s-20;
  }

  &__products {
    margin-top: $s-20;
  }
}
@mixin full-border() {
  &:after {
    content: '';
    position: absolute;
    height: 1px;
    background-color: $gray-500;
    bottom: 0;
    width: auto;
    left: -$container-padding;
    right: -$container-padding;
  }
}

@include media-breakpoint-down(md) {
  .mp-detail {
    &__top {
      flex-wrap: wrap;
    }

    &__logo-and-info {
      width: 100%;
      align-items: flex-start;
      padding-right: 0;
    }

    &__logo {
      .user-logo {
        width: 112px;
      }
    }

    &__info {
      margin-left: $s-4;
    }

    &__tagline {
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      margin-top: 0;
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
    }

    &__more-details {
      width: 100%;
    }

    &__connection {
      margin-top: $s-3;
    }

    .business-more-details {
      width: 100%;
      border-radius: 0;
      background-color: transparent;
      padding: $s-4 0;
      margin-top: 0;
      position: relative;

      @include full-border;

      &--company {
        width: 100%;

        .business-more-details__item {
          flex: 1 1 50%;
          max-width: 300px;
        }
      }

      &__item {
        flex: 1 1 50%;
        max-width: 300px;
      }
    }

    &__about-and-extra-details {
      margin-top: 0;
      flex-wrap: wrap;
    }

    &__about {
      width: 100%;
      padding-right: 0;
      margin-top: $s-4;
      padding-bottom: $s-8;
      position: relative;
      @include full-border;

      h5 {
        margin-bottom: 0;
      }

      &--desktop {
        display: none;
      }

      &--mobile {
        display: block;
      }
    }

    &__extra-details-and-socials {
      padding: $s-8 0;
      position: relative;
      @include full-border;
      margin-top: 0;
      width: 100%;
    }

    &__gallery-and-map {
      display: flex;
      flex-wrap: wrap;
      margin-top: $s-8;

      > div:last-child {
        max-width: 100%;
        flex: 0 0 100%;
        margin-top: $s-8;
      }

      > div:first-child {
        max-width: 100%;
        flex: 0 0 100%;
        padding-right: 0;
      }
    }

    &__testimonials {
      margin-top: $s-8;
    }

    &__products {
      margin-top: $s-8;
    }
  }
}
</style>
