<template>
  <div class="metric-single-status">
    <div class="metric-single-status__total">
      <span class="label">{{ totalLabel }}</span>
      <span class="value">{{ total }}</span>
    </div>
    <div class="metric-single-status__proportions">
      <div class="metric-single-status__proportions__positive">
        <span class="label">{{ positiveLabel }}</span>
        <span class="value">{{ positive }}</span>
      </div>
      <div class="metric-single-status__proportions__negative">
        <span class="label">{{ negativeLabel }}</span>
        <span class="value">{{ negative }}</span>
      </div>
    </div>
    <div class="metric-single-status__loader-placeholder" v-show="loading">
      <div
        class="metric-single-status__loading"
        :class="{ 'metric-single-status--skeleton': loading }"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    endpoint: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      total: 0,
      totalLabel: ' ',
      negative: 0,
      negativeLabel: ' ',
      positive: 0,
      positiveLabel: ' ',
    };
  },
  mounted() {
    this.sync();
  },
  methods: {
    async sync() {
      if (!this.endpoint) {
        this.loading = false;
        return;
      }
      this.loading = true;
      try {
        let response = await this.$axios.get(`v1/stats/${this.endpoint}`);
        this.total = response.data.total;
        this.totalLabel = response.data.totalLabel;
        this.positive = response.data.positive;
        this.positiveLabel = response.data.positiveLabel;
        this.negative = response.data.negative;
        this.negativeLabel = response.data.negativeLabel;
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.metric-single-status {
  background-color: $white;
  width: 100%;
  text-align: center;
  position: relative;
  min-height: 285px;
  display: flex;
  flex-wrap: wrap;

  .label {
    font-size: $text-lg;
    display: block;
    color: $gray-800;
  }

  &__total {
    flex: 1 1 50%;
    padding: 86.5px 10px;
    @include collapsable-border(1px, $gray-500);

    .value {
      font-size: 72px;
      line-height: 80px;
      font-weight: 300;
      color: $gray-800;
      display: block;
      padding-top: 8px;
    }
  }

  &__proportions {
    flex: 1 1 50%;
    display: flex;
    flex-wrap: wrap;

    div {
      height: 50%;
      flex: 1 1 100%;
      padding: 28px 10px;
      @include collapsable-border(1px, $gray-500);

      .value {
        font-weight: 300;
        color: $gray-800;
        font-size: $text-4xl;
        line-height: $text-5xl;
        padding-top: 15px;
        display: block;
      }
    }

    &__negative {
      .value {
        color: $accent-red !important;
      }
    }
  }

  &:after {
    content: ' ';
    clear: both;
    display: table;
  }

  &__loader-placeholder {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
  }

  &__loading {
    height: 100%;
    width: 100%;
  }

  &--skeleton {
    @include component-placeholder();
    z-index: 999999;
  }
}

@include media-breakpoint-down(sm) {
  .metric-single-status {
    margin-left: -#{$container-padding};
    margin-right: -#{$container-padding};
    width: auto;
    border-right: 0;
    border-left: 0;

    &__total {
      flex: 1 1 100%;
      padding: 56px 10px;
    }

    &__proportions {
      flex: 1 1 100%;

      div {
        height: auto;
        flex: 1 1 50%;
      }
    }
  }
}
</style>
