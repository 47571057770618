var render = function() {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "store-page" },
    [
      !_vm.business
        ? _c("Loader")
        : [
            _c("div", { staticClass: "store__header" }, [
              _c(
                "div",
                { staticClass: "store__container" },
                [
                  _c(
                    "div",
                    { staticClass: "store__header__logo" },
                    [
                      _c("LogoMarkStoreHeader"),
                      _vm._v(" "),
                      _c("LogoStoreHeader")
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("h3", { staticClass: "store__header__partner-logo" }, [
                    _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.route(
                            "store." + _vm.type,
                            ((_obj = {}),
                            (_obj[_vm.type] = this.business.slug),
                            _obj)
                          )
                        }
                      },
                      [
                        _c(
                          "VClamp",
                          { attrs: { autoresize: "", "max-lines": 1 } },
                          [
                            _vm._v(
                              _vm._s(_vm.business.business_name) + " Store"
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "store__header__cart",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          _vm.showCartSlideout = true
                        }
                      }
                    },
                    [
                      _c("IconBag"),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.cartCount) +
                            " / " +
                            _vm._s(_vm.grossTotal.format())
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.showCartSlideout
                    ? _c(
                        "Slideout",
                        {
                          staticClass: "slideout--cart-slideout",
                          on: {
                            close: function($event) {
                              _vm.showCartSlideout = false
                            }
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "store__container" },
                            [
                              _c("Cart", {
                                attrs: {
                                  business: _vm.business,
                                  type: _vm.type
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm._t("default", null, { business: _vm.business }),
            _vm._v(" "),
            _c("div", { staticClass: "store__footer" }, [
              _c("div", { staticClass: "store__footer__contact-details" }, [
                _vm.business.geocoded_address && _vm.showAddressOrPhone
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.business.geocoded_address.maps_url,
                          rel: "noopener noreferrer nofollow",
                          target: "_blank"
                        }
                      },
                      [
                        _c("IconAddress", {
                          attrs: { width: "18", height: "18" }
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.business.place_description))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.business.contact_number && _vm.showAddressOrPhone
                  ? _c(
                      "a",
                      { attrs: { href: "tel:" + _vm.business.contact_number } },
                      [
                        _c("IconTelephone", {
                          attrs: { width: "18", height: "18" }
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.business.contact_number))
                        ])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.business.website
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.business.website,
                          rel: "noopener noreferrer nofollow",
                          target: "_blank"
                        }
                      },
                      [
                        _c("IconWebsite", {
                          attrs: { width: "18", height: "18" }
                        }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm.business.website))])
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.business.business_email
                  ? _c(
                      "a",
                      {
                        attrs: { href: "mailto:" + _vm.business.business_email }
                      },
                      [
                        _c("IconEmail", {
                          attrs: { width: "18", height: "18" }
                        }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm.business.business_email))
                        ])
                      ],
                      1
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "store__footer__app-logo" },
                [_c("LogoStoreFooter")],
                1
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }