<template>
  <div v-if="product.images.length" class="pd__gallery">
    <div class="pd__gallery__slider-wrapper">
      <div class="pd__gallery__slider-ratio-helper">
        <flickity
          ref="flickity"
          :options="flickityOptions"
          class="pd__gallery__slider"
        >
          <img
            v-for="image in images"
            :key="image.id"
            :data-flickity-lazyload="image.urls.large"
            class="carousel-cell-image"
            width="880"
            height="880"
          />
        </flickity>
        <div v-if="images.length > 1" class="pd__gallery__slider-mobile-nav">
          <span>{{ currentIndex + 1 }} of {{ images.length }}</span>
        </div>
      </div>
    </div>
    <div v-if="images.length > 1" class="pd__gallery__nav">
      <a
        href="#"
        v-for="(image, index) in images"
        class="pd__gallery__nav__item"
        @click.prevent="select(index)"
      >
        <img :src="image.urls.thumb" width="180" height="180" />
      </a>
    </div>
  </div>
</template>
<script>
import Flickity from 'vue-flickity';

export default {
  components: {
    Flickity,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    images() {
      return this.product.images.sort((a, b) => a.id - b.id);
    },
  },
  data() {
    return {
      currentIndex: 0,
      flickityOptions: {
        prevNextButtons: false,
        pageDots: false,
        wrapAround: true,
        adaptiveHeight: false,
        lazyLoad: 2,
      },
    };
  },
  mounted() {
    if (this.product.images.length) {
      this.$refs.flickity.on('change', (index) => {
        this.currentIndex = index;
      });
    }
  },
  methods: {
    select(index) {
      this.$refs.flickity.select(index);
    },
  },
};
</script>
<style lang="scss">
.carousel-cell-image {
  transition: opacity 0.4s;
  opacity: 0;
  width: 100%;
  height: auto;
  line-height: 0;
  font-size: 0;
}

.carousel-cell-image.flickity-lazyloaded,
.carousel-cell-image.flickity-lazyerror {
  opacity: 1;
}

.pd__gallery {
  max-width: 440px;
  width: 100%;
  margin: 0 auto;

  &__slider-wrapper {
    border: 1px solid $gray-500;
  }

  &__slider-ratio-helper {
    width: 100%;
    height: 0;
    padding-bottom: 100%;
    position: relative;
  }

  &__slider-mobile-nav {
    position: absolute;
    bottom: $s-4;
    right: $s-4;
    padding: 6px 10px;
    border-radius: 2px;
    background-color: rgba($gray-800, 0.5);
    z-index: 3;
    display: flex;
    align-items: center;

    span {
      font-size: 12px;
      font-weight: 500;
      color: $white;
      font-feature-settings: 'tnum';
      font-variant-numeric: tabular-nums;
    }
  }

  &__slider {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }

  &__nav {
    $gutter: 16px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: $s-4;
    margin-right: -$gutter / 2;
    margin-left: -$gutter / 2;

    &__item {
      width: 100%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
      flex: 0 0 80px + $gutter;
      max-width: 80px + $gutter;
      margin-bottom: $s-3;

      img {
        border: 1px solid $gray-500;
        max-width: 100%;
        height: auto;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .pd__gallery {
    &__nav {
      display: none;
    }
  }
}
</style>
