var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationProvider", {
    ref: "provider",
    staticClass: "form-group",
    attrs: { tag: "div", vid: _vm.vid, name: _vm.name },
    scopedSlots: _vm._u(
      [
        {
          key: "default",
          fn: function(ref) {
            var errors = ref.errors
            return [
              _c("label", { staticClass: "v-checkbox" }, [
                _c("input", {
                  attrs: { type: "checkbox" },
                  domProps: { value: _vm.value, checked: _vm.checked },
                  on: { change: _vm.updateInput }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "v-checkbox__wrapper" }, [
                  _c("span", { staticClass: "v-checkbox__icon" }, [
                    _c(
                      "svg",
                      {
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          viewBox: "0 0 12 12"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            d: "M4 11L0 7l1.5-1.5L4 8l6-6 1.5 1.5L4 11z"
                          }
                        })
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "v-checkbox__label" },
                    [_vm._t("default")],
                    2
                  )
                ])
              ]),
              _vm._v(" "),
              errors[0]
                ? _c("span", { staticClass: "form-group__error" }, [
                    _vm._v(_vm._s(errors[0]))
                  ])
                : _vm._e()
            ]
          }
        }
      ],
      null,
      true
    )
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }