<template>
  <div v-on-clickaway="hide" class="dropdown">
    <slot name="button" :toggle="toggle">
      <button class="btn btn--white" @click="toggle">
        {{ btnLabel }}
      </button>
    </slot>
    <transition name="dropdown-transition">
      <div v-if="isOpen" class="dropdown__menu">
        <slot name="items" :hide="hide"></slot>
      </div>
    </transition>
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';

export default {
  mixins: [clickaway],
  props: {
    btnLabel: {
      type: String,
      required: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggle() {
      this.isOpen = !this.isOpen;
    },
    show() {
      this.isOpen = true;
    },
    hide() {
      this.isOpen = false;
    },
  },
};
</script>
<style lang="scss">
.dropdown {
  position: relative;
  float: left;

  .btn {
    color: $gray-800;
  }

  &__menu {
    top: $btn-height + 8px;
    background-color: #fff;
    position: absolute;
    z-index: 1;
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    overflow: hidden;
  }

  &__item {
    width: 100%;
    font-weight: 500;
    color: $black;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 16px;
    display: block;
    background-color: $white;
    transition: background-color 0.15s ease-out;
  }

  @at-root {
    .no-touchevents {
      .dropdown__item:hover {
        background-color: $gray-100;
      }
    }
  }
}

.dropdown-transition-enter-active,
.dropdown-transition-leave-active {
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
}

.dropdown-transition-enter,
.dropdown-transition-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
