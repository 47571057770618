<template>
  <div class="companies-products">
    <div class="companies-products__top-bar">
      <div class="companies-products__top-bar__left">
        <h6 class="companies-products__title">Products</h6>
      </div>
      <div class="companies-products__top-bar__right">
        <VSelect
          v-model="orderBy"
          name="Order by"
          :options="orderByOptions"
          class="filter-select"
        />
        <SearchInput v-model="filters.search" />
      </div>
    </div>
    <div v-if="loadingItems" class="companies-products__loading">
      Loading...
    </div>
    <template v-else>
      <div v-if="!items.length" class="companies-products__no-items">
        No products.
      </div>
      <div v-else class="companies-products__items">
        <a
          :href="route('marketplace.product.show', product.slug)"
          v-for="product in items"
          :key="product.id"
          class="companies-products__item"
        >
          <div class="companies-products__item__image">
            <img
              :src="
                product.image
                  ? product.image.urls.medium
                  : getProductPlaceholderImg()
              "
              alt=""
              width="640"
              height="640"
            />
          </div>
          <div class="companies-products__item__title">
            <VClamp autoresize :max-lines="1">{{ product.name }}</VClamp>
          </div>
          <div class="companies-products__item__tags">
            <CardTag type="sector">{{ product.category.sector.name }}</CardTag>
            <CardTag type="category">{{ product.category.name }}</CardTag>
          </div>
          <div class="companies-products__item__description">
            <VClamp autoresize :max-lines="3">{{ product.description }}</VClamp>
          </div>
          <ProductPriceAndCommission :product="product" />
        </a>
      </div>
    </template>
    <div class="companies-products__pagination">
      <SimplePagination
        :page="page"
        :is-last-page="isLastPage"
        @prev="prev"
        @next="next"
      />
    </div>
  </div>
</template>
<script>
import VSelect from '~/components/form/VSelect';
import SearchInput from '~/components/datatable/SearchInput';
import VClamp from 'vue-clamp';
import IconPrice from '~/components/icons/IconPrice';
import { formatMoney, formatRateToPercentage } from '~/utils';
import IconCommission from '~/components/icons/IconCommission';
import CardTag from '~/components/CardTag';
import ListingMixin from '~/pages/shared/ListingMixin';
import Product from '~/models/Product';
import PersistentStorage from '~/persistent-storage';
import ProductPriceAndCommission from '~/components/ProductPriceAndCommission';
import { getProductPlaceholderImg } from '~/utils';

export default {
  mixins: [ListingMixin],
  components: {
    ProductPriceAndCommission,
    CardTag,
    IconCommission,
    IconPrice,
    VSelect,
    SearchInput,
    VClamp,
  },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      perPage: 8,
      filters: {
        search: '',
      },
      orderBy: '-net_total',
      orderByOptions: [
        {
          value: '-net_total',
          label: 'Sort: Most sales',
        },
        {
          value: '-price',
          label: 'Sort: Price',
        },
        {
          value: '-default_commission_rate',
          label: 'Sort: Commission',
        },
      ],
      queryCustomParams: {
        with_net_total: true,
      },
      loading: false,
    };
  },
  methods: {
    formatMoney,
    formatRateToPercentage,
    getProductPlaceholderImg,
    getBaseQuery() {
      return new Product()
        .include('category.sector', 'image')
        .where('company_id', this.company.id)
        .where('archived', 0);
    },
    afterFetch() {
      PersistentStorage.setLastCompanyMarketplaceUrl(window.location.href);
    },
  },
};
</script>
<style lang="scss">
.companies-products {
  $gutter: 32px;

  &__top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0 $s-4;
    border-bottom: 1px solid $gray-500;

    &__left {
      display: flex;
    }

    &__right {
      display: flex;
    }

    .filter-select {
      padding-bottom: 0;
      margin-right: $s-4;
    }
  }

  &__title {
    color: rgba($gray-800, 0.5);
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
  }

  &__loading,
  &__no-items {
    color: rgba($gray-800, 0.25);
    text-align: center;
    padding: $s-48 0;
    font-size: $text-lg;
    width: 100%;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter / 2;
    margin-left: -$gutter / 2;
    padding-top: $s-8;
  }

  &__item {
    width: 100%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: $s-16;

    &__image {
      border: 1px solid $gray-500;

      img {
        width: 100%;
        height: auto;
      }
    }

    &__title {
      margin-top: $s-4;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: $gray-800;
    }

    &__tags {
      display: flex;
      flex-wrap: wrap;
      margin-top: $s-3;

      .card-tag {
        margin-bottom: $s-2;
      }
    }

    &__description {
      margin-top: $s-2;
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      color: rgba($gray-800, 0.5);
      min-height: 54px;
    }

    .product-price-and-commission {
      margin-top: $s-5;
    }
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-bottom: $s-10;
  }
}

@include media-breakpoint-down(md) {
  .companies-products {
    &__top-bar {
      padding: 0;
      border-bottom: none;

      &__right {
        display: none;
      }
    }

    &__item {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
      margin-bottom: $s-12;

      &__title {
        margin-top: $s-4;
        font-size: 18px;
        line-height: 28px;
      }

      &__description {
        display: none;
      }

      .product-price-and-commission {
        margin-top: $s-2;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .companies-products {
    $gutter: 16px;

    &__items {
      margin-right: -$gutter / 2;
      margin-left: -$gutter / 2;
    }

    &__item {
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: $s-8;
    }
  }
}
</style>
