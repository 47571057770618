var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.visible
    ? _c(
        "Modal",
        {
          staticClass: "confirm-modal",
          attrs: { size: "sm" },
          on: { "modal-close": _vm.handleClose }
        },
        [
          _c("div", { staticClass: "modal__header" }, [
            _c(
              "h3",
              {
                staticClass: "modal__title",
                class: { "confirm-modal__title--no-close": _vm.hideCloseBtn }
              },
              [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
            ),
            _vm._v(" "),
            !_vm.hideCloseBtn
              ? _c(
                  "button",
                  {
                    staticClass: "modal__close",
                    attrs: { type: "button" },
                    on: { click: _vm.handleClose }
                  },
                  [_c("IconClose")],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal__body" },
            [
              _vm.subtitle
                ? _c("p", { domProps: { innerHTML: _vm._s(_vm.subtitle) } })
                : _vm._e(),
              _vm._v(" "),
              _vm.onlyOk
                ? [
                    _c(
                      "LoadingBtn",
                      {
                        staticClass: "btn",
                        attrs: { loading: _vm.loading },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleYes($event)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.okButtonLabel) + "\n      ")]
                    )
                  ]
                : [
                    _c(
                      "LoadingBtn",
                      {
                        staticClass: "btn",
                        class: { "btn--accent-red": _vm.danger },
                        attrs: { loading: _vm.loading },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleYes($event)
                          }
                        }
                      },
                      [_vm._v("Yes, I'm sure\n      ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn--secondary",
                        attrs: { href: "#" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.handleNo($event)
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  ]
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }