var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm._t(
        "cell(" + _vm.field.key + ")",
        [
          !_vm.cell
            ? [_vm._v(" ")]
            : !_vm.cell.type
            ? [_vm._v("\n      " + _vm._s(_vm.cell) + "\n    ")]
            : _vm.cell.type === "link"
            ? [
                _c(
                  "a",
                  { attrs: { href: _vm.cell.value.url, target: "_blank" } },
                  [_vm._v(_vm._s(_vm.cell.value.label))]
                )
              ]
            : _vm.cell.type === "links"
            ? [
                _vm._l(_vm.cell.value, function(value, index) {
                  return [
                    _c("a", { attrs: { href: value.url, target: "_blank" } }, [
                      _vm._v(_vm._s(value.label))
                    ]),
                    _vm._v(" "),
                    index !== _vm.cell.value.length - 1
                      ? [_vm._v(", ")]
                      : _vm._e()
                  ]
                })
              ]
            : _vm.cell.type === "invoices-links"
            ? [
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        return _vm.openInvoicesModal(_vm.cell.value)
                      }
                    }
                  },
                  [_vm._v("Download")]
                )
              ]
            : _vm.cell.type === "stock-status-badge"
            ? [_c("StockStatusBadge", { attrs: { count: _vm.cell.value } })]
            : _vm.cell.value
            ? [_vm._v("\n      " + _vm._s(_vm.cell.value) + "\n    ")]
            : _vm._e()
        ],
        { item: _vm.item }
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }