<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" fill-rule="evenodd">
      <path
        d="M23 13c.6 0 1 .4 1 1v9c0 .6-.4 1-1 1h-1V13zM2 13v11H1c-.6 0-1-.4-1-1v-9c0-.6.4-1 1-1h1zM19 0c.6 0 1 .4 1 1v22c0 .6-.4 1-1 1h-6v-4h-2v4H5c-.6 0-1-.4-1-1V1c0-.6.4-1 1-1zm-9 15H7v2h3v-2zm7 0h-3v2h3v-2zm-7-4H7v2h3v-2zm7 0h-3v2h3v-2zm-7-4H7v2h3V7zm7 0h-3v2h3V7zm-7-4H7v2h3V3zm7 0h-3v2h3V3z"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>
<script>
import Icon from '~/components/icons/Icon';

export default {
  extends: Icon,
};
</script>
<style scoped>
svg g {
  fill: currentColor;
}
</style>
