<script>
export default {
  props: {
    rules: {
      type: [String, Object],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    vid: {
      type: String,
      default: undefined,
    },
    labelInfoText: {
      type: String,
      required: false,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    infoText: {
      type: String,
      required: false,
      default: '',
    },
    noLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    dark: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
