<template>
  <DashboardPage title="Dashboard">
    <MetricSection title="Overview">
      <div class="metric-section__tiles">
        <MetricValue title="Sales" endpoint="sales" />
        <MetricValue
          title="Company Commission"
          endpoint="totalPartnersCommission"
        />
        <MetricValue title="Platform Commission" endpoint="appFee" />
        <MetricValue
          title="Total Products Sold"
          format="integer"
          endpoint="orderItemsCount"
        />
      </div>
    </MetricSection>
    <MetricSection
      title="Analytics"
      :link="{ href: route('admin.sales'), title: 'All Sales' }"
    >
      <MetricTrend
        endpoint="analytics"
        has-service-filter
        has-category-filter
        :options="[
          { label: 'Sales', value: 'sales' },
          { label: 'Commission', value: 'commission' },
        ]"
      />
    </MetricSection>
    <div class="metric-row">
      <div class="metric-row__half-column">
        <MetricSection
          title="Partners"
          :link="{ href: route('admin.partners'), title: 'All Partners' }"
        >
          <MetricPerformance
            :options="[
              { label: 'Sales', value: 'sales' },
              { label: 'Commission', value: 'commission' },
            ]"
            endpoint="topPartners"
          />
        </MetricSection>
      </div>
      <div class="metric-row__half-column">
        <MetricSection title="Categories">
          <MetricPerformance
            :options="[
              { label: 'Sales', value: 'sales' },
              { label: 'Commission', value: 'commission' },
            ]"
            endpoint="topCategories"
          />
        </MetricSection>
      </div>
    </div>
    <div class="metric-row">
      <div class="metric-row__half-column">
        <MetricSection title="Onboarding Status">
          <MetricSingleStatus endpoint="onboardingStatus" />
        </MetricSection>
      </div>
      <div class="metric-row__half-column">
        <MetricSection title="User Status">
          <MetricTotalBreakdown endpoint="userStatus" />
        </MetricSection>
      </div>
    </div>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import MetricSection from '~/components/MetricSection';
import MetricValue from '~/components/metrics/MetricValue';
import MetricPerformance from '~/components/metrics/MetricPerformance';
import MetricTrend from '~/components/metrics/MetricTrend';
import MetricSingleStatus from '~/components/metrics/MetricSingleStatus';
import MetricTotalBreakdown from '~/components/metrics/MetricTotalBreakdown';
import MetricPageMixin from '~/pages/dashboard/common/MetricPageMixin';

export default {
  extends: Page,
  mixins: [MetricPageMixin],
  components: {
    MetricTotalBreakdown,
    MetricSingleStatus,
    MetricValue,
    MetricPerformance,
    MetricTrend,
    MetricSection,
    DashboardPage,
    Datatable,
  },
};
</script>
