import Money from '~/money';

const Price = (money, vatRate) => {
  if (!(money instanceof Money)) {
    money = Money(money);
  }

  const vatAmount = money.multiply(vatRate);
  return {
    getMoney() {
      return money;
    },

    getVatRate() {
      return vatRate;
    },

    vatAmount() {
      return vatAmount;
    },

    net() {
      return money;
    },

    gross() {
      return money.add(vatAmount);
    },
  };
};

export default Price;
