<script>
import { countString } from '~/utils';

export default {
  props: {
    big: {
      type: Boolean,
      required: false,
      default: false,
    },
    all: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    classes() {
      let classes = [];

      if (this.big) {
        classes.push(`business-more-details--big`);
      }

      return classes;
    },
    size() {
      return this.big ? 24 : 15;
    },
  },
  methods: {
    countString,
  },
};
</script>
<style lang="scss">
.business-more-details {
  margin-top: $s-6;
  display: flex;
  flex-wrap: wrap;
  padding-right: 84px;

  &__item {
    display: flex;
    align-items: center;
    flex: 1 1 50%;
    margin: $s-2 0;
    padding-right: $s-4;

    svg {
      color: rgba($gray-800, 0.25);
    }

    span {
      margin-left: $s-2;
      color: rgba($gray-800, 0.5);
      font-size: 12px;
      line-height: 18px;
      font-weight: 500;
      white-space: nowrap;
    }
  }

  &--big {
    .business-more-details__item {
      margin: $s-4 0;

      span {
        margin-left: $s-4;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
      }
    }
  }

  &--company {
    .business-more-details__item {
      flex: 1 1 100%;
    }
  }
}
@include media-breakpoint-down(md) {
  .business-more-details {
    margin-top: $s-4;
    padding-right: 0;

    &__item {
      padding-right: 0;
      flex: 1 1 100%;
    }
  }
}
</style>
