<template>
  <div class="layout layout-store">
    <slot></slot>
    <ConfirmModal />
    <PortalTarget name="modals" multiple />
  </div>
</template>
<script>
import ConfirmModal from '~/components/confirm-modal/ConfirmModal';

export default {
  components: {
    ConfirmModal,
  },
};
</script>
<style lang="scss">
.layout-store {
  background-color: $gray-100;
  min-height: 100vh;
}
</style>
