var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M12 2C6.486 2 2 6.486 2 12s4.486 10 10 10 10-4.486 10-10S17.514 2 12 2zm2.215 15.237c-.55.216-2.46 1.128-3.56.159a1.397 1.397 0 01-.492-1.099c0-.831.274-1.556.766-3.297.087-.33.193-.756.193-1.094 0-.584-.222-.74-.823-.74-.293 0-.618.105-.912.215l.162-.666c.656-.267 1.48-.592 2.184-.592 1.058 0 1.836.528 1.836 1.531 0 .29-.05.796-.155 1.146l-.608 2.152c-.126.435-.353 1.394-.001 1.678.347.28 1.168.132 1.572-.06l-.162.667zm-1.005-8.57a1.25 1.25 0 110-2.501 1.25 1.25 0 010 2.5z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }