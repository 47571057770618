var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "marketplace__card", class: _vm.classes }, [
    _c(
      "div",
      { staticClass: "marketplace__card__left" },
      [
        _c(
          "div",
          { staticClass: "marketplace__card__logo" },
          [
            _c("UserLogo", {
              attrs: {
                logo: _vm.business.logo,
                colour: _vm.business.logo_colour,
                "business-name": _vm.business.business_name,
                size: 200
              }
            })
          ],
          1
        ),
        _vm._v(" "),
        _vm.userType !== _vm.UserEnum.ADMIN
          ? [
              !_vm.connection
                ? _c(
                    "a",
                    {
                      staticClass:
                        "marketplace__card__connection marketplace__card__connection--connect",
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.connect($event)
                        }
                      }
                    },
                    [
                      _c("IconConnect", {
                        attrs: { width: "16", height: "16" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("Connect")])
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass: "marketplace__card__connection",
                      class:
                        "marketplace__card__connection--" +
                        _vm.connection.statusClassName(),
                      on: { click: function($event) {} }
                    },
                    [
                      _c("IconConnect", {
                        attrs: { width: "16", height: "16" }
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(_vm.connection.statusLabel()))])
                    ],
                    1
                  )
            ]
          : _vm._e()
      ],
      2
    ),
    _vm._v(" "),
    _c("div", { staticClass: "marketplace__card__right" }, [
      _c("h4", { staticClass: "marketplace__card__title" }, [
        _c(
          "a",
          {
            attrs: { href: _vm.getUrl() },
            on: {
              mouseover: function($event) {
                _vm.isHovering = true
              },
              mouseout: function($event) {
                _vm.isHovering = false
              }
            }
          },
          [
            _c("VClamp", { attrs: { autoresize: "", "max-lines": 1 } }, [
              _vm._v(_vm._s(_vm.business.business_name))
            ])
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "marketplace__card__fixed-height" },
        [
          _c(
            "div",
            { staticClass: "marketplace__card__tags" },
            [
              _vm._l(_vm.business.services, function(service) {
                return _vm.type === _vm.UserEnum.PARTNER
                  ? _c(
                      "CardTag",
                      {
                        key: "service_" + service.id,
                        staticClass: "marketplace__card__tag",
                        attrs: { type: "service" }
                      },
                      [_vm._v(_vm._s(service.name))]
                    )
                  : _vm._e()
              }),
              _vm._v(" "),
              _vm._l(_vm.business.sectors, function(sector) {
                return _c(
                  "CardTag",
                  {
                    key: "sector_" + sector.id,
                    staticClass: "marketplace__card__tag",
                    attrs: { type: "sector" }
                  },
                  [_vm._v(_vm._s(sector.name))]
                )
              })
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "marketplace__card__description" },
            [
              _c(
                "VClamp",
                {
                  attrs: { autoresize: "", "max-lines": 3 },
                  scopedSlots: _vm._u([
                    {
                      key: "after",
                      fn: function() {
                        return [
                          _c(
                            "a",
                            {
                              staticClass:
                                "marketplace__card__description__read-more",
                              attrs: { href: _vm.getUrl() },
                              on: {
                                mouseover: function($event) {
                                  _vm.isHovering = true
                                },
                                mouseout: function($event) {
                                  _vm.isHovering = false
                                }
                              }
                            },
                            [_vm._v("Read more")]
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                },
                [
                  _vm._v(
                    "\n          " + _vm._s(_vm.business.about) + "\n          "
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.type === _vm.UserEnum.PARTNER
            ? [_c("PartnerMoreDetails", { attrs: { partner: _vm.business } })]
            : [_c("CompanyMoreDetails", { attrs: { company: _vm.business } })]
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "btn btn--marketplace-card",
          attrs: { href: _vm.getUrl() }
        },
        [_vm._v("View profile")]
      ),
      _vm._v(" "),
      _vm.connection && _vm.connection.isAccepted()
        ? _c(
            "div",
            { staticClass: "marketplace__card__app-logo" },
            [_c("LogoMark")],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }