import Vue from 'vue';
import { Ziggy } from '~/ziggy';
import route from 'ziggy-js';
import '~/plugins/toasted';
import axiosPlugin from '~/plugins/axios';
import vueApiQueryPlugin from '~/plugins/vue-api-query';
import '~/plugins/confirm-modal';
import '~/plugins/portal-vue';
import '~/plugins/tooltip';
import pages from '~/pages';
import { createStore } from '~/store';
import { url } from '~/utils';

Vue.mixin({
  methods: {
    route: (name, params, absolute) => route(name, params, absolute, Ziggy),
    url: url,
    platformAsset: (path) => {
      return url(`assets/${process.env.MIX_PLATFORM_SLUG}/${path}`);
    },
  },
});

Object.defineProperty(Vue.prototype, '$global', { get: () => window.Laravel });

async function createApp() {
  const store = createStore();

  store.commit('global/setCountries', window.Laravel.countries);
  await store.dispatch('global/setCountry', window.Laravel.country);
  store.commit('auth/setUser', window.Laravel.user);
  store.commit('auth/setUserable', window.Laravel.userable);

  const app = {
    el: '#app',
    name: 'App',
    store,
    components: {
      'layout-auth': require('~/layouts/Auth').default,
      'layout-dashboard': require('~/layouts/Dashboard').default,
      'layout-marketing': require('~/layouts/Marketing').default,
      'layout-landing': require('~/layouts/Landing').default,
      'layout-store': require('~/layouts/Store').default,
      'dashboard-page': require('~/components/DashboardPage').default,
      'error-page': require('~/pages/ErrorPage').default,
      ...pages,
    },
  };

  axiosPlugin(app);
  vueApiQueryPlugin(app);

  return app;
}

const mountApp = (app) => {
  new Vue(app).$mount('#app');
};

createApp().then(mountApp);
