<template>
  <VSelect
    v-model="currentValue"
    name="Sector"
    value-key="id"
    label-key="name"
    :options="sectors"
    empty-value-label="All sectors"
    :class="{ 'filter-select': filterSelect }"
    :no-label="noLabel"
  />
</template>
<script>
import SectorField from '~/components/sectors-fields/SectorField';

export default {
  extends: SectorField,
  props: {
    noLabel: {
      type: Boolean,
      required: false,
      default: false,
    },
    filterSelect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
};
</script>
