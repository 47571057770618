var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pd__intro" }, [
    _c("h3", { staticClass: "pd__intro__title" }, [
      _vm._v(
        "\n    " +
          _vm._s(_vm.product.company.business_name) +
          " - " +
          _vm._s(_vm.product.name) +
          "\n  "
      )
    ]),
    _vm._v(" "),
    _vm.product.size
      ? _c("h5", { staticClass: "pd__intro__size" }, [
          _vm._v(_vm._s(_vm.product.size))
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.showTags
      ? _c(
          "div",
          { staticClass: "pd__intro__tags" },
          [
            _c("CardTag", { attrs: { type: "sector" } }, [
              _vm._v(_vm._s(_vm.product.category.sector.name))
            ]),
            _vm._v(" "),
            _c("CardTag", { attrs: { type: "category" } }, [
              _vm._v(_vm._s(_vm.product.category.name))
            ])
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "pd__intro__description" },
      [_c("TextareaText", [_vm._v(_vm._s(_vm.product.description))])],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }