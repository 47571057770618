var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("FieldWrapper", {
    attrs: {
      rules: _vm.rules,
      name: _vm.name,
      label: _vm.label,
      vid: _vm.vid,
      "label-info-text": _vm.labelInfoText
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var inputId = ref.inputId
          var errors = ref.errors
          return [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.currentValue,
                  expression: "currentValue"
                }
              ],
              staticClass: "form-control",
              attrs: {
                id: inputId,
                placeholder: _vm.placeholder,
                name: _vm.vid
              },
              domProps: { value: _vm.currentValue },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.currentValue = $event.target.value
                }
              }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }