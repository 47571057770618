<script>
import Partner from '~/models/Partner';
import ApprovalEnum from '~/enums/ApprovalEnum';

export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    slug: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      business: null,
    };
  },
  methods: {
    getBaseQuery() {
      return new Partner()
        .include('sectors')
        .where('has_profile_complete', 1)
        .where('has_stripe_connected', 1)
        .where('approval', ApprovalEnum.APPROVED);
    },
    fetchBusiness() {
      return this.getBaseQuery().find(this.slug);
    },
  },
};
</script>
