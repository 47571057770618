<script>
import Connection from '~/models/Connection';
import ConnectionStatusEnum from '~/enums/ConnectionStatusEnum';
import ConnectionApplicantEnum from '~/enums/ConnectionApplicantEnum';
import ApproveOrDeclineBase from '~/components/ApproveOrDeclineBase';

export default {
  extends: ApproveOrDeclineBase,
  props: {
    connection: {
      type: Connection,
      required: true,
    },
  },
  computed: {
    approveString() {
      if (this.connection.applicant === ConnectionApplicantEnum.COMPANY) {
        return 'Approve brand';
      }

      return 'Approve partner';
    },
    declineString() {
      if (this.connection.applicant === ConnectionApplicantEnum.COMPANY) {
        return 'Decline brand';
      }

      return 'Decline partner';
    },
  },
  methods: {
    async approve() {
      await this.updateConnectionStatus(ConnectionStatusEnum.ACCEPTED);
    },
    async decline() {
      await this.updateConnectionStatus(ConnectionStatusEnum.DECLINED);
    },
    async updateConnectionStatus(status) {
      let confirmation =
        status === ConnectionStatusEnum.ACCEPTED ? 'Confirm' : 'Decline';
      const { confirmed, stopLoading } = await this.$confirm({
        subtitle: `${confirmation} new connection request?`,
        withLoading: true,
        danger: status === ConnectionStatusEnum.DECLINED,
      });

      if (!confirmed) {
        return;
      }

      try {
        this.connection.status = status;
        await this.connection.save();

        this.$emit('updated', this.connection);

        this.$toasted.success(
          status === ConnectionStatusEnum.ACCEPTED
            ? 'Connection established!'
            : 'Connection declined!'
        );
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        stopLoading();
      }
    },
  },
};
</script>
