var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    { attrs: { size: "sm" }, on: { "modal-close": _vm.handleClose } },
    [
      _c("div", { staticClass: "modal__header" }, [
        _c("h3", { staticClass: "modal__title" }, [_vm._v("Download invoice")]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "modal__close",
            attrs: { type: "button" },
            on: { click: _vm.handleClose }
          },
          [_c("IconClose")],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal__body" },
        [
          _c("VSelect", {
            attrs: {
              name: "Select type",
              "value-key": "link",
              options: _vm.invoices
            },
            model: {
              value: _vm.selectedInvoiceLink,
              callback: function($$v) {
                _vm.selectedInvoiceLink = $$v
              },
              expression: "selectedInvoiceLink"
            }
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal__btn-wrapper" }, [
            _c(
              "a",
              { staticClass: "btn", attrs: { href: _vm.selectedInvoiceLink } },
              [_vm._v("Download")]
            )
          ])
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }