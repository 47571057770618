var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Dashboard" } },
    [
      _c("MetricSection", { attrs: { title: "Overview" } }, [
        _c(
          "div",
          { staticClass: "metric-section__tiles" },
          [
            _c("MetricValue", {
              attrs: {
                title: "Total Transactions",
                format: "integer",
                endpoint: "transactionCount/agent"
              }
            }),
            _vm._v(" "),
            _c("MetricValue", {
              attrs: { title: "Commission", endpoint: "commission/agent" }
            }),
            _vm._v(" "),
            _c("MetricValue", {
              attrs: {
                title: "Total products",
                format: "integer",
                endpoint: "productsCount/agent"
              }
            })
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "MetricSection",
        { attrs: { title: "Commission" } },
        [
          _c("MetricTrend", {
            attrs: {
              endpoint: "analytics/agent",
              "partners-endpoint": "availablePartners/agent",
              "products-endpoint": "availableProducts/agent",
              "has-partner-filter": "",
              "has-product-filter": "",
              "has-category-filter": "",
              options: [{ label: "Commission", value: "commission" }]
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "metric-row" }, [
        _c(
          "div",
          { staticClass: "metric-row__half-column" },
          [
            _c(
              "MetricSection",
              { attrs: { title: "Stock Count" } },
              [
                _c("MetricPerformance", {
                  attrs: { "has-time-filter": false, endpoint: "stock/agent" }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "metric-row__half-column" })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }