<template>
  <ModalWithSteps
    class="modal--with-steps register-modal"
    @modal-close="handleClose"
  >
    <div class="modal__header">
      <h3 class="modal__title">Sign up</h3>
      <button type="button" class="modal__close" @click="handleClose">
        <IconClose />
      </button>
    </div>
    <div class="modal__steps__progress">
      <div :style="{ width: `${progressPercentage}%` }"></div>
    </div>
    <div class="modal__body">
      <div v-show="currentStep === 1" class="modal__step">
        <form class="form" @submit.prevent="validateStep(1)">
          <h6 class="register-modal__question">
            Select the option that best describes your business?
          </h6>
          <UserTypeSelect v-model="typeAndIsIndividual" />
          <div class="modal__step__actions">
            <LoadingBtn
              :loading="loadingStep[1]"
              :disabled="!form.type"
              class="btn"
              type="submit"
            >
              Next
            </LoadingBtn>
          </div>
        </form>
      </div>
      <div v-show="currentStep === 2" class="modal__step">
        <ValidationObserver ref="formTwo" v-slot="{ handleSubmit, invalid }">
          <form
            class="form"
            @submit.prevent="handleSubmit(() => validateStep(2))"
          >
            <h6 class="register-modal__question">
              Select which sectors are relevant to your business
            </h6>
            <ValidationProvider
              name="sectors_ids"
              vid="sectors_ids"
              v-slot="{ errors }"
            >
              <div class="form-group">
                <MultiplePillSelect
                  v-model="form.sectors_ids"
                  :loading="loadingSectors"
                  :options="sectors"
                  value-key="id"
                  label-key="name"
                />
                <span v-if="errors[0]" class="form-group__error">{{
                  errors[0]
                }}</span>
              </div>
            </ValidationProvider>
            <div class="modal__step__actions">
              <a
                href="#"
                class="btn btn--secondary"
                @click.prevent="currentStep = 1"
                >Back</a
              >
              <LoadingBtn
                :loading="loadingStep[2]"
                :disabled="invalid"
                class="btn"
                type="submit"
              >
                Next
              </LoadingBtn>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div v-show="currentStep === 3" class="modal__step">
        <ValidationObserver ref="formThree" v-slot="{ handleSubmit, invalid }">
          <form
            class="form"
            @submit.prevent="handleSubmit(() => validateStep(3))"
          >
            <h6 class="register-modal__question">
              Select which services you offer for each sector
            </h6>
            <ServicesBySectorSelect
              v-model="form.services_ids_per_sector"
              :only-sectors-ids="form.sectors_ids"
            />
            <div class="modal__step__actions">
              <a
                href="#"
                class="btn btn--secondary"
                @click.prevent="currentStep = 2"
                >Back</a
              >
              <LoadingBtn
                :loading="loadingStep[3]"
                :disabled="invalid"
                class="btn"
                type="submit"
              >
                Next
              </LoadingBtn>
            </div>
          </form>
        </ValidationObserver>
      </div>
      <div v-show="currentStep === 4" class="modal__step">
        <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
          <h6 class="register-modal__question">
            Almost there! We just need a few more details about your business
          </h6>
          <form class="form" @submit.prevent="handleSubmit(submit)">
            <VInput
              v-model="form.name"
              label="Your name"
              name="Name"
              vid="name"
              rules="required|min:3"
            />
            <VInput
              v-model="form.email"
              label="Your email"
              name="Email"
              vid="email"
              rules="required|email"
            />
            <VInput
              v-model="form.business_name"
              name="Business name"
              vid="business_name"
              rules="required|min:3"
            />
            <VInput
              v-model="form.business_email"
              label="Business Email"
              name="Business Email"
              vid="business_email"
              rules="email"
              label-info-text="- Optional"
            />
            <VInput
              v-model="form.password"
              class="form-group"
              name="Password"
              vid="password"
              rules="required|secure_password|min:8"
              type="password"
            />
            <VInput
              v-model="form.password_confirmation"
              class="form-group"
              name="Password Confirmation"
              vid="password_confirmation"
              rules="required|confirmed:password"
              type="password"
            />
            <VCheckbox
              v-model="form.tc_accepted"
              :value="true"
              vid="tc_accepted"
              class="auth-box__accept"
              >I accept the
              <a :href="route('terms-and-conditions')" target="_blank">Terms</a>
              and
              <a :href="route('privacy-policy')" target="_blank"
                >Privacy Policy</a
              ></VCheckbox
            >
            <div class="modal__step__actions">
              <a
                href="#"
                class="btn btn--secondary"
                @click.prevent="currentStep = 3"
                >Back</a
              >
              <LoadingBtn
                :loading="loading"
                :disabled="invalid"
                class="btn"
                type="submit"
              >
                Next
              </LoadingBtn>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </ModalWithSteps>
</template>
<script>
import ModalWithSteps from '~/components/ModalWithSteps';
import VTextarea from '~/components/form/VTextarea';
import { ValidationObserver } from 'vee-validate';
import VInput from '~/components/form/VInput';
import LoadingBtn from '~/components/LoadingBtn';
import IconClose from '~/components/icons/IconClose';
import VCheckbox from '~/components/form/VCheckbox';
import UserTypeSelect from '~/pages/auth/UserTypeSelect';
import MultiplePillSelect from '~/components/form/MultiplePillSelect';
import Sector from '~/models/Sector';
import ServicesBySectorSelect from '~/pages/auth/ServicesBySectorSelect';
import ValidationProvider from '~/components/form/ValidationProvider';
import UserEnum from '~/enums/UserEnum';
import {
  getPlatformName,
  hasFeatureServices,
  isCas,
  isProduction,
} from '~/utils';
import ApiExceptionEnum from '~/enums/ApiExceptionEnum';
import ConnectionStatusEnum from '~/enums/ConnectionStatusEnum';

export default {
  components: {
    ServicesBySectorSelect,
    MultiplePillSelect,
    VTextarea,
    ValidationObserver,
    VInput,
    LoadingBtn,
    ModalWithSteps,
    IconClose,
    VCheckbox,
    UserTypeSelect,
    ValidationProvider,
  },
  data() {
    return {
      loading: false,
      loadingStep: {
        1: false,
        2: false,
        3: false,
      },
      totalSteps: 4,
      currentStep: 1,
      form: {
        type: null,
        is_individual: null,
        name: '',
        email: '',
        business_name: '',
        business_email: '',
        password: '',
        password_confirmation: '',
        tc_accepted: false,

        sectors_ids: [],
        services_ids_per_sector: {},
      },
      sectors: [],
      loadingSectors: false,
    };
  },
  computed: {
    typeAndIsIndividual: {
      get() {
        return {
          type: this.form.type,
          is_individual: this.form.is_individual,
        };
      },
      set(val) {
        this.form.type = val.type;
        this.form.is_individual = val.is_individual;
      },
    },
    progressPercentage() {
      return (this.currentStep / this.totalSteps) * 100;
    },
  },
  async created() {
    await this.loadSectors();
  },
  methods: {
    async loadSectors() {
      this.loadingSectors = true;
      try {
        this.sectors = await Sector.params({ per_page: 10000 })
          .include('servicesCount')
          .$get();

        if (hasFeatureServices()) {
          this.sectors = this.sectors.filter((s) => s.services_count > 0);
        }

        this.sectors = this.sectors.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loadingSectors = false;
      }
    },

    async submit() {
      this.loading = true;

      try {
        await this.$axios.get('sanctum/csrf-cookie', {
          baseURL: this.url(),
        });

        let attributes = {
          ...this.form,
        };

        if (
          attributes.is_individual === null ||
          attributes.is_individual === undefined
        ) {
          delete attributes.is_individual;
        }

        await this.$axios.post('auth/register', attributes);

        window.location.href = this.route('dashboard').toString();

        setTimeout(() => {
          this.handleClose();
        }, 500);
      } catch (e) {
        const data = e?.response?.data ?? {};
        if (data.code === ApiExceptionEnum.ACCOUNT_NOT_APPROVED) {
          this.handleClose();
          await this.$confirm({
            title: `BOOM - You’re nearly there!!!`,
            subtitle: `<p>Thank you for Registering.</p><p>Your details have been sent for approval.  A member of our team will be in touch shortly.</p>`,
            withLoading: false,
            onlyOk: true,
            okButtonLabel: 'See you soon',
            hideCloseBtn: true,
          });
          return;
        }

        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    async validateStep(step) {
      this.loadingStep[step] = true;

      try {
        await this.$axios.post(`auth/register/validate-step`, {
          ...this.form,
          step,
        });

        // Companies only select the sector.
        if (
          this.currentStep === 2 &&
          (this.form.type === UserEnum.COMPANY || !hasFeatureServices())
        ) {
          this.currentStep = 4;
          return;
        }

        this.currentStep += 1;
      } catch (e) {
        let $form = this.getStepForm(step);
        this.$axios.handleError(e, $form);
      } finally {
        this.loadingStep[step] = false;
      }
    },

    getStepForm(step) {
      switch (step) {
        case 1:
          return this.$refs.formOne;
        case 2:
          return this.$refs.formTwo;
        case 3:
          return this.$refs.formThree;
      }
    },
    handleClose() {
      this.$emit('close');
    },
    hasFeatureServices,
  },
};
</script>
<style lang="scss">
.register-modal {
  .modal__dialog {
    max-width: 528px;
  }

  .modal__step {
    &__actions {
      margin-top: $s-8;
    }
  }

  &__question {
    color: rgba($black, 0.5);
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    margin: $s-2 auto $s-10;
    text-align: center;
    max-width: 338px;
  }

  .services-by-sector-select {
    margin: 0 -#{$modal-body-padding} $s-16;
  }
}
</style>
