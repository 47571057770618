<template>
  <div class="store-listing">
    <div class="store-listing__desktop-top-bar">
      <div class="store-listing__desktop-top-bar__left">
        <SectorFilter
          v-model="filters['category.sector.id']"
          :only-sectors="business.sectors"
          filter-select
          no-label
        />
        <CategoryFilter
          v-model="filters['category.id']"
          :sector-id.sync="filters['category.sector.id']"
          :only-sectors-ids="business.sectors.map((s) => s.id)"
          filter-select
          no-label
        />
      </div>
      <div class="store-listing__desktop-top-bar__center">
        <span>Products</span>
      </div>
      <div class="store-listing__desktop-top-bar__right">
        <VSelect
          v-model="orderBy"
          name="Order by"
          :options="orderByOptions"
          class="filter-select"
        />
        <SearchInput v-model="filters.search" />
      </div>
    </div>
    <div class="store-listing__mobile-top-bar">
      <div class="store-listing__mobile-top-bar__title">
        Products
      </div>
      <div class="mobile-sort-and-filter">
        <div class="mobile-sort-and-filter__sort">
          <VSelect
            v-model="orderBy"
            name="Order by"
            :options="orderByOptions"
            class="filter-select"
          />
        </div>
        <div
          class="mobile-sort-and-filter__filter"
          @click="showFilterSlideout = true"
        >
          <span>Filter</span>
        </div>
      </div>
      <SearchInput v-model="filters.search" />
      <Slideout
        v-if="showFilterSlideout"
        @close="showFilterSlideout = false"
        class="slideout-filters slideout-filters--black dark-theme-input"
      >
        <h5 class="slideout-filters__title">Filter</h5>
        <div class="slideout-filters__body">
          <SectorFilter
            v-model="filters['category.sector.id']"
            :only-sectors="business.sectors"
            no-label
          />
          <CategoryFilter
            v-model="filters['category.id']"
            :sector-id.sync="filters['category.sector.id']"
            :only-sectors-ids="business.sectors.map((s) => s.id)"
            no-label
          />
          <a
            href="#"
            class="btn btn--lg btn--black"
            @click.prevent="showFilterSlideout = false"
            >Done</a
          >
        </div>
      </Slideout>
    </div>
    <div v-if="loadingItems" class="store-listing__loading">
      Loading...
    </div>
    <template v-else>
      <div v-if="!items.length" class="store-listing__no-items">
        No products.
      </div>
      <div v-else class="store-listing__items">
        <div
          v-for="product in items"
          :key="product.id"
          class="store-listing__item"
        >
          <StoreProductCard
            :product="product"
            :get-product-url="getProductUrl"
          />
        </div>
      </div>
    </template>
    <div class="store-listing__pagination">
      <SimplePagination
        :page="page"
        :is-last-page="isLastPage"
        @prev="prev"
        @next="next"
      />
    </div>
  </div>
</template>
<script>
import ListingMixin from '~/pages/shared/ListingMixin';
import VSelect from '~/components/form/VSelect';
import SearchInput from '~/components/datatable/SearchInput';
import StoreProductCard from '~/pages/store/partials/StoreProductCard';
import Slideout from '~/components/Slideout';
import MobileSortAndFilterMixin from '~/components/MobileSortAndFilterMixin';
import CategoryFilter from '~/components/sectors-fields/CategoryFilter';
import SectorFilter from '~/components/sectors-fields/SectorFilter';

export default {
  mixins: [ListingMixin, MobileSortAndFilterMixin],
  components: {
    StoreProductCard,
    VSelect,
    SearchInput,
    Slideout,
    CategoryFilter,
    SectorFilter,
  },
  props: {
    business: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      perPage: 8,
      filters: {
        'category.sector.id': '',
        'category.id': '',
        search: '',
        archived: 0,
      },
      orderBy: '-name',
      orderByOptions: [
        {
          value: '-name',
          label: 'Sort: A-Z',
        },
        {
          value: '-price',
          label: 'Sort: Price',
        },
      ],
      type: null,
      showFilterSlideout: false,
      queryCustomParams: {
        with_stock_total: true,
      },
    };
  },
  methods: {
    getBaseQuery() {},
    getProductUrl() {},
  },
};
</script>
<style lang="scss">
.store-listing {
  $gutter: 24px;
  margin: $s-8 0;

  &__desktop-top-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: $s-4 0;
    border-bottom: 1px solid $gray-500;

    &__left {
      display: flex;
    }

    &__right {
      display: flex;
    }

    &__center {
      span {
        color: rgba($black, 0.5);
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
      }
    }

    .sector-and-category-filters {
      display: flex;
    }

    .filter-select {
      padding-bottom: 0;
      margin-right: $s-4;
    }
  }

  &__mobile-top-bar {
    display: none;
  }

  &__loading,
  &__no-items {
    color: rgba($gray-800, 0.25);
    text-align: center;
    padding: $s-48 0;
    font-size: $text-lg;
    width: 100%;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter / 2;
    margin-left: -$gutter / 2;
    padding-top: $s-8;
  }

  &__item {
    width: 100%;
    padding-right: $gutter / 2;
    padding-left: $gutter / 2;
    flex: 0 0 25%;
    max-width: 25%;
    margin-bottom: $s-12;
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $s-2 auto $s-16;

    > * {
      margin: 0 10px;
    }
  }
}
@include media-breakpoint-down(md) {
  .store-listing {
    margin: 0;

    &__desktop-top-bar {
      display: none;
    }

    &__mobile-top-bar {
      display: block;

      &__title {
        text-align: center;
        padding: $s-4 $container-padding;
        margin: 0 -#{$container-padding};
        border-bottom: 1px solid $gray-500;
        color: rgba($black, 0.5);
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        background-color: $white;
      }

      .filter-select {
        padding-bottom: 0;
      }
    }

    &__loading,
    &__no-items {
      padding: $s-40 0;
    }

    &__items {
    }

    &__item {
      flex: 0 0 33.3333%;
      max-width: 33.3333%;
    }
  }
}

@include media-breakpoint-down(sm) {
  .store-listing {
    $gutter: 16px;

    &__items {
      margin-right: -$gutter / 2;
      margin-left: -$gutter / 2;
    }

    &__item {
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
</style>
