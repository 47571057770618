<script>
import UserEnum from '~/enums/UserEnum';
import Company from '~/models/Company';
import Partner from '~/models/Partner';
import { ValidationObserver } from 'vee-validate';
import LoadingBtn from '~/components/LoadingBtn';
import MediaModelEnum from '~/enums/MediaModelEnum';

export default {
  components: {
    ValidationObserver,
    LoadingBtn,
  },
  data() {
    return {
      loading: false,
      form: null,
    };
  },
  created() {
    this.form = this.getForm();
  },

  computed: {
    id() {
      return this.userable.id;
    },
    userable() {
      return this.$global.userable;
    },

    mediaFor() {
      return this.$global.userType === UserEnum.COMPANY
        ? MediaModelEnum.COMPANY
        : MediaModelEnum.PARTNER;
    },
  },

  methods: {
    async submit() {
      this.loading = true;

      try {
        const model = this.getModel();
        await model.save();
        this.$toasted.success('Settings saved.');

        this.$store.commit('auth/updateUserable', model);
      } catch (e) {
        this.$toasted.error('Failed to save. The form has some errors.');
        this.$axios.handleError(e, this.$refs.form);
      } finally {
        this.loading = false;
      }
    },

    getModel() {
      switch (this.$global.userType) {
        case UserEnum.COMPANY:
          return new Company({
            id: this.id,
            ...this.prepareForm(this.form),
          });
        case UserEnum.PARTNER:
          return new Partner({
            id: this.id,
            ...this.prepareForm(this.form),
          });
      }
    },

    prepareForm(form) {
      return form;
    },
  },
};
</script>
