var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "marketing-page marketing-page-terms-and-conditions" },
      [
        _c("div", { staticClass: "marketing-page__banner" }, [
          _c("div", { staticClass: "container" }, [
            _c("h2", { staticClass: "marketing-page__title" }, [
              _vm._v("Privacy and Cookies")
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "marketing-page__content" }, [
          _c("div", { staticClass: "container" }, [
            _c("h5", [_vm._v("KJB Hair Limited Trading as CaskadePRO")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        We need to process your personal information to run our business and\n        provide you with our services. By accepting our Terms & Conditions,\n        you are confirming that you have read and understand this policy\n        including how and why we use your information. If you don’t want us to\n        collect or process your personal information in the ways described in\n        this policy, you shouldn’t use our services.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Your privacy is of the utmost importance to us, and we will never give\n        your personal details to any outside company for mailing or marketing\n        purposes.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        When you buy from our website / sales platform, we have to collect\n        certain personal information from you (for example: your name, email\n        address, payment address and card details). You may review, change, or\n        remove that name through your account settings. All this information\n        is held on secure servers. KJB Hair Ltd complies fully with all\n        applicable Data Protection and consumer legislation, and will treat\n        all your personal information as completely confidential.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        We know that our customers value having control over their own\n        information, so KJB Hair Ltd gives you the choice of providing,\n        editing or removing certain information. You may change or correct\n        your KJB Hair Limited account information through your account\n        settings or by contacting us. You can also request the deletion of the\n        personal information in your account by emailing\n        "
              ),
              _c("a", { attrs: { href: "mailto:caskade@lanaicreative.com" } }, [
                _vm._v("caskade@lanaicreative.com")
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Upon request, KJB Hair Limited will provide you with information about\n        whether we hold any of your personal information. In certain cases\n        where we process your information, you may also have a right to\n        restrict or limit the ways in which we use your personal information.\n        In certain circumstances, you also have the right to request the\n        deletion of your personal information, and to obtain a copy of your\n        personal information in an easily accessible format.\n      "
              )
            ]),
            _vm._v(" "),
            _c("ol", [
              _c("li", [
                _vm._v(
                  "\n          You may also have certain rights in regards to your information,\n          such as: data access and portability (including the right to obtain\n          a copy of your personal data you provided to KJB Hair Limited, by\n          contacting us); data correction (including the ability to update\n          your personal data, by contacting us)\n        "
                )
              ]),
              _vm._v(" "),
              _c("li", [
                _vm._v(
                  "\n          data deletion (including the right to have KJB Hair Limited delete\n          your personal information, except information we are required to\n          retain, by contacting us)\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        On occasion, KJB Hair Limited may need to contact you. Primarily,\n        these messages are delivered by email. Some messages from KJB Hair\n        Limited are service-related and necessary for members.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        You agree that KJB Hair Limited can send you non-marketing emails or\n        messages, such as those related to transactions, delivery or security.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        You can unsubscribe at any time from marketing emails through the\n        opt-out link included in all marketing emails.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        We principally rely on consent to send marketing messages and for\n        third-party data sharing related to advertising. You can opt in on\n        receiving such emails when you sign up or register with KJB Hair\n        Limited.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        We respect your privacy. KJB Hair Limited will not disclose your name,\n        email address or other personal information to third parties without\n        your consent, except as specified in this policy.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        KJB Hair Limited also needs to engage third-party companies and\n        individuals (such as companies / brands who you are purchasing\n        products from, payment processors, and analytics and security\n        providers and courier companies) to help us operate, provide, and\n        market the services. These third parties have only limited access to\n        your information, may use your information only to perform these tasks\n        on our behalf, and are obligated to KJB Hair Limited not to disclose\n        or use your information for other purposes.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        If you no longer want KJB Hair Limited to use your information to\n        provide the services to you, you may close your account. KJB Hair\n        Limited will retain and use your information to the extent necessary\n        to comply with our legal obligations.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        To give you the best and most efficient service, we use reputable\n        third-party banking and distribution institutions to handle our credit\n        card transactions and order fulfilment. They receive the information\n        needed to verify and authorise your payment card and to process your\n        order. All these organisations are under strict obligation to keep\n        your personal information private.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        If you have any further questions in regards to our privacy policy,\n        please don’t hesitate to contact us on\n        "
              ),
              _c("a", { attrs: { href: "mailto:caskade@lanaicreative.com" } }, [
                _vm._v("caskade@lanaicreative.com")
              ])
            ]),
            _vm._v(" "),
            _c("h5", [_vm._v("Security")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        We realise how important it is to store any information that you\n        provide as securely as possible, and KJB Hair Ltd maintains the\n        highest levels of security. Our site uses high-level SSL encryption\n        technology, the most advanced security software currently available\n        for online transactions.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        When a page is secure, ‘https’ will replace the ‘http’ at the front of\n        the website address in your browser address window. A small locked\n        padlock will also appear in the bottom bar of your browser window.\n      "
              )
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Your account information is protected by a password. It is important\n        that you protect against unauthorised access to your account and\n        information by choosing your password carefully and by keeping your\n        password and computer secure, such as by signing out after using the\n        Services. If you have any questions about the security of your\n        personal information, you can contact us at\n        "
              ),
              _c("a", { attrs: { href: "mailto:caskade@lanaicreative.com" } }, [
                _vm._v("caskade@lanaicreative.com")
              ])
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Unfortunately, no method of transmission over the internet or method\n        of electronic storage is 100% secure. Therefore, while we strive to\n        protect your personal information, we can’t guarantee its absolute\n        security.\n      "
              )
            ]),
            _vm._v(" "),
            _c("h5", [_vm._v("Cookies")]),
            _vm._v(" "),
            _c("p", [
              _vm._v(
                "\n        Cookies are tiny text files stored on your computer when you visit\n        certain web pages. KJB Hair LTD uses cookies to keep track of what you\n        have in your basket and to remember you when you return to our site.\n        To shop at KJB Hair Ltd, you need to have all cookies enabled\n      "
              )
            ])
          ])
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }