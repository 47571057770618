var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "metric-total-breakdown" },
    [
      _vm._l(_vm.values, function(value) {
        return _c("div", { staticClass: "metric-total-breakdown__line" }, [
          _c("div", { staticClass: "label" }, [
            _c("span", { staticClass: "name" }, [_vm._v(_vm._s(value.label))]),
            _vm._v(" "),
            _c("span", { staticClass: "actual-value" }, [
              _vm._v(_vm._s(value.value))
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "progress" },
            [_c("progress-line", { attrs: { value: value.percent } })],
            1
          )
        ])
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.loading,
              expression: "loading"
            }
          ],
          staticClass: "metric-total-breakdown__loader-placeholder"
        },
        [
          _c("div", {
            staticClass: "metric-total-breakdown__loading",
            class: { "metric-total-breakdown--skeleton": _vm.loading }
          })
        ]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }