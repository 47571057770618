<template>
  <DashboardPage title="Dashboard">
    <MetricSection title="Overview">
      <div class="metric-section__tiles">
        <MetricValue
          title="Total Transactions"
          format="integer"
          endpoint="transactionCount/agent"
        />
        <MetricValue title="Commission" endpoint="commission/agent" />
        <MetricValue
          title="Total products"
          format="integer"
          endpoint="productsCount/agent"
        />
      </div>
    </MetricSection>
    <MetricSection title="Commission">
      <MetricTrend
        endpoint="analytics/agent"
        partners-endpoint="availablePartners/agent"
        products-endpoint="availableProducts/agent"
        has-partner-filter
        has-product-filter
        has-category-filter
        :options="[{ label: 'Commission', value: 'commission' }]"
      ></MetricTrend>
    </MetricSection>
    <div class="metric-row">
      <div class="metric-row__half-column">
        <MetricSection title="Stock Count">
          <MetricPerformance
            :has-time-filter="false"
            endpoint="stock/agent"
          ></MetricPerformance>
        </MetricSection>
      </div>
      <div class="metric-row__half-column"></div>
    </div>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import MetricSection from '~/components/MetricSection';
import MetricValue from '~/components/metrics/MetricValue';
import MetricTrend from '~/components/metrics/MetricTrend';
import MetricPerformance from '~/components/metrics/MetricPerformance';
import MetricPageMixin from '~/pages/dashboard/common/MetricPageMixin';

export default {
  extends: Page,
  mixins: [MetricPageMixin],
  components: {
    DashboardPage,
    Datatable,
    MetricSection,
    MetricValue,
    MetricTrend,
    MetricPerformance,
  },
};
</script>
