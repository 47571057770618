var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "testimonials-forms" },
    [
      !_vm.testimonials.length
        ? _c(
            "a",
            {
              staticClass: "btn btn--secondary",
              attrs: { href: "#" },
              on: { click: _vm.onAdd }
            },
            [_vm._v("Add testimonial")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.testimonials, function(testimonial, index) {
        return _c("TestimonialForm", {
          key: testimonial.id,
          attrs: {
            partner: _vm.userable,
            testimonial: testimonial,
            last: index === _vm.testimonials.length - 1
          },
          on: { add: _vm.onAdd, updated: _vm.onUpdated, removed: _vm.onRemoved }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }