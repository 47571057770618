<template>
  <a
    v-if="product.video"
    href="#"
    class="pd__watch-video"
    @click.prevent="openLightbox"
  >
    <IconPlay width="16" height="16" />
    <span>Watch product video</span>
  </a>
</template>
<script>
import BigPicture from 'bigpicture';
import IconPlay from '~/components/icons/IconPlay';

export default {
  components: {
    IconPlay,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  created() {},

  methods: {
    openLightbox(event) {
      let attribute;

      let video = this.product.video;
      if (video.provider === 'youtube') {
        attribute = { ytSrc: video.id };
      } else {
        attribute = { vimeoSrc: video.id };
      }

      BigPicture({
        el: event.target,
        ...attribute,
      });
    },
  },
};
</script>
<style lang="scss">
.pd__watch-video {
  display: flex;
  align-items: center;

  svg {
    color: $gray-800;
    margin-right: $s-2;
  }

  span {
    font-size: 14px;
    line-height: 20px;
    color: $gray-800;
    font-weight: 500;
  }
}
</style>
