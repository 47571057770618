<script>
export default {
  computed: {
    place: {
      get() {
        return {
          place_id: this.form.place_id,
          place_description: this.form.place_description,
        };
      },

      set(value) {
        this.form.place_id = value.place_id;
        this.form.place_description = value.place_description;
      },
    },
  },
};
</script>
