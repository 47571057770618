<template>
  <div class="layout layout-landing">
    <header class="landing-header">
      <div class="container">
        <div class="landing-header__left-placeholder">
          &nbsp;
        </div>
        <div class="landing-header__menu">
          <LandingAndMarketingMenu />
        </div>
      </div>
    </header>
    <slot />
    <footer class="landing-footer">
      <div class="container">
        <a :href="route('privacy-policy')">Privacy policy</a>
        <a :href="route('terms-and-conditions')">Terms and conditions</a>
        <a :href="route('delivery')">Delivery</a>
        <a :href="route('returns')">Returns</a>
      </div>
    </footer>
    <div v-if="isCas" class="supported-by">
      <div class="container">
        <img :src="url('images/supported-by.png')" />
      </div>
    </div>
    <PortalTarget name="modals" multiple />
    <ConfirmModal />
  </div>
</template>
<script>
import LandingAndMarketingMenu from '~/layouts/partials/LandingAndMarketingMenu';
import { isCas } from '~/utils';
import ConfirmModal from '~/components/confirm-modal/ConfirmModal';

export default {
  data() {
    return {
      isCas: isCas(),
    };
  },
  components: {
    LandingAndMarketingMenu,
    ConfirmModal,
  },
};
</script>
<style lang="scss">
.layout-landing {
  min-height: 100vh;
  background-image: $landing-gradient;
}
.landing-header {
  .container {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.landing-footer {
  background-color: rgba($black, 0.03);

  .container {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    color: rgba($white, 0.75);
    font-size: 14px;
    font-weight: 300;
    padding: 0 $s-4;
    transition: color 0.15s ease-out;
  }

  @at-root {
    .no-touchevents {
      .landing-footer a:hover {
        color: rgba($white, 1);
      }
    }
  }
}

.supported-by {
  padding: $s-16 0 $s-10;
  background-color: #fff;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 320px;
    }
  }
}
</style>
