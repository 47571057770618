<template>
  <div class="form-group">
    <label>
      <span>Address</span>
      <span class="optional-label">- Also accepts Google Maps Plus Code</span>
    </label>
    <div class="form-control-wrapper form-control-wrapper--with-info-icon">
      <div class="typeahead" v-on-clickaway="onClickAway">
        <div class="typeahead__input">
          <input
            v-model="input"
            type="text"
            @input="onInput"
            autocomplete="chrome-off"
          />
          <Loader v-if="loading" />
        </div>
        <transition name="typeahead-options-transition">
          <div v-show="showPredictions" class="typeahead__options">
            <span
              v-if="predictions.length === 0 && !loading"
              class="typeahead__no-results"
              style="text-align: center;"
              >No addresses found.</span
            >
            <a
              v-for="prediction in predictions"
              href="#"
              class="typeahead__item"
              @click.prevent="onSelect(prediction)"
              >{{ prediction.description }}</a
            >
          </div>
        </transition>
      </div>
      <div
        class="form-control-info"
        v-tooltip="
          'Please enter your Google Maps Plus Code if your Street Address is not prompted in the address bar.'
        "
      >
        <IconMoreInfo width="20" height="20" />
      </div>
    </div>
  </div>
</template>
<script>
import { mixin as clickaway } from 'vue-clickaway';
import { ValidationProvider } from 'vee-validate';
import { debounce } from '~/utils';
import Loader from '~/components/Loader';
import IconMoreInfo from '~/components/icons/IconMoreInfo';

export default {
  mixins: [clickaway],
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  components: {
    Loader,
    ValidationProvider,
    IconMoreInfo,
  },
  data() {
    return {
      input: this.value.place_description,
      loading: false,
      showPredictions: false,
      predictions: [],
      selectedPrediction: {
        place_description: this.value?.place_description,
        place_id: this.value?.place_id,
      },
    };
  },
  computed: {},
  async mounted() {
    this.$_request = null;
  },
  methods: {
    onInput() {
      this.showPredictions = true;
      this.debouncedGetPredictions();
    },
    onSelect(prediction) {
      this.input = prediction.description;
      this.selectedPrediction = {
        place_description: prediction.description,
        place_id: prediction.place_id,
      };
      this.$emit('input', this.selectedPrediction);
      this.showPredictions = false;
    },

    onClickAway() {
      if (this.selectedPrediction.place_id) {
        this.input = this.selectedPrediction.place_description;
      } else {
        this.input = '';
      }
      this.showPredictions = false;
    },

    debouncedGetPredictions: debounce(function () {
      this.getPredictions();
    }, 200),

    async getPredictions() {
      this.loading = true;

      // Cancel previous request.
      if (this.$_request) {
        this.$_request.cancel();
      }

      this.$_request = this.$axios.CancelToken.source();

      try {
        let response = await this.$axios.get('/v1/google-places-autocomplete', {
          params: {
            input: this.input,
          },
          cancelToken: this.$_request.token,
        });

        if (response.data.status !== 'OK') {
          this.predictions = [];
        } else {
          this.predictions = response.data.predictions;
        }

        this.$_request = null;
      } catch (e) {
        if (this.$axios.isCancel(e)) {
          return;
        }

        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
.typeahead {
  position: relative;

  &__input {
    position: relative;

    input {
      padding-right: $s-8;
    }

    .loader {
      position: absolute;
      font-size: 8px;
      margin: 0;
      right: $s-4;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  &__options {
    top: calc(100% + 10px);
    background-color: #fff;
    position: absolute;
    z-index: 1;
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    overflow: hidden;
  }

  &__item {
    width: 100%;
    font-weight: 500;
    color: $black;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 20px;
    padding: 10px 16px;
    display: block;
    background-color: $white;
    transition: background-color 0.15s ease-out;
  }

  &__no-results {
    padding: 20px;
    width: 100%;
    text-align: center;
    display: block;
    background-color: $white;
    font-size: 12px;
    line-height: 20px;
  }

  @at-root {
    .no-touchevents {
      .typeahead__item:hover {
        background-color: $gray-100;
      }
    }
  }
}

.typeahead-options-transition-enter-active,
.typeahead-options-transition-leave-active {
  transition: opacity 0.1s ease-out, transform 0.1s ease-out;
}

.typeahead-options-transition-enter,
.typeahead-options-transition-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
