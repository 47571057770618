<template>
  <div class="pd__add-to-cart-form">
    <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid, errors }">
      <form @submit.prevent="handleSubmit(add)" class="form">
        <div
          v-if="product.colors.length"
          class="form-group pd__add-to-cart-form__color"
        >
          <label><span>Choose a colour</span></label>
          <div class="pd__add-to-cart-form__color__samples">
            <a
              href="#"
              v-for="color in product.colors"
              :key="color"
              class="pd__add-to-cart-form__color__sample"
              :class="{
                'pd__add-to-cart-form__color__sample--selected':
                  color === form.color,
              }"
              :style="{ backgroundColor: `#${color}` }"
              @click.prevent="form.color = color"
            ></a>
          </div>
        </div>
        <VInput
          v-if="product.question"
          v-model="form.answer"
          :label="product.question"
          vid="answer"
          rules="required|min:1"
          :disabled="outOfStock"
        />
        <div class="pd__add-to-cart-form__qty-and-btn">
          <VQtyInput
            v-model="form.qty"
            name="quantity"
            vid="qty"
            no-label
            rules="numeric|min_value:1"
            :disabled="outOfStock"
          />
          <LoadingBtn
            :loading="loading"
            :disabled="invalid || !!(product.colors.length && !form.color)"
            class="btn btn--lg btn--black"
            type="submit"
            >{{ outOfStock ? 'Out of stock' : 'Add to cart' }} -
            {{
              Price(product.price, vatRate).gross().multiply(form.qty).format()
            }}
          </LoadingBtn>
          <span
            v-if="errors.qty && errors.qty.length"
            class="pd__add-to-cart-form__error"
            >{{ errors.qty[0] }}</span
          >
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>
<script>
import { ValidationObserver } from 'vee-validate';
import VInput from '~/components/form/VInput';
import VQtyInput from '~/components/form/VQtyInput';
import LoadingBtn from '~/components/LoadingBtn';
import Price from '~/price';
import { mapGetters } from 'vuex';

export default {
  components: {
    ValidationObserver,
    VInput,
    VQtyInput,
    LoadingBtn,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      Price,
      loading: false,
      form: {
        color: null,
        answer: null,
        qty: 1,
      },
    };
  },
  computed: {
    ...mapGetters('global', ['vatRate']),
    outOfStock() {
      return this.product.stock_count === 0;
    },
  },
  methods: {
    async add() {
      if (this.outOfStock) {
        return;
      }

      this.loading = true;

      await this.$store.dispatch('cart/add', {
        product: {
          id: this.product.id,
          name: this.product.name,
          company_id: this.product.company_id,
          delivery_cost: this.product.delivery_cost,
          company: {
            business_name: this.product.company.business_name,
          },
          price: this.product.price,
          size: this.product.size,
        },
        qty: this.form.qty,
        options: {
          color: this.form.color,
          answer: this.form.answer,
        },
      });

      this.form.qty = 1;
      this.form.color = null;
      this.form.answer = null;
      this.$toasted.success('Product added to the cart!');

      this.$refs.form.reset();

      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.pd__add-to-cart-form {
  margin-top: $s-12;

  label {
    color: rgba($gray-800, 0.5);
  }

  &__color {
    &__samples {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: -$s-4;
    }

    &__sample {
      width: 40px;
      height: 40px;
      margin-right: $s-4;
      margin-bottom: $s-4;
      border-radius: 50%;
      position: relative;

      &--selected {
        &:after {
          content: '';
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translateX(-50%) translateY(-50%);
          width: 18px;
          height: 18px;
          background-color: $white;
          border-radius: 50%;
        }
      }
    }
  }

  &__qty-and-btn {
    display: flex;
    align-items: flex-start;
    position: relative;

    > div:first-child {
      flex-basis: 30%;
      margin-right: $s-6;
    }

    .btn {
      flex: 1 0 70%;
    }
  }

  // Hide the errors in the qty field as there's not enough space.
  .form-group {
    .error {
      display: none;
    }
  }

  &__error {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 10px;
    display: block;
    text-align: right;
    font-size: 10px;
    font-weight: 300;
    line-height: 16px;
    color: $primary;
  }
}

@include media-breakpoint-down(sm) {
  .pd__add-to-cart-form {
    &__qty-and-btn {
      > div:first-child {
        margin-right: $s-4;
      }
    }
  }
}
</style>
