<template>
  <div class="store-page">
    <Loader v-if="!business" />
    <template v-else>
      <div class="store__header">
        <div class="store__container">
          <div class="store__header__logo">
            <LogoMarkStoreHeader />
            <LogoStoreHeader />
          </div>
          <h3 class="store__header__partner-logo">
            <a
              :href="
                route(`store.${type}`, {
                  [type]: this.business.slug,
                })
              "
            >
              <VClamp autoresize :max-lines="1"
                >{{ business.business_name }} Store</VClamp
              ></a
            >
          </h3>
          <a
            href="#"
            class="store__header__cart"
            @click.prevent="showCartSlideout = true"
          >
            <IconBag />
            <span>{{ cartCount }} / {{ grossTotal.format() }}</span>
          </a>
          <Slideout
            v-if="showCartSlideout"
            @close="showCartSlideout = false"
            class="slideout--cart-slideout"
          >
            <div class="store__container">
              <Cart :business="business" :type="type" />
            </div>
          </Slideout>
        </div>
      </div>
      <slot :business="business"></slot>
      <div class="store__footer">
        <div class="store__footer__contact-details">
          <a
            v-if="business.geocoded_address && showAddressOrPhone"
            :href="business.geocoded_address.maps_url"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <IconAddress width="18" height="18" />
            <span>{{ business.place_description }}</span>
          </a>
          <a
            v-if="business.contact_number && showAddressOrPhone"
            :href="`tel:${business.contact_number}`"
          >
            <IconTelephone width="18" height="18" />
            <span>{{ business.contact_number }}</span>
          </a>
          <a
            v-if="business.website"
            :href="business.website"
            rel="noopener noreferrer nofollow"
            target="_blank"
          >
            <IconWebsite width="18" height="18" />
            <span>{{ business.website }}</span>
          </a>
          <a
            v-if="business.business_email"
            :href="`mailto:${business.business_email}`"
          >
            <IconEmail width="18" height="18" />
            <span>{{ business.business_email }}</span>
          </a>
        </div>
        <div class="store__footer__app-logo">
          <LogoStoreFooter />
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import ConfirmModal from '~/components/confirm-modal/ConfirmModal';
import Loader from '~/components/Loader';
import IconBag from '~/components/icons/IconBag';
import IconEmail from '~/components/icons/IconEmail';
import IconWebsite from '~/components/icons/IconWebsite';
import IconTelephone from '~/components/icons/IconTelephone';
import IconAddress from '~/components/icons/IconAddress';
import { mapGetters } from 'vuex';
import Cart from '~/components/Cart';
import IconDropUp from '~/components/icons/IconDropUp';
import Slideout from '~/components/Slideout';
import VClamp from 'vue-clamp';
import LogoStoreHeader from '~/components/LogoStoreHeader';
import LogoMarkStoreHeader from '~/components/LogoMarkStoreHeader';
import LogoStoreFooter from '~/components/LogoStoreFooter';
import PersistentStorage from '~/persistent-storage';
import { isTt1 } from '~/utils';

export default {
  components: {
    Slideout,
    IconDropUp,
    Cart,
    IconBag,
    Loader,
    ConfirmModal,
    IconEmail,
    IconWebsite,
    IconTelephone,
    IconAddress,
    VClamp,
    LogoStoreHeader,
    LogoMarkStoreHeader,
    LogoStoreFooter,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
    business: {
      validator: (prop) => typeof prop === 'object' || prop === null,
      required: true,
    },
  },
  data() {
    return {
      showCartSlideout: false,
    };
  },

  computed: {
    ...mapGetters('cart', ['cartCount', 'grossTotal']),
    showAddressOrPhone() {
      if (isTt1()) {
        return false;
      }

      if (this.business.is_individual) {
        return false;
      }

      return true;
    },
  },

  mounted() {
    window.addEventListener('pageshow', this.onPageShow);
  },
  destroyed() {
    window.removeEventListener('pageshow', this.onPageShow);
  },
  methods: {
    onPageShow(event) {
      // Added due to safari back-forward cache.
      // @https://gomakethings.com/fixing-safaris-back-button-browser-cache-issue-with-vanilla-js/
      // If the user added the product to the cart and then clicks back, it won't show.
      if (event.persisted) {
        this.$store.commit('cart/setItems');
      }
    },
  },
};
</script>
<style lang="scss">
.store-page {
  overflow: hidden;

  .loader {
    margin: $s-24 0;
  }
}

.store {
  &__container {
    max-width: 1128px + 30px;
    padding: 0 $container-padding;
    margin: 0 auto;
  }

  &__header {
    padding: $s-5 0;

    .store__container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__logo {
      flex: 0 0 200px;
    }

    &__cart {
      flex: 0 0 200px;
      display: flex;
      justify-content: flex-end;
    }

    .logo {
      width: 168px;
    }

    .logo-mark {
      display: none;
    }

    &__partner-logo {
      font-size: 30px;
      font-weight: 300;
      line-height: 48px;
      color: $gray-800;
      padding: 0 $s-2;
    }

    &__cart {
      display: flex;
      align-items: center;

      svg {
        color: rgba($gray-800, 0.25);
        margin-right: $s-4;
      }

      span {
        font-size: 12px;
        color: rgba($gray-800, 0.5);
      }
    }

    .slideout--cart-slideout {
      padding: $s-8 0 0;
      background-color: $gray-800;

      .store__container {
        display: block;
      }
    }
  }

  &__footer {
    background-color: $gray-800;

    &__contact-details {
      padding: $s-6 $s-4;
      display: flex;
      justify-content: center;
      align-items: center;

      a {
        color: rgba($white, 0.75);
        margin: 0 $s-6;
        font-size: 14px;
        display: flex;
        align-items: center;

        svg {
          margin-right: $s-3;
        }

        @at-root {
          .no-touchevents {
            .store__footer__contact-details a:hover {
              color: $white;
            }
          }
        }
      }
    }

    &__app-logo {
      padding: $s-5 0;
      background-color: rgba($white, 0.03);
      display: flex;
      align-items: center;
      justify-content: center;

      .logo {
        width: 168px;
      }
    }
  }
}
@include media-breakpoint-down(md) {
  .store {
    &__header {
      background-color: $white;
      border-bottom: 1px solid $gray-500;

      .logo {
        display: none;
      }

      &__logo {
        flex: 0 0 34px;
      }

      &__cart {
        flex: 0 0 34px;
      }

      .logo-mark {
        display: block;
        width: 32px;
      }

      &__partner-logo {
        font-size: 18px;
        line-height: 28px;
      }

      &__cart {
        svg {
          margin-right: 0;
        }

        span {
          display: none;
        }
      }

      .slideout--cart-slideout {
        padding: $s-6 0 $s-2;
      }
    }

    &__footer {
      &__contact-details {
        padding: $s-8 $container-padding;
        display: flex;
        flex-direction: column;

        a {
          margin: $s-4 0;

          svg {
            margin-right: $s-2;
          }
        }
      }
    }
  }
}
</style>
