var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "store-similar" },
    [
      _vm.loading
        ? _c("Loader")
        : [
            _c("div", { staticClass: "store-similar__top-bar" }, [
              _c("h5", { staticClass: "store-similar__top-bar__title" }, [
                _vm._v("Similar products")
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "store-similar__products" },
              _vm._l(_vm.products, function(product) {
                return _c(
                  "div",
                  {
                    key: product.id,
                    staticClass: "store-similar__products__item"
                  },
                  [
                    _c("StoreProductCard", {
                      attrs: {
                        product: product,
                        "get-product-url": _vm.getProductUrl
                      }
                    })
                  ],
                  1
                )
              }),
              0
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }