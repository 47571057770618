<template>
  <DashboardPage title="Marketplace">
    <div class="marketplace">
      <p class="marketplace__intro">
        {{ introText }}
      </p>
      <div class="marketplace__desktop-top-bar">
        <div class="marketplace__desktop-top-bar__left">
          <SectorFilter
            v-model="filters['sectors.id']"
            filter-by-auth
            filter-select
            no-label
          />
          <!--          <CategoryFilter-->
          <!--            v-if="type !== UserEnum.PARTNER"-->
          <!--            v-model="filters['sectors.categories.id']"-->
          <!--            :sector-id.sync="filters['sectors.id']"-->
          <!--            filter-by-auth-->
          <!--            filter-select-->
          <!--            no-label-->
          <!--          />-->
          <ServiceFilter
            v-if="type === UserEnum.PARTNER && hasFeatureServices()"
            v-model="filters['services.id']"
            :sector-id.sync="filters['sectors.id']"
            filter-by-auth
            filter-select
            no-label
          />
        </div>
        <div class="marketplace__desktop-top-bar__right">
          <VSelect
            v-model="orderBy"
            name="Order by"
            :options="orderByOptions"
            class="filter-select"
          />
          <SearchInput v-model="filters.search" />
        </div>
      </div>
      <div class="marketplace__mobile-top-bar">
        <div class="mobile-sort-and-filter">
          <div class="mobile-sort-and-filter__sort">
            <VSelect
              v-model="orderBy"
              name="Order by"
              :options="orderByOptions"
              class="filter-select"
            />
          </div>
          <div
            class="mobile-sort-and-filter__filter"
            @click="showFilterSlideout = true"
          >
            <span>Filter</span>
          </div>
        </div>
        <SearchInput v-model="filters.search" />
        <Slideout
          v-if="showFilterSlideout"
          @close="showFilterSlideout = false"
          class="slideout-filters"
        >
          <h5 class="slideout-filters__title">Filter</h5>
          <div class="slideout-filters__body">
            <SectorFilter
              v-model="filters['sectors.id']"
              filter-by-auth
              no-label
            />
            <CategoryFilter
              v-if="type !== UserEnum.PARTNER"
              v-model="filters['sectors.categories.id']"
              :sector-id.sync="filters['sectors.id']"
              filter-by-auth
              no-label
            />
            <ServiceFilter
              v-if="type === UserEnum.PARTNER"
              v-model="filters['services.id']"
              :sector-id.sync="filters['sectors.id']"
              filter-by-auth
              no-label
            />
            <a
              href="#"
              class="btn btn--lg"
              @click.prevent="showFilterSlideout = false"
              >Done</a
            >
          </div>
        </Slideout>
      </div>
      <div class="marketplace__listing">
        <div v-if="loadingItems" class="marketplace__listing__loading">
          Loading...
        </div>
        <template v-else>
          <div v-if="!items.length" class="marketplace__listing__no-items">
            No {{ pluralLabel }} found.
          </div>
          <div
            v-else
            v-for="item in items"
            :key="item.id"
            class="marketplace__listing__col"
          >
            <MarketplaceCard
              :type="type"
              :applicant="userable"
              :business="item"
              :connection="getConnection(item)"
              @connect-request="handleConnectRequest"
            />
          </div>
        </template>
      </div>
      <div class="marketplace__pagination" v-if="showPagination">
        <SimplePagination
          :page="page"
          :is-last-page="isLastPage"
          @prev="prev"
          @next="next"
        />
      </div>
    </div>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import DashboardPage from '~/components/DashboardPage';
import SelectDropdown from '~/components/SelectDropdown';
import MarketplaceCard from '~/pages/dashboard/marketplace/MarketplaceCard';
import VSelect from '~/components/form/VSelect';
import SearchInput from '~/components/datatable/SearchInput';
import UserEnum from '~/enums/UserEnum';
import ListingMixin from '~/pages/shared/ListingMixin';
import PersistentStorage from '~/persistent-storage';
import Modal from '~/components/Modal';
import IconClose from '~/components/icons/IconClose';
import Slideout from '~/components/Slideout';
import MobileSortAndFilterMixin from '~/components/MobileSortAndFilterMixin';
import { mapGetters, mapState } from 'vuex';
import SectorField from '~/components/sectors-fields/SectorField';
import SectorFilter from '~/components/sectors-fields/SectorFilter';
import CategoryFilter from '~/components/sectors-fields/CategoryFilter';
import ServiceFilter from '~/components/sectors-fields/ServiceFilter';
import { hasFeatureServices } from '~/utils';
import ApprovalEnum from '~/enums/ApprovalEnum';

export default {
  extends: Page,
  mixins: [ListingMixin, MobileSortAndFilterMixin],
  components: {
    ServiceFilter,
    CategoryFilter,
    SectorFilter,
    SectorField,
    Slideout,
    VSelect,
    MarketplaceCard,
    DashboardPage,
    SelectDropdown,
    SearchInput,
    Modal,
    IconClose,
  },
  data() {
    return {
      UserEnum,
      filters: {
        'sectors.id': '',
        'sectors.categories.id': '',
        'services.id': '',
        search: '',
        has_stripe_connected: 1,
        has_profile_complete: 1,
        approval: ApprovalEnum.APPROVED,
      },
      orderByOptions: [],
      connections: [],
      pluralLabel: '',
      type: null,
      introText: '',
    };
  },
  computed: {
    ...mapState('auth', ['userable']),
    ...mapGetters('auth', ['userType']),
  },

  async created() {
    this.loadingItems = true;
    this.loadFromQueryString();
    // TODO: maybe add a beforeFetch callback on the ListingMixin
    if (this.userType !== UserEnum.ADMIN) {
      await this.loadConnections();
    }
    await this.fetch();
    this.loadingItems = false;
  },

  methods: {
    getConnectionsBaseQuery() {},
    async loadConnections() {
      // Load the companies connections.
      // TODO: maybe get a simpler map with partner_id => status
      this.connections = await this.getConnectionsBaseQuery()
        .params({
          per_page: 1000,
        })
        .$get();
    },

    getBaseQuery() {},

    getConnection(business) {},

    handleConnectRequest(connection) {
      this.connections.push(connection);
    },
    afterFetch() {
      PersistentStorage.setLastMarketplaceUrl(window.location.href);
    },
    hasFeatureServices,
  },
};
</script>
<style lang="scss">
.marketplace {
  &__intro {
    max-width: 700px;
    font-size: 32px;
    line-height: 44px;
    color: rgba($gray-800, 0.5);
    margin: 30px 0 45px;
  }

  &__desktop-top-bar {
    display: flex;
    justify-content: space-between;
    padding: $s-4 0;
    border-bottom: 1px solid $gray-500;

    &__left {
      display: flex;
    }

    &__right {
      display: flex;
    }

    .filter-select {
      padding-bottom: 0;
      margin-right: $s-4;
    }
  }

  &__mobile-top-bar {
    display: none;
  }

  &__listing {
    $gutter: 30px;
    display: flex;
    flex-wrap: wrap;
    margin-right: -$gutter / 2;
    margin-left: -$gutter / 2;

    &__loading,
    &__no-items {
      color: rgba($gray-800, 0.25);
      text-align: center;
      padding: $s-20 0;
      font-size: $text-lg;
      width: 100%;
    }

    &__col {
      width: 100%;
      padding-right: $gutter / 2;
      padding-left: $gutter / 2;
      flex: 0 0 50%;
      max-width: 50%;
    }
  }

  &__loading {
    color: rgba($gray-800, 0.25);
    text-align: center;
    padding: $s-6 0;
    font-size: $text-lg;
  }

  &__pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: $s-6 auto $s-4;

    > * {
      margin: 0 10px;
    }
  }
}

@include media-breakpoint-down(md) {
  .marketplace {
    &__intro {
      display: none;
    }

    &__desktop-top-bar {
      display: none;
    }

    &__mobile-top-bar {
      display: block;
      margin-top: -$s-6;
      padding-bottom: $s-4;

      .mobile-sort-and-filter {
        .form-group {
          padding-bottom: 0;
        }

        .filter-select {
          margin-right: 0;
        }
      }
    }

    &__listing {
      display: block;
      margin: 0;

      &__col {
        padding: 0;
        max-width: 100%;
        width: 100%;
      }
    }
  }
}
</style>
