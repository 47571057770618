<template>
  <DashboardPage title="Partners">
    <Tabs>
      <Tab name="Live" selected>
        <PartnersTable
          ref="livePartnersTable"
          :approval="ApprovalEnum.APPROVED"
        />
      </Tab>
      <Tab name="Pending">
        <PartnersTable
          ref="pendingPartnersTable"
          :approval="ApprovalEnum.PENDING"
        >
          <template v-slot:row-actions="{ item }">
            <ApproveBusiness
              :business-type="UserEnum.PARTNER"
              :business="getModelFromObject(item._meta)"
              @updated="onUpdated"
            />
          </template>
        </PartnersTable>
      </Tab>
      <Tab name="Declined">
        <PartnersTable
          ref="declinedPartnersTable"
          :approval="ApprovalEnum.DECLINED"
        />
      </Tab>
    </Tabs>
  </DashboardPage>
</template>
<script>
import Page from '~/pages/Page';
import Datatable from '~/components/datatable/Datatable';
import DashboardPage from '~/components/DashboardPage';
import PartnersTable from '~/pages/dashboard/admin/partials/PartnersTable';
import Tabs from '~/components/Tabs';
import Tab from '~/components/Tab';
import ApproveBusiness from '~/components/ApproveBusiness';
import Partner from '~/models/Partner';
import UserEnum from '~/enums/UserEnum';
import ApprovalEnum from '~/enums/ApprovalEnum';

export default {
  extends: Page,
  components: {
    ApproveBusiness,
    PartnersTable,
    DashboardPage,
    Datatable,
    Tabs,
    Tab,
  },
  data() {
    return {
      UserEnum,
      ApprovalEnum,
    };
  },
  methods: {
    getModelFromObject(item) {
      return new Partner(item);
    },
    async onUpdated() {
      await this.$refs.pendingPartnersTable.refresh();
      await this.$refs.livePartnersTable.refresh();
      await this.$refs.declinedPartnersTable.refresh();
    },
  },
};
</script>
