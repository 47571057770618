var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M17.238 13.435c2.629.003 4.759 2.142 4.762 4.782C22 20.86 19.868 23 17.238 23c-2.63 0-4.762-2.141-4.762-4.783 0-2.641 2.132-4.782 4.762-4.782zm.08-12.033l2.377 1.592L6.682 22.598l-2.378-1.591L17.318 1.402zm-.08 14.902a1.909 1.909 0 00-1.905 1.913c0 1.057.853 1.913 1.905 1.913a1.909 1.909 0 001.905-1.913 1.909 1.909 0 00-1.905-1.913zM6.762 1c2.63 0 4.762 2.141 4.762 4.783-.003 2.64-2.133 4.78-4.762 4.782C4.132 10.565 2 8.424 2 5.783 2 3.14 4.132 1 6.762 1zm0 2.87a1.909 1.909 0 00-1.905 1.913c0 .507.2.994.558 1.352a1.9 1.9 0 001.347.56 1.909 1.909 0 001.905-1.912A1.909 1.909 0 006.762 3.87z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }