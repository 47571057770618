var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M4.667 19.333a1.833 1.833 0 110 3.667 1.833 1.833 0 010-3.667zm14.666 0a1.833 1.833 0 110 3.667 1.833 1.833 0 010-3.667zM1.27 1.27a.916.916 0 011.296 0L5.046 3.75h16.12a.916.916 0 01.87 1.207l-2.75 8.25a.915.915 0 01-.87.626H5.127L3.75 15.667h18.333a.916.916 0 110 1.833H1.917a.918.918 0 01-.733-1.467l2.566-3.422V5.046L1.27 2.565a.916.916 0 010-1.296z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }