<template>
  <div class="datatable-wrapper">
    <div class="datatable-wrapper__top-bar">
      <SearchInput v-model="filters.search" />
      <div class="datatable-wrapper__top-bar__actions">
        <slot name="actions"></slot>
      </div>
      <CreateReport :get-request="getRequest" />
    </div>
    <div class="datatable">
      <Loader v-if="initialLoading" />
      <template v-else>
        <Table
          :fields="fields"
          :items="resources"
          :loading="loading"
          :active-order-by="orderBy"
          @sort="onSort"
        >
          <template v-if="loading" v-slot:body="{ items }">
            <tr v-for="item in items" :key="item.id">
              <td class="table__cell">
                <span>&nbsp;</span>
              </td>
            </tr>
          </template>
          <template v-else v-slot:[`cell(actions)`]="{ item }">
            <slot :name="`cell(actions)`" v-bind:item="item" />
          </template>
        </Table>
        <div class="datatable__footer">
          <div class="datatable__per-page">
            <span>Rows per page:</span>
            <select v-model="perPage">
              <option v-for="option in perPageOptions" :value="option"
                >{{ option }}
              </option>
            </select>
          </div>
          <div class="datatable__from-to">
            <span
              >{{ pagination.from }}-{{ pagination.to }} of
              {{ pagination.total }}</span
            >
          </div>
          <div class="datatable__nav">
            <a
              href="#"
              class="datatable__nav__prev"
              :class="{ disabled: disablePrev }"
              @click.prevent="onPrev"
            >
              <IconChevronLeft :width="20" :height="20" />
            </a>
            <a
              href="#"
              class="datatable__nav__next"
              :class="{ disabled: disableNext }"
              @click.prevent="onNext"
            >
              <IconChevronRight :width="20" :height="20" />
            </a>
            <div
              v-if="loading && onFirstRequest"
              class="datatable__footer__loader"
            ></div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import SearchInput from '~/components/datatable/SearchInput';
import { debounce } from '~/utils';
import ButtonDropdown from '~/components/ButtonDropdown';
import IconChevronLeft from '~/components/icons/IconChevronLeft';
import IconChevronRight from '~/components/icons/IconChevronRight';
import CreateReport from '~/components/datatable/CreateReport';
import Loader from '~/components/Loader';
import Table from '~/components/Table';

export default {
  components: {
    Loader,
    CreateReport,
    Table,
    ButtonDropdown,
    SearchInput,
    IconChevronLeft,
    IconChevronRight,
  },
  props: {
    getBaseQuery: {
      type: Function,
      required: true,
    },
    queryCustomParams: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    defaultOrderBy: {
      type: String,
      required: false,
      default: '-created_at',
    },
  },
  data() {
    return {
      fields: [],
      initialLoading: true,
      loading: true,
      page: 1,
      perPage: 10,
      pagination: {
        from: 0,
        to: 0,
        total: null,
        last_page: null,
      },
      resources: [],
      filters: {
        search: '',
      },
      perPageOptions: [10, 50, 100],
      onFirstRequest: true,
      orderBy: this.defaultOrderBy,
    };
  },
  computed: {
    disablePrev() {
      return this.page === 1;
    },

    disableNext() {
      return this.page === this.pagination.last_page;
    },
  },
  created() {
    // Simulate table height for the loader to be visible.
    this.resources = this.getPlaceholderResources();
    this.$_request = null;
    this.fetchResources();
  },
  watch: {
    page() {
      this.debouncedFetchResources();
    },

    perPage() {
      this.debouncedFetchResources();
    },
    filters: {
      handler() {
        this.debouncedFetchResources();
      },
      deep: true,
    },
  },
  methods: {
    debouncedFetchResources: debounce(function () {
      this.fetchResources();
    }, 200),

    async fetchResources(noCache = false) {
      // Cancel previous request.
      if (this.$_request) {
        this.$_request.cancel();
      }

      this.loading = true;

      this.$_request = this.$axios.CancelToken.source();

      try {
        this.request = this.getRequest();

        if (noCache) {
          this.request.withHeaders({ 'Cache-Control': 'no-cache' });
        }

        let response = await this.request.getWithCancelToken(
          this.$_request.token
        );

        this.resources = response.data;
        this.fields = response.meta.header;
        this.pagination.from = response.meta.from;
        this.pagination.to = response.meta.to;
        this.pagination.last_page = response.meta.last_page;
        this.pagination.total = response.meta.total;
        this.$_request = null;
        this.onFirstRequest = false;
      } catch (e) {
        if (this.$axios.isCancel(e)) {
          return;
        }

        this.$axios.handleError(e);
      } finally {
        this.loading = false;
        this.initialLoading = false;
      }
    },

    getRequest(additionalParams = {}) {
      return this.getBaseQuery()
        .applyFilters(this.filters)
        .page(this.page)
        .params({
          per_page: this.perPage,
          ...this.queryCustomParams,
          ...additionalParams,
        })
        .orderBy(this.orderBy);
    },

    async onSort(direction, property) {
      let newOrderBy = direction === 'desc' ? `-${property}` : property;

      if (newOrderBy !== this.orderBy) {
        this.orderBy = newOrderBy;
        this.debouncedFetchResources();
      }
    },

    async onChangePage() {
      this.fetchResources();
    },

    getPlaceholderResources() {
      let items = [];
      let i = 0;
      for (i = 0; i < this.perPage; i++) {
        items.push({ id: i });
      }

      return items;
    },

    onPrev() {
      if (this.disablePrev) {
        return;
      }

      this.page--;
    },
    onNext() {
      if (this.disableNext) {
        return;
      }

      this.page++;
    },
  },
};
</script>
<style lang="scss">
.datatable-wrapper {
  &__top-bar {
    display: flex;
    margin-bottom: 16px;

    > .search-input {
    }

    &__actions {
      padding-left: $s-4;
      flex: 1 0 auto;
      margin-left: auto;
      display: flex;
      justify-content: flex-end;
    }
  }
}

.datatable {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  .table {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom: none;

    tbody tr:last-child {
      border-bottom: 0;
    }
  }

  &__footer {
    background-color: $white;
    padding: 12px 24px;
    border: 1px solid $border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    overflow: hidden;

    font-size: 12px;
    line-height: 20px;
    color: rgba($black, 0.5);

    > div {
      margin-left: 32px;
    }

    &__loader {
      @include component-placeholder();
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &__per-page {
    display: flex;
    align-items: center;

    span {
      display: inline-block;
      margin-right: 6px;
    }

    select {
      width: auto;
      border-radius: $border-radius;
      font-size: 12px;
      line-height: 20px;
      color: $black;
      opacity: 0.5;
      padding: 6px 8px;
      height: 32px;
      padding-right: 20px;
      background-size: 16px auto, 100%;
      background-position: right 6px top 50%, 0 0;
    }
  }

  &__from-to {
    display: inline-block;
  }

  &__nav {
    svg {
      width: 24px;
      height: 24px;
    }

    a {
      color: $black;
      opacity: 0.5;
      transition: color 0.15s ease-out, opacity 0.15s ease-out;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.1;
      }
    }

    @at-root {
      .no-touchevents {
        .datatable__nav a:not(.disabled):hover {
          opacity: 1;
          color: $primary;
        }
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .datatable {
    &__footer {
      padding: 12px 10px;

      > div {
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }

    &__per-page {
      margin-right: auto;
    }
  }
}

@include media-breakpoint-down(xxs) {
  .datatable {
    &__per-page {
      span {
        display: none;
      }
    }
  }
}
</style>
