<template>
  <div class="tabs__pane" v-show="isActive">
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    name: { required: true },
    tooltip: { type: String, required: false },
  },

  data() {
    return {
      isActive: false,
    };
  },

  computed: {
    href() {
      return '#' + this.name.toLowerCase().replace(/ /g, '-');
    },
  },
};
</script>
