<template>
  <div class="multi-uploader">
    <div
      class="droparea"
      @dragover.prevent.stop="handleDragOver"
      @dragleave.prevent.stop="handleDragLeave"
      @drop.prevent.stop="handleDrop"
      @click="onClickUpload"
    >
      <a href="#" class="btn btn--secondary" @click.prevent="() => {}"
        >Browse files</a
      >
      <span class="droparea__info">or drag them in</span>
      <div v-if="showProgressBar" class="multi-uploader__state">
        <span>Uploading...</span>
        <div v-if="true" class="multi-uploader__progress">
          <div
            class="multi-uploader__progress__bar"
            :style="{ width: `${totalProgress}%` }"
          ></div>
        </div>
      </div>
    </div>
    <input ref="file" type="file" :multiple="true" @change="onInputChange" />
  </div>
</template>
<script>
import getDroppedFiles from '@uppy/utils/lib/getDroppedFiles';
import LoadingBtn from '~/components/LoadingBtn';
import log from '~/log';
import { generateUniqueId } from '~/utils';
import InitializeUppyMixin from '~/components/upload/InitializeUppyMixin';
import UploadProgressStates from '~/components/upload/UploadProgressStates';

export default {
  mixins: [InitializeUppyMixin],
  components: { LoadingBtn },
  props: {
    for: {
      type: String,
      required: true,
    },
    collection: {
      type: String,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    limit: {
      type: Number,
      required: true,
    },
    allowedFileTypes: {
      type: Array,
      required: false,
      default: () => ['image/jpeg'],
    },
  },
  data() {
    return {
      uniqueId: generateUniqueId(),
      isDraggingOver: false,
      removeDragOverClassTimeout: null,
      uploading: false,
      UploadProgressStates: UploadProgressStates,
    };
  },

  computed: {
    uppy() {
      if (this.loadingUppy) {
        return null;
      }

      return this.$store.state[`uppy-${this.uniqueId}`].uppy;
    },
    files() {
      return this.uppy?.files ?? {};
    },
    filesArray() {
      return Object.keys(this.files).map((file) => this.files[file]);
    },
    filesToShow() {
      return this.filesArray.filter((file) => !file.progress.uploadComplete);
    },
    erroredFiles() {
      return this.filesArray.filter((file) => file.error);
    },
    completeFiles() {
      return this.filesArray.filter((file) => file.progress.uploadComplete);
    },
    processingFiles() {
      return this.filesArray.filter(
        (file) => file.progress.preprocess || file.progress.postprocess
      );
    },
    totalProgress() {
      // Show a 10 percent min as we don't have chunked uploads implemented yet.
      return Math.max(this.uppy?.totalProgress, 10);
    },
    error() {
      return this.uppy?.error;
    },
    isAllErrored() {
      return this.error && this.erroredFiles.length === this.filesArray.length;
    },
    isAllComplete() {
      return (
        this.totalProgress === 100 &&
        this.completeFiles.length === Object.keys(this.files).length &&
        this.processingFiles.length === 0
      );
    },
    uploadState() {
      return this.getUploadingState(
        this.isAllErrored,
        this.isAllComplete,
        this.files
      );
    },
    showProgressBar() {
      return (
        this.uploadState === UploadProgressStates.STATE_PREPROCESSING ||
        this.uploadState === UploadProgressStates.STATE_UPLOADING ||
        this.uploadState === UploadProgressStates.STATE_POSTPROCESSING
      );
    },
  },

  async mounted() {
    this.$_uppy = await this.initUppy({ id: this.uniqueId });
  },

  methods: {
    async handleDrop(event) {
      clearTimeout(this.removeDragOverClassTimeout);

      this.isDraggingOver = false;

      const logDropError = (error) => {
        log(error);
      };
      let files = await getDroppedFiles(event.dataTransfer, {
        logDropError,
      });

      this.addFiles(files);
    },
    handleDragOver(event) {
      // 1. Add a small (+) icon on drop
      // (and prevent browsers from interpreting this as files being _moved_ into the browser, https://github.com/transloadit/uppy/issues/1978)
      event.dataTransfer.dropEffect = 'copy';

      clearTimeout(this.removeDragOverClassTimeout);
      this.isDraggingOver = true;
    },

    handleDragLeave() {
      clearTimeout(this.removeDragOverClassTimeout);
      // Timeout against flickering, this solution is taken from drag-drop library. Solution with 'pointer-events: none' didn't work across browsers.
      this.removeDragOverClassTimeout = setTimeout(() => {
        this.isDraggingOver = false;
      }, 50);
    },

    onInputChange(event) {
      const files = Array.from(event.target.files);
      this.addFiles(files);

      // We clear the input after a file is selected, because otherwise
      // change event is not fired in Chrome and Safari when a file
      // with the same name is selected.
      // ___Why not use value="" on <input/> instead?
      //    Because if we use that method of clearing the input,
      //    Chrome will not trigger change if we drop the same file twice (Issue #768).
      event.target.value = null;
    },

    onClickUpload() {
      this.$refs.file.click();
    },

    async addFiles(files) {
      let remaining = this.limit - this.count;
      if (files.length > remaining) {
        this.$toasted.error(`You can add a max of ${this.limit} images.`);
      }

      // We can't upload any more images.
      if (remaining <= 0) {
        return;
      }

      // Cap at remaining. i.e. if we have 3 left and the user uploads 6, we get the first 3.
      files = files.splice(0, remaining);

      const descriptors = files.map((file) => ({
        name: file.name,
        type: file.type,
        data: file,
        meta: {
          for: this.for,
          collection: this.collection,
        },
      }));

      try {
        await this.$_uppy.addFiles(descriptors);
        await this.$_uppy.upload();
      } catch (err) {
        this.$axios.handleError(err);
      }
    },

    getUploadingState(isAllErrored, isAllComplete, files) {
      if (isAllErrored) {
        return UploadProgressStates.STATE_ERROR;
      }

      if (isAllComplete) {
        return UploadProgressStates.STATE_COMPLETE;
      }

      let state = UploadProgressStates.STATE_WAITING;
      const fileIDs = Object.keys(files);
      for (let i = 0; i < fileIDs.length; i++) {
        const progress = files[fileIDs[i]].progress;
        // If ANY files are being uploaded right now, show the uploading state.
        if (progress.uploadStarted && !progress.uploadComplete) {
          return UploadProgressStates.STATE_UPLOADING;
        }
        // If files are being preprocessed AND postprocessed at this time, we show the
        // preprocess state. If any files are being uploaded we show uploading.
        if (
          progress.preprocess &&
          state !== UploadProgressStates.STATE_UPLOADING
        ) {
          state = UploadProgressStates.STATE_PREPROCESSING;
        }
        // If NO files are being preprocessed or uploaded right now, but some files are
        // being postprocessed, show the postprocess state.
        if (
          progress.postprocess &&
          state !== UploadProgressStates.STATE_UPLOADING &&
          state !== UploadProgressStates.STATE_PREPROCESSING
        ) {
          state = UploadProgressStates.STATE_POSTPROCESSING;
        }
      }
      return state;
    },
  },
};
</script>
<style lang="scss">
.multi-uploader {
  position: relative;

  &__state {
    position: absolute;
    bottom: 0;
    width: 100%;

    > span {
      font-size: 12px;
      color: rgba($black, 0.5);
      padding: $s-2;
      display: inline-block;
    }
  }

  &__progress {
    position: relative;
    background-color: $gray-300;
    height: 20px;
    width: 100%;

    &__bar {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0;
      background-color: rgba($green-500, 0.5);
      transition: transform 0.1s;
    }
  }

  input[type='file'] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }
}
.droparea {
  border: 1px dashed $gray-500;
  min-height: 192px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__info {
    font-size: 12px;
    padding-top: $s-4;
    color: rgba($black, 0.5);
  }
}
</style>
