var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VSelect", {
    class: { "filter-select": _vm.filterSelect },
    attrs: {
      name: "Category",
      "value-key": "id",
      "label-key": "name",
      options: _vm.options,
      "empty-value-label": "All categories",
      "no-label": _vm.noLabel
    },
    model: {
      value: _vm.currentValue,
      callback: function($$v) {
        _vm.currentValue = $$v
      },
      expression: "currentValue"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }