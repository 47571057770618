var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard-header" }, [
    _c(
      "a",
      {
        staticClass: "dashboard-header__logo",
        attrs: { href: _vm.route("dashboard") }
      },
      [_c("Logo")],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "dashboard-header__drawer-toggle",
        attrs: { href: "#" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.toggle($event)
          }
        }
      },
      [
        _c(
          "svg",
          {
            attrs: { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 40 40" }
          },
          [
            _c("path", {
              attrs: {
                d:
                  "M7.02 28.74v1h25.96v-1zm0-9.36v1h25.96v-1zm0-9.36v1h25.96v-1z",
                fill: "#1c1d1d"
              }
            })
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }