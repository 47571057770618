var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.hidePrev || !_vm.hideNext
    ? _c("div", { staticClass: "simple-pagination" }, [
        _c(
          "a",
          {
            staticClass: "simple-pagination__arrow",
            class: { "simple-pagination__arrow--disabled": _vm.hidePrev },
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onPrev($event)
              }
            }
          },
          [_c("IconChevronLeft", { attrs: { width: "30", height: "30" } })],
          1
        ),
        _vm._v(" "),
        _c("span", [_vm._v("Page " + _vm._s(_vm.page))]),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "simple-pagination__arrow",
            class: { "simple-pagination__arrow--disabled": _vm.hideNext },
            attrs: { href: "#" },
            on: {
              click: function($event) {
                $event.preventDefault()
                return _vm.onNext($event)
              }
            }
          },
          [_c("IconChevronRight", { attrs: { width: "30", height: "30" } })],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }