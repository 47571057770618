var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M22.707 12.293l-11-11A1.002 1.002 0 0011 1H2a1 1 0 00-1 1v9c0 .265.105.52.293.707l11 11a.997.997 0 001.414 0l9-9a.999.999 0 000-1.414zM7 9a2 2 0 11-.001-3.999A2 2 0 017 9z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }