<script>
import UppyVuexStore from '~/components/upload/UppyVuexStore';

const getUppy = () => import(/* webpackChunkName: 'uppy-core' */ '@uppy/core');
const getUppyXhr = () =>
  import(/* webpackChunkName: 'uppy-xhr' */ '@uppy/xhr-upload');
import TempMedia from '~/models/TempMedia';
import log from '~/log';

export default {
  data() {
    return {
      loadingUppy: true,
    };
  },
  created() {
    this.$_uppy = null;
  },
  methods: {
    async initUppy({ id }) {
      try {
        let Uppy = await getUppy();
        Uppy = Uppy.default || Uppy;

        let XHRUpload = await getUppyXhr();
        XHRUpload = XHRUpload.default || XHRUpload;

        let uppy = new Uppy({
          id,
          autoProceed: false,
          allowMultipleUploads: true,
          restrictions: {
            maxFileSize: 20000000, // 20 MB
            allowedFileTypes: this.allowedFileTypes,
          },
          store: new UppyVuexStore({
            id: id,
            store: this.$store,
          }),
        });

        uppy.use(XHRUpload, {
          endpoint: new TempMedia().endpoint(),
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            ...this.$axios.getCsrfTokenHeader(),
          },
          fieldName: 'file',
          withCredentials: true,
        });

        uppy.on('upload-success', (file, response) => {
          this.$emit('upload-success', file, response);
        });

        uppy.on('upload-error', (file, error, response) => {
          this.$toasted.error(file.error);
          this.$emit('upload-error', file, error, response);
        });

        return uppy;
      } catch (e) {
        log(e);
      } finally {
        this.loadingUppy = false;
      }
    },
  },

  beforeDestroy() {
    this.$_uppy.store.cleanUp();
  },
};
</script>
