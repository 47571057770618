var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          var invalid = ref.invalid
          return [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.submit)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("SingleUploader", {
                      attrs: {
                        media: _vm.form.avatar,
                        for: _vm.MediaModelEnum.USER,
                        collection: _vm.MediaCollectionEnum.AVATAR
                      },
                      on: { "upload-success": _vm.onAvatarChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "preview",
                            fn: function(ref) {
                              var media = ref.media
                              return [
                                _c("UserAvatar", {
                                  attrs: {
                                    avatar: media,
                                    size: 64,
                                    colour: _vm.user.avatar_colour
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "text",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "single-uploader__title" },
                                  [_vm._v("Change avatar")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "single-uploader__subtitle" },
                                  [_vm._v("400x400px jpeg with max size 20MB.")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c("VInput", {
                  attrs: { name: "Name", vid: "name", rules: "required" },
                  model: {
                    value: _vm.form.name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "name", $$v)
                    },
                    expression: "form.name"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    value: _vm.user.email,
                    name: "Email",
                    vid: "email",
                    disabled: true
                  }
                }),
                _vm._v(" "),
                _c(
                  "LoadingBtn",
                  {
                    staticClass: "btn",
                    attrs: {
                      loading: _vm.loading,
                      disabled: invalid,
                      type: "submit"
                    }
                  },
                  [_vm._v("Save\n    ")]
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }