<template>
  <div class="search-by-marketplace-id">
    <h6 class="search-by-marketplace-id__label">Enter your Marketplace ID</h6>
    <div class="search-by-marketplace-id__input">
      <input
        v-for="(v, index) in values"
        type="tel"
        pattern="[0-9]"
        :key="index"
        :data-id="index"
        :value="v"
        ref="input"
        @input="onValueChange"
        @focus="onFocus"
        @keydown="onKeyDown"
        @paste="($event) => onPaste($event, index)"
        maxlength="1"
      />
    </div>
    <div
      class="search-by-marketplace-id__result-wrapper"
      :style="{ opacity: loading === null ? 0 : 1 }"
    >
      <Loader v-if="loading" />
      <template v-else>
        <div v-if="partner" class="search-by-marketplace-id__result">
          <UserLogo
            :logo="partner.logo"
            :colour="partner.logo_colour"
            :business-name="partner.business_name"
            :size="48"
          />
          <h5>{{ partner.business_name }}</h5>
          <a :href="theUrl" class="btn btn--accent-green">Go!</a>
        </div>
        <div
          v-else-if="!validVal"
          class="search-by-marketplace-id__result__not-found"
        >
          <span>Enter a 5 digit code.</span>
        </div>
        <div v-else class="search-by-marketplace-id__result__not-found">
          <span>Not found.</span>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import Loader from '~/components/Loader';
import Partner from '~/models/Partner';
import UserLogo from '~/components/UserLogo';
import { debounce } from '~/utils';

const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40,
};

export default {
  components: {
    Loader,
    UserLogo,
  },
  data() {
    const fields = 5;

    return {
      fields,
      values: Array(fields).fill(''),
      loading: null,
      partner: null,
    };
  },
  computed: {
    val() {
      if (!this.values.length) {
        return null;
      }

      return parseInt(this.values.join(''));
    },
    validVal() {
      return !isNaN(this.val) && this.val > 10000;
    },
    theUrl() {
      if (!this.partner) {
        return '';
      }

      return this.route('store.partner', {
        partner: this.partner.slug,
      }).toString();
    },
  },
  watch: {
    val() {
      if (this.validVal) {
        this.debouncedSearch();
      }
    },
  },
  methods: {
    debouncedSearch: debounce(function () {
      this.search();
    }, 200),

    async search() {
      this.loading = true;
      try {
        this.partner = await Partner.where('code', this.val).firstOrNull();
      } catch (e) {
        this.$axios.handleError(e);
      } finally {
        this.loading = false;
      }
    },

    onFocus(e) {
      e.target.select(e);
    },

    onValueChange(e) {
      const index = parseInt(e.target.dataset.id);
      const { fields } = this;
      e.target.value = e.target.value.replace(/[^\d]/gi, '');

      if (e.target.value === '' || !e.target.validity.valid) {
        return;
      }

      let next;
      const value = e.target.value;
      let { values } = this;
      values = Object.assign([], values);
      if (value.length > 1) {
        let nextIndex = value.length + index - 1;
        if (nextIndex >= fields) {
          nextIndex = fields - 1;
        }
        next = this.$refs.input[nextIndex];
        const split = value.split('');
        split.forEach((item, i) => {
          const cursor = index + i;
          if (cursor < fields) {
            values[cursor] = item;
          }
        });
        this.values = values;
      } else {
        next = this.$refs.input[index + 1];
        values[index] = value;
        this.values = values;
      }
      if (next) {
        const element = next;
        element.focus();
        element.select();
      }
    },
    onKeyDown(e) {
      const index = parseInt(e.target.dataset.id);
      const prevIndex = index - 1;
      const nextIndex = index + 1;
      const prev = this.$refs.input[prevIndex];
      const next = this.$refs.input[nextIndex];
      switch (e.keyCode) {
        case KEY_CODE.backspace: {
          e.preventDefault();
          const vals = [...this.values];
          if (this.values[index]) {
            vals[index] = '';
            this.values = vals;
          } else if (prev) {
            vals[prevIndex] = '';
            prev.focus();
            this.values = vals;
          }
          break;
        }
        case KEY_CODE.left:
          e.preventDefault();
          if (prev) {
            prev.focus();
          }
          break;
        case KEY_CODE.right:
          e.preventDefault();
          if (next) {
            next.focus();
          }
          break;
        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault();
          break;
        default:
          break;
      }
    },

    onPaste(e, i) {
      let paste = (e.clipboardData || window.clipboardData).getData('text');
      paste = paste.toUpperCase();

      let remaining = this.fields - i;
      let substring = paste.substr(0, remaining);
      let digits = substring.split('');

      let initialStart = i;
      for (; i < this.fields; i++) {
        this.$set(this.values, i, digits[i - initialStart]);
      }

      // Revert the last
      i = i - 1;
      this.$refs.input[i].focus();

      e.preventDefault();
    },
  },
};
</script>
<style lang="scss">
.search-by-marketplace-id {
  max-width: 312px;
  margin: 0 auto;

  &__label {
    text-align: center;
    color: $white;
    margin-bottom: $s-6;
  }

  &__input {
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      border: 1px solid rgba($white, 0.5);
      background-color: transparent;
      text-align: center;
      color: $white;
      font-size: $text-lg;
      width: 56px;
      margin: 0 4px;
      border-radius: 2px;

      &:focus {
        border: 1px solid $white;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__result-wrapper {
    /*opacity: 0;*/
    height: 80px;
    background-color: $white;
    margin-top: $s-4;
    border-radius: 2px;
    position: relative;
    padding: 0 $s-4;
    display: flex;
    align-items: center;

    .loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
  }

  &__result {
    width: 100%;
    display: flex;
    align-items: center;

    .user-logo {
      margin-right: $s-3;
    }

    h5 {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: $s-4;
    }

    .btn {
      margin-left: auto;
    }

    &__not-found {
      width: 100%;
      display: block;
      text-align: center;
    }
  }
}
</style>
