<template>
  <div class="search-input">
    <IconSearch :width="20" :height="20" />
    <input
      type="text"
      :value="value"
      @input="onInput"
      placeholder="Search..."
    />
  </div>
</template>
<script>
import IconSearch from '~/components/icons/IconSearch';
import { debounce } from '~/utils';

export default {
  components: {
    IconSearch,
  },
  props: {
    value: {
      type: String,
      required: true,
    },
  },
  methods: {
    onInput: debounce(function (e) {
      this.$emit('input', e.target.value);
    }, 300),
  },
};
</script>
<style lang="scss">
.search-input {
  position: relative;

  input {
    border-radius: $border-radius;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    padding: 10px 10px 10px 42px;
    height: 40px;
    background-color: $white;
  }

  svg {
    opacity: 0.25;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
