<template>
  <span>
    <slot :name="`cell(${field.key})`" v-bind:item="item">
      <template v-if="!cell">&nbsp;</template>
      <template v-else-if="!cell.type">
        {{ cell }}
      </template>
      <template v-else-if="cell.type === 'link'">
        <a :href="cell.value.url" target="_blank">{{ cell.value.label }}</a>
      </template>
      <template v-else-if="cell.type === 'links'">
        <template v-for="(value, index) in cell.value">
          <a :href="value.url" target="_blank">{{ value.label }}</a>
          <template v-if="index !== cell.value.length - 1">, </template>
        </template>
      </template>
      <template v-else-if="cell.type === 'invoices-links'">
        <a href="#" @click.prevent="openInvoicesModal(cell.value)">Download</a>
      </template>
      <template v-else-if="cell.type === 'stock-status-badge'">
        <StockStatusBadge :count="cell.value" />
      </template>
      <template v-else-if="cell.value">
        {{ cell.value }}
      </template>
    </slot>
  </span>
</template>
<script>
import StockStatusBadge from '~/components/StockStatusBadge';

export default {
  components: {
    StockStatusBadge,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    field: {
      type: Object,
      required: true,
    },
    cell: {
      validator: (prop) =>
        typeof prop === 'object' ||
        typeof prop === 'string' ||
        typeof prop === 'number' ||
        prop === null,
      required: true,
    },
  },
  methods: {
    openInvoicesModal(invoices) {
      this.$emit('open-invoices-modal', invoices);
    },
  },
};
</script>
