var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M22.214 8.802l-6.49-.945-2.9-5.886a.954.954 0 00-1.644 0L8.276 7.857l-6.49.945a.917.917 0 00-.51 1.565l4.697 4.585-1.108 6.475a.919.919 0 001.33.968L12 19.338l5.805 3.057a.915.915 0 001.33-.968l-1.109-6.475 4.698-4.585a.92.92 0 00-.508-1.565h-.002z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }