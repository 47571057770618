var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Products" } },
    [
      _c(
        "Tabs",
        [
          _c(
            "Tab",
            { attrs: { name: "Active", selected: "" } },
            [
              _c("ProductsTable", {
                ref: "productsTable",
                scopedSlots: _vm._u([
                  {
                    key: "row-actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.hide(item._meta)
                              }
                            }
                          },
                          [
                            _c("IconArchive", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    boundariesElement: "body",
                                    content: "Archive product"
                                  },
                                  expression:
                                    "{\n                boundariesElement: 'body',\n                content: 'Archive product',\n              }"
                                }
                              ]
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Inactive" } },
            [
              _c("ProductsTable", {
                ref: "hiddenProductsTable",
                attrs: { hidden: "" },
                scopedSlots: _vm._u([
                  {
                    key: "row-actions",
                    fn: function(ref) {
                      var item = ref.item
                      return [
                        _c(
                          "a",
                          {
                            attrs: { href: "#" },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                $event.stopPropagation()
                                return _vm.show(item._meta)
                              }
                            }
                          },
                          [
                            _c("IconUnArchive", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    boundariesElement: "body",
                                    content: "Unarchive product"
                                  },
                                  expression:
                                    "{\n                boundariesElement: 'body',\n                content: 'Unarchive product',\n              }"
                                }
                              ]
                            })
                          ],
                          1
                        )
                      ]
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }