var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 24 24",
        xmlns: "http://www.w3.org/2000/svg"
      }
    },
    [
      _c("g", { attrs: { fill: "none", "fill-rule": "evenodd" } }, [
        _c("path", {
          attrs: {
            d:
              "M15.639 14.407l-2.355 2.321a19.111 19.111 0 01-3.4-2.613 19.353 19.353 0 01-2.612-3.4L9.591 8.36a1.01 1.01 0 00.208-1.1L7.382 1.614A1.009 1.009 0 006.2 1.036L1.756 2.209A1.007 1.007 0 001 3.218a21.337 21.337 0 006.039 13.741A21.339 21.339 0 0020.784 23c.472.015.891-.3 1.01-.756l1.172-4.44a1.009 1.009 0 00-.577-1.186L16.748 14.2a1.009 1.009 0 00-1.109.207z",
            "fill-rule": "nonzero"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }