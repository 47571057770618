var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          var invalid = ref.invalid
          return [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.submit)
                  }
                }
              },
              [
                _c("VSelect", {
                  attrs: {
                    name: "Show agent dropdown?",
                    vid: "show_agent_dropdown",
                    options: _vm.yesNoOptions,
                    "label-info-text": "Available to customers on checkout"
                  },
                  model: {
                    value: _vm.form.show_agent_dropdown,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "show_agent_dropdown", $$v)
                    },
                    expression: "form.show_agent_dropdown"
                  }
                }),
                _vm._v(" "),
                _c("VPercent", {
                  attrs: {
                    name: "Default agent commission rate (%)",
                    vid: "default_agent_commission_rate",
                    rules: "decimal|max_value:100|min_value:0",
                    "info-text":
                      "Set a unique commission rate for sales by all agents"
                  },
                  model: {
                    value: _vm.defaultAgentCommissionPercentage,
                    callback: function($$v) {
                      _vm.defaultAgentCommissionPercentage = $$v
                    },
                    expression: "defaultAgentCommissionPercentage"
                  }
                }),
                _vm._v(" "),
                _vm.showHairSettings
                  ? [
                      _c("VInput", {
                        attrs: {
                          name: "Number of stores",
                          vid: "stores_count"
                        },
                        model: {
                          value: _vm.form.stores_count,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "stores_count", $$v)
                          },
                          expression: "form.stores_count"
                        }
                      }),
                      _vm._v(" "),
                      _c("VInput", {
                        attrs: {
                          name: "Number of sections",
                          vid: "sections_count"
                        },
                        model: {
                          value: _vm.form.sections_count,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "sections_count", $$v)
                          },
                          expression: "form.sections_count"
                        }
                      }),
                      _vm._v(" "),
                      _c("VInput", {
                        attrs: {
                          name: "Number of employees",
                          vid: "employees_count"
                        },
                        model: {
                          value: _vm.form.employees_count,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "employees_count", $$v)
                          },
                          expression: "form.employees_count"
                        }
                      }),
                      _vm._v(" "),
                      _c("VSelect", {
                        attrs: {
                          name: "Front of house?",
                          vid: "is_front_of_house",
                          options: _vm.yesNoOptions,
                          placeholder: "Choose option"
                        },
                        model: {
                          value: _vm.form.is_front_of_house,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "is_front_of_house", $$v)
                          },
                          expression: "form.is_front_of_house"
                        }
                      }),
                      _vm._v(" "),
                      _c("VSelect", {
                        attrs: {
                          name: "WiFi",
                          vid: "has_wifi",
                          options: _vm.yesNoOptions,
                          placeholder: "Choose option"
                        },
                        model: {
                          value: _vm.form.has_wifi,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "has_wifi", $$v)
                          },
                          expression: "form.has_wifi"
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "LoadingBtn",
                  {
                    staticClass: "btn",
                    attrs: {
                      loading: _vm.loading,
                      disabled: invalid,
                      type: "submit"
                    }
                  },
                  [_vm._v("\n      Save\n    ")]
                )
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }