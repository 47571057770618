<template>
  <FieldWrapper
    v-slot="{ inputId, errors }"
    :rules="rules"
    :name="name"
    :label="label"
    :vid="vid"
    :label-info-text="labelInfoText"
    :disabled="disabled"
    :no-label="noLabel"
  >
    <select :id="inputId" v-model="currentValue" :name="vid">
      <option v-if="loading" value="">Loading...</option>
      <option v-else-if="emptyValueLabel" value="">{{
        emptyValueLabel
      }}</option>
      <option v-else-if="placeholder" disabled value="">{{
        placeholder
      }}</option>
      <option
        v-for="option in options"
        :key="option[valueKey]"
        :value="getValue(option)"
        >{{ option[labelKey] }}
      </option>
    </select>
  </FieldWrapper>
</template>

<script>
import InputMixin from '~/components/form/InputMixin';

export default {
  mixins: [InputMixin],
  props: {
    emptyValueLabel: {
      type: String,
      required: false,
      default: '',
    },
    valueKey: {
      type: String,
      required: false,
      default: 'value',
    },
    labelKey: {
      type: String,
      required: false,
      default: 'label',
    },
    object: {
      type: Boolean,
      required: false,
      default: false,
    },
    options: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (!this.isSame(val, this.currentValue)) {
        this.currentValue = val;
      }
    },
  },
  methods: {
    getValue(option) {
      if (this.object) {
        return option;
      }

      return option[this.valueKey];
    },

    isSame(val1, val2) {
      if (this.object) {
        return val1[this.key] === val2[this.key];
      }

      return val1 === val2;
    },
  },
};
</script>
