<template>
  <div class="dashboard-header">
    <a :href="route('dashboard')" class="dashboard-header__logo">
      <Logo />
    </a>
    <a href="#" class="dashboard-header__drawer-toggle" @click.prevent="toggle">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path
          d="M7.02 28.74v1h25.96v-1zm0-9.36v1h25.96v-1zm0-9.36v1h25.96v-1z"
          fill="#1c1d1d"
        />
      </svg>
    </a>
  </div>
</template>
<script>
import Logo from '~/components/Logo';

export default {
  components: {
    Logo,
  },
  methods: {
    toggle() {
      this.$emit('toggle-open');
    },
  },
};
</script>
<style lang="scss">
.dashboard-header {
  height: 72px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
  border-bottom: 1px solid $gray-500;

  .logo {
    max-width: 168px;
  }

  &__drawer-toggle {
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
</style>
