var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DashboardPage",
    { attrs: { title: "Settings" } },
    [
      _c(
        "SettingsWrapper",
        [
          _c(
            "Tab",
            { attrs: { name: "Contact Details" } },
            [_c("TabContactDetails")],
            1
          ),
          _vm._v(" "),
          _c("Tab", { attrs: { name: "Account" } }, [_c("TabAccount")], 1),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Change Password" } },
            [_c("TabChangePassword")],
            1
          ),
          _vm._v(" "),
          _c(
            "Tab",
            { attrs: { name: "Bank Details" } },
            [_c("TabBankDetails")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }