<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit, invalid }">
    <form class="form" @submit.prevent="handleSubmit(submit)">
      <VSelect
        v-model="form.show_agent_dropdown"
        name="Show agent dropdown?"
        vid="show_agent_dropdown"
        :options="yesNoOptions"
        label-info-text="Available to customers on checkout"
      />
      <VPercent
        v-model="defaultAgentCommissionPercentage"
        name="Default agent commission rate (%)"
        vid="default_agent_commission_rate"
        rules="decimal|max_value:100|min_value:0"
        info-text="Set a unique commission rate for sales by all agents"
      />
      <template v-if="showHairSettings">
        <VInput
          v-model="form.stores_count"
          name="Number of stores"
          vid="stores_count"
        />
        <VInput
          v-model="form.sections_count"
          name="Number of sections"
          vid="sections_count"
        />
        <VInput
          v-model="form.employees_count"
          name="Number of employees"
          vid="employees_count"
        />
        <VSelect
          v-model="form.is_front_of_house"
          name="Front of house?"
          vid="is_front_of_house"
          :options="yesNoOptions"
          placeholder="Choose option"
        />
        <VSelect
          v-model="form.has_wifi"
          name="WiFi"
          vid="has_wifi"
          :options="yesNoOptions"
          placeholder="Choose option"
        />
      </template>
      <LoadingBtn
        :loading="loading"
        :disabled="invalid"
        class="btn"
        type="submit"
      >
        Save
      </LoadingBtn>
    </form>
  </ValidationObserver>
</template>
<script>
import VSelect from '~/components/form/VSelect';
import VInput from '~/components/form/VInput';
import TabCompanyOrPartnerBase from '~/pages/dashboard/common/settings/TabCompanyOrPartnerBase';
import { fractionToPercentage, percentageToFraction } from '~/utils';
import VPercent from '~/components/form/VPercent';

export default {
  extends: TabCompanyOrPartnerBase,
  components: {
    VPercent,
    VSelect,
    VInput,
  },
  data() {
    return {
      yesNoOptions: [
        {
          label: 'Yes',
          value: true,
        },
        {
          label: 'No',
          value: false,
        },
      ],
    };
  },
  computed: {
    defaultAgentCommissionPercentage: {
      get() {
        return fractionToPercentage(
          this.form.default_agent_commission_rate || 0
        );
      },
      set(value) {
        this.form.default_agent_commission_rate = percentageToFraction(value);
      },
    },
    showHairSettings() {
      const services = ['Hairdressing', 'Barbering'];
      return this.userable.services
        .map((s) => s.name)
        .some((n) => services.includes(n));
    },
  },
  methods: {
    getForm() {
      return {
        show_agent_dropdown: this.userable.show_agent_dropdown,
        default_agent_commission_rate: this.userable
          .default_agent_commission_rate,
        stores_count: this.userable.stores_count,
        sections_count: this.userable.sections_count,
        employees_count: this.userable.employees_count,
        is_front_of_house: this.userable.is_front_of_house,
        has_wifi: this.userable.has_wifi,
      };
    },
  },
};
</script>
