<template>
  <div class="layout layout-auth">
    <div class="logo-wrapper">
      <a :href="route('home')"><Logo /></a>
    </div>
    <slot />
    <PortalTarget name="modals" multiple />
    <ConfirmModal />
  </div>
</template>
<script>
import Logo from '~/components/Logo';
import ConfirmModal from '~/components/confirm-modal/ConfirmModal';
export default {
  components: {
    Logo,
    ConfirmModal,
  },
};
</script>
<style lang="scss">
body.body-auth {
  background-color: $gray-100;
}
.layout-auth {
  .logo-wrapper {
    text-align: center;
  }

  .logo {
    width: 210px;
    margin: $s-16 auto $s-8;
    display: inline-flex;
  }
}
</style>
