<template>
  <div class="marketing-page marketing-page-terms-and-conditions">
    <div class="marketing-page__banner">
      <div class="container">
        <h2 class="marketing-page__title">{{ title }}</h2>
      </div>
    </div>
    <div class="marketing-page__error-content">
      <p>{{ subtitle }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss">
.marketing-page__error-content {
  p {
    text-align: center;
    font-size: 20px;
    margin: $s-16;
  }
}
</style>
