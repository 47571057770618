<template>
  <StorePageWrapper :type="type" :business="slug">
    <div class="store__container">
      <CompanyListing :business="business" />
    </div>
  </StorePageWrapper>
</template>
<script>
import StorePageWrapper from '~/pages/store/StorePageWrapper';
import CompanyListing from '~/pages/store/partials/CompanyListing';
import StorePageMixin from '~/pages/store/StorePageMixin';

export default {
  mixins: [StorePageMixin],
  components: {
    StorePageWrapper,
    CompanyListing,
  },
  async created() {
    try {
      this.business = await this.fetchBusiness();
    } catch (e) {
      this.$axios.handleError(e);
    }
  },
};
</script>
