var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ValidationObserver", {
    ref: "form",
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var handleSubmit = ref.handleSubmit
          var invalid = ref.invalid
          return [
            _c(
              "form",
              {
                staticClass: "form",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return handleSubmit(_vm.submit)
                  }
                }
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group" },
                  [
                    _c("SingleUploader", {
                      attrs: {
                        media: _vm.form.logo,
                        for: _vm.mediaFor,
                        collection: _vm.MediaCollectionEnum.LOGO
                      },
                      on: { "upload-success": _vm.onLogoChange },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "preview",
                            fn: function(ref) {
                              var media = ref.media
                              return [
                                _c("UserLogo", {
                                  attrs: {
                                    logo: media,
                                    colour: _vm.userable.logo_colour,
                                    "business-name": _vm.userable.business_name,
                                    size: 100
                                  }
                                })
                              ]
                            }
                          },
                          {
                            key: "text",
                            fn: function() {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "single-uploader__title" },
                                  [_vm._v("Change logo")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  { staticClass: "single-uploader__subtitle" },
                                  [_vm._v("400x400px jpeg with max size 20MB.")]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.userType === _vm.UserEnum.PARTNER
                  ? [
                      _c("VInput", {
                        attrs: {
                          value: _vm.userable.code,
                          name: "Marketplace ID",
                          readonly: "",
                          "info-text":
                            "Share this unique ID with your clients so they can easily find your store on " +
                            _vm.route("home")
                        }
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "Business Name",
                    vid: "business_name",
                    placeholder: "Your busines name...",
                    rules: "required",
                    "label-info-text": "- displayed on your marketplace profile"
                  },
                  model: {
                    value: _vm.form.business_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "business_name", $$v)
                    },
                    expression: "form.business_name"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "Business Email",
                    vid: "business_email",
                    "label-info-text":
                      "- for invoices and official communications"
                  },
                  model: {
                    value: _vm.form.business_email,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "business_email", $$v)
                    },
                    expression: "form.business_email"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: { name: "Owner Name", vid: "owner_name" },
                  model: {
                    value: _vm.form.owner_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "owner_name", $$v)
                    },
                    expression: "form.owner_name"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    label:
                      "Contact Number (with country code eg. +353861802036)",
                    name: "Contact Number",
                    vid: "contact_number"
                  },
                  model: {
                    value: _vm.form.contact_number,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "contact_number", $$v)
                    },
                    expression: "form.contact_number"
                  }
                }),
                _vm._v(" "),
                _c("AddressTypeahead", {
                  model: {
                    value: _vm.place,
                    callback: function($$v) {
                      _vm.place = $$v
                    },
                    expression: "place"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "Registered Company Name",
                    vid: "trading_name",
                    placeholder: "Enter your Registered Company Name"
                  },
                  model: {
                    value: _vm.form.trading_name,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "trading_name", $$v)
                    },
                    expression: "form.trading_name"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "Company Reg. No.",
                    vid: "reg_no",
                    placeholder: "Enter your registration..."
                  },
                  model: {
                    value: _vm.form.reg_no,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "reg_no", $$v)
                    },
                    expression: "form.reg_no"
                  }
                }),
                _vm._v(" "),
                _c("VTextarea", {
                  attrs: {
                    name: "About",
                    vid: "about",
                    "label-info-text": "- displayed on your marketplace profile"
                  },
                  model: {
                    value: _vm.form.about,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "about", $$v)
                    },
                    expression: "form.about"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "Tagline",
                    vid: "tagline",
                    placeholder: "Your company tagline",
                    "label-info-text": "- displayed on your marketplace profile"
                  },
                  model: {
                    value: _vm.form.tagline,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "tagline", $$v)
                    },
                    expression: "form.tagline"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "Website",
                    vid: "website",
                    placeholder: "http://www.mywebsite.com"
                  },
                  model: {
                    value: _vm.form.website,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "website", $$v)
                    },
                    expression: "form.website"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "Facebook Url",
                    vid: "facebook",
                    rules: "url"
                  },
                  model: {
                    value: _vm.form.facebook,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "facebook", $$v)
                    },
                    expression: "form.facebook"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: { name: "Twitter Url", vid: "twitter", rules: "url" },
                  model: {
                    value: _vm.form.twitter,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "twitter", $$v)
                    },
                    expression: "form.twitter"
                  }
                }),
                _vm._v(" "),
                _c("VInput", {
                  attrs: {
                    name: "Instagram Url",
                    vid: "instagram",
                    rules: "url"
                  },
                  model: {
                    value: _vm.form.instagram,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "instagram", $$v)
                    },
                    expression: "form.instagram"
                  }
                }),
                _vm._v(" "),
                _vm.userType === _vm.UserEnum.PARTNER
                  ? _c(
                      "div",
                      { staticClass: "form-group" },
                      [
                        _c("label", [
                          _c("span", [_vm._v("Add photos to your gallery")])
                        ]),
                        _vm._v(" "),
                        _c("MultiUploader", {
                          attrs: {
                            for: _vm.mediaFor,
                            collection: _vm.MediaCollectionEnum.IMAGES,
                            count: _vm.form.images.length,
                            limit: _vm.imagesLimit
                          },
                          on: { "upload-success": _vm.onMediaAdded }
                        }),
                        _vm._v(" "),
                        _c("MediaList", {
                          attrs: { list: _vm.form.images },
                          on: { remove: _vm.handleRemoveMedia }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "LoadingBtn",
                  {
                    staticClass: "btn",
                    attrs: {
                      loading: _vm.loading,
                      disabled: invalid,
                      type: "submit"
                    }
                  },
                  [_vm._v("Save\n    ")]
                )
              ],
              2
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }