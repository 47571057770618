<template>
  <div v-if="show" class="incomplete-profile-banner">
    <div class="incomplete-profile-banner__left">
      <span class="incomplete-profile-banner__label">Almost there</span>
      <span class="incomplete-profile-banner__action">{{ message }}</span>
    </div>
    <div class="incomplete-profile-banner__right">
      <a :href="settingsUrl" class="btn btn--transparent-white-button"
        >Complete profile</a
      >
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import UserEnum from '~/enums/UserEnum';

export default {
  computed: {
    ...mapState('auth', ['user', 'userable']),
    message() {
      let message = 'We need you to';

      if (!this.hasStripe) {
        message = `${message} connect your Stripe account,`;
      }

      if (!this.hasAddress) {
        message = `${message} add your address,`;
      }

      if (
        !this.hasContactNumber &&
        this.user.userable_type !== UserEnum.AGENT
      ) {
        message = `${message} add contact number,`;
      }

      // Remove the trailing comma.
      message = message.slice(0, -1);

      return message;
    },
    show() {
      if (this.user.userable_type === UserEnum.ADMIN) {
        return false;
      }
      
      return (
        !this.userable.has_profile_complete ||
        !this.userable.has_stripe_connected
      );
    },
    hasStripe() {
      return !!this.userable.stripe_details_submitted_at;
    },
    hasAddress() {
      return !!this.userable.geocoded_address && !!this.userable.country_id;
    },
    hasContactNumber() {
      return !!this.userable.contact_number;
    },
    settingsRoute() {
      return `${this.user.userable_type}.settings`;
    },
    settingsUrl() {
      let baseUrl = this.route(this.settingsRoute);

      if (!this.hasStripe) {
        return `${baseUrl}#bank-details`;
      }

      return `${baseUrl}#business-details`;
    },
  },
};
</script>
<style lang="scss">
.incomplete-profile-banner {
  background-color: $primary;
  padding: $s-4 $s-8;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__label {
    display: block;
    font-size: 14px;
    line-height: 20px;
    font-weight: 300;
    color: rgba($white, 0.75);
  }

  &__action {
    display: block;
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
    color: $white;
  }
}
</style>
