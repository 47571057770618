var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "datatable-wrapper" }, [
    _c(
      "div",
      { staticClass: "datatable-wrapper__top-bar" },
      [
        _c("SearchInput", {
          model: {
            value: _vm.filters.search,
            callback: function($$v) {
              _vm.$set(_vm.filters, "search", $$v)
            },
            expression: "filters.search"
          }
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "datatable-wrapper__top-bar__actions" },
          [_vm._t("actions")],
          2
        ),
        _vm._v(" "),
        _c("CreateReport", { attrs: { "get-request": _vm.getRequest } })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "datatable" },
      [
        _vm.initialLoading
          ? _c("Loader")
          : [
              _c("Table", {
                attrs: {
                  fields: _vm.fields,
                  items: _vm.resources,
                  loading: _vm.loading,
                  "active-order-by": _vm.orderBy
                },
                on: { sort: _vm.onSort },
                scopedSlots: _vm._u(
                  [
                    _vm.loading
                      ? {
                          key: "body",
                          fn: function(ref) {
                            var items = ref.items
                            return _vm._l(items, function(item) {
                              return _c("tr", { key: item.id }, [
                                _c("td", { staticClass: "table__cell" }, [
                                  _c("span", [_vm._v(" ")])
                                ])
                              ])
                            })
                          }
                        }
                      : {
                          key: "cell(actions)",
                          fn: function(ref) {
                            var item = ref.item
                            return [
                              _vm._t("cell(actions)", null, { item: item })
                            ]
                          }
                        }
                  ],
                  null,
                  true
                )
              }),
              _vm._v(" "),
              _c("div", { staticClass: "datatable__footer" }, [
                _c("div", { staticClass: "datatable__per-page" }, [
                  _c("span", [_vm._v("Rows per page:")]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.perPage,
                          expression: "perPage"
                        }
                      ],
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.perPage = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.perPageOptions, function(option) {
                      return _c("option", { domProps: { value: option } }, [
                        _vm._v(_vm._s(option) + "\n            ")
                      ])
                    }),
                    0
                  )
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "datatable__from-to" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm.pagination.from) +
                        "-" +
                        _vm._s(_vm.pagination.to) +
                        " of\n            " +
                        _vm._s(_vm.pagination.total)
                    )
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "datatable__nav" }, [
                  _c(
                    "a",
                    {
                      staticClass: "datatable__nav__prev",
                      class: { disabled: _vm.disablePrev },
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onPrev($event)
                        }
                      }
                    },
                    [
                      _c("IconChevronLeft", {
                        attrs: { width: 20, height: 20 }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "a",
                    {
                      staticClass: "datatable__nav__next",
                      class: { disabled: _vm.disableNext },
                      attrs: { href: "#" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.onNext($event)
                        }
                      }
                    },
                    [
                      _c("IconChevronRight", {
                        attrs: { width: 20, height: 20 }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.loading && _vm.onFirstRequest
                    ? _c("div", { staticClass: "datatable__footer__loader" })
                    : _vm._e()
                ])
              ])
            ]
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }